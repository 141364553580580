<ul *ngIf="!noCeus" class="list-inline font-size-1">
  <ng-container *ngFor="let ceu of CEUs">
    <li *ngIf="ceu.ceuCount > 0" class="list-inline-item mr-3" title="1 bedroom">
      <span class="fad fa-certificate text-muted mr-1"></span>
      {{ ceu.ceuCount }} {{ ceu.certificationBody.shortName }} {{ ceu.certificationBody.baseTypeAbbreviation }}
    </li>
    <li *ngIf="ceu.certificationBody.subType1Name && ceu.subType1CEUCount > 0" class="list-inline-item mr-3" title="1 bedroom">
      <span class="fad fa-certificate text-muted mr-1"></span>
      {{ ceu.subType1CEUCount }} {{ ceu.certificationBody.shortName }} {{ ceu.certificationBody.subType1Abbreviation }}
    </li>
    <li *ngIf="ceu.certificationBody.subType2Name && ceu.subType2CEUCount > 0" class="list-inline-item mr-3" title="1 bedroom">
      <span class="fad fa-certificate text-muted mr-1"></span>
      {{ ceu.subType2CEUCount }} {{ ceu.certificationBody.shortName }} {{ ceu.certificationBody.subType2Abbreviation }}
    </li>
    <li *ngIf="ceu.certificationBody.subType3Name && ceu.subType3CEUCount > 0" class="list-inline-item mr-3" title="1 bedroom">
      <span class="fad fa-certificate text-muted mr-1"></span>
      {{ ceu.subType3CEUCount }} {{ ceu.certificationBody.shortName }} {{ ceu.certificationBody.subType3Abbreviation }}
    </li>
  </ng-container>
</ul>
<ul *ngIf="noCeus" class="list-inline font-size-1">
  <li class="list-inline-item mr-3" title="1 bedroom">
    <span class="fas fa-empty-set text-muted mr-1"></span>
    No CEUs
  </li>
</ul>
