import { Injectable, OnInit, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';
import { UserAuthenticationViewModel, UserProfileViewModel, RegistrationViewModel, StringViewModel } from '../_models/generatedModels';
import { SettingsProvider } from './settingsProvider.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import Enumerable from 'linq';
import { AdManagerService, OrganizationService } from './generatedServices';
import { FAQuery } from '../_models/models';

declare var zE: any;
declare var _hsq: any;

@Directive()
@Injectable()
export class AuthenticationService implements OnInit {
  private baseUrl: string;
  public user: UserProfileViewModel;

  @Output()
  public loggedIn = new EventEmitter();

  constructor(private http: HttpClient, private settings: SettingsProvider, private adManager: AdManagerService, private router: Router) {
    this.baseUrl = settings.configuration.baseUrl;
  }

  login(username: string, password: string) {
    console.log(this.baseUrl + '/users/authenticate');
    return this.http.post<any>(this.baseUrl + '/users/authenticate', {
      email: username,
      password: password
    }).pipe(
      map((user) => {
        console.log('Logged In2');
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(this.settings.authTokenName, JSON.stringify(user));
        }
        this.fetchUserProfile().subscribe();

        return user;
      })
    );
  }

  logAttribution(organizationId: number, amount: number) {
    try {
      if (organizationId === null) {
        console.log('organizationId is null');
        return;
      }
      if (localStorage.getItem('ad-attribution-id') === null) {
        return;
      }
      var clickId = +localStorage.getItem('ad-attribution-id');
      this.adManager.logAttribution(clickId, amount, organizationId).subscribe();
    } catch (e) {
      //do nothing if fails
    }
  }

  loginOffice365(token: string) {
    console.log(this.baseUrl + '/users/completeMsSignOn');
    let model = new StringViewModel();
    model.value = token;
    return this.http.post<any>(this.baseUrl + '/users/completeMsSignOn', model).pipe(
      map((user) => {
        console.log('Logged In2');
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(this.settings.authTokenName, JSON.stringify(user));
        }
        this.fetchUserProfile().subscribe();

        return user;
      })
    );
  }

  signUp(newUser: RegistrationViewModel): Observable<UserAuthenticationViewModel> {
    return this.http.post<UserAuthenticationViewModel>(`${this.baseUrl}/users/signup`, newUser).pipe(
      map((user: UserAuthenticationViewModel) => {
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(this.settings.authTokenName, JSON.stringify(user));
        }
        this.fetchUserProfile().subscribe();
        return user;
      })
    );
  }

  refreshClaims(token: string) {
    var model = new StringViewModel();
    model.value = token;
    return this.http.get<any>(this.baseUrl + '/users/refreshToken?access_token=' + token).pipe(
      map((user) => {
        console.log('Token Refreshed');
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(this.settings.authTokenName, JSON.stringify(user));
        }
        this.fetchUserProfile().subscribe();

        return user;
      })
    );
  }

  searchIcons(model: String): Observable<any> {
    var query = new FAQuery();
    query.query = 'query { search( version: "5.12.0", query: "' + model + '", first: 5) { id, label, unicode } }';

    return this.http.post<any>('https://api.fontawesome.com/', query).pipe(map((result: any) => result));
  }

  refreshClaimsAndRefreshUser(): Promise<any> {
    var token = this.getToken();
    var model = new StringViewModel();
    model.value = token;

    return new Promise((resolve: any, reject) => {
      this.http.get<any>(this.baseUrl + '/users/refreshToken?access_token=' + token).subscribe((user) => {
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(this.settings.authTokenName, JSON.stringify(user));
        }
        this.fetchUserProfile().subscribe((result) => {
          resolve();
        });
      });
    });
  }

  getToken(): any {
    let currentUser = JSON.parse(localStorage.getItem(this.settings.authTokenName));
    if (currentUser && currentUser.token) {
      return currentUser.token;
    }
  }

  impersonateUser(user: UserAuthenticationViewModel) {
    if (user && user.token) {
      let currentUser = JSON.parse(localStorage.getItem(this.settings.authTokenName));
      localStorage.setItem('behaviorlive-impersonated-currentUser', JSON.stringify(currentUser));
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem(this.settings.authTokenName, JSON.stringify(user));
      this.fetchUserProfile().subscribe();
    }
  }

  unimpersonateUser() {
    let currentUser = JSON.parse(localStorage.getItem('behaviorlive-impersonated-currentUser'));
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem(this.settings.authTokenName, JSON.stringify(currentUser));
    localStorage.removeItem('behaviorlive-impersonated-currentUser');
    this.fetchUserProfile().subscribe();
  }

  get impersonated() {
    return localStorage.getItem('behaviorlive-impersonated-currentUser');
  }

  setToken(user: UserAuthenticationViewModel): any {
    if (user && user.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem(this.settings.authTokenName, JSON.stringify(user));
      this.fetchUserProfile().subscribe();
    }
  }

  ngOnInit() {
    this.fetchUserProfile().subscribe();
  }

  fetchUserProfile(): Observable<UserProfileViewModel> {
    return this.http.get<UserProfileViewModel>(this.baseUrl + '/users/profile').pipe(
      map((user) => {
        this.user = user;
        this.load3rdPartyTrackingData(user.firstName, user.lastName, user.email, user.id);
        this.loggedIn.emit();
        return user;
      })
    );
  }

  getUser(): UserAuthenticationViewModel {
    let user: UserAuthenticationViewModel = JSON.parse(localStorage.getItem(this.settings.authTokenName));
    if (typeof user.id === 'string') {
      user.id = parseInt(user.id)
    }

    return user;
  }

  loginFromQuerystringToken(token: any) {
    let helper = new JwtHelperService();
    var decodedToken = helper.decodeToken(token);
    var model = new UserAuthenticationViewModel();
    model.id = decodedToken['primarysid'];
    model.firstName = decodedToken['given_name'];
    model.lastName = decodedToken['family_name'];
    model.email = decodedToken['email'];
    model.superUser = decodedToken['true'];
    model.token = token;
    this.setToken(model);
  }

  getClaims() {
    let helper = new JwtHelperService();
    return helper.decodeToken(this.getUser().token);
  }

  hasAdminClaim(organizationId: number, claimName: string): boolean {
    var org = Enumerable.from(this.user.organizations).firstOrDefault((x) => x.id == organizationId);
    if (!org) {
      return false;
    }

    if (Enumerable.from(org.adminClaims).any((x) => x == claimName)) {
      return true;
    }

    return false;
  }

  hasClaim(claimName: string, value: any): boolean {
    let claims = this.getClaims();

    let matchedClaim = claims[claimName];

    if (matchedClaim instanceof Array) {
      return matchedClaim.find((x) => x == value) >= 0;
    } else {
      return matchedClaim == value;
    }
  }

  isOrganizationAdmin(companyId: number): boolean {
    return this.hasClaim('OrganizationAdmin', companyId);
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem(this.settings.authTokenName)) {
      return true;
    } else {
      return false;
    }
  }

  userBelongsToOrganization(orgId: any, isPublic: boolean): boolean {
    if (isPublic) {
      return true;
    }

    if (!this.isLoggedIn()) {
      return false;
    }
    if (!this.user) {
      return false;
    }

    if (this.user.organizations.findIndex((x) => x.id == orgId) >= 0 || this.user.organizations.findIndex((x) => x.name.toString().toLowerCase() === orgId.toString().toLowerCase()) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  load3rdPartyTrackingData(firstName: string, lastname: string, email: string, id: number) {
    if (this.impersonated) {
      return;
    }

    let name = firstName + ' ' + lastname;
    //let email = this.user.email;
    zE(function () {
      zE.identify({
        name: name,
        email: email,
        organization: 'VIP',
      });
    });

    try {
      _hsq.push([
        'identify',
        {
          email: email,
          firstname: firstName,
          lastname: lastname,
          id: id,
        },
      ]);
    } catch {}
  }

  isEmailVerified(): boolean {
    return this.user && this.user.emailVerified;
  }

  isSuperUser(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }

    return this.hasClaim('SuperUser', true.toString());
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(this.settings.authTokenName);
    this.user = null;
    let currentUrl = this.router.url;
    this.router.navigate([currentUrl]);
    //this.router.navigate(['/']);
  }
}
