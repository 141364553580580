import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { JobsService } from '../_services/generatedServices';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { JobViewModel, EmploymentType, PayInterval, EmployeeCount, StringViewModel, JobSearchResultViewModel, JobSearchParameters, JobSearchResultsViewModel, OrganizationViewModel, CompanyViewModel } from '../_models/generatedModels';
import { AuthenticationService } from '../_services/authentication.service';
import { JobSearchService } from '../_services/job-search.service';
import { Enums } from '../_models/generatedEnums';
import { ToasterService } from '../_services/toaster.service';
import { GoogleLocation } from '../_controls/google-autocomplete';
import { UUID } from "../_helpers/extensions.module";
import { DomSanitizer, Meta, SafeHtml } from "@angular/platform-browser";
import { AnalyticsService } from "../_services/analytics.service";

@Component({
  selector: 'view-job',
  templateUrl: './viewJob.component.html',
})
export class ViewJobComponent implements OnInit, OnDestroy {
  EmploymentTypeEnum = Enums.EmploymentTypeEnum;
  EmploymentType = EmploymentType;
  EmployeeCount = EmployeeCount;
  EmployeeCountEnum = Enums.EmployeeCountEnum;
  PayInterval = PayInterval;
  private routeSubscription: Subscription;
  initialized: boolean;
  public job: JobViewModel;
  routeDataSubscription: Subscription;
  fromSearch: boolean;
  fromExternal: boolean;
  confirmApply: boolean;
  externalBoard: string;
  clickSource: string;
  similarJobs: JobSearchResultViewModel[];
  jobsFromCompany: JobSearchResultsViewModel;
  authInitialized: Subscription;
  canSendMessage = false;
  messageText = '';
  similarCompanies: CompanyViewModel[];
  companyWebsiteClean: string;
  userId: number | undefined;
  clientIdentifier: string;
  Math = Math;
   structuredMetaData: SafeHtml;

  constructor(private jobData: JobsService, private sanitizer: DomSanitizer, private analyticsService: AnalyticsService, private metaService: Meta, private router: Router, private route: ActivatedRoute, public auth: AuthenticationService, private toaster: ToasterService, public searchService: JobSearchService) { }

  getCleanUrl(url: string): string {
    try {
      // Use the URL constructor to parse the URL
      const parsedUrl = new URL(url);
      // Return only the hostname
      return parsedUrl.hostname;
    } catch (error) {
      //console.error('Invalid URL:', error);
      return url; // Return the original URL if it's invalid
    }
  }

  getSafeHTML(jsonLD: {[key: string]: any}): SafeHtml {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
    // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn() && this.auth.getUser()) {
      this.userId = this.auth.getUser()?.id;
    }
    if (localStorage.getItem('avertisementGuid') === null) {
      localStorage.setItem('avertisementGuid', UUID.UUID());
    }
    this.clientIdentifier = localStorage.getItem('avertisementGuid');
    this.routeDataSubscription = this.route.data.subscribe((v) => {
      this.fromSearch = v.from_search;
      this.confirmApply = v.confirmApply;

      this.externalBoard = this.route.snapshot.queryParamMap.get("board");
      this.fromExternal = this.externalBoard ? true : false;

      this.route.paramMap.subscribe((params) => {
        const jobId = parseInt(params.get('jobId'), 10);
        let observables: Observable<any>[] = [];
        let getProgress = false;

        observables.push(this.jobData.getSingleJob(jobId));

        forkJoin(observables).subscribe((results) => {
          this.setClickSource(this.route.snapshot.queryParamMap.get("c"));

          this.job = results[0];
          this.analyticsService.setTitleAndLogPageView(this.job.title + ' at ' + this.job.company.name);
          this.metaService.updateTag({
            name: 'description',
            content: this.job.description
          });

          this.setStructuredMetaData();
          this.companyWebsiteClean = this.getCleanUrl(this.job.company.website);

          this.searchService.prevJobId = this.searchService.currJobId
          this.searchService.currJobId = this.job.id;

          //this.companyData.getSimilar(this.job.companyId, 6).subscribe((similarCompanies) => {
          // this.similarCompanies = similarCompanies;
          this.similarCompanies = [];
          // });

          let params = new JobSearchParameters();
          params.companies = [this.job.companyId];

          params.pageSize = 6;

          this.jobData.search(params).subscribe((jobsFromCompany) => {
            this.jobsFromCompany = jobsFromCompany;
          });

          this.initialized = true;

        }, (error) => {
          if (error.status) {
            switch (error.status) {
              case 404:
              case '404':
                this.router.navigate(['jobnotfound']);
                break;
              default:
                this.router.navigate(['notfound']);
            }
          }
        });

        this.jobData.getSimilarJobs(jobId, 3).subscribe((similarJobs) => {
          this.similarJobs = similarJobs;
        });
      });
    });
  }

  searchSimilarJobs() {
    this.searchService.reset();
    this.searchService.initializeParams();
    this.searchService.searchParameters.similiarJobID = this.job.id;
    this.searchService.searchQuery = this.job.title;

    this.searchService.locationModel = new GoogleLocation();
    this.searchService.locationModel.lattitude = this.job.companyLocation.lattitude;
    this.searchService.locationModel.longitude = this.job.companyLocation.longitude;
    this.searchService.locationModel.fullText = (this.job.companyLocation.city ? this.job.companyLocation.city + ", " : "") + this.job.companyLocation.state;
    this.searchService.searchParameters.radiusInMiles = 50;

    this.router.navigate(['jobs/search']);
  }

  sendRouteObject() {
    this.router.navigate(['jobs/search']);
  }


  setFavorite(jobId: number, val: boolean) {
    let subscription = val ? this.jobData.setFavorite(jobId) : this.jobData.deleteFavorite(jobId);
    subscription.subscribe((result) => { });
    this.job.favorite = val;
  }

  setFavoriteSimilarJobs(jobId: number, val: boolean) {
    let index = this.similarJobs.findIndex((x) => x.id === jobId);
    this.similarJobs[index].favorite = val;

    let subscription = val ? this.jobData.setFavorite(jobId) : this.jobData.deleteFavorite(jobId);
    subscription.subscribe((result) => { });
  }

  removeHtml(html: string) {
    return html.replace(/<[^>]*>/g, '');
  }

  setClickSource(param) {
    if (this.fromExternal) {
      this.clickSource = this.externalBoard;
    } else {
      switch (param) {
        case '1':
          this.clickSource = "CompanyClick";
          break;
        case '2':
          this.clickSource = "SimilarJobClick";
          break;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.routeDataSubscription) {
      this.routeDataSubscription.unsubscribe();
    }

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.authInitialized) {
      this.authInitialized.unsubscribe();
    }
  }

  private setStructuredMetaData() {
    var metaData = {
      "@context": "https://schema.org/",
      "@type": "JobPosting",
      "title": this.job.title,
      "description": this.job.description,
      "identifier": {
        "@type": "PropertyValue",
        "name": "BehaviorLive",
        "value": this.job.id
      },
      "datePosted": this.job.postDate,
      "employmentType": "FULL_TIME",
      "hiringOrganization": {
        "@type": "Organization",
        "name": this.job.companyName,
        "sameAs": "https://behaviorlive.com/companies/" + this.job.companyId,
        "logo": "https://behaviorlive.com/fetchimage?filename=987af5ad-89c5-47bf-ae3b-c516370cb711BehaviorLive-01-500x500.jpg"
      },
      "jobLocation": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": this.job.companyLocation.street1,
          "addressLocality": this.job.companyLocation.city,
          "addressRegion": this.job.companyLocation.state,
          "postalCode": this.job.companyLocation.postalCode,
          "addressCountry": "US"
        }
      },
      "baseSalary": {
        "@type": "MonetaryAmount",
        "currency": "USD",
        "value": {
          "@type": "QuantitativeValue",
          "value": this.job.payRateMax,
          "unitText": "YEAR"
        }
      }
    }

    this.structuredMetaData = this.getSafeHTML(metaData);


  }
}
