<div class="d-lg-flex">
  <div class="container d-lg-flex align-items-lg-center  space-top-0 space-bottom-2 space-lg-0">
    <div class="w-100 mx-auto space-5">
      <div *ngIf="!errorMessage" class="w-md-80 w-lg-50 text-center mx-md-auto">
        <!-- Title -->
        <div class="mb-5">
          <h1 class="h2 font-weight-normal">Please wait while we log you in...</h1>
        </div>
        <!-- End Title -->

        <page-load-header></page-load-header>
      </div>
      <div *ngIf="errorMessage" class="w-md-80 w-lg-50 text-center mx-md-auto">
        <div class="alert alert-danger" role="alert" innerHTML="{{ errorMessage }}"></div>

        <a class="btn btn-primary transition-3d-hover" [routerLink]="['/']">Home</a>
      </div>
    </div>
  </div>
</div>
