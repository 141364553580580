import { Component, OnInit, OnDestroy } from '@angular/core';
import { InstructorService, CourseService, EventService, OrganizationService } from '../_services/generatedServices';
import { ActivatedRoute } from '@angular/router';
import { InstructorViewModel, CourseViewModel, EventViewModel, CourseViewModelSimple } from '../_models/generatedModels';
import { Subscription, combineLatest, forkJoin } from 'rxjs';
import { AnalyticsService } from '../_services/analytics.service';

@Component({
  selector: 'bxl-view-instructor',
  templateUrl: 'view-instructor.component.html',
})
export class ViewInstructorComponent implements OnInit, OnDestroy {
  initialized: boolean;
  instructor: InstructorViewModel;
  similarInstructors: InstructorViewModel[];
  id: any;
  courses: CourseViewModelSimple[];
  events: EventViewModel[];
  organizationId: string;
  conferenceId: string;

  constructor(private route: ActivatedRoute, private analyticsService: AnalyticsService, private instructorData: InstructorService, private orgService: OrganizationService, private courseData: CourseService, private eventData: EventService) {}

  ngOnInit(): void {
    this.initialized = false;
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
      this.organizationId = params.get('organizationId');
      this.conferenceId = params.get('conferenceId');
      console.log(this.organizationId);

      let similar = this.instructorData.getSimilar(this.id, 4);
      if (this.organizationId) {
        similar = this.instructorData.getSimilarInstructorsForOrganization(this.id, this.organizationId, 6);
      }

      forkJoin(this.instructorData.getById(this.id), this.courseData.getCoursesForInstructor(this.id), similar, this.eventData.getEventsForInstructor(this.id)).subscribe((results) => {
        this.instructor = results[0];
        this.similarInstructors = results[2];
        this.analyticsService.setTitleAndLogPageView(this.instructor.fullNameWithCredentials + ' - View Instructor');

        if (this.organizationId) {
          this.orgService.getOrganization(this.organizationId).subscribe((org) => {
            this.courses = results[1].filter((x) => x.restrictToOrganizationId == org.id);
            this.events = results[3].filter((x) => x.restrictToOrganizationId == org.id);
            this.initialized = true;
          });
        } else {
          this.courses = results[1];
          this.events = results[3];
          this.initialized = true;
        }
      });
    });
  }

  ngOnDestroy(): void {
  }
}
