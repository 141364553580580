import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'bxl-login-from-email',
  templateUrl: 'login-from-email.component.html',
})
export class LoginFromEmailComponent implements OnInit {

  initialized = false;
  constructor(private router: Router, private route: ActivatedRoute, private auth: AuthenticationService) {

  }

  ngOnInit(): void {
    let token = this.route.snapshot.queryParamMap.get('token');
    console.log(token);
    let redirect = this.route.snapshot.queryParamMap.get('redirect');
    let id = this.route.snapshot.queryParamMap.get('id');
    this.auth.refreshClaims(token).subscribe(result=>{
      if (redirect){
      this.router.navigate([redirect]);
      }else{
        this.router.navigate(['/'])
      }
    });
    this.initialized = true;
  }
}
