import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routeTransitionAnimations } from '../route-transition-animations-ts.component';

@Component({
  selector: 'bxl-inductee-wrapper',
  templateUrl: 'inductee-wrapper.component.html',
  animations: [routeTransitionAnimations]
})
export class InducteeWrapperComponent implements OnInit {
  initialized = false;
  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animationState'];
   }
}
