import { Component, OnInit } from '@angular/core';
import { SettingsProvider } from '../_services/settingsProvider.service';

@Component({
  selector: 'corkyy-terms-of-service',
  templateUrl: 'terms-of-service.component.html',
})
export class TermsOfServiceComponent implements OnInit {
  initialized = false;
  constructor(public settings: SettingsProvider) {}

  ngOnInit(): void {
    this.initialized = true;
  }
}
