<page-load-header *ngIf="!initialized"></page-load-header>

<!-- ========== MAIN CONTENT ========== -->
<main id="content" *ngIf="initialized" role="main">
  <!-- Profile Section -->
  <div class="container space-2">
    <div class="row">
      <div class="col-lg-4 mb-9 mb-lg-0">
        <!-- Sidebar Info -->
        <div class="card shadow-sm p-5 mb-5">
          <div class="border-bottom text-center pb-5 mb-5">
            <!-- Avatar -->
            <div class="mb-3 mx-auto">
              <img class="img-fluid" src="/fetchimage?filename={{ company.navIconUrl }}&width=100" width="110" height="110" alt="Image Description" />
            </div>
            <!-- End Avatar -->

            <h1 class="h5">{{ company.name }}</h1>

            <!-- End Review -->

            <div class="mb-4">
              <a class="font-size-1" target="_blank" href="{{ company.website | websiteUrlPipe }}">{{ company.website | websiteUrlPipe }}</a>
            </div>

            <div class="mb-2">
              <!-- Buttons -->
              <a class="btn btn-sm btn-soft-primary transition-3d-hover" (click)="showCompanyJobs()">{{ company.jobCount }} Open Jobs</a>
              <!-- End Buttons -->
            </div>
          </div>

          <div class="border-bottom pb-5 mb-5">
            <div id="SVGCompanyStatsIcon" class="row">
              <div *ngIf="company.city && company.state" class="col-6 mb-5">
                <!-- Stats -->
                <div class="text-center">
                  <figure [inlineSVG]="'../../assets/svg/icons/icon-8.svg'" class="ie-height-48 max-width-5 mb-2 mx-auto"></figure>
                  <span class="h6 d-block font-weight-medium mb-0"> {{ company.city + ', ' + company.state }}</span>
                  <span class="d-block text-secondary font-size-1">Headquarters</span>
                </div>
                <!-- End Stats -->
              </div>

              <div *ngIf="company.yearFounded && company.yearFounded > 0" class="col-6 mb-5">
                <!-- Stats -->
                <div class="text-center">
                  <figure [inlineSVG]="'../../assets/svg/icons/icon-11.svg'" class="ie-height-48 max-width-5 mb-2 mx-auto"></figure>
                  <span class="h6 d-block font-weight-medium mb-0">{{ company.yearFounded }}</span>
                  <span class="d-block text-secondary font-size-1">Founded</span>
                </div>
                <!-- End Stats -->
              </div>

              <div *ngIf="company.employeeCount && company.employeeCount > 0" class="col-6 mb-5">
                <!-- Stats -->
                <div class="text-center">
                  <figure [inlineSVG]="'../../assets/svg/icons/icon-7.svg'" class="ie-height-48 max-width-5 mb-2 mx-auto"></figure>
                  <span class="h6 d-block font-weight-medium mb-0">{{ company.employeeCount | enumValue: EmployeeCount:EmployeeCountEnum }}</span>
                  <span class="d-block text-secondary font-size-1">Employees</span>
                </div>
                <!-- End Stats -->
              </div>

<!--              <div *ngIf="company.industry" class="col-6 mb-5">-->
<!--                &lt;!&ndash; Stats &ndash;&gt;-->
<!--                <div class="text-center">-->
<!--                  <figure [inlineSVG]="'../../assets/svg/icons/icon-13.svg'" class="ie-height-48 max-width-5 mb-2 mx-auto"></figure>-->
<!--                  <span class="h6 d-block font-weight-medium mb-0">{{ company.industry.industryName }}</span>-->
<!--                  <span class="d-block text-secondary font-size-1">Industry</span>-->
<!--                </div>-->
<!--                &lt;!&ndash; End Stats &ndash;&gt;-->
<!--              </div>-->

              <div *ngIf="company.corporateStructure" class="col-6">
                <!-- Stats -->
                <div class="text-center">
                  <figure [inlineSVG]="'../../assets/svg/icons/icon-22.svg'" class="ie-height-48 max-width-5 mb-2 mx-auto"></figure>
                  <span class="h6 d-block font-weight-medium mb-0">{{ company.corporateStructure }}</span>
                  <span class="d-block text-secondary font-size-1">Corpoate Structure</span>
                </div>
                <!-- End Stats -->
              </div>
            </div>
          </div>

          <div *ngIf="company.facebookUrl || company.twitterUrl || company.linkedInUrl" class="border-bottom pb-5 mb-5">
            <h4 class="font-size-1 font-weight-semi-bold text-uppercase mb-3">Linked accounts</h4>

            <!-- Linked Account -->
            <a *ngIf="company.linkedInUrl" class="media align-items-center mb-3" target="_blank" href="https://linkedIn.com/{{ company.linkedInUrl }}">
              <div class="u-sm-avatar mr-3">
                <img class="img-fluid" src="../../assets/img/160x160/linkedIn.png" alt="Image Description" />
              </div>
              <div class="media-body">
                <h4 class="font-size-1 text-dark mb-0">LinkedIn</h4>
                <small class="d-block text-secondary">linkedIn.com/{{ company.linkedInUrl }}</small>
              </div>
            </a>
            <!-- End Linked Account -->

            <!-- Linked Account -->
            <a *ngIf="company.twitterUrl" class="media align-items-center mb-3" target="_blank" href="https://twitter.com/{{ company.twitterUrl }}">
              <div class="u-sm-avatar mr-3">
                <img class="img-fluid" src="../../assets/img/160x160/img19.png" alt="Image Description" />
              </div>
              <div class="media-body">
                <h4 class="font-size-1 text-dark mb-0">Twitter</h4>
                <small class="d-block text-secondary">twitter.com/{{ company.twitterUrl }}</small>
              </div>
            </a>
            <!-- End Linked Account -->

            <!-- Linked Account -->
            <a *ngIf="company.facebookUrl" class="media align-items-center" target="_blank" href="https://facebook.com/{{ company.facebookUrl }}">
              <div class="u-sm-avatar mr-3">
                <img class="img-fluid" src="../../assets/img/160x160/img20.png" alt="Image Description" />
              </div>
              <div class="media-body">
                <h4 class="font-size-1 text-dark mb-0">Facebook</h4>
                <small class="d-block text-secondary">facebook.com/{{ company.facebookUrl }}</small>
              </div>
            </a>
            <!-- End Linked Account -->
          </div>

          <!-- End View More - Link -->
        </div>
        <!-- End Sidebar Info -->
      </div>

      <div class="col-lg-8">
        <div class="pl-lg-4">


          <div class="border-bottom pb-5 mb-5" [editorHTML]="company.summary"></div>


          <!-- Gallery -->
          <div class="row mx-gutters-2 mb-6 mt-5">
<!--            <div *ngFor="let image of company.images; let i = index" class="col-5 col-sm-3">-->
<!--              &lt;!&ndash; Gallery &ndash;&gt;-->
<!--              <a *ngIf="i < 4" class="js-fancybox u-media-viewer" [ngClass]="{ 'd-none d-md-block': i > 1 }" href="javascript:;" [attr.data-src]="'/fetchimage?filename=' + image.blobPath" data-fancybox="fancyboxGallery6" data-caption="Front in frames - image #01" data-speed="700" data-is-infinite="true">-->
<!--                <img class="img-fluid rounded" src="/fetchimage?filename={{ image.blobPath }}&width=160&height=160&mode=Crop" alt="Image Description" />-->

<!--                <span class="u-media-viewer__container">-->
<!--                  <span class="u-media-viewer__icon">-->
<!--                    <span class="fas fa-plus u-media-viewer__icon-inner"></span>-->
<!--                  </span>-->
<!--                </span>-->
<!--              </a>-->
<!--              <img *ngIf="i >= 4" class="js-fancybox d-none" alt="Image Description" data-fancybox="fancyboxGallery6" [attr.data-src]="'/fetchimage?filename=' + image.blobPath" data-caption="Front in frames - image #05" data-speed="700" data-is-infinite="true" />-->

<!--              &lt;!&ndash; End Gallery &ndash;&gt;-->
<!--            </div>-->
          </div>

          <ng-container *ngIf="company.benefits && company.benefits.length > 0">
            <div class="pb-5 mb-5">
              <div class="mb-4">
                <h3 class="h5">Benefits:</h3>
              </div>
              <ul class="list-unstyled mb-7">
                <li *ngFor="let benefit of company.benefits" class="py-3">
                  <div class="media">
                    <span class="btn btn-xs btn-icon btn-soft-primary rounded-circle mr-3">
                      <span class="fas fa-check btn-icon__inner"></span>
                    </span>
                    <div class="media-body text-secondary">
                      {{ benefit }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

  </div>
  <!-- End Profile Section -->
</main>
<!-- ========== END MAIN CONTENT ========== -->
