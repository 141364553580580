import { Injectable } from '@angular/core';
import { CourseService } from './generatedServices';
import { CourseSearchParameters, CourseViewModel, CourseViewModelSimple } from '../_models/generatedModels';

@Injectable()
export class OnDemandSearchService {
  courseSearchParams: CourseSearchParameters;
  loading: boolean;
  page: number;
  courses: CourseViewModelSimple[];
  resultCount: number;
  currentOrgId: number;
  public initialized = false;

  constructor(private courseData: CourseService) {}

  initialize(orgId: number): Promise<any> {
    let promise = new Promise((resolve) => {
      if (this.initialized && orgId == this.currentOrgId) {
        resolve(null);
        return;
      }

      this.courseSearchParams = new CourseSearchParameters();
      this.courseSearchParams.page = 0;

      if (orgId) {
        this.courseSearchParams.organizationId = orgId;
      }

      this.currentOrgId = orgId;

      this.loading = true;
      this.courseData.search(this.courseSearchParams).subscribe((result) => {
        this.courses = result.courses;
        this.resultCount = result.resultCount;
        this.page = result.page;
        this.initialized = true;
        this.loading = false;
        resolve(null);
      });
    });
    return promise;
  }

  public search(clearPages: boolean) {
    if (clearPages) {
      this.courseSearchParams.page = 0;
    }

    this.loading = true;
    this.courseData.search(this.courseSearchParams).subscribe((result) => {
      this.courses = result.courses;
      this.resultCount = result.resultCount;
      this.page = result.page;
      this.loading = false;
    });
  }

  public loadMore(): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      this.courseSearchParams.page = this.page + 1;
      this.courseData.search(this.courseSearchParams).subscribe(
        (result) => {
          this.page = result.page;

          result.courses.forEach((job) => {
            this.courses.push(job);
          });

          resolve(null);
        },
        (error) => reject()
      );
    });
    return promise;
  }
}

@Injectable()
export class OrganizationSearchService extends OnDemandSearchService {}
