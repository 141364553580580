import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CompanyViewModel, JobViewModel, EmployeeCount, CompanyType, UserProfileViewModel } from 'src/app/_models/generatedModels';
import { JobsService } from 'src/app/_services/generatedServices';
import { JobSearchService } from 'src/app/_services/job-search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Enums } from 'src/app/_models/generatedEnums';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'view-company-details',
  templateUrl: './viewCompany.component.html'
})
export class ViewCompanyComponent implements OnInit, AfterViewInit {
  initialized: boolean;
  public company: CompanyViewModel;
  EmployeeCount = EmployeeCount;
  EmployeeCountEnum = Enums.EmployeeCountEnum;
  CompanyType = CompanyType;
  similarCompanies: CompanyViewModel[];
  Math = Math;
  numbers: number[];

  constructor(private modalService: NgbModal, private router: Router, public auth: AuthenticationService, private toaster: ToasterService, private jobsData: JobsService, private route: ActivatedRoute, public searchService: JobSearchService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const companyId = params.get('companyId');

      this.jobsData.getCompanyById(companyId).subscribe(result => {
        this.company = result;
        this.initialized = true;
      });

      this.jobsData.getSimilarCompanies(companyId, 6).subscribe(similar => {
        this.similarCompanies = similar;
      });
    });
  }


  showCompanyJobs() {
    this.searchService.reset();
    this.searchService.initializeParams();
    this.searchService.searchParameters.singleCompanyId = this.company.id;
    this.router.navigate(['jobs/search']);
  }


  ngAfterViewInit(): void {
    (<any>$).HSCore.components.HSFancyBox.init('.js-fancybox');
  }
}
