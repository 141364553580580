<div class="container-fluid mt-5 mb-3">
  <h3 *ngIf="isModal" class="text-center mb-1">Let's check your <strong>camera</strong> & <strong>mic</strong></h3>

  <page-load-header *ngIf="!initialized"></page-load-header>

  <div *ngIf="initialized && publisher && !publisher.accessAllowed" class="alert alert-danger">Please allow access to your camera and mic so we can get you set up! You should see a prompt from your browser.</div>

  <page-load-header *ngIf="initialized && publisher && !publisher.accessAllowed"></page-load-header>

  <div [ngClass]="{ 'd-none': !initialized || !publisher || !publisher.accessAllowed }">
    <div class="row mb-1">
      <div class="col-md-6">
        <h1 class="text-center text-secondary"><i class="fas fa-video"></i></h1>
        <div class="camera-wrapper" #publisherDiv></div>
        <p class="text-center">Face Visible?</p>
      </div>

      <div class="col-md-6">
        <h1 class="text-center text-secondary"><i class="fas fa-microphone"></i></h1>
        <div class="sound-bar-contain">
          <div #soundBar class="sound-bar"></div>
        </div>
        <p class="text-center">Sound bar reacts to your voice?</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <select class="form-control" [(ngModel)]="selectedDevice.videoDeviceId" (change)="setVideoSource($event)">
          <option *ngFor="let device of videoInputs" [value]="device.deviceId">{{ device.label }}</option>
        </select>
      </div>
      <div class="col-md-6">
        <select class="form-control" [(ngModel)]="selectedDevice.audioDeviceId" (change)="setAudioSource($event)">
          <option *ngFor="let device of audioInputs" [value]="device.deviceId">{{ device.label }}</option>
        </select>
      </div>
    </div>
    <div *ngIf="isModal" class="row mt-3 mb-2">
      <div class="col text-center">
        <button type="button " class="btn btn-success mr-2" (click)="save()">Save</button>
        <button type="button" class="btn btn-outline-danger" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
