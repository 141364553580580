<ng-container *ngIf="initialized">
  <form class="form" [formGroup]="formGroup" novalidate>
    <div class="form-group mb-0">
      <label for="exampleInputEmail1">Question</label>
      <input type="text" class="form-control form-control-sm" id="exampleInputEmail1" formControlName="questionText" aria-describedby="emailHelp" />
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Answers</label>
      <div formArrayName="options" *ngFor="let option of formGroup.controls.options['controls']; let i = index">
        <div [formGroupName]="i" class="input-group mb-1">
          <input type="text" formControlName="optionText" class="form-control form-control-sm" aria-describedby="button-addon2" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary btn-sm" (click)="removeOption(i)" type="button">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="invalid-message">
            <div>Option is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mb-3">
      <button type="submit" class="btn btn-outline-dark btn-xs mr-2" (click)="addOption()">Add Answer</button>
    </div>
    <div class="d-flex justify-content-between">
      <div>
        <button type="submit" class="btn btn-success btn-xs mr-2" (click)="doneClicked()">Done</button>
        <button type="submit" *ngIf="poll.id" class="btn btn-outline-danger btn-xs" (click)="delete()">Delete</button>
        <button type="submit" *ngIf="!poll.id" class="btn btn-outline-danger btn-xs" (click)="cancel()">Cancel</button>
      </div>
      <div *ngIf="visible">
        <button type="button" disabled class="btn btn-xs btn-warning mr-1">Visible</button>
        <button type="button" (click)="visible = false" class="btn btn-xs btn-outline-warning">Hidden</button>
      </div>
      <div *ngIf="!visible">
        <button type="button" (click)="visible = true" class="btn btn-xs btn-outline-warning mr-1">Visible</button>
        <button type="button" disabled class="btn btn-xs btn-warning">Hidden</button>
      </div>
    </div>
  </form>
</ng-container>
