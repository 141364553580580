import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { takeUntil, take } from 'rxjs/operators';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class HoFTimeoutService implements OnDestroy {
  subscription: any;
  secondsDisplay: any;
  minutesDisplay: any;
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  endTime = 5;
  constructor(private titleService: Title,  private router: Router, private auth: AuthenticationService) {}

  endCount = new Subject();

  // end time in minutes
  resetTimer(endTime: number = this.endTime) {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    const interval = 1000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval)
      .pipe(take(duration))
      .subscribe(
        (value) => this.render((duration - +value) * interval),
        (err) => {},
        () => {
          this.router.navigate(['/wiba/hof']);
          //this.authService.logOutUser();
          console.log('timeout');
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);

    console.log(this.minutesDisplay + ":" + this.secondsDisplay);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }
}
