import { Component, OnInit, Input } from '@angular/core';
import { JobViewModel } from '../_models/generatedModels';

@Component({
  selector: 'job-details',
  templateUrl: 'job-details.component.html'
})
export class JobDetailsComponent implements OnInit {
  constructor() {}

  @Input() selectedJob: JobViewModel;

  ngOnInit(): void {}
}
