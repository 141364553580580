import { Component, OnInit, ViewChild, Input, NgZone, Inject } from '@angular/core';
import { UserService, EventService, CourseService } from 'src/app/_services/generatedServices';
import { StringViewModel, UserLookupViewModel, EventViewModel, CardTokenViewModel, RegistrationViewModel, CourseViewModel, MemberLookupResult } from 'src/app/_models/generatedModels';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import { MemberLookupModalComponent } from "../../conferences/registration/member-lookup-modal.component";
declare var fbq: any;
declare var gtag: any;
declare var ga: any;
declare var zE: any;

@Component({
  selector: 'bxl-registration-modal',
  templateUrl: 'registration-modal.component.html',
})
export class RegistrationModalComponent implements OnInit {
  submitComplete: Promise<{}> | undefined;
  public emailAddress: string;
  public password: string;
  public firstName: string;
  public lastName: string;
  public cardNumber: string;
  public promoCode: string;
  public promoError: string;
  public office365Auth: boolean;
  public actualPrice: number;
  public actualPriceEvent: number;
  public optIn: boolean;
  public expMo: string;
  public expYr: string;
  public cvv: string;
  public errorMessage: string;
  public stage = 1;
  linkedInUrl: string;
  public isMember: boolean;
  membershipLookupResult: MemberLookupResult;

  constructor(private userService: UserService,public modalService: NgbModal, public settings: SettingsProvider, @Inject(DOCUMENT) private document: Document, private eventData: EventService, private router: Router, private courseData: CourseService, public authService: AuthenticationService, public activeModal: NgbActiveModal, private zone: NgZone) {}
  @ViewChild('theForm', { static: true }) public userFrm: NgForm;
  @Input() event: EventViewModel;
  @Input() course: CourseViewModel;

  ngOnInit(): void {
    zE('webWidget', 'hide');

    if (this.course) {
      this.actualPrice = this.course.price;
    }

    if (this.event) {
      this.actualPriceEvent = this.event.price;
      console.log(this.actualPriceEvent);
    }

    if (this.authService.isLoggedIn()) {
      this.gotoStage4();
    }
  }

  checkMembership() {
    const modalRef = this.modalService.open(MemberLookupModalComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static'
    });
    if (this.course){
      modalRef.componentInstance.organizationName = this.course.organizationShortName;
      modalRef.componentInstance.courseEvent = this.course;

    }else{
      modalRef.componentInstance.organizationName = this.event.organizationName;
      modalRef.componentInstance.courseEvent = this.event;

    }

    modalRef.result.then(
      (result: MemberLookupResult) => {
        console.log('fetching data');
        this.membershipLookupResult = result;
        this.calculatePrice();
      },
      (reason) => {}
    );
    return;
  }

  calculatePrice() {
    this.actualPriceEvent = this.membershipLookupResult.success ? this.event?.memberPrice : this.event?.price;
    this.actualPrice = this.membershipLookupResult.success ? this.course?.memberPrice : this.course?.price;
  }

  emailEntered(): void {
    if (!this.userFrm.valid) {
      this.markAllTouched();
      return;
    }

    let model = new StringViewModel();
    model.value = this.emailAddress;
    this.submitComplete = new Promise((resetButton: any, reject) => {
      this.userService.lookupByEmail(model).subscribe(
        (result: UserLookupViewModel) => {
          if (result.office365Auth) {
            this.document.location.href = 'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=' + this.settings.office365ClientId + '&redirect_uri=' + this.settings.siteUrl + '/msoauth/complete&response_type=code&state=bl&scope=User.Read';
          } else if (result.found) {
            this.office365Auth = result.office365Auth;
            this.stage = 2;
            this.firstName = result.firstName;
            resetButton();
          } else {
            this.stage = 3;
            resetButton();
          }
        },
        (error) => resetButton()
      );
    });

    console.log('email ' + this.emailAddress);
  }

  linkedInRegistration() {}

  cleanEmail() {
    console.log('clean email');
    this.emailAddress = this.emailAddress.trim();
  }

  resetForm(): void {
    this.password = null;
    this.firstName = null;
    this.lastName = null;
    this.stage = 1;
  }

  applyPromoCodeCourse() {
    let model = new StringViewModel();
    model.value = this.promoCode;
    this.courseData.checkPromoCode(this.course.id, model).subscribe(
      (result) => {
        this.promoError = '';
        if (result.discountPercentage > 0) {
          this.actualPrice = this.course.price - this.course.price * (result.discountPercentage / 100);
        } else if (result.discountAmount > 0) {
          this.actualPrice = this.course.price - result.discountAmount;
        }
        console.log(this.course.price);
      },
      (error) => {
        this.promoError = error.error;
        this.actualPrice = this.course.price
      }
    );
  }

  applyPromoCodeEvent() {
    let model = new StringViewModel();
    model.value = this.promoCode;
    this.courseData.checkPromoCode(this.event.id, model).subscribe(
      (result) => {
        this.promoError = '';
        if (result.discountPercentage > 0) {
        this.actualPriceEvent = this.event.price - this.event.price * (result.discountPercentage / 100);
        } else if (result.discountAmount > 0) {
          this.actualPriceEvent = this.event.price - result.discountAmount;
        }
        console.log(this.event.price);
      },
      (error) => {
        this.promoError = error.error;
        this.actualPriceEvent = this.event.price;
      }
    );
  }

  forgotPassword() {
    console.log(this.emailAddress);
    let model = new StringViewModel();
    model.value = this.emailAddress;
    this.userService.initiatePasswordReset(model).subscribe((result) => {
      this.stage = 6;
    });
  }

  gotoStage4(): void {
    if (this.event == null && this.course == null) {
      this.activeModal.close();
      zE('webWidget', 'show');
      return;
    }

    if (this.event?.captureLinkedInProfile) {
      let userId = this.authService.user.id;
      let redirect_uri = this.settings.siteUrl + '/api/users/linked-in/complete-oauth';
      this.linkedInUrl = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&redirect_uri=' + redirect_uri + '&client_id=' + this.settings.linkedInClientKey + '&state=' + userId + ',' + this.event.id + '&scope=r_liteprofile%20r_emailaddress%20r_basicprofile';
    }
    console.log(this.event);
    console.log(this.course);

    if (this.event != null) {
      this.stage = 4;
    }

    if (this.course != null) {
      this.stage = 5;
    }
  }

  signUp(): void {
    this.markAllTouched();
    if (!this.userFrm.valid) {
      return;
    }

    let model = new RegistrationViewModel();
    model.email = this.emailAddress;
    model.password = this.password;
    model.firstName = this.firstName;
    model.lastName = this.lastName;

    this.submitComplete = new Promise((resetButton: any, reject) => {
      this.authService.signUp(model).subscribe(
        (result) => {
          resetButton();
          fbq('track', 'CompleteRegistration');
          gtag('event', 'conversion', {
            event_category: 'sign-up',
            event_label: 'sign-up',
            value: 0,
          });
          gtag('event', 'conversion', { send_to: 'AW-10797976216/m9T9CPbfyf8CEJiV8Jwo' });
          this.gotoStage4();
        },
        (error) => resetButton()
      );
    });
  }

  login(): void {
    this.markAllTouched();
    if (!this.userFrm.valid) {
      return;
    }

    this.submitComplete = new Promise((resetButton: any, reject) => {
      this.authService.login(this.emailAddress, this.password).subscribe(
        (user) => {
          if (user) {
            this.gotoStage4();
          } else {
            this.errorMessage = 'Invalid username or password. Please try again.';
          }
          resetButton();
        },
        (error) => {
          this.errorMessage = 'Invalid username or password. Please try again.';
          resetButton();
        }
      );
    });
  }

  close() {
    zE('webWidget', 'show');
    this.activeModal.dismiss();
  }

  markAllTouched() {
    Object.keys(this.userFrm.controls).forEach((field) => {
      // {1}
      const control = this.userFrm.control.get(field);
      console.log(control); // {2}
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true }); // {3}
    });
  }

  completeRegistration() {
    if (!this.userFrm.valid) {
      this.markAllTouched();
      return;
    }
    this.submitComplete = new Promise((resetButton: any, reject) => {
      if (this.actualPriceEvent > 0) {
        this.completePaid(resetButton);
      } else {
        this.completeFree(resetButton);
      }
    });
  }

  completeFree(resetButton) {
    let token = new CardTokenViewModel();
    token.cardToken = '';
    token.promoCode = this.promoCode;
    token.optIn = this.optIn;
    token.memberPrice = this.membershipLookupResult?.success;
    this.eventData.registerExistingUser(this.event.id, token).subscribe(
      (event) => {
        resetButton();
        this.activeModal.close(event);
        zE('webWidget', 'show');
        this.authService.logAttribution(this.event.restrictToOrganizationId, 0);
        fbq('track', 'Purchase', { value: 0.0, currency: 'USD' });
        gtag('event', 'conversion', {
          event_category: 'purchase',
          event_label: 'live-event',
          value: 0,
        });
        // ga('send', 'event', 'conversion', 'purchase', 'live-event', 0);
        gtag('event', 'conversion', {
          send_to: 'AW-10797976216/1tMrCOP-ov8CEJiV8Jwo',
          value: 0,
          currency: 'USD',
          transaction_id: '',
        });
      },
      (error) => {
        console.log("error here");
        resetButton();
        this.errorMessage = error.error[0];
      }
    );
  }

  completePaid(resetButton) {
    let card = { number: this.cardNumber, exp_month: this.expMo, exp_year: this.expYr, cvc: this.cvv };
    (<any>window).Stripe.card.createToken(card, (status: number, response: any) => {
      if (status === 200) {
        let token = new CardTokenViewModel();
        token.cardToken = response.id;
        token.promoCode = this.promoCode;
        token.optIn = this.optIn;
        token.memberPrice = this.membershipLookupResult?.success;


        this.zone.run(() => {
          this.eventData.registerExistingUser(this.event.id, token).subscribe(
            (event) => {
              this.activeModal.close(event);
              zE('webWidget', 'show');
              this.authService.logAttribution(this.event.restrictToOrganizationId, this.actualPriceEvent);

              fbq('track', 'Purchase', { value: this.actualPriceEvent, currency: 'USD' });
              gtag('event', 'conversion', {
                event_category: 'purchase',
                event_label: 'live-event',
                value: this.actualPriceEvent,
              });
              gtag('event', 'conversion', {
                send_to: 'AW-10797976216/1tMrCOP-ov8CEJiV8Jwo',
                value: this.actualPriceEvent,
                currency: 'USD',
                transaction_id: '',
              });

              resetButton();
            },
            (error) => {
              console.log("error here");
              resetButton();
              this.errorMessage = error.error[0];
            }
          );
        });
      } else {
        resetButton();
        this.errorMessage = response.error.message;
        console.error(response.error.message);
      }
    });
  }

  purchaseCourse() {
    if (!this.userFrm.valid) {
      this.markAllTouched();
      return;
    }

    this.submitComplete = new Promise((resetButton: any, reject) => {
      console.log(this.course.price);
      if (this.actualPrice > 0) {
        this.purchaseCoursePaid(resetButton);
      } else {
        this.purchaseCourseFree(resetButton);
      }
    });
  }

  purchaseCourseFree(resetButton) {
    let token = new CardTokenViewModel();
    token.cardToken = '';
    token.promoCode = this.promoCode;
    token.memberPrice = this.membershipLookupResult?.success;

    this.courseData.purchase(this.course.id, token).subscribe(
      (course) => {
        fbq('track', 'Purchase', { value: 0.0, currency: 'USD' });
        this.authService.logAttribution(this.course.restrictToOrganizationId, 0);

        gtag('event', 'conversion', {
          event_category: 'purchase',
          event_label: 'course',
          value: 0,
        });
        gtag('event', 'conversion', {
          send_to: 'AW-10797976216/1tMrCOP-ov8CEJiV8Jwo',
          value: 0,
          currency: 'USD',
          transaction_id: '',
        });
        this.activeModal.close(course);
        zE('webWidget', 'show');
      },
      (error) => resetButton()
    );
  }

  purchaseCoursePaid(resetButton) {
    let card = { number: this.cardNumber, exp_month: this.expMo, exp_year: this.expYr, cvc: this.cvv };
    (<any>window).Stripe.card.createToken(card, (status: number, response: any) => {
      if (status === 200) {
        let token = new CardTokenViewModel();
        token.cardToken = response.id;
        token.promoCode = this.promoCode;
        token.memberPrice = this.membershipLookupResult?.success;


        this.zone.run(() => {
          this.courseData.purchase(this.course.id, token).subscribe(
            (course) => {
              resetButton();
              this.activeModal.close(course);
              zE('webWidget', 'show');
              this.authService.logAttribution(this.course.restrictToOrganizationId, this.actualPrice);

              fbq('track', 'Purchase', { value: this.actualPrice, currency: 'USD' });
              gtag('event', 'conversion', {
                event_category: 'purchase',
                event_label: 'course',
                value: this.actualPrice,
              });
              gtag('event', 'conversion', {
                send_to: 'AW-10797976216/1tMrCOP-ov8CEJiV8Jwo',
                value: this.actualPrice,
                currency: 'USD',
                transaction_id: '',
              });
            },
            (error) => {
              resetButton();
              this.errorMessage = error.error[0];
            }
          );
        });
      } else {
        resetButton();
        this.errorMessage = response.error.message;
      }
    });
  }
}
