<ng-container *ngIf="course">
  <div class="row">
    <div class="col-md-12 col-lg-8 u-ver-divider u-ver-divider--none-lg mb-9 mb-md-0">
      <div class="ml-lg-2">
        <div class="mb-5 d-none d-md-block">
          <h2 class="font-weight-semi-bold">{{ course.title }}</h2>
          <h5>
            <a class="link-muted" [routerLink]="['/instructors', course.instructor.slug]">
              <span class="fas fa-chalkboard-teacher mr-1"></span>
              {{ course.instructor.fullNameWithCredentials }} <span *ngIf="course.instructor.pronouns"> ({{ course.instructor.pronouns }})</span>
            </a>
          </h5>
        </div>
        <div class="mb-5 d-block d-md-none">
          <h4 class="font-weight-semi-bold">{{ course.title }}</h4>
          <h5>
            <a class="link-muted" [routerLink]="['/instructors', course.instructor.slug]">
              <span class="fas fa-chalkboard-teacher mr-1"></span>
              {{ course.instructor.fullNameWithCredentials }} <span *ngIf="course.instructor.pronouns"> ({{ course.instructor.pronouns }})</span>
            </a>
          </h5>
        </div>

        <div class="d-block text-center d-lg-none mb-7">
          <div class="text-center mx-auto mb-3">
            <img class="img-fluid" src="/fetch-avatar?userId={{ course.instructor.id }}&width=120"  width="130" />
          </div>
          <div class="row mb-1">
            <div *ngIf="course.courseEventStatus.toString() != CourseEventStatus[CourseEventStatus.NotForSale].toString()" class="col text-center">
              <h5 *ngIf="course.price > 0">
                {{ course.price | currency: course.currencyCode:'symbol' }}
              </h5>
              <h5 *ngIf="course.price == 0">Free!</h5>
            </div>
            <div *ngIf="course.courseEventStatus.toString() == CourseEventStatus[CourseEventStatus.NotForSale].toString()" class="col text-center">
              <h5>Not for Sale</h5>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <bxl-ceu-display *ngIf="course.assessmentId" [CEUs]="course.ceus"></bxl-ceu-display>
              <h6 *ngIf="!course.assessmentId" class="align-middle text-danger">No CEUs (Yet) Missing Assessment From Speaker</h6>

            </div>
          </div>

          <button *ngIf="course.courseEventStatus.toString() != CourseEventStatus[CourseEventStatus.NotForSale].toString()" type="button btn-block" class="btn" (click)="purchase()" [course-purchase-button]="course"></button>
        </div>

        <!-- Features Section -->
        <div id="SVGmockupBg">
          <!-- Nav Classic -->
          <ul class="nav nav-classic nav-rounded nav-justified border" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a class="nav-link font-weight-medium active" id="pills-one-example1-tab" data-toggle="pill" href="#pills-one-example1" role="tab" aria-controls="pills-one-example1" aria-selected="true">
                <div class="d-md-flex justify-content-md-center align-items-md-center">
                  <figure class="ie-height-40 d-none d-md-block w-100 max-width-5 mr-3" [inlineSVG]="'../../assets/svg/icons/icon-24.svg'"></figure>
                  <span class="d-none d-md-block">Course</span>&nbsp;Info
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link font-weight-medium" id="pills-two-example1-tab" data-toggle="pill" href="#pills-two-example1" role="tab" aria-controls="pills-two-example1" aria-selected="false">
                <div class="d-md-flex justify-content-md-center align-items-md-center">
                  <figure class="ie-height-40 d-none d-md-block w-100 max-width-5 mr-3" [inlineSVG]="'../../assets/svg/icons/icon-7.svg'"></figure>
                  Reviews
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link font-weight-medium" id="pills-three-example1-tab" data-toggle="pill" href="#pills-three-example1" role="tab" aria-controls="pills-three-example1" aria-selected="false">
                <div class="d-md-flex justify-content-md-center align-items-md-center">
                  <figure class="ie-height-40 d-none d-md-block w-100 max-width-5 mr-3" [inlineSVG]="'../../assets/svg/icons/icon-22.svg'"></figure>
                  Materials
                </div>
              </a>
            </li>
          </ul>
          <!-- End Nav Classic -->

          <!-- Tab Content -->
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade pt-6 show active" id="pills-one-example1" role="tabpanel" aria-labelledby="pills-one-example1-tab">
              <img *ngIf="course.marketingSplashPath && !course.trailerAssetId" class="img-fluid" src="https://bxlimages.blob.core.windows.net/images/{{ course.marketingSplashPath }}" />
              <video class="azuremediaplayer amp-default-skin amp-big-play-centered embed-responsive embed-responsive-16by9" controls *ngIf="course.trailerAssetId" poster="https://bxlimages.blob.core.windows.net/posters/{{ course.marketingSplashPath }}" id="vplayer"></video>
              <h3 class="mt-3">Course Description</h3>
              <div innerHTML="{{ course.description }}"></div>
            </div>

            <div class="tab-pane fade pt-6" id="pills-two-example1" role="tabpanel" aria-labelledby="pills-two-example1-tab">
              <div *ngIf="reviews && reviews.length == 0" class="text-center">
                <div class="mb-5">
                  <p class="font-size-3">No Reviews Yet</p>
                </div>
              </div>
              <ng-container *ngIf="reviews && reviews.length > 0">
                <!-- Review Header -->
                <div class="d-sm-flex justify-content-sm-between align-items-sm-center border-bottom pb-3 mb-4">
                  <div class="d-flex align-items-center mb-2 mb-sm-0">
                    <h4 class="h5 font-weight-semi-bold mb-0">
                      Reviews <small class="text-muted ml-1">({{ reviews.length }})</small>
                    </h4>
                  </div>

                  <!-- Select -->
                  <select class="js-select selectpicker dropdown-select" data-width="fit" data-style="btn-soft-secondary btn-xs">
                    <option value="showCategoryByFilterSelect1" selected>Newest first</option>
                    <option value="showCategoryByFilterSelect2">Highest rated</option>
                    <option value="showCategoryByFilterSelect3">Lowest rated</option>
                  </select>
                  <!-- End Select -->
                </div>
                <!-- End Review Header -->

                <!-- Review -->

                <div *ngFor="let review of reviews" class="media">
                  <div class="u-avatar mr-3">
                    <img class="img-fluid rounded-circle"  src="https://bxlimages.blob.core.windows.net/avatar-cache/231-avatar.png" alt="Images Description" />
                  </div>
                  <div class="media-body border-bottom pb-6 mb-6">
                    <span class="h6 font-weight-semi-bold mr-2">{{ review.authorFirstName.substr(0, 1).toUpperCase() }} {{ review.authorLastName.substr(0, 1).toUpperCase() }}</span>
                    <span class="text-warning">
                      <span *ngIf="review.rating > 0" class="fas fa-star"></span>
                      <span *ngIf="review.rating > 1" class="fas fa-star"></span>
                      <span *ngIf="review.rating > 2" class="fas fa-star"></span>
                      <span *ngIf="review.rating > 3" class="fas fa-star"></span>
                      <span *ngIf="review.rating > 4" class="fas fa-star"></span>
                    </span>

                    <p>{{ review.text }}</p>

                    <!-- Reply -->

                    <!-- End Reply -->
                  </div>
                </div>
              </ng-container>
              <!-- End Review -->

              <div *ngIf="false" class="text-center">
                <a href="#">Read More Reviews <span class="fas fa-angle-right ml-1"></span></a>
              </div>
            </div>

            <div class="tab-pane fade pt-6" id="pills-three-example1" role="tabpanel" aria-labelledby="pills-three-example1-tab">
              <h4 class="h5 font-weight-semi-bold mb-3">Materials</h4>

              <div *ngIf="!course.materials || course.materials.length == 0" class="text-center">
                <div class="mb-5">
                  <p class="font-size-3">No Materials</p>
                </div>
              </div>
              <div>
                <table>
                  <tr *ngFor="let material of course.materials">
                    <td>
                      <h4>
                        <img style="width: 40px" src="../../assets/filetypes/{{ material.fileType }}.png" />
                        {{ material.description }} <span class="h6 text-muted">(Available with purchase)</span>
                      </h4>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- End Tab Content -->
        </div>
        <!-- End Features Section -->
      </div>
    </div>
    <div class="col-md-4 col-lg-4">
      <!-- App Info -->
      <div class="mr-lg-2">
        <div class="mb-7">
          <div class="text-center mx-auto mb-3">
            <img class="img-fluid" src="/fetch-avatar?userId={{ course.instructor.id }}&width=120" alt="Images Description" width="130" />
          </div>
          <div class="row mb-1">
            <div *ngIf="course.courseEventStatus.toString() != CourseEventStatus[CourseEventStatus.NotForSale].toString()" class="col text-center">
              <h5 *ngIf="course.price > 0">
                {{ course.price | currency: course.currencyCode:'symbol' }}
              </h5>
              <h5 *ngIf="course.price == 0">Free!</h5>
            </div>
            <div *ngIf="course.courseEventStatus.toString() == CourseEventStatus[CourseEventStatus.NotForSale].toString()" class="col text-center">
              <h5>Not for Sale</h5>
            </div>
          </div>

          <button *ngIf="course.courseEventStatus.toString() != CourseEventStatus[CourseEventStatus.NotForSale].toString()" type="button" class="btn btn-block" (click)="purchase()" [course-purchase-button]="course"></button>
        </div>

        <div class="mb-md-7">
          <h1 class="h6 font-weight-semi-bold">CEUs Issued upon completion</h1>
          <bxl-ceu-display *ngIf="course.assessmentId" [CEUs]="course.ceus"></bxl-ceu-display>
          <h6 *ngIf="!course.assessmentId" class="align-middle text-danger">No CEUs (Yet) Missing Assessment From Speaker</h6>
        </div>

        <div class="mb-md-7">
          <h1 class="h6 font-weight-semi-bold">Categories</h1>

          <span *ngFor="let tag of course.tags" class="d-inline-block mr-1 mb-2"
            ><a class="btn btn-xs btn-soft-secondary" [routerLink]="['/course-library', tag]">{{ tag }}</a></span
          >
        </div>

        <div *ngIf="course.coInstructors.length > 0" class="d-none d-md-block mb-7">
          <h2 class="h6 font-weight-semi-bold">Co-Instructors</h2>

          <a *ngFor="let sInstructor of course.coInstructors" class="d-flex align-items-start mb-4" [routerLink]="['/instructors', sInstructor.slug]">
            <div class="position-relative u-avatar">
              <img class="img-fluid rounded-circle" [fallback]="'fetch-avatar?userId=' + sInstructor.id" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ sInstructor.id }}-avatar.png" alt="Image Descriptions" />
            </div>

            <div class="ml-3">
              <span class="d-block text-dark">{{ sInstructor.fullName }}</span>
              <small class="d-block text-secondary">{{ sInstructor.credentials }}</small>
            </div>
          </a>
        </div>

        <div class="d-none d-md-block mb-7">
          <h3 class="h6 font-weight-semi-bold">About {{ course.instructor.firstName }}</h3>

          <p innerHTML="{{ course.instructor.biography }}"></p>
        </div>

        <div class="d-none d-md-block">
          <a class="small text-secondary" href="#"><span class="far fa-flag mr-1"></span> Report abuse</a>
        </div>
      </div>
      <!-- End App Info -->
    </div>
  </div>
</ng-container>
