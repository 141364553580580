import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SettingsProvider } from './_services/settingsProvider.service';
import { NavigationService } from './_services/navigation-service';
import { HttpParams } from '@angular/common/http';

declare var zE: any;
import { UserAuthenticationViewModel } from './_models/generatedModels';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from './_services/analytics.service';
import { AuthenticationService } from './_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'app';
  constructor(public translate: TranslateService, private router: Router, private route: ActivatedRoute, private auth: AuthenticationService, private analytics: AnalyticsService, private titleService: Title, public settings: SettingsProvider, private navService: NavigationService) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');

    var language = localStorage.getItem('language');
    if (language != null){
      translate.use(language);
    }
  }

  ngOnInit() {

    let referralToken = this.getParamValueQueryString('referral_token');
    console.log('Token' + referralToken);
    if (referralToken) {
      // TODO: if the user is logged in and the referral token doesnt exist, then create a network link
      localStorage.setItem('referralToken', referralToken);
    }

    this.route.queryParams.subscribe((params) => {
      console.log(params);
      if (params['authorize']) {
        console.log('logging in from token');
        this.auth.loginFromQuerystringToken(params['authorize']);
      }
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      console.log(evt.url);

      if (!evt.url.includes('hof')) {
        zE('webWidget', 'show');
        zE('webWidget:on', 'close', () => {
          zE('webWidget', 'show');
        });
      }

      var title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
      if (title && title.length > 0) {
        this.analytics.setTitleAndLogPageView(title);
      }

      this.setZendeskPath();

      const tree = this.router.parseUrl(this.router.url);
      if (tree.fragment) {
        setTimeout(() => {
          const element = document.querySelector('#' + tree.fragment);
          if (element) {
            element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
          }
        }, 200);

        return;
      }

      this.navService.triggerNavigationEnded();
      window.scrollTo(0, 0);
    });
  }

  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  setZendeskPath() {
    zE('webWidget', 'updatePath', {
      url: this.router.url,
      title: this.router.url,
    });
  }

  getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });

      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
}
