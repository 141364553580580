import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/generatedServices';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StringViewModel } from '../_models/generatedModels';

@Component({
  selector: 'corkyy-confirm-identity',
  templateUrl: 'confirm-email.component.html'
})
export class ConfirmEmailComponent implements OnInit {
  initialized = false;
  confirmed = false;
  errorMessage = '';
  constructor(private router: Router, private route: ActivatedRoute, public auth: AuthenticationService, private user: UserService) {}

  ngOnInit(): void {
    this.initialized = true;

  }
}
