<form>
  <div>
    <div class="text-right"><a [routerLink]="" (click)="clearFilters()">Clear Filters</a></div>
  </div>
  <div class="border-bottom pb-4 mb-4">
    <h4 class="font-size-1 font-weight-semi-bold text-uppercase mb-3">Save Search</h4>

    <input class="form-control form-control-sm" [(ngModel)]="searchService.filterName" name="filterName" required placeholder="enter a name for this search" type="text" />
    <div class="custom-control custom-switch mt-1 mb-1">
      <input type="checkbox" class="custom-control-input" [(ngModel)]="searchService.emailNotifications" name="emailNotifications" id="customSwitch1" />
      <label class="custom-control-label pt-1" for="customSwitch1">Email Results</label>
    </div>
    <button [disabled]="!searchService.filterName" type="button" (click)="saveFilter()" class="btn btn-sm btn-block btn-admin-soft-primary transition-3d-hover mt-2">Save <span *ngIf="searchService.selectedFilter">As New</span></button>
    <button [disabled]="!searchService.filterName" *ngIf="searchService.selectedFilter" type="button" (click)="updateFilter()" class="btn btn-sm btn-block btn-soft-primary transition-3d-hover mt-2">Update Filter</button>
    <button [disabled]="!searchService.filterName" *ngIf="searchService.selectedFilter" type="button" (click)="deleteFilter()" class="btn btn-sm btn-block btn-soft-danger transition-3d-hover mt-2">Delete Filter</button>
  </div>

  <div *ngIf="searchService.locationModel && searchService.locationModel.lattitude && searchService.locationModel.longitude" class="border-bottom pb-4 mb-4">
    <h4 class="font-size-1 font-weight-semi-bold text-uppercase mb-3">Distance</h4>
    <select class="form-control form-control-sm" name="radiusInMiles" (change)="changed($event)" [(ngModel)]="searchService.searchParameters.radiusInMiles">
      <option value="5">< 5 miles</option>
      <option value="10">< 10 miles</option>
      <option value="15">< 15 miles</option>
      <option value="25">< 25 miles</option>
      <option value="50">< 50 miles</option>
      <option value="100">< 100 miles</option>
    </select>

    <!-- End Range Slider -->
  </div>

  <!-- <div class="border-bottom pb-4 mb-4">
    <h4 class="font-size-1 font-weight-semi-bold text-uppercase mb-3">Salary</h4>

    <ng5-slider [(value)]="searchService.searchParameters.salaryMin" [(highValue)]="searchService.searchParameters.salaryMax" (userChangeEnd)="changed($event)" [options]="options"></ng5-slider>
  </div>

  <div class="border-bottom pb-4 mb-4">
    <h4 class="font-size-1 font-weight-semi-bold text-uppercase mb-3">Job type</h4>

    <div *ngFor="let employmentType of employmentTypes" class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-secondary mb-2">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="employment{{ employmentType.value }}" name="employment{{ employmentType.value }}" value="{{ employmentType.value }}" (change)="updateEmploymentType($event)" [checked]="searchService.searchParameters.employmentType.indexOf(employmentType.value) >= 0" />
        <label class="custom-control-label" for="employment{{ employmentType.value }}">{{ employmentType.name }}</label>
      </div>
      <small> </small>
    </div>
  </div> -->
  <!--
  <div class="border-bottom pb-4 mb-4">
    <h4 class="font-size-1 font-weight-semi-bold text-uppercase mb-3">Location</h4>

  <ng-container *ngFor="let location of locations; let idx = index">
    <div *ngIf="idx < 5" class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-secondary mb-2">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="location{{ location.value }}" name="location{{ location.value }}" value="{{ location.value }}" (change)="updateLocation($event)" [checked]="searchService.searchParameters.locations.indexOf(location.value) >= 0" />
        <label class="custom-control-label" for="location{{ location.value }}">{{ location.name }}</label>
      </div>
      <small> </small>
    </div>
  </ng-container>
  <div class="collapse" id="collapseLocation">
    <ng-container *ngFor="let location of locations; let idx = index">
      <div *ngIf="idx >= 5" class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-secondary mb-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="location{{ location.value }}" name="location{{ location.value }}" value="{{ location.value }}" (change)="updateLocation($event)" [checked]="searchService.searchParameters.locations.indexOf(location.value) >= 0" />
          <label class="custom-control-label" for="location{{ location.value }}">{{ location.name }}</label>
        </div>
        <small> </small>
      </div>
    </ng-container>
  </div>
  <a class="link link-collapse small font-size-1" data-toggle="collapse" href="#collapseLocation" role="button" aria-expanded="false" aria-controls="collapseLocation">
    <span class="link-collapse__default">View more</span>
    <span class="link-collapse__active">View less</span>
    <span class="link__icon ml-1">
      <span class="link__icon-inner">+</span>
    </span>
  </a>
  </div>

  -->

  <div class="border-bottom pb-4 mb-4">
    <h4 class="font-size-1 font-weight-semi-bold text-uppercase mb-3">Company</h4>

    <!-- Weeks Checkbox -->
    <ng-container *ngFor="let company of companies; let idx = index">
      <div *ngIf="idx < 5" class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-secondary mb-2">
        <div class="custom-control custom-checkbox">
<!--          <input type="checkbox" class="custom-control-input" id="company{{ company.value }}" name="company{{ company.value }}" value="{{ company.value }}" (change)="updateCompanies($event)" [checked]="searchService.searchParameters.companies.indexOf(company.value) >= 0" />-->
          <label class="custom-control-label" for="company{{ company.value }}">{{ company.name }}</label>
        </div>
        <small> </small>
      </div>
    </ng-container>
    <!-- End Weeks Checkbox -->
    <!-- View More - Collapse -->
    <div class="collapse" id="collapseCompany">
      <ng-container *ngFor="let company of companies; let idx = index">
        <div *ngIf="idx >= 5" class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-secondary mb-2">
          <div class="custom-control custom-checkbox">
<!--            <input type="checkbox" class="custom-control-input" id="company{{ company.value }}" name="company{{ company.value }}" value="{{ company.value }}" (change)="updateCompanies($event)" [checked]="searchService.searchParameters.companies.indexOf(company.value) >= 0" />-->
            <label class="custom-control-label" for="company{{ company.value }}">{{ company.name }}</label>
          </div>
          <small> </small>
        </div>
      </ng-container>
    </div>
    <!-- End View More - Collapse -->
    <!-- Link -->
    <a class="link link-collapse small font-size-1" data-toggle="collapse" href="#collapseCompany" role="button" aria-expanded="false" aria-controls="collapseCompany">
      <span class="link-collapse__default">View more</span>
      <span class="link-collapse__active">View less</span>
      <span class="link__icon ml-1">
        <span class="link__icon-inner">+</span>
      </span>
    </a>
    <!-- End Link -->
  </div>

  <!-- <div class="border-bottom pb-4 mb-4">
    <h4 class="font-size-1 font-weight-semi-bold text-uppercase mb-3">Experience Level</h4>
    <div *ngFor="let experienceType of experienceTypes" class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-secondary mb-2">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="experience{{ experienceType.value }}" name="experience{{ experienceType.value }}" value="{{ experienceType.value }}" (change)="updateExperience($event)" [checked]="searchService.searchParameters.experience.indexOf(experienceType.value) >= 0" />
        <label class="custom-control-label" for="experience{{ experienceType.value }}">{{ experienceType.name }}</label>
      </div>
      <small> </small>
    </div>
  </div> -->



  <button (click)="clearFilters()" type="button" class="btn btn-sm btn-block btn-soft-secondary transition-3d-hover">Clear All</button>
</form>
<div class="mt-5"></div>
<ad-display class="mt-3" [isMargin]="true"></ad-display>
