import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators, ValidationErrors, FormControl, FormArray, FormGroup, AsyncValidatorFn } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from '../_services/generatedServices';


@Injectable()
export class ValidatorHelper {
  constructor(private userService: UserService) { }

  validateEmailNotTaken(userId: number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => this.userService.isEmailDuplicate(control.value, userId || 0)),
        map((res)  => {
          return res ? { emailTaken: true } : null;
        })
      );
    };
  }

  static totalCEUValidator(): ValidatorFn {
    return (formGroup: FormGroup) => {
      console.log('running validator');

      const ceuCountControl = formGroup.get('ceuCount');
      const subType1CEUCountControl = formGroup.get('subType1CeuCount');
      const subType2CEUCountControl = formGroup.get('subType2CeuCount');
      const subType3CEUCountControl = formGroup.get('subType3CeuCount');


      var totalSubCount = 0;
      if (subType1CEUCountControl.value) {
        totalSubCount += +subType1CEUCountControl.value;
      }

      if (subType2CEUCountControl.value) {
        totalSubCount += +subType2CEUCountControl.value;
      }

      if (subType3CEUCountControl.value) {
        totalSubCount += +subType2CEUCountControl.value;
      }

      const totalCEUs = ceuCountControl.value;
      console.log(totalCEUs);
      console.log(totalSubCount);

      if (totalSubCount > totalCEUs) {

        return { totalCEUExceeded: true };
      }
      return null;
    };
  }

  static requiredNoWhitespace(control: AbstractControl): ValidationErrors | null {
    if (typeof control.value === 'string') {
      const val: string = control.value ? control.value : '';
      return val.trim().length === 0 ? { 'required': true } : null;
    } else {
      return (control.value == null || control.value.length === 0) ? { 'required': true } : null;
    }
  }

  //to be used as a FormGroup validator where fromDate and toDate are the FormControl field names to evaluate
  static dateRangeValid(fromDate: string, toDate: string) {
    return (group: FormGroup): { [key: string]: any } | null => {
      let date1 = group.controls[fromDate].value;
      let date2 = group.controls[toDate].value;

      if (date2 == null) {
        return null;
      } else if (date1 > date2) {
        return { 'dateRangeValid': true };
      }
      return null;
    }
  }

  //to be used as a FormGroup validator where control1 and control2 are the FormControl names to evaluate
  static bothOrNoneRequired(control1: string, control2: string) {
    return (group: FormGroup): { [key: string]: any } | null => {
      let controlA = group.controls[control1].value;
      let controlB = group.controls[control2].value;
      if (typeof controlA === 'string') {
        controlA = controlA.trim() == "" ? null : controlA;
      }
      if (typeof controlB === 'string') {
        controlB = controlB.trim() == "" ? null : controlB;
      }

      if ((controlA == null && controlB == null) || (controlA != null && controlB != null)) {
        return null;
      } else {
        return { 'bothOrNoneRequired': true };
      }
    }
  }

  public static uniqueBy = (field: string, caseSensitive: boolean = true): ValidatorFn => {
    return (formArray: FormArray): { [key: string]: boolean } | null => {
      const controls: AbstractControl[] = formArray.controls.filter((formGroup: FormGroup) => {
        return ValidatorHelper.isPresent(formGroup.get(field)!.value);
      });
      const uniqueObj: any = { uniqueBy: true };
      let find: boolean = false;

      if (controls.length == 1) {
        const mainControl: AbstractControl = (<FormGroup>controls[0]).get(field)!;
        let newErrors = mainControl.errors;

        if (ValidatorHelper.isPresent(newErrors)) {
          // delete uniqueBy error
          delete newErrors!['uniqueBy'];
          if (ValidatorHelper.isBlank(newErrors)) {
            // {} to undefined/null
            newErrors = null;
          }
        }
        mainControl.setErrors(newErrors);
      }

      if (controls.length > 1) {
        for (let i: number = 0; i < controls.length; i++) {
          const formGroup: FormGroup = <FormGroup>controls[i];
          const mainControl: AbstractControl = formGroup.get(field)!;
          const val: string = mainControl.value;

          const mainValue: string = (caseSensitive && typeof val === 'string') ? val.toLowerCase() : val;
          let findMatch: boolean = false;
          controls.forEach((group: FormGroup, index: number) => {
            if (i === index) {
              // Same group
              return;
            }
            const currControl: any = group.get(field);
            const tempValue: string = currControl.value;
            const currValue: string = (caseSensitive && typeof tempValue === 'string') ? tempValue.toLowerCase() : tempValue;

            if (mainValue === currValue) {
              findMatch = true;
              find = true;
            }
          });
          let newErrors: any;
          if (findMatch) {
            if (ValidatorHelper.isBlank(mainControl.errors)) {
              newErrors = uniqueObj;
            } else {
              newErrors = Object.assign(mainControl.errors, uniqueObj);
            }
          } else {
            newErrors = mainControl.errors;

            if (ValidatorHelper.isPresent(newErrors)) {
              // delete uniqueBy error
              delete newErrors['uniqueBy'];
              if (ValidatorHelper.isBlank(newErrors)) {
                // {} to undefined/null
                newErrors = null;
              }
            }
          }
          // Add specific errors based on condition
          mainControl.setErrors(newErrors);
        }

        if (find) {
          // Set errors to whole formArray
          return uniqueObj;
        }
      }

      // Clean errors
      return null;
    };
  }

  static isNil = (value: any): value is (null | undefined) => {
    return value === null || typeof (value) === 'undefined';
  };

  static isObject = (value: any): boolean => {
    return value && value.constructor === Object;
  };

  static isBlank = (value: any): boolean => {
    return ValidatorHelper.isNil(value) ||
      (ValidatorHelper.isObject(value) && Object.keys(value).length === 0) ||
      value.toString().trim() === '';
  };

  static isPresent = (value: any): boolean => {
    return !ValidatorHelper.isBlank(value);
  };
}
