<div  class="card">
  <!-- Header -->
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="h6 mb-0">{{ 'people' | translate }}</h3>

      <button *ngIf="showInput" type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <!-- End Header -->

  <!-- Body -->
  <div style="position: relative">
    <div *ngIf="loadError" style="z-index: 9999; position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-content: center; align-items: center">
      <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; opacity: 0.6; background-color: black"></div>
      <div  style="z-index: 10000">
        <div class="text-light">Failed to load active users</div>
        <div class="text-center"><button type="button" (click)="getActiveUsers()" class="btn btn-light">Try again</button></div>
      </div>
    </div>
    <div #peopleContainer class="card-body" style="">
      <ng-container *ngIf="role == EventRole.Host && activeUsers.onScreenUsers && activeUsers.onScreenUsers.length > 0">
        <p class="text-center">{{ 'invited-on-screen' | translate }}</p>
        <ul class="pl-3" style="list-style: none">
          <ng-container *ngFor="let activeUser of activeUsers.onScreenUsers">
            <li *ngIf="activeUser.connected">
              <div class="d-flex align-items-center pb-3">
                <div class="mr-auto">
                  <img class="avatar u-sm-avatar rounded-circle" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ activeUser.id }}-avatar.png"  />
                  <span class="participant-name ml-2">{{ activeUser.firstName }} {{ activeUser.lastName }} </span>
                </div>

                <!-- <span *ngIf="role == EventRole.Viewer && user.id != activeUser.id" class="participant-menu">
                  <button type="button" class="btn btn-icon text-muted  transition-3d-hover" tooltip="Invite user to private video chat" placement="top" show-delay="250">
                    <span class="fas fa-user-friends btn-icon__inner"></span>
                  </button>

                </span> -->
                <span class="participant-menu">
                  <button type="button" class="btn btn-ouline-secondary transition-3d-hover" (click)="removeUser(activeUser)" tooltip="Remove User" placement="top" show-delay="250">{{ 'remove' | translate }}</button>
                </span>
              </div>
            </li>
          </ng-container>
        </ul>
      </ng-container>

      <div style="margin: 18px 15px 0px; box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15); border-radius: 4px">
        <div class="input-group mb-5">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
          </div>
          <input type="text" class="form-control" placeholder="{{ 'search-by-name-or-email' | translate }}" [(ngModel)]="filter" (ngModelChange)="onFilterChanged($event)" aria-label="Username" aria-describedby="basic-addon1" />
        </div>
      </div>

      <div class="participant-list" style="">
        <div>
          <page-load-header *ngIf="!initialized"></page-load-header>
          <ul *ngIf="initialized">
            <ng-container *ngFor="let activeUser of activeUsers.users">
              <li *ngIf="activeUser.connected">
                <div class="d-flex align-items-center pb-3">
                  <div class="mr-auto">
                    <img class="avatar u-sm-avatar rounded-circle" style="vertical-align: top" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ activeUser.id }}-avatar.png"  />
                    <span class="participant-name ml-2">{{ activeUser.firstName }} {{ activeUser.lastName }} </span>
                    <span class="participant-name ml-6 font-size-1 text-muted d-block" style="margin-top: -15px">{{ activeUser.flag }}&nbsp;&nbsp; {{ activeUser.city }}, {{ activeUser.state }} </span>
                    <span *ngIf="auth.isSuperUser()" class="participant-name ml-6 font-size-1 text-muted d-block" style="margin-top: -5px">{{ activeUser.os }} {{ activeUser.device }} {{ activeUser.browser }} </span>
                  </div>

                  <span *ngIf="role == EventRole.Host && user.id != activeUser.id && !activeUser.allowOnScreen && showInput" class="participant-menu">
                    <button type="button" class="btn btn-icon text-muted transition-3d-hover" (click)="inviteUserOnScreen(activeUser)" tooltip="Invite user on screen" placement="top" show-delay="250">
                      <span class="fas fa-user-plus btn-icon__inner"></span>
                    </button>
                  </span>
                  <span *ngIf="user.id == activeUser.id" class="participant-menu">
                    <strong>{{ 'this-is-you' | translate }}</strong>
                  </span>
                </div>
                <div></div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="people-pages">
        <div class="left"><a *ngIf="currentPage > 0" type="button" class="text-primary cursor-pointer" (click)="goToPrevPage()">Prev</a></div>
        <div class="info">Page {{ currentPage + 1 }} of {{ activeUsers.totalPages + 1 }} ({{ activeUsers.count }} total)</div>
        <div class="right"><a *ngIf="currentPage < activeUsers.totalPages" type="button" class="text-primary cursor-pointer" (click)="goToNextPage()">Next</a></div>

        <!-- <a *ngFor="let page of pageCount; let i = index" type="button" class="text-primary cursor-pointer mr-1" (click)="goToPage(i)">{{ i + 1 }}</a> -->
      </div>
    </div>
  </div>
  <!-- End Body -->

  <!-- End Footer -->
</div>
