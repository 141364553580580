import {
  Injectable
} from "@angular/core";
import { EventOrderBy, OrganizationEventSearchParams } from "../_models/generatedModels";
import { Enums } from "../_models/generatedEnums";

@Injectable()
export class ConferenceEventSearchService {
  currentPage = 0;
  pageSize = 20;
  resultCount = 0;
  public searchParams: OrganizationEventSearchParams;
  returnElement: any;

  EventOrderBy = EventOrderBy;
  OrderByEnum = Enums.EventOrderByEnum;

  constructor() {
    this.searchParams = new OrganizationEventSearchParams();
    this.searchParams.courseEventStatus = null;
    this.searchParams.orderBy = EventOrderBy.EventDate;
    this.searchParams.roomId = null;
    this.searchParams.selectedDay = null;
    this.searchParams.isAdminSearch = true;
  }
}
