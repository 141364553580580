<div *ngIf="loadError || loading" style="z-index: 9999; position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-content: center; align-items: center">
  <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; opacity: 0.2; background-color: black"></div>
  <div *ngIf="!loading" style="z-index: 10000">
    <div class="text-light">Failed to load chat</div>
    <div class="text-center"><button type="button" (click)="reload()" class="btn btn-light">Try again</button></div>
  </div>
  <page-load-header *ngIf="loading"></page-load-header>
</div>
<ng-container *ngIf="initialized || loadError || loading">
  <div *ngIf="!hideCarat" class="chat-button-wrapper">
    <button type="button" (click)="collapseChat()" class="collapse-chat-btn btn btn-sm btn-icon btn-secondary rounded-circle transition-3d-hover mb-1">
      <span class="fa fa-caret-down"></span>
    </button>
  </div>
  <div #messageContainer (scroll)="onScroll($event)" class="live-messages">
    <ul>
      <li *ngFor="let message of exisitingMessages" class="mb-3" [ngClass]="{ 'replies chat-message': user.id != message.user.id, sent: user.id == message.user.id }">
        <img class="avatar u-sm-avatar rounded-circle" style="width: 32px; height:32px;border:.1px solid #1e2022" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ message.user.id }}-avatar.png"  />
        <span *ngIf="user.id != message.user.id">{{ message.user.firstName + ' ' + message.user.lastName }}</span>

        <p *ngIf="message.isEventLink" class="message-content">
          <bxl-chat-event-link [eventIdOrSlug]="message.eventLinkId"></bxl-chat-event-link>
        </p>
        <p *ngIf="!message.isEventLink" class="message-content" innerHTML="{{ message.message }}"></p>

        <ul class="tapbackList">
          <li class="tapbackItem" *ngFor="let tapback of message.tapBacks">
            {{ tapback.emoji }}<span class="tapbackcount">{{ tapback.users.length }}</span>
            <div class="tapback-container">
              <div class="tapback-user" *ngFor="let user of tapback.users">{{ user }}</div>
            </div>
          </li>
        </ul>
        <div *ngIf="user.id != message.user.id && event.disableChat == false" class="tapback btn-toolbar" role="toolbar">
          <div class="btn-group" role="group" aria-label="First group">
            <ng-container [ngTemplateOutlet]="tapback" [ngTemplateOutletContext]="{ message: message }"></ng-container>
          </div>
        </div>
      </li>

      <li *ngFor="let message of messages" class="mb-3" [ngClass]="{ 'replies chat-message': user.id != message.userId, sent: user.id == message.userId, 'system-message': message.systemMessage }">
        <img class="avatar u-sm-avatar rounded-circle" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ message.userId }}-avatar.png"  /><span *ngIf="user.id != message.userId">{{ message.name }}</span>
        <p *ngIf="message.eventLink" class="message-content">
          <bxl-chat-event-link [eventIdOrSlug]="message.eventLinkId"></bxl-chat-event-link>
        </p>
        <p *ngIf="!message.eventLink" class="message-content" innerHTML="{{ message.message }}">Event Link</p>
        <ul class="tapbackList">
          <li class="tapbackItem" *ngFor="let tapback of message.tapBacks">
            {{ tapback.emoji }}<span class="tapbackcount">{{ tapback.users.length }}</span>
            <div class="tapback-container">
              <div class="tapback-user" *ngFor="let user of tapback.users">{{ user }}</div>
            </div>
          </li>
        </ul>
        <div *ngIf="user.id != message.userId && event.disableChat == false" class="tapback btn-toolbar" role="toolbar">
          <div class="btn-group" role="group" aria-label="First group">
            <ng-container [ngTemplateOutlet]="tapback" [ngTemplateOutletContext]="{ message: message }"></ng-container>
          </div>
        </div>
      </li>
      <li style="line-height: 0">&nbsp;</li>
    </ul>
    <p *ngIf="messages.length + exisitingMessages.length == 0" class="no-chats">{{ 'say-hello' | translate }}</p>
  </div>
  <div class="bxl-emoji-picker" [style.visibility]="toggled ? 'visible' : 'hidden'">
    <div class="btn-group" role="group" aria-label="First group">
      <button type="button" (click)="handleSelection('😀')" class="btn btn-lg xs-no-padding btn-white">😀</button>
      <button type="button" (click)="handleSelection('😂')" class="btn btn-lg xs-no-padding btn-white">😂</button>
      <button type="button" (click)="handleSelection('😲')" class="btn btn-lg xs-no-padding btn-white">😲</button>
      <button type="button" (click)="handleSelection('😞')" class="btn btn-lg xs-no-padding btn-white">😞</button>
      <button type="button" (click)="handleSelection('👍')" class="btn btn-lg xs-no-padding btn-white">👍</button>
      <button type="button" (click)="handleSelection('👎')" class="btn btn-lg xs-no-padding btn-white">👎</button>
      <button type="button" (click)="handleSelection('❤️')" class="btn btn-lg xs-no-padding btn-white">❤️</button>
      <button type="button" (click)="handleSelection('❗️')" class="btn btn-lg xs-no-padding btn-white">❗️</button>
      <button type="button" (click)="handleSelection('❓')" class="btn btn-lg xs-no-padding btn-white">❓</button>
    </div>
  </div>
  <div *ngIf="event.disableChat == false || role != EventRole.Viewer" class="input-group">
    <input #chatbox type="text" class="form-control" [(ngModel)]="chatMessage" (keydown.shift.enter)="sendAndTranslate()" (keydown.enter)="sendMessage()" placeholder="{{ 'type-a-message' | translate }}" aria-label="" aria-describedby="button-addon2" />
    <div *ngIf="!isMobile" class="input-group-append">
      <button class="btn btn-white" (click)="toggled = !toggled" type="button" id="button-addon2">😄</button>
    </div>
    <div *ngIf="isMobile" class="input-group-append">
      <button class="btn btn-blue" style="background-color: #0e7afe; color: white" (click)="sendMessage()" type="button" id="button-addon2"><i class="fas fa-arrow-up"></i></button>
    </div>
  </div>
  <div *ngIf="event.disableChat == true && role == EventRole.Viewer">
    <h5 class="text-center text-white m-0">Chat is read-only</h5>
    <small class="d-block text-center text-white">For technical support, click help</small>
  </div>
</ng-container>
<ng-template #tapback let-message="message">
  <div class="btn-group" role="group" aria-label="First group">
    <button type="button" (click)="tapBack(message.id, '😀')" class="btn btn-xs xs-no-padding btn-white">😀</button>
    <button type="button" (click)="tapBack(message.id, '😂')" class="btn btn-xs xs-no-padding btn-white">😂</button>
    <button type="button" (click)="tapBack(message.id, '😲')" class="btn btn-xs xs-no-padding btn-white">😲</button>
    <button type="button" (click)="tapBack(message.id, '😞')" class="btn btn-xs xs-no-padding btn-white">😞</button>
    <button type="button" (click)="tapBack(message.id, '👍')" class="btn btn-xs xs-no-padding btn-white">👍</button>
    <button type="button" (click)="tapBack(message.id, '👎')" class="btn btn-xs xs-no-padding btn-white">👎</button>
    <button type="button" (click)="tapBack(message.id, '❤️')" class="btn btn-xs xs-no-padding btn-white">❤️</button>
    <button type="button" (click)="tapBack(message.id, '❗️')" class="btn btn-xs xs-no-padding btn-white">❗️</button>
    <button type="button" (click)="tapBack(message.id, '❓')" class="btn btn-xs xs-no-padding btn-white">❓</button>
  </div>
</ng-template>
