<!-- ========== MAIN ========== -->
<main id="content" role="main">
  <!-- Breadcrumb Section -->

  <!-- Content Section -->
  <div class="bg-light">
    <div class="container space-2">
      <h3 class="text-center">Welcome to {{settings.brand.brandName}}!</h3>
      <h5 class="text-center">Someone has created an account for you. Please choose a password below, and we will get you on you way!</h5>
      <div class="row mt-5 justify-content-center">
        <div class="col-md-6">
          <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          <form [formGroup]="passwordForm" (ngSubmit)="save()">
            <!-- Input -->

            <!-- Input -->
            <div class="mb-6">
              <div class="js-form-message">
                <label class="form-label">
                  password
                </label>

                <div class="form-group">
                  <input id="newPassword" type="password" class="form-control" formControlName="password" name="newPassword" placeholder="Enter your password" />
                  <div class="invalid-message">
                    <div>Please enter a valid password.</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Input -->

            <!-- Input -->
            <div class="js-form-message mb-6">
              <label class="form-label">
                Confirm password
              </label>

              <div class="form-group">
                <input type="password" class="form-control" name="confirmNewPassword" formControlName="confirmPassword" placeholder="Confirm your password" />
                <div *ngIf="f.errors?.notSame" class="invalid-message-visible">
                  <div>Passwords do not match.</div>
                </div>
              </div>
            </div>
            <!-- End Input -->

            <div class="w-lg-50">
              <!-- Password Strength -->

              <!-- End Password Strength -->

              <!-- Buttons -->
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-sm btn-primary transition-3d-hover mr-1">Done</button>
              <button type="button" (click)="cancel()" class="btn btn-sm btn-soft-secondary transition-3d-hover">Cancel</button>
              <!-- End Buttons -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- End Content Section -->
</main>
<!-- ========== END MAIN ========== -->
