import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AnalyticsService {
  constructor(private titleService: Title, private router: Router, private auth:AuthenticationService) {}

  public setTitleAndLogPageView(title: string) {
    if (this.auth && this.auth.impersonated){
      console.log('User is impersonated. Ignoring tracking');
      return;
    }

    let win = <any>window;
    var _hsq = (win._hsq = win._hsq || []);

    if (title && title.length > 0) {
      console.log('title: ' + title);
      console.log('Path: ' + this.router.url);
      this.titleService.setTitle(title);

      if (_hsq != undefined) {
        console.log('tracking hubspot page view');
        _hsq.push(['setPath', this.router.url]);
        _hsq.push(['trackPageView']);
      }
    }
  }
}
