import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-live-event-container',
  template: `<router-outlet></router-outlet>`,
})
export class RouterOutletContainerComponent implements OnInit {
  initialized = false;
  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
  }
}
