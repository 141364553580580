<!-- ========== MAIN CONTENT ========== -->
<main (resized)="onResized($any($event))" *ngIf="initialized" id="content" role="main">
  <!-- Hero Section -->
  <div id="SVGHero" class="position-relative bg-white overflow-hidden" style="">
    <div class="container position-relative z-index-2 space-top-2 space-bottom-3 space-top-md-2">
      <div class="row align-items-lg-center">
        <div class="col-lg-5 mb-11 mb-lg-0">
          <!-- Info -->
          <div class="mb-6">
            <h1 class="text-white font-weight-semi-bold mb-3">
              {{ 'hybrid-conferences' | translate }},
              <span class="text-indigo">
                <strong class="u-text-animation u-text-animation--typing"></strong>
              </span>
              <br />
              {{ 'ceu-events-and-courses' | translate }}
            </h1>
            <p class="text-white-70">{{ 'state-of-art-solution' | translate }}</p>
          </div>

          <!-- <a class="btn btn-white btn-wide btn-pill text-primary shadow-soft transition-3d-hover mb-2 mb-sm-0 mr-4" [routerLink]="['/products-services']">Products & Services</a> -->
          <a class="btn btn-primary btn-wide btn-pill transition-3d-hover mb-2 mb-sm-0" [routerLink]="['events']">{{ 'find-events' | translate }}</a>
          <!-- End Info -->
        </div>

        <div class="col-lg-7">
          <div class="position-relative">
            <!-- SVG Laptop Mockup -->
            <figure class="ie-devices-v4-laptop mb-3 mb-sm-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 999.1 576.5"
                style="enable-background: new 0 0 999.1 576.5"
                xml:space="preserve"
                class="injected-svg js-svg-injector"
                data-img-paths='[
                     {"targetId": "#SVGlaptopImg1", "newPath": "../../assets/img/1618x1010/img3.jpg"}
                   ]'
                data-parent="#SVGHero"
              >
                <style type="text/css">
                  .laptop-0 {
                    fill: #ffffff;
                  }
                  .laptop-1 {
                    fill: #f7f7f7;
                  }
                  .laptop-2 {
                    fill: #efefef;
                  }
                  .laptop-3 {
                    fill: url(#laptopID1);
                  }
                </style>
                <path class="laptop-0 fill-white" d="M881.3,576.5H117.5c-14.4,0-26.2-11.8-26.2-26.2V26.2C91.3,11.8,103.1,0,117.5,0h763.9  c14.4,0,26.2,11.8,26.2,26.2v524.2C907.5,564.7,895.7,576.5,881.3,576.5z"></path>
                <circle class="laptop-1" cx="498" cy="23.3" r="3"></circle>
                <path class="laptop-0" d="M996.3,560.7H2.9c-1.5,0-2.8-1.3-2.8-2.8l0,0c0-1.5,1.3-2.8,2.8-2.8h993.4c1.5,0,2.8,1.3,2.8,2.8l0,0  C999.1,559.4,997.9,560.7,996.3,560.7z"></path>
                <path class="laptop-2" d="M499.6,562.5L0,560.7c0,0,24.1,11.3,117.8,15.8H498h3.1h380.2c93.7-4.5,117.8-15.8,117.8-15.8L499.6,562.5z"></path>
                <linearGradient id="laptopID1" gradientUnits="userSpaceOnUse" x1="403.8206" y1="558.6469" x2="594.97" y2="558.6469">
                  <stop offset="5.908129e-07" style="stop-color: #f0f1f2"></stop>
                  <stop offset="5.235744e-02" style="stop-color: #f6f7f8"></stop>
                  <stop offset="0.1708" style="stop-color: #fdfdfd"></stop>
                  <stop offset="0.5" style="stop-color: #ffffff"></stop>
                  <stop offset="0.8292" style="stop-color: #fdfdfd"></stop>
                  <stop offset="0.9476" style="stop-color: #f6f7f8"></stop>
                  <stop offset="1" style="stop-color: #f0f1f2"></stop>
                </linearGradient>
                <path class="laptop-3" d="M591.4,562.2H407.4c-1.9,0-3.5-1.6-3.5-3.5v-3.5H595v3.5C595,560.6,593.4,562.2,591.4,562.2z"></path>
                <g>
                  <defs>
                    <rect id="laptopID2" x="121.7" y="37.5" width="752.5" height="469.7"></rect>
                  </defs>
                  <clipPath id="laptopID3">
                    <use xlink:href="#laptopID2" style="overflow: visible"></use>
                  </clipPath>
                  <g style="clip-path: url(#laptopID3)">
                    <!-- Apply your (1618px width to 1010px height) image here -->
                    <image id="SVGlaptopImg1" style="overflow: visible" width="1618" height="1010" xlink:href="../../../assets/homepage-screenshot.jpeg" transform="matrix(0.4651 0 0 0.4651 121.7472 37.4679)"></image>
                  </g>
                  <use xlink:href="#laptopID2" style="overflow: visible; fill: none; stroke: #e7eaf3; stroke-miterlimit: 10"></use>
                </g>
              </svg>
            </figure>
            <!-- SVG Laptop Mockup -->

            <!-- Card Info -->
            <div class="position-sm-absolute bottom-0 right-0 max-width-40 ml-auto mb-sm-n5">
              <div class="card border-0 bg-primary shadow-primary-lg rounded p-5">
                <h2 class="h5 text-white font-weight-semi-bold mb-3">
                  Author Content &<br />
                  Get Paid
                </h2>
                <p class="text-white">Want some passive income? Sign up today, and start selling your content!</p>
                <p class="font-size-1 font-weight-medium" style="color: #051d40">Our world-class marketing team can help you host a live event!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SVG Background Shape -->
    <div class="gradient-overlay-half-home-hero svg-divider-polygon position-absolute top-0 right-0 bottom-0 left-0">
      <figure class="ie-circles-hero"></figure>
    </div>
    <!-- End SVG Background Shape -->
  </div>

  <!-- End Hero Section -->
  <div class="container space-2">
    <!-- Title -->
    <div class="d-sm-flex justify-content-sm-between align-items-sm-center mb-5">
      <h2 class="h3 font-weight-medium">{{ 'upcoming-conferences' | translate }}</h2>
      <a class="link-muted" [routerLink]="['/conferences']">
        {{ 'view-all' | translate }}
        <span class="fas fa-angle-right small ml-2"></span>
      </a>
    </div>
    <!-- End Title -->

    <div class="row">
      <div *ngIf="!conferences" class="col-md-12">
          <p-skeleton width="100%" height="200px"></p-skeleton>
      </div>
      <div *ngIf="conferences" class="col-md-12">
        <p-carousel [numVisible]="4" [numScroll]="1" [value]="conferences" [responsiveOptions]="responsiveOptions">
          <ng-template let-conf pTemplate="item">
            <bxl-conference-item [conference]="conf" [isGrid]="true"></bxl-conference-item>
          </ng-template>
        </p-carousel>
        <!-- <carousel [height]="420" [cellWidth]="400">
          <div *ngFor="let conf of conferences" class="carousel-cell">
            <bxl-conference-item [conference]="conf" [isGrid]="true"></bxl-conference-item>
          </div>
        </carousel> -->
      </div>
    </div>
  </div>

  <div id="SVGbgElements6" class="position-relative overflow-hidden">
    <div class="container">
      <!-- Title -->
      <div class="d-sm-flex justify-content-sm-between align-items-sm-center mb-5">
        <h2 class="h3 font-weight-medium">{{ 'featured-on-demand-content' | translate }}</h2>
        <a class="link-muted" [routerLink]="['/course-library']">
          {{ 'search-library' | translate }}
          <span class="fas fa-angle-right small ml-2"></span>
        </a>
      </div>
      <!-- End Title -->

      <div class="row">
        <div *ngIf="!featuredCourses" class="col-md-12">
          <p-skeleton width="100%" height="200px"></p-skeleton>
      </div>
        <div *ngIf="featuredCourses" class="col-md-12">
          <p-carousel [numVisible]="5" [value]="featuredCourses" [responsiveOptions]="responsiveOptions2">
            <ng-template let-course pTemplate="item">
              <div [routerLink]="['/courses', course.slug]" class="card cursor-pointer">
                <bxl-course-item [course]="course" [isGrid]="true"></bxl-course-item>
              </div>
            </ng-template>
          </p-carousel>
        </div>
      </div>
    </div>

    <!-- SVG Background Element -->
    <figure class="position-absolute top-0 right-0 z-index-n1 w-100 h-100">
      <img class="js-svg-injector" src="../../assets/svg/components/bg-elements-6.svg" alt="SVG" data-parent="#SVGbgElements6" />
    </figure>
    <!-- End SVG Background Element -->
  </div>
</main>
<!-- ========== END MAIN CONTENT ========== -->
<!-- ========== MAIN CONTENT ========== -->

<!-- ========== END MAIN CONTENT ========== -->
