<div class="hof-home hof-page-wrapper">
  <video [ngClass]="{ 'd-none': !initialized }" autoplay muted loop id="myVideo">
    <source src="/assets/hof-background.mp4" type="video/mp4" />
  </video>
  <div class="hof-title my-auto mx-auto text-white text-center" style="z-index: 100">
    <img class="mb-5 img-fluid" src="/assets/wiba-logo-dark.png" style="width: 800px" />
    <h1>Hall of Fame</h1>
    <h5>Presented By...</h5>
    <img class="mb-5 img-fluid" src="/assets/svg/main-logo-white.svg" style="width: 400px" />

  </div>
</div>
