import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { OrganizationService } from '../_services/generatedServices';

@Injectable()
export class CompanyAdminGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthenticationService, private org: OrganizationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let orgId = route.paramMap.get('organizationId');

    return new Promise<boolean>((resolve) => {
      if (!this.auth.isLoggedIn()) {
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        resolve(false);
        return;
      }

      this.org.getOrganization(orgId).subscribe((result) => {
        if (this.auth.isSuperUser()) {
          resolve(true);
          return;
        } else if (this.auth.isOrganizationAdmin(result.id)) {
          // logged in so return true

          resolve(true);
          return;
        } else {
          //refresh claims
          this.auth.refreshClaimsAndRefreshUser().then((presult) => {
            if (this.auth.isOrganizationAdmin(result.id)) {
              // logged in so return true

              resolve(true);
              return;
            } else {
              this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
              resolve(false);
              return;
            }
          });
        }
      });
    });
  }
}
