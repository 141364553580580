<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="card mb-5">
      <div class="card-body">
        <img src="/assets/logo.png" class="img-fluid mx-auto d-block" alt="Cinque Terre">
        <div *ngIf="errorMessage">
          <div class="alert alert-danger" role="alert">
            {{errorMessage}}
          </div>

        </div>
        <form class="form" [formGroup]="loginForm" (ngSubmit)="onSave()">
          <div class="form-group">
            <a href="" class="float-right">Forgot password?</a>
            <label for="uname1">Username</label>
            <input type="text" class="form-control form-control-lg" name="uname1" id="uname1" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">First Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control form-control-lg" id="pwd1" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
          </div>
          <div class="form-group py-4">            
            <button type="submit" class="btn btn-primary btn-lg float-right" ngbAutofocus id="btnLogin" [promiseBtn]="submitComplete">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
