<page-load-header *ngIf="!initialized"></page-load-header>


<ng-container *ngIf="initialized && ad">
  <div *ngIf="isMargin" class="mb-4" style="border: 1px solid #e7eaf3">
    <a [href]="ad.clickUrl" (click)="adClicked()" target="_blank" class="ad-link">
      <img loading="lazy" src="https://hangfire.behaviorlive.com/ad-impression?auctionId={{ad.auctionId}}&clientIdentifier={{clientId}}&fileName={{ad.marginAssetPath}}&bust={{cacheBust}}" alt="ad" class="img-fluid"/>
    </a>
  </div>

  <a *ngIf="!isMargin" [href]="ad.clickUrl" (click)="adClicked()" target="_blank" class="ad-link">
    <img loading="lazy" src="https://hangfire.behaviorlive.com/ad-impression?auctionId={{ad.auctionId}}&clientIdentifier={{clientId}}&fileName={{ad.bannerAssetPath}}&bust={{cacheBust}}" alt="ad" class="img-fluid"/>
  </a>

</ng-container>
