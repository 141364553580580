<div #fullscreenContainer class="hof-container d-flex">
  <div [@triggerName]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
  <nav class="navbar fixed-bottom navbar-light bg-light hof-nav">
    <a class="navbar-brand" [routerLink]="['about']"><i class="yellow-text fas fa-star"></i> About WIBA's Hall of Fame</a>
    <a class="navbar-brand" [routerLink]="['timeline']"> <i class="yellow-text fas fa-star"></i>Timeline</a>
    <a class="navbar-brand" [routerLink]="['inductees']"> <i class="yellow-text fas fa-star"></i> Inductees</a>
    <a class="navbar-brand ml-auto" [routerLink]="['.']"><i class="yellow-text fas fa-star"></i> Home</a>
  </nav>
</div>
