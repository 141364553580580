import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/_services/generatedServices';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { LiveEventViewModel, PagedActiveUserViewModel, QuestionHistoryViewModel } from 'src/app/_models/generatedModels';
import { ChatViewModel, EventRole } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AnalyticsService } from 'src/app/_services/analytics.service';
declare var zE: any;

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}

@Component({
  selector: 'bxl-presenter-view',
  templateUrl: 'presenter-view.component.html',
})
export class PresenterViewComponent implements OnInit {
  connection: HubConnection;
  eventIdOrSlug: any;
  event: LiveEventViewModel;
  userCount: number;
  showPeople: boolean = true;
  showPolls: boolean;
  showChat: boolean = true;
  showMaterials: boolean;
  randomUserIds: number[];
  public questions: ChatViewModel[] = new Array();
  initialized: boolean;
  role: EventRole;
  tools: boolean;

  constructor(private analyticsService: AnalyticsService, private route: ActivatedRoute, private router: Router, private eventData: EventService, private auth: AuthenticationService) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);

    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.forward();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.backward();
    }
  }

  ngOnInit(): void {
    this.role = EventRole.Host;
    this.analyticsService.setTitleAndLogPageView('Presenter View');

    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('tools')) {
        this.tools = true;
      } else {
        this.tools = false;
      }
      this.route.paramMap.subscribe((params) => {
        this.eventIdOrSlug = params.get('id');
        this.loadZendesk();

        this.eventData.getLiveEvent(this.eventIdOrSlug).subscribe((event) => {
          this.event = event;
          this.InitSignalR();
        });
      });
    });
  }

  get windowCount(): number {
    var count = 1;
    if (this.showPolls) count++;
    if (this.showPeople) count++;
    if (this.showMaterials) count++;

    return count;
  }

  usersUpdated(event: PagedActiveUserViewModel) {
    this.userCount = event.totalCount;
    this.randomUserIds = event.randomUserIds;
  }

  exitPage() {
    if (this.auth.isSuperUser()) {
      this.router.navigate(['/super-user/events']);
      return;
    }
    this.router.navigate(['/account/live-events']);
  }

  loadZendesk() {
    zE('webWidget', 'hide');
    zE('webWidget:on', 'close', () => {
      zE('webWidget', 'hide');
    });

    zE('webWidget:on', 'chat:unreadMessages', () => {
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    });
  }

  InitSignalR(): any {
    this.connection = new HubConnectionBuilder()
      .withUrl('/liveEventHub', { accessTokenFactory: () => this.auth.getToken() })
      .withAutomaticReconnect()
      .build();

    this.connection.start();

    this.connection.on('postQuestion', (model: QuestionHistoryViewModel, eventId: number) => {
      if (eventId === this.eventIdOrSlug) {
        this.questions.push(new ChatViewModel(model.id, model.user.firstName + ' ' + model.user.lastName, model.question, model.user.id));
      }
    });
    this.initialized = true;
  }

  forward() {
    console.log('here');
    this.eventData.slideControl('Right').subscribe();
  }

  backward() {
    this.eventData.slideControl('Left').subscribe();
  }
}
