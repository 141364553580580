<div *ngIf="initialized" class="card">
  <!-- Header -->
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="h6 mb-0">{{ 'handouts' | translate }}</h3>

      <button *ngIf="showInput" type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <!-- End Header -->

  <!-- Body -->

  <div #questionContainer class="card-body" style="">
    <div class="participant-list" style="">
      <div>
        <div *ngIf="!materials || materials.length == 0" class="text-center">
          <div class="mb-5">
            <p class="font-size-3">{{ 'no-handouts' | translate }}</p>
          </div>
        </div>
        <ul class="mb-0">
          <ng-container *ngFor="let material of materials">
            <li class="mb-0">
              <div class="d-flex align-items-center pb-1">
                <div class="mr-auto">
                  <span class="participant-name">
                    <h6>
                      <a target="_blank" href="https://bxlimages.blob.core.windows.net/materials/{{ material.blobPath }}">
                        <img style="width: 20px" src="../../assets/filetypes/{{ material.fileType }}.png" />
                        {{ material.description }}</a
                      >
                    </h6></span
                  >
                </div>
                <span *ngIf="role != EventRole.Viewer" class="participant-menu ml-2">
                  <button type="button" class="btn btn-xs btn-danger transition-3d-hover" style="white-space: nowrap" (click)="deleteMaterial(material)" tooltip="Play Video" placement="top" show-delay="250">{{ 'delete' | translate }}</button>
                </span>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="role != EventRole.Viewer" class="card-footer d-flex">
    <kendo-upload [(ngModel)]="myFiles" [saveUrl]="uploadUrl" [multiple]="false" (complete)="onComplete($event)" (success)="successEventHandler($event)"></kendo-upload>
  </div>

  <!-- End Footer -->
</div>
