<ng-container>
  <div id="orgAdmin">
    <div class="container-fluid space-top-1">
      <div class="mb-sm-0 mb-md-3">
        <div class="d-flex justify-content-between" style="margin-top: -15px">
          <!-- <div>My Account</div>
          <a role="button" class="btn btn-sm btn-outline-secondary" type="button" tabindex="0" target="_blank">Conference Website <i class="fas fa-external-link"></i></a>-->
        </div>
        <div *ngIf="auth.user" class="media d-sm-flex align-items-sm-center">
          <span *ngIf="!auth.user.avatar" class="btn btn-lg btn-icon btn-dark rounded-circle mr-3">
            <span class="btn-icon__inner">{{ auth.user.firstName.substr(0, 1).toUpperCase() }}{{ auth.user.lastName.substr(0, 1).toUpperCase() }}</span>
          </span>
          <div *ngIf="auth.user?.avatar" class="u-lg-avatar position-relative mb-3 mb-sm-0 mr-3">
            <img class="img-fluid rounded-circle" src="/fetchimage?filename={{ auth.user.avatar }}&width=100&height=100&mode=crop"  />
            <span class="badge badge-md badge-outline-success badge-pos badge-pos--bottom-right rounded-circle">
              <span class="fas fa-check"></span>
            </span>
          </div>
          <div class="media-body">
            <h1 class="h3 font-weight-medium mb-1">{{ 'howdy' | translate }}, {{ auth.user.firstName }}!</h1>
            <span class="d-block">{{ auth.user.email }}</span>
          </div>
        </div>
      </div>
      <div class="row mb-1 d-block d-lg-none">
        <div class="col-md-12 p-0">
          <div class="dropdown">
            <button class="btn btn-icon btn-white font-size-2" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-bars"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <a class="dropdown-item" *ngFor="let item of navItems" [routerLink]="item.routerLink" [routerLinkActive]="'active'">{{ item.text }}</a>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="container-fluid bg-light container-separator">
      <div class="row flex-nowrap space-1 min-height-100vh">
        <div class="col-md-4 col-12 sidebar hidePrint">
          <ul class="nav nav-pills flex-column">
            <li class="nav-item"></li>
            <li class="nav-item" *ngFor="let item of navItems">
              <a class="nav-link" [routerLink]="item.routerLink" [routerLinkActive]="'active'">{{ item.text }}</a>
            </li>
          </ul>
          <ad-display class="mt-2" [isMargin]="true"></ad-display>

        </div>
        <div class="col-md col-12 main pa-1">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-container>
