<div class="container mb-2 expose">
  <div class="row">
    <div class="col-lg-8 col-md-12">
      <h3>{{ selectedJob.title }}</h3>
<!--      <h5>{{ selectedJob.companyName }} - {{ selectedJob.companyLocation.city + ', ' + selectedJob.companyLocation.state }}</h5>-->
    </div>
    <div class="col-lg-4 col-md-12 pt-1">
<!--      <apply-recommend-buttons [job]="selectedJob"></apply-recommend-buttons>-->
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" innerHTML="{{ selectedJob.description }}"></div>
  </div>
  <div class="row float-right">
    <a class="p-2" [routerLink]="['.', selectedJob.id]" routerLinkActive="router-link-active">View Full Job Post</a>
  </div>
  <div class="clearfix"></div>
</div>
