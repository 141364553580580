import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LiveEventViewModel, EventCompletionViewModel, CompleteEventViewModel, CreateReviewViewModel } from 'src/app/_models/generatedModels';
import { EventService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { HubConnection } from '@microsoft/signalr';
import { TokenModel } from 'src/app/_models/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var UAParser: any;

@Component({
  selector: 'ceu-tokens',
  templateUrl: 'ceu-tokens.component.html',
})
export class CeuTokensComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;

  public ceuToken1: string;
  public ceuToken2: string;
  public ceuToken3: string;
  public ceuToken4: string;
  public ceuError: string;
  @Input() event: LiveEventViewModel;
  @Input() playChime: boolean;
  @Input() connection: HubConnection;
  public completionStatus: EventCompletionViewModel;

  @Output()
  closed = new EventEmitter();

  @Output()
  tokenReceived = new EventEmitter<TokenModel>();

  constructor(private eventData: EventService, private toaster: ToasterService, public auth: AuthenticationService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.completionStatus = new EventCompletionViewModel();
    this.eventData.getEventUserCompletionStatus(this.event.id).subscribe((result) => {
      this.completionStatus = result;
      this.initSignalR();
      this.setupForm();
    });
  }
  setupForm(): any {
    this.formGroup = this.fb.group({
      rating: [null, [Validators.required]],
      text: ['', [Validators.required]],
    });

    this.initialized = true;
  }

  initSignalR() {
    this.connection.on('showToken', (eventId: number, token: string, tokenNumber: number) => {
      if (eventId !== this.event.id) {
        return;
      }

      let model = new TokenModel();
      model.token = token;
      model.tokenNumber = tokenNumber;
      this.tokenReceived.emit(model);
      this.playAudio();
    });
  }

  isMobileDevice(): boolean {
    let parser = new UAParser();
    let result = parser.getResult();

    if (result.os.name == 'Mac OS' || result.os.name == 'Windows' || result.os.name == 'Chrome OS') return false;

    return true;
  }

  playAudio() {
    if (!this.playChime) {
      return;
    }

    let audio = new Audio();
    audio.src = '../../../assets/audio/chimes-glassy.mp3';
    audio.load();
    audio.play();
  }

  public onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toaster.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton: any, reject) => {
      const formData: CreateReviewViewModel = Object.assign({}, null, this.formGroup.value);
      formData.isMobile = this.isMobileDevice();
      this.eventData.completeEvent(this.event.id, formData).subscribe(
        (result) => {
          this.ceuError = null;
          this.completionStatus = result;
          this.toaster.messageDialog('This event is complete. You can download your certificate on the right, or any time in "My Account"', 'Event Complete').subscribe();
          resetButton();
        },
        (error) => {
          this.ceuError = error.error[0];
          resetButton();
        }
      );
    });
  }

  keyUp1() {
    localStorage.setItem(this.event.id + 'token1', this.ceuToken1);
  }
  keyUp2() {
    localStorage.setItem(this.event.id + 'token2', this.ceuToken2);
  }
  keyUp3() {
    localStorage.setItem(this.event.id + 'token3', this.ceuToken3);
  }
  keyUp4() {
    localStorage.setItem(this.event.id + 'token4', this.ceuToken4);
  }

  close() {
    console.log('close');
    this.closed.emit();
  }
}
