import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/_services/generatedServices';
import { EventViewModel, EventSearchParameters, ConferenceViewModel, ConferenceViewModelSimple } from 'src/app/_models/generatedModels';
import { Title } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import { AuthenticationService } from "../../_services/authentication.service";

export class ItemListing {
  date: Date;
  event: EventViewModel;
  conference: ConferenceViewModelSimple;
  isEvent: boolean;
}

@Component({
  selector: 'upcoming-events-list',
  templateUrl: 'upcoming-events-list-component.component.html',
})



export class UpcomingEventsListComponent implements OnInit {
  events: EventViewModel[];
  initialized: boolean;
  conferences: ConferenceViewModelSimple[];
  items: ItemListing[];

  constructor(private eventService: EventService, public auth: AuthenticationService, private settings: SettingsProvider) {}

  ngOnInit(): void {
    let params = new EventSearchParameters();
    params.onlyShowFuture = true;

    if (this.settings.applicationName === 'BehaviorLive') {
      params.excludeIfConference = true;
    } else {
      params.excludeIfConference = false;
    }

    forkJoin(this.eventService.getAll(params), this.eventService.getUpcomingConferences(true)).subscribe((events) => {
      this.events = events[0];
      this.conferences = events[1];

      var tmp: ItemListing[] = [];
      this.events.forEach((item) => {
        let listing = new ItemListing();
        listing.date = item.startTime;
        listing.event = item;
        listing.isEvent = true;
        tmp.push(listing);
      });

      this.conferences.forEach((conference) => {
        let listing = new ItemListing();
        listing.date = conference.startDate;
        listing.conference = conference;
        listing.isEvent = false;
        tmp.push(listing);
      });

      this.items = tmp.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      this.initialized = true;
    });
  }
}
