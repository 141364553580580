<div class="hof-page-wrapper hof-stars">
  <div class="hof-title my-auto mx-auto text-white text-center">
    <div class="row mb-3">
      <div class="col-md-12 flex">
        <div style="position: absolute; background-color: #ffc107; top:48%; left: 0; right: 0; height: 20px; z-index: 0"></div>
        <div class="d-flex justify-content-center">
          <img [routerLink]="'bea'" class="u-giant-avatar rounded-circle mr-6" style="z-index: 5" src="../../assets/wiba-hof/Bea-Barrett.png"  />
          <img [routerLink]="'martha'" class="u-giant-avatar rounded-circle mr-6" style="z-index: 5" src="../../assets/wiba-hof/martha.png"  />
          <img [routerLink]="'evalyn'" class="u-giant-avatar rounded-circle mr-6" style="z-index: 5" src="../../assets/wiba-hof/evalyn.png"  />
          <img [routerLink]="'judith'" class="u-giant-avatar rounded-circle mr-6" style="z-index: 5" src="../../assets/wiba-hof/julie.png"  />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12 text-center">
          <h2 class="dh2">Honoring Greatness</h2>
        </div>
      </div>
    </div>
  </div>
</div>
