/// <reference path="../../../node_modules/@types/googlemaps/index.d.ts"/>
// https://www.petrelli.biz/2017/06/05/an-angular-and-ionic-component-for-google-place-autocomplete/
import { Component, Input, forwardRef, ElementRef, ViewChild, OnInit, NgZone } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'places-autocomplete',
  host: {},
  template: `
    <div class="input-group">
      <!-- <input type="text" class="form-control" placeholder="City, state, or zip" aria-label="City, state, or zip" aria-describedby="locationInputAddon" value="United Kingdom" /> -->
      <input class="{{ cssClass }}" aria-describedby="locationInputAddon" type="text" placeholder="{{ placeholder }}" [(ngModel)]="autocompleteInput" (keyup)="changed($event)" #addresstext />
      <div class="input-group-append">
        <span class="input-group-text">
          <span class="fas fa-map-marker-alt" id="locationInputAddon"></span>
        </span>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlacesAutoCompleteControl),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PlacesAutoCompleteControl),
      multi: true
    }
  ]
})
export class PlacesAutoCompleteControl implements ControlValueAccessor, OnInit {
  someAttr = 'anyValue';
  validSelection = false;
  autocompleteInputSaved: string;
  @Input() autocompleteInput: string;
  // @Input() autocompleteInputSaved: string;
  @Input() adressType: string;
  @ViewChild('addresstext', { static: true }) addresstext: any;
  cssClass = '';
  placeholder = '';

  constructor(elementRef: ElementRef, private zone: NgZone) {
    this.cssClass = elementRef.nativeElement.getAttribute('class');
    this.placeholder = elementRef.nativeElement.getAttribute('placeholder');
    elementRef.nativeElement.setAttribute('class', '');
    elementRef.nativeElement.setAttribute('placeholder', '');
  }

  ngOnInit() {
    this.autocompleteInputSaved = this.autocompleteInput;
    if (this.autocompleteInput) {
      this.validSelection = true;
    }

    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
      componentRestrictions: { country: 'US' },
      types: [this.adressType] // 'establishment' / 'address' / 'geocode'
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.autocompleteInputSaved = this.autocompleteInput;
      const place = autocomplete.getPlace();
      const location = this.parseAddress(place);
      console.log(location);
      this.zone.run(() => {
        this.validSelection = place.geometry != null;
        this.propagateChange(location);
      });
    });
  }

  changed(event) {
    if (event.key === 'Enter') {
      return;
    }

    if (this.autocompleteInput !== this.autocompleteInputSaved) {
      this.validSelection = false;
      this.propagateChange(null);
    }
  }

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  writeValue(value: GoogleLocation): void {
    if (!value) {
      return;
    }

    console.log('write value');
    if (value.fullText && value.lattitude && value.longitude) {
      this.validSelection = true;
    }
    // this.autocompleteInput = value.fullText;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public validate(c: FormControl) {
    console.log(this.validSelection);
    if (this.validSelection === false && this.autocompleteInput && this.autocompleteInput.length > 0) {
      return {
        invalidLocation: {
          valid: false
        }
      };
    }

    return null;
  }

  private parseAddress(place: google.maps.places.PlaceResult) {
    let address_components: Array<AddressComponent> = place.address_components;
    let location: GoogleLocation = {};

    location.fullText = place.formatted_address;

    if (!Array.isArray(address_components)) {
      return null;
    }

    if (!address_components.length) {
      return null;
    }

    location.lattitude = place.geometry.location.lat();
    location.longitude = place.geometry.location.lng();

    for (let i = 0; i < address_components.length; i++) {
      const component: AddressComponent = address_components[i];

      if (this.isStreetNumber(component)) {
        location.street_number = component.long_name;
      }

      if (this.isStreetName(component)) {
        location.street_name = component.long_name;
      }

      if (this.isCity(component)) {
        location.city = component.long_name;
        location.cityCode = component.short_name;
      }

      if (this.isCountry(component)) {
        location.country = component.long_name;
        location.countryCode = component.short_name;
      }

      if (this.isState(component)) {
        location.state = component.long_name;
        location.stateCode = component.short_name;
      }

      if (this.isPostalCode(component)) {
        location.postal_code = component.long_name;
      }
    }
    return location;
  }

  private isStreetNumber(component: AddressComponent): boolean {
    return component.types.includes('street_number');
  }

  private isStreetName(component: AddressComponent): boolean {
    return component.types.includes('route');
  }

  private isCity(component): boolean {
    return component.types.includes('locality');
  }

  private isState(component): boolean {
    return component.types.includes('administrative_area_level_1');
  }

  private isCountry(component): boolean {
    return component.types.includes('country');
  }

  private isPostalCode(component): boolean {
    return component.types.includes('postal_code');
  }
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}

export class GoogleLocation {
  fullText?: string;
  lattitude?: number;
  longitude?: number;
  street_number?: string;
  street_name?: string;
  city?: string;
  cityCode?: string;
  state?: string;
  stateCode?: string;
  country?: string;
  countryCode?: string;
  postal_code?: string;
}
