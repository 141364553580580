import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
declare var UAParser: any;

@Component({
  selector: 'bxl-suggest-mobile-app-modal-cs',
  templateUrl: 'suggest-mobile-app-modal-cs.component.html',
})
export class SuggestMobileAppModalCsComponent implements OnInit {
  initialized = false;
  isAndroid: boolean;
  isIOS: boolean;
  constructor(public activeModal: NgbActiveModal, public settings: SettingsProvider) {}

  ngOnInit(): void {
    let parser = new UAParser();
    let result = parser.getResult();

    this.isAndroid = result.os.name == 'Android';
    this.isIOS = result.os.name == 'iOS';
    this.initialized = true;
  }
}
