import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from "../_services/authentication.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'bxl-super-user',
  templateUrl: 'super-user.component.html',
})
export class SuperUserComponent implements OnInit {
  initialized = true;
  padMargins: boolean;

  constructor(private titleService: Title, public auth: AuthenticationService, private router: Router) {

  }


  ngOnInit(): void {
    this.titleService.setTitle('SuperUser - BehaviorLive');

    if (this.router.url.includes('billing')) {
      this.padMargins = false;
    } else {
      this.padMargins = true;
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      console.log(evt.url);

      if (evt.url.includes('billing')) {
        this.padMargins = false;
      } else {
        this.padMargins = true;
      }
    });

    this.auth.refreshClaimsAndRefreshUser().then(() => {
      this.initialized = true;
    });
  }

  logout() {
    this.auth.logout();
  }
}
