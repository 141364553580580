import { HttpClient } from '@angular/common/http';
import { Directive, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GoogleObj, GoogleTranslateResponse } from '../_models/models';
import { SettingsProvider } from './settingsProvider.service';

@Directive()
@Injectable()
export class GoogleTranslateService {


  constructor(private http: HttpClient,  private router: Router) {
  }


  translate(theModel: GoogleObj): Observable<GoogleTranslateResponse> {
       return this.http.post<GoogleTranslateResponse>(`https://translation.googleapis.com/language/translate/v2?key=AIzaSyC6nP7szUz9kJC_QsjfEGa3ltY--d3pZrE`, theModel)
            .pipe(map((result: GoogleTranslateResponse) => result));
  }
}
