<body class="bg-img-hero-fixed" style="background-image: url(../../assets/svg/illustrations/error-404.svg);">
  <!-- ========== HEADER ========== -->

  <!-- ========== END HEADER ========== -->
  <!-- ========== MAIN ========== -->
  <main id="content" role="main">
    <!-- Hero Section -->
    <div class="d-lg-flex">
      <div class="container d-lg-flex align-items-lg-center min-height-lg-100vh space-4">
        <div class="w-lg-60 w-xl-50">
          <!-- Title -->
          <div class="mb-5">
            <h1 class="text-primary font-weight-normal">Job not <span class="font-weight-semi-bold">found</span></h1>
            <p class="mb-0">Sorry, it appears you have come across a job not in our system.</p>
            <p class="mb-0">We have thousands of jobs to choose from. Click the 'Search Jobs' button below.</p>
          </div>
          <!-- End Title -->

          <a class="btn btn-primary btn-wide transition-3d-hover mr-2" [routerLink]="['']" (click)="searchJobs()">Search Jobs</a>
        </div>
      </div>
    </div>
    <!-- End Hero Section -->
  </main>
  <!-- ========== END MAIN ========== -->
  <!-- ========== FOOTER ========== -->
  <footer class="position-md-absolute right-md-0 bottom-md-0 left-md-0">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center space-1">
        <!-- Copyright -->
        <p class="small text-muted mb-0">&copy; 2024 BehaviorLive LLC</p>
        <!-- End Copyright -->
        <!-- Social Networks -->
        <ul class="list-inline mb-0">
          <li class="list-inline-item">
            <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent" href="#">
              <span class="fab fa-facebook-f btn-icon__inner"></span>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent" href="#">
              <span class="fab fa-google btn-icon__inner"></span>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent" href="#">
              <span class="fab fa-twitter btn-icon__inner"></span>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent" href="#">
              <span class="fab fa-github btn-icon__inner"></span>
            </a>
          </li>
        </ul>
        <!-- End Social Networks -->
      </div>
    </div>
  </footer>
  <!-- ========== END FOOTER ========== -->


</body>
