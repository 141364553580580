import { Injectable, OnInit } from '@angular/core';
import { JobSearchParameters, SavedJobSearchParameters, EmploymentType, Experience, JobSearchHistoryParameters, JobSearchResultsViewModel, JobSearchResultViewModel, JobViewModel } from '../_models/generatedModels';
import { GoogleLocation } from '../_controls/google-autocomplete';
import { Key } from 'protractor';
import { JobsService, UserService } from "./generatedServices";

@Injectable()
export class JobSearchService implements OnInit {
  jobResults: JobSearchResultsViewModel;
  jobs: JobSearchResultViewModel[];
  public recentSearches: JobSearchHistoryParameters[];
  public selectedRecentSearch: JobSearchHistoryParameters;
  searchQuery: string;
  selectedJob: JobViewModel;
  loading = false;

  public currJobId = 0;
  public prevJobId = 0;
  public jobSearchHistoryId = 0;
  public showGrid = false;
  public showFilter = false;
  public filterName: string;

  locationModel: GoogleLocation;
  public selectedFilter: SavedJobSearchParameters;
  searchParameters: JobSearchParameters;
  page: number;
  public emailNotifications: boolean;


  constructor(public jobsData: JobsService, private  user: UserService) {
    this.initializeParams();

    if (!this.recentSearches) {
      this.getRecentSearches();
    }
  }

  public initializeParams() {
    this.searchParameters = new JobSearchParameters();
    this.searchParameters.sessionId = localStorage.getItem('sessionId');
    this.searchParameters.currentSessionId = localStorage.getItem('currentSessionId');
    this.clearFilters();
    this.searchParameters.query = '';
    this.searchQuery = '';
  }

  clearFilters() {
    this.searchParameters.radiusInMiles = 25;
    this.searchParameters.salaryMin = 0;
    this.searchParameters.salaryMax = 255;
    this.searchParameters.singleCompanyId = 0;
    this.searchParameters.similiarJobID = null;
    this.searchParameters.recommendJobs = false;
    this.searchParameters.employmentType = [];
    this.searchParameters.experience = [];
    this.searchParameters.companies = [];
    this.searchParameters.locations = [];
    this.searchParameters.query = '';
    this.locationModel = null;
    this.selectedFilter = null;
    this.filterName = '';
    this.emailNotifications = true;
  }

  public hasFilter() {

    var filter =
      this.searchParameters.radiusInMiles != 25 ||
      this.searchParameters.salaryMin != 0 ||
      this.searchParameters.salaryMax != 255 ||
      this.searchParameters.similiarJobID != null ||
      this.hasArrayValue(this.searchParameters.employmentType) ||
      this.hasArrayValue(this.searchParameters.experience) ||
      this.hasArrayValue(this.searchParameters.companies) ||
      this.hasArrayValue(this.searchParameters.locations) ||
      this.searchParameters.query != '' ||
      this.searchParameters.query != '' ||
      this.locationModel != null ||
      this.selectedFilter != null ||
      this.filterName != '';

    return filter;
  }

  hasArrayValue(ary) {
    if (Array.isArray(ary)) {
      return ary.length > 0 ? true : false;
    } else {
      return false;
    }
  }


  public hasRecentSearch() {
    if (this.recentSearches) {
      return this.recentSearches.length;
    } else {
      return false;
    }
  }

  public getRecentSearches(): Promise<any> {
    this.jobsData.getRecentSearches

    let promise = new Promise((resolve:any, reject) => {
      let sessionId = localStorage.getItem('sessionId');
      let currentSessionId = localStorage.getItem('currentSessionId');

      this.jobsData.getRecentSearches(10, sessionId, currentSessionId).subscribe(
        result => {
          this.recentSearches = result;
          resolve();
        },
        error => reject()
      );
    });

    return promise;
  }

  loadRecentSearch(selectedSearch: JobSearchHistoryParameters) {
    if (!selectedSearch) {
      this.initializeParams();
      this.search();
      this.filterName = '';
      this.selectedRecentSearch = null;
      return;
    }

    this.selectedRecentSearch = selectedSearch;
    this.selectedRecentSearch = null;
    this.filterName = '';

    this.emailNotifications = false;

    this.loadSearchParameters(selectedSearch, selectedSearch.locationText);
    this.locationModel.fullText = selectedSearch.locationText;
  }

  loadSavedFilter(selectedFilter: SavedJobSearchParameters) {
    if (!selectedFilter) {
      this.initializeParams();
      this.search();
      this.filterName = '';
      this.selectedRecentSearch = null;
      this.emailNotifications = false;
      return;

    }

    this.selectedFilter = selectedFilter;
    this.selectedRecentSearch = null;
    this.emailNotifications = selectedFilter.emailNotifications;
    this.filterName = selectedFilter.name;

    this.loadSearchParameters(selectedFilter, selectedFilter.locationText);
  }

  loadSearchParameters(searchParameter: JobSearchParameters, locationText: string) {
    this.searchParameters.radiusInMiles = searchParameter.radiusInMiles;
    this.searchParameters.salaryMin = searchParameter.salaryMin;
    this.searchParameters.salaryMax = searchParameter.salaryMax;

    if (searchParameter.employmentType) {
      this.searchParameters.employmentType = <any>searchParameter.employmentType.map((value, key) => EmploymentType[<number>value].toString());
    }

    if (searchParameter.experience) {
      this.searchParameters.experience = <any>searchParameter.experience.map((value, key) => Experience[<number>value].toString());
    }

    if (searchParameter.companies) {
      this.searchParameters.companies = <any>searchParameter.companies.map(val => val.toString());
    }

    this.searchParameters.locations = searchParameter.locations;

    this.searchParameters.lattitude = searchParameter.lattitude;
    this.searchParameters.longitude = searchParameter.longitude;
    this.locationModel = new GoogleLocation();
    this.locationModel.lattitude = searchParameter.lattitude;
    this.locationModel.longitude = searchParameter.longitude;
    this.locationModel.fullText = locationText;
    this.searchQuery = searchParameter.query;
    this.searchParameters.query = searchParameter.query;

    this.search();
  }

  public search(): Promise<any> {
    if (this.loading) return;

    if (this.searchParameters == null) {
      this.initializeParams();
    }

    this.searchParameters.currentSessionId = localStorage.getItem('currentSessionId');

    let promise = new Promise((resolve:any, reject) => {
      //let query = new JobSearchParameters();
      this.searchParameters.query = this.searchQuery;
      if (this.locationModel) {
        this.searchParameters.lattitude = this.locationModel.lattitude;
        this.searchParameters.longitude = this.locationModel.longitude;
        this.searchParameters.locationTextSearch = this.locationModel.fullText;
        this.searchParameters.locationSearchCity  = this.locationModel.city;
        this.searchParameters.locationSearchState = this.locationModel.state;
      } else {
        this.searchParameters.lattitude = null;
        this.searchParameters.longitude = null;
      }
      this.searchParameters.page = 0;

      this.loading = true;
      if (this.searchQuery === undefined || this.searchQuery === null) {
        this.searchQuery = '';
      }

      this.jobsData.search(this.searchParameters).subscribe(
        result => {
          this.jobResults = result;
          this.page = result.page;
          this.jobs = result.jobs;
          this.jobSearchHistoryId = result.jobSearchHistoryId;
          this.loading = false;

          resolve();
        },
        error => reject()
      )
    })
      .then(res => {
        this.getRecentSearches();
      });
    return promise;
  }

  public loadMore(): Promise<any> {
    let promise = new Promise((resolve:any, reject) => {
      this.searchParameters.page = this.page + 1;
      this.jobsData.search(this.searchParameters).subscribe(
        result => {
          this.page = result.page;

          result.jobs.forEach(job => {
            this.jobs.push(job);
          });

          resolve();
        },
        error => reject()
      );
    });
    return promise;
  }

  ngOnInit(): void { }

  reset(): any {
    this.jobResults = null;
    this.jobs = null;
    this.searchQuery = null;
    this.selectedJob = null;
    this.searchParameters = null;
  }
}
