import { Component, Input, OnInit } from '@angular/core';
import { AdManagerService } from "../_services/generatedServices";
import { ServedAdViewModel } from "../_models/generatedModels";
import { guid } from "@progress/kendo-angular-common";
import { UUID } from "../_helpers/extensions.module";
import { cli } from "webpack";

@Component({
  selector: 'ad-display',
  templateUrl: 'banner-ad-display.component.html',
})
export class BannerAdDisplayComponent implements OnInit {
  initialized = false;
  ad: ServedAdViewModel;
   clientId: string;
  @Input() isMargin = false;
  cacheBust = UUID.UUID();

  constructor(private adManagerService: AdManagerService) { }

  ngOnInit(): void {
    if (localStorage.getItem('avertisementGuid') === null) {
      localStorage.setItem('avertisementGuid', UUID.UUID());
    }
    this.clientId = localStorage.getItem('avertisementGuid');
    this.adManagerService.serveAd(this.clientId).subscribe((ad) => {
      this.ad = ad;
      this.initialized = true;
    });
  }

  public adClicked() {
    this.adManagerService.clickAd(this.ad.auctionId, this.clientId).subscribe(clickResult=>{
      localStorage.setItem('ad-attribution-id', clickResult.toString());
    });

    console.log('ad clicked');
  }
}
