import { Component, OnInit, Input } from '@angular/core';
import { ConferenceViewModel, ConferenceViewModelSimple, EventViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'bxl-conference-item',
  styles: [':host { display: contents; }'],
  templateUrl: 'conference-item.component.html',
})
export class ConferenceItemComponent implements OnInit {
  @Input() conference: ConferenceViewModelSimple;
  @Input() rootRoute: string = "/";
  @Input() isGrid = false;
  initialized = false;
  timezone: string;
  constructor(public auth: AuthenticationService) {}

  ngOnInit(): void {
    this.initialized = true;

    let timeZone = moment.tz.guess();
    let timeZoneOffset = new Date(this.conference.startDate).getTimezoneOffset();
    this.timezone = moment.tz(timeZone).format('z');
  }
}
