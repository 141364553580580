<!--component html goes here -->
<page-load-header *ngIf="!initialized"></page-load-header>
<div *ngIf="initialized" class="bg-white">
  <div class="container space-2">
    <div class="row">
      <div class="col-lg-3 mb-7 mb-lg-0">
        <!-- Profile Card -->
        <div class="card p-1 mb-4">
          <div class="card-body text-center">
            <div class="mb-3">
              <img class="img-fluid" [fallback]="'fetch-avatar?userId=' + instructor.id" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ instructor.id }}-avatar.png" />
            </div>

            <div class="mb-3">
              <h1 class="h6 font-weight-medium mb-0">
                {{ instructor.fullName }} <span *ngIf="instructor.pronouns">({{ instructor.pronouns }})</span>
              </h1>
              <small class="d-block text-muted">{{ instructor.credentials }}</small>
            </div>

            <!-- <div class="mb-2">
              <a class="btn btn-sm btn-soft-primary transition-3d-hover" href="#">
                <span class="far fa-envelope mr-2"></span>
                Send a Message
              </a>
            </div> -->
          </div>
        </div>
        <!-- End Profile Card -->

        <!-- Contacts  -->
        <div class="card mb-4">
          <div class="card-header pt-4 pb-3 px-0 mx-4">
            <h2 class="h6 mb-0">Related Speakers</h2>
          </div>

          <div class="card-body pt-3 pb-4 px-4">
            <ng-container *ngFor="let sInstructor of similarInstructors">
              <!-- User -->
              <a class="d-flex align-items-start mb-4" [routerLink]="[conferenceId ? '/' + conferenceId + '/instructors' : '/instructors', sInstructor.slug]">
                <div class="position-relative u-avatar">
                  <img class="img-fluid rounded-circle" [fallback]="'fetch-avatar?userId=' + sInstructor.id" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ sInstructor.id }}-avatar.png"/>
                </div>

                <div class="ml-3">
                  <span class="d-block text-dark">{{ sInstructor.fullName }}</span>
                  <small class="d-block text-secondary">{{ sInstructor.credentials }}</small>
                </div>
              </a>
            </ng-container>
            <!-- End User -->
          </div>
        </div>
        <!-- End Contacts  -->
      </div>

      <div class="col-lg-9">
        <!-- User Details -->
        <div class="mb-4">
          <h2 class="h4">
            {{ instructor.fullName }}<span *ngIf="instructor.pronouns">({{ instructor.pronouns }})</span>
          </h2>
          <h4 class="h6 text-secondary mb-0">{{ instructor.credentials }}</h4>
        </div>
        <!-- End User Details -->

        <!-- Info -->
        <div class="mb-4" innerHTML="{{ instructor.biography }}"></div>
        <!-- End Info -->

        <!-- Collections -->
        <ul class="list-inline d-flex align-items-center">
          <li class="list-inline-item mb-3 mb-sm-0 mr-5">
            <div class="d-flex align-items-center">
              <span class="bg-warning text-white font-weight-medium rounded py-2 px-3 mr-2">{{ courses.length }}</span>
              <div class="text-secondary">Recordings</div>
            </div>
          </li>
          <li class="list-inline-item mb-3 mb-sm-0 mr-5">
            <div class="d-flex align-items-center">
              <span class="bg-info text-white font-weight-medium rounded py-2 px-3 mr-2">{{ events.length }}</span>
              <div class="text-secondary">Upcoming Events</div>
            </div>
          </li>

          <li class="list-inline-item mb-3 mb-sm-0">
            <div class="d-flex align-items-center">
              <img class="mr-2" src="../../assets/svg/illustrations/verified-user.svg" style="width: 40px" />
              <div class="text-secondary">Verified</div>
            </div>
          </li>
        </ul>
        <!-- End Collections -->

        <hr class="my-7" />
        <h2 *ngIf="events && events.length > 0" class="h4 font-weight-medium mb-2">Upcoming Events</h2>
        <div *ngFor="let evt of events" class="border-bottom mb-5">
          <bxl-event-item [event]="evt"></bxl-event-item>
        </div>

        <h2 *ngIf="courses && courses.length > 0" class="h4 font-weight-medium mb-2">Courses</h2>
        <bxl-course-item *ngFor="let course of courses" [course]="course"></bxl-course-item>
      </div>
    </div>
  </div>
</div>
