<page-load-header *ngIf="!initialized"></page-load-header>
<main id="content" *ngIf="initialized" role="main">
 <div [innerHTML]="structuredMetaData"></div>
  <track-job [jobId]="job.id" action="View"></track-job>

  <!-- Hero Section -->
  <div class="container">
    <div class="border-bottom">
      <button *ngIf="fromSearch" type="button" class="btn btn-sm btn-soft-github mt-2 mb-2 mr-2" [routerLink]="['../']" fragment="job{{ job.id }}"><i class="fas fa-arrow-alt-left"></i></button>
      <button *ngIf="!fromSearch && !fromExternal" type="button" class="btn btn-sm btn-soft-github mt-2 mb-4" (click)="sendRouteObject()">Search All Jobs</button>
      <!-- <button *ngIf="fromSearch || fromExternal" type="button" class="btn btn-sm btn-primary mt-2 mb-2" (click)="searchSimilarJobs()"> Search Similar Jobs</button> -->
      <!-- Avatar -->
      <!-- End Avatar -->
      <div class="row">
        <div class="col-sm-12 col-lg-6 mb-3 mb-lg-0">
          <div class="media">
            <div class="u-lg-avatar mr-4">
              <img class="img-fluid" src="/fetchimage?filename={{ job.company.navIconUrl }}&mode=Crop" alt="Image Description" />
            </div>
            <div class="media-body">
              <h1 class="h4 mb-1">
                {{ job.title }}
                <!-- <a [routerLink]="[]" class="ml-1 mr-1 mb-auto align-top">
                  <i *ngIf="job.favorite" (click)="setFavorite(job.id, false)" class="fas fa-heart text-primary font-size-1 align-top"></i>
                  <i *ngIf="!job.favorite" (click)="setFavorite(job.id, true)" class="fal fa-heart text-primary font-size-1 align-top"></i>
                </a> -->
              </h1>
              <a class="d-block" [routerLink]="['/companies', job.companyId]">
                <span class="font-weight-semi-bold text-dark">{{ job.company.name }}</span>
              </a>
            </div>

            <!-- End Review -->
          </div>
        </div>

        <div class="col-sm-12 col-lg-6 text-lg-right">
          <div class="commission-wrapper" *ngIf="job.shareAndEarn">
            <h5 class="cursor-pointer">Commission Eligible <i class="fas fa-trophy-alt"></i></h5>
            <div class="commission-div">
              Corkyy is paid a commission if a candidate is hired through this job post. You can earn 10% of that commission by doing one of two things:
              <ol>
                <li>click the <strong>share icon</strong> on this page to share the job link wherever you like; if someone is hired through your shared link the 10% commission is yours; or</li>
                <li>click the <strong>recommend button</strong> and be the first to recommend specific people for this role. When they get hired, that 10% commission is yours.</li>
              </ol>
              <p class="text-dark">All commission payments are issued through our payment provider, Stripe; a payment fee will be subtracted out of any 10% reward.</p>
              <p class="text-dark">Copying and pasting the URL for this page will not earn you a reward; to share this role and earn the 10%, you must click the share icon or recommend button.</p>
            </div>
          </div>
          <div class="d-flex justify-content-md-end align-items-center">
            <!-- Bookmark -->
            <!-- End Bookmark -->
            <div *ngIf="!job.closed && job.published; else elseClosed" class="btn-group" role="group" aria-label="Basic example">
              <a class="btn btn-sm btn-admin-primary" target="_blank" href="track-job-apply?jobId={{job.id}}&clientIdentifier={{clientIdentifier}}&userId={{userId}}">Apply</a>
            </div>
            <ng-template #elseClosed>We apologize, but the hiring company is no longer accepting applications for this role.</ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Hero Section -->
  <!-- Jobs Description Section -->
  <div class="container">
    <div class="border-bottom space-2">
      <div class="row">
        <div class="col-lg-8 mb-9 mb-lg-0">

          <div class="mb-4">
            <h2 class="h5">Job Description</h2>
            <p class="text-muted font-size-1">Posted {{ job.postDate | timeAgo }}</p>
          </div>

          <div class="mb-7" innerHTML="{{ job.description }}"></div>

          <!-- Title -->
          <div *ngIf="job.skills.length > 0" class="mb-4">
            <h3 class="h5">Skills:</h3>
          </div>
          <!-- End Title -->
          <!-- Skills -->
          <ul class="list-inline mb-7">
            <li *ngFor="let skill of job.skills" class="list-inline-item pb-3">
              <a class="btn btn-xs btn-gray btn-pill" href="">{{ skill }}</a>
            </li>
          </ul>
          <!-- End Skills -->

          <ng-container *ngIf="job.responsibilities && job.responsibilities.length > 0">
            <!-- Title -->
            <div class="mb-4">
              <h3 class="h5">Responsibilities:</h3>
            </div>
            <!-- End Title -->
            <!-- List -->
            <div class="mb-7" innerHTML="{{ job.responsibilities }}"></div>
          </ng-container>

          <ng-container *ngIf="job.requirements && job.requirements.length > 0">
            <!-- Title -->
            <div class="mb-4">
              <h3 class="h5">Requirements:</h3>
            </div>
            <!-- End Title -->
            <!-- List -->
            <div class="mb-7" innerHTML="{{ job.requirements }}"></div>
          </ng-container>

          <ng-container *ngIf="job.company.benefits && job.company.benefits.length > 0">
            <div class="pb-5 mb-5">
              <div class="mb-4">
                <h3 class="h5">Benefits:</h3>
              </div>
              <ul class="list-unstyled mb-7">
                <li *ngFor="let benefit of job.company.benefits" class="py-3">
                  <div class="media">
                    <span class="btn btn-xs btn-icon btn-soft-primary rounded-circle mr-3">
                      <span class="fas fa-check btn-icon__inner"></span>
                    </span>
                    <div class="media-body text-secondary">
                      {{ benefit }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>

        <div class="col-lg-4">
          <div class="pl-lg-4">
            <!-- Sidebar Info -->
            <div class="card border-0 shadow-sm mb-3">
              <!-- Header -->
              <header id="SVGwave1BottomShapeID1" class="card-header border-bottom-0 bg-admin-primary text-white p-0">
                <div class="pt-5 px-5">
                  <h3 class="h5">Job Summary</h3>
                </div>

                <figure [inlineSVG]="'../../assets/svg/components/wave-1-bottom.svg'" class="ie-wave-1-bottom mt-n5"></figure>
              </header>
              <!-- End Header -->
              <!-- Content -->
              <div class="card-body pt-1 px-5 pb-5">
                <!-- Icon Block -->
                <div *ngIf="job.company.website" class="media mb-3">
                  <div class="min-width-4 text-center text-admin-primary mt-1 mr-3">
                    <span class="fas fa-globe"></span>
                  </div>
                  <div class="media-body">
                    <a class="font-weight-medium" target="_blank" href="{{ job.company.website | websiteUrlPipe }}">{{ companyWebsiteClean.replace('www.', '') | websiteUrlPipe: false }}</a>
                    <small class="d-block text-secondary">Website</small>
                  </div>
                </div>
                <!-- End Icon Block -->
                <!-- Icon Block -->
                <div class="media mb-3">
                  <div class="min-width-4 text-center text-admin-primary mt-1 mr-3">
                    <span class="fas fa-map-marked-alt"></span>
                  </div>
                  <div class="media-body">
                    <span class="d-block font-weight-medium">{{ job.companyLocation.city }}, {{ job.companyLocation.state }}</span>
                    <small class="d-block text-secondary">Location</small>
                  </div>
                </div>
                <!-- End Icon Block -->
                <!-- Icon Block -->
                <div class="media mb-3" *ngIf="!job.fromThirdPartyBoard">
                  <div class="min-width-4 text-center text-admin-primary mt-1 mr-3">
                    <span class="fas fa-clock"></span>
                  </div>
                  <div class="media-body">
                    <span class="d-block font-weight-medium">{{ job.employmentType | enumValue: EmploymentType:EmploymentTypeEnum }}</span>
                    <small class="d-block text-secondary">Job type</small>
                  </div>
                </div>
                <!-- End Icon Block -->
                <!-- Icon Block -->
                <ng-container *ngIf="job.salaryPublic">
                  <div class="media mb-3" *ngIf="job.payInterval.toString() == PayInterval[PayInterval.Annually]">
                    <div class="min-width-4 text-center text-admin-primary mt-1 mr-3">
                      <span class="fas fa-money-bill-alt"></span>
                    </div>
                    <div class="media-body">
                      <span class="d-block font-weight-medium"
                        ><span *ngIf="job.payRateMin && job.payRateMin > 0">${{ Math.round(job.payRateMin / 1000) }}k - </span>${{ Math.round(job.payRateMax / 1000) }}k</span
                      >
                      <small class="d-block text-secondary">Salary</small>
                    </div>
                  </div>
                  <div class="media mb-3" *ngIf="job.payInterval.toString() == PayInterval[PayInterval.Hourly]">
                    <div class="min-width-4 text-center text-admin-primary mt-1 mr-3">
                      <span class="fas fa-money-bill-alt"></span>
                    </div>
                    <div class="media-body">
                      <span class="d-block font-weight-medium"
                        ><span *ngIf="job.payRateMin && job.payRateMin > 0">${{ job.payRateMin }} - </span>${{ job.payRateMax }}</span
                      >
                      <small class="d-block text-secondary">Hourly</small>
                    </div>
                  </div>
                </ng-container>
                <!-- End Icon Block -->
                <!-- Icon Block -->
                <div class="media mb-3">
                  <div class="min-width-4 text-center text-admin-primary mt-1 mr-3">
                    <span class="fas fa-business-time"></span>
                  </div>
                  <div class="media-body">
                    <span class="d-block font-weight-medium">{{ job.company.corporateStructure }}</span>
                    <small class="d-block text-secondary">Company Holding</small>
                  </div>
                </div>
                <!-- End Icon Block -->
                <!-- Icon Block -->
                <div class="media mb-3">
                  <div class="min-width-4 text-center text-admin-primary mt-1 mr-3">
                    <span class="fas fa-briefcase"></span>
                  </div>
                  <div class="media-body">
                    <span class="d-block font-weight-medium">{{ job.company.employeeCount | enumValue: EmployeeCount:EmployeeCountEnum }}</span>
                    <small class="d-block text-secondary">Employees</small>
                  </div>
                </div>
                <!-- End Icon Block -->
              </div>
              <!-- End Content -->
            </div>
            <!-- End Sidebar Info -->
            <!-- End Link -->
            <!-- Company About -->
            <div class="mb-4">
              <h3>{{ job.company.name }}</h3>
              <h4 class="h6">About</h4>
              <p class="font-size-1 mb-0" innerHTML="{{ job.company.summary | truncate: 200 }}"></p>
              <a class="font-size-1" [routerLink]="['/companies', job.companyId]">View company profile</a>
            </div>
            <!-- End Company About -->
            <!-- Company Contacts -->
<!--            <div class="mb-7">-->
<!--              <h4 class="h6">Address</h4>-->
<!--              <address class="text-secondary font-size-1">-->
<!--                <span class="d-block mb-2">-->
<!--                  {{ job.company.street }} {{ job.company.street2 }} <br />-->
<!--                  {{ job.company.city }} {{ job.company.state }}, {{ job.company.postalCode }}-->
<!--                </span>-->
<!--              </address>-->
<!--            </div>-->
            <h4 *ngIf="similarCompanies && similarCompanies.length > 0" class="font-size-1 font-weight-semi-bold text-uppercase mb-3">Similar Companies</h4>

            <!-- Related Companies -->
            <ng-container *ngFor="let sCompany of similarCompanies; let i = index">
              <div *ngIf="i < 4" class="media align-items-center mb-3">
                <div class="u-sm-avatar mr-3">
                  <img class="img-fluid" src="/fetchimage?filename={{ sCompany.navIconUrl }}&width=100" alt="Image Description" />
                </div>
                <div class="media-body">
                  <h4 class="font-size-1 mb-0">
                    <a [routerLink]="['/companies', sCompany.id]">{{ sCompany.name }}</a>
                  </h4>

                  <div class="d-md-flex align-items-md-center">
                    <small class="text-muted">{{ sCompany.city }}, {{ sCompany.state }}</small>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- End Related Companies -->
            <!-- View More - Collapse -->
            <div class="collapse" id="collapseRelatedCompanies">
              <!-- Related Companies -->
              <ng-container *ngFor="let sCompany of similarCompanies; let i = index">
                <div *ngIf="i >= 4" class="media align-items-center mb-3">
                  <div class="u-sm-avatar mr-3">
                    <img class="img-fluid" src="/fetchimage?filename={{ sCompany.navIconUrl }}&width=100" alt="Image Description" />
                  </div>
                  <div class="media-body">
                    <h4 class="font-size-1 mb-0">
                      <a [routerLink]="['/companies', sCompany.id]">{{ sCompany.name }}</a>
                    </h4>

                    <div class="d-md-flex align-items-md-center">
                      <small class="text-muted">{{ sCompany.city }}, {{ sCompany.state }}</small>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- End Related Companies -->
              <!-- End Related Companies -->
            </div>
            <!-- End View More - Collapse -->
            <!-- View More - Link -->
            <a *ngIf="similarCompanies && similarCompanies.length > 3" class="link link-collapse small font-size-1" data-toggle="collapse" href="#collapseRelatedCompanies" role="button" aria-expanded="false" aria-controls="collapseRelatedCompanies">
              <span class="link-collapse__default">View more</span>
              <span class="link-collapse__active">View less</span>
              <span class="link__icon ml-1">
                <span class="link__icon-inner">+</span>
              </span>
            </a>
            <!-- End Company Contacts -->
            <!-- Subscribe -->
            <!-- End Subscribe -->
          </div>
        </div>
      </div>

      <!-- Sticky Block End Point -->
      <div id="stickyBlockEndPoint"></div>
    </div>
  </div>
  <!-- End Jobs Description Section -->
  <!-- Featured Jobs Section -->
  <ng-container *ngIf="jobsFromCompany && jobsFromCompany.resultCount > 0">
    <div id="SVGbgElements6" class="position-relative overflow-hidden">
      <div class="container space-2 space-md-3">
        <!-- Title -->
        <div class="d-sm-flex justify-content-sm-between align-items-sm-center mb-5">
          <h2 class="h3 font-weight-medium">More Jobs from this Company ({{ jobsFromCompany.resultCount }})</h2>
          <a class="link-muted" [routerLink]="['/jobs/search']" [queryParams]="{ companyId: job.companyId }">
            See all Jobs
            <span class="fas fa-angle-right small ml-2"></span>
          </a>
        </div>
        <!-- End Title -->

        <div class="row">
          <div class="col-md-12">
            <div class="card-deck">
              <ng-container *ngFor="let job of jobsFromCompany.jobs; let idx = index">
                <div class="card card-frame text-center mb-3">
                  <!-- Card -->
                  <div class="card-body p-6">
                    <div class="u-avatar u-lg-avatar mx-auto mb-4">
                      <img class="img-fluid rounded" src="/fetchimage?filename={{ job.thumbnailUrl }}&width=178&height=106&mode=Crop" alt="Image Description" />
                    </div>
                    <div class="mb-4">
                      <h4 class="h6 mb-1">{{ job.title }}</h4>
                      <p>{{ job.city }}, {{ job.state }}</p>
                    </div>
                    <a class="btn btn-sm btn-soft-primary btn-wide" [routerLink]="['/jobs', job.id]" [queryParams]="{ c: '1' }">View Details</a>
                  </div>
                  <!-- End Card -->
                </div>
                <div class="job-details w-100 d-xs-block d-md-none"></div>
                <div *ngIf="(idx + 1) % 2 === 0" class="job-details w-100 d-none d-sm-block d-lg-none"></div>
                <div *ngIf="(idx + 1) % 3 === 0" class="job-details w-100 d-none d-lg-block"></div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <!-- SVG Background Element -->
      <figure class="position-absolute top-0 right-0 z-index-n1 w-100 h-100">
        <img class="js-svg-injector" src="../../assets/svg/components/bg-elements-6.svg" alt="SVG" data-parent="#SVGbgElements6" />
      </figure>
      <!-- End SVG Background Element -->
    </div>
  </ng-container>

  <!-- Similar Jobs -->
  <!-- <div *ngIf="similarJobs && similarJobs.length > 0" class="container">

    <div class="w-md-80 w-lg-50 mb-9">
      <h2 class="font-weight-medium">Similar Jobs</h2>
      <button *ngIf="fromSearch || fromExternal" type="button" class="btn btn-sm btn-primary mt-2 mb-2" (click)="searchSimilarJobs()"> Search Similar Jobs</button>

    </div>

    <div *ngIf="similarJobs" class="card-deck d-block d-lg-flex">

      <div *ngFor="let job of similarJobs" class="card mb-5 mb-lg-0">
        <div class="card-header p-0 border-bottom-0">

          <div class="d-flex align-items-center mb-2">

            <div class="btn-group">
              <button type="button" class="btn btn-sm btn-soft-dark btn-icon transition-3d-hover dropdown-toggle hide-arrow rounded-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="fas fa-user-cog btn-icon__inner"></span>
              </button>

              <div class="dropdown-menu">
                <a class="dropdown-item" [apply-button]="job" href="#">Apply</a>
                <a class="dropdown-item" [recommend-button]="job" href="#">Recommend</a>
                <div *ngIf="auth.isLoggedIn()" class="dropdown-divider"></div>
                <a *ngIf="auth.isLoggedIn()" class="dropdown-item" [share-button]="job" href="#">Share</a>
              </div>
            </div>
          </div>

        </div>
        <div class="card-body p-4">
          <div class="text-center">
            <div class="mx-auto mb-3">
              <a [routerLink]="['/jobs', job.id]" [queryParams]="{c: '2'}">
                <img class="img-fluid" src="/fetchimage?filename={{ job.thumbnailUrl }}&width=178&height=106&mode=Pad" alt="Image Description" />
              </a>
            </div>


            <div class="mb-4">
              <h3 class="h5 mb-1">
                <a [routerLink]="['/jobs', job.id]" [queryParams]="{c: '2'}">{{ job.title }}</a>
              </h3>
              <ul class="list-inline font-size-1 text-muted">
                <li class="list-inline-item">
                  <a class="link-muted" [routerLink]="['/companies', job.companyId]">{{ job.companyName }}</a>
                </li>
              </ul>
            </div>


            <p *ngIf="job.teaser">{{ job.teaser }}</p>
            <p *ngIf="!job.teaser">{{ removeHtml(job.description) | truncate: [80, '...'] }}</p>
          </div>
        </div>

        <div class="card-footer text-center py-4">

          <div class="row align-items-center">
            <div class="col-6 u-ver-divider">
              <h4 class="small text-secondary mb-0">Location</h4>
              <small class="fas fa-map-marker-alt text-secondary align-middle mr-1"></small>
              <span class="align-middle">{{ job.city + ', ' + job.state }}</span>
            </div>
            <div class="col-6">
              <h4 class="small text-secondary mb-0">Type</h4>

              <span class="align-middle">{{ job.employmentType | enumValue: EmploymentType:EmploymentTypeEnum }}</span>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div> -->

<!--  <app-scroll-top></app-scroll-top>-->
</main>
<!-- ========== END MAIN CONTENT ========== -->
