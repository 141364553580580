import { ConferenceMobileTileViewModel, PageElementViewModel, SponsorshipProductViewModel } from './generatedModels';
import * as customBuild from 'src/ckCustomBuild/ckeditor';


export class ChatViewModel {
  eventLinkId: string;
  constructor(id: number, name: string, message: string, userId: number) {
    this.name = name;
    this.message = message;
    this.userId = userId;
    this.id = id;
  }
  public id: number;
  public name: string;
  public message: string;
  public userId: number;
  public systemMessage: boolean;
  public eventLink: boolean;
  public tapBacks: any[];
}

export class RichTextEditorComponent {
  editor = customBuild.ClassicEditor as {
    create: any;
  };
  //...
}

export class GoogleObj {
  q: string[];
  target: string;
}

export class Caption {
  public caption: string;
  public isFinal: boolean;
}

export class PrinterSettingsState {
  public isPDF: boolean;
  public onePerPage: boolean;
  selectedPrinter?: number;
}

export class SpreadsheetColumn {
  constructor(field:string, required:boolean){
    this.field = field;
    this.required = required;
  }
  public field: string;
  public required: boolean;
}

export class ProductListingData {
  public selectedTags: string[];
  public selectedFormat: number;
  public includeNoTags: boolean;
}

export class Translation {
  translatedText: string;
  detectedSourceLanguage: string;
}

export class Data {
  translations: Translation[];
}

export class GoogleTranslateResponse {
  data: Data;
}

export class PagedResult<T> {
  public currentPage: number;
  public pageCount: number;
  public pageSize: number;
  public rowCount: number;
  public firstRowOnPage: number;
  public lastRowOnPage: number;
  public results: T[];
}

export class TokenModel {
  public token: string;
  public tokenNumber: number;
}

export class FAQuery {
  public query: string;
}

// export class SponsorshipCartModel {
//   public product: SponsorshipProductViewModel;
//   public variationId?: number;
//   public variationName: string;
//   public qty: number;
// }

export enum EventRole {
  Host,
  Guest,
  Viewer,
}

export enum RegistrantEditMode {
  Add,
  ChangePackage,
  Transfer,
}

export class DraggablePageElementViewModel extends PageElementViewModel {
  public temp?: boolean;
  public title: string;
  public iconClass: string;
  public showForOrganization: boolean;
}

export class DraggableConferenceMobileTileViewModel extends ConferenceMobileTileViewModel {
  public temp?: boolean;
}

export class SelectedDevice {
  constructor(audioDeviceId: string, videoDeviceId: string) {
    this.audioDeviceId = audioDeviceId;
    this.videoDeviceId = videoDeviceId;
  }
  public audioDeviceId: string;
  public videoDeviceId: string;
}
