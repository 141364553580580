<div class="vh-100 d-flex">
  <div class="row">
    <div class="col">
      <div class="alert alert-warning">Please look over these common solutions and if you don't see your answer, then please chat our team.</div>

      <div class="p-2">
        <div>
          <ul class="text-prechat">
            <li>
              <div class="d-block"><strong>Stream is not playing</strong></div>
              Most of the time a simple reloading of the event will fix this. Click the 3 dots at the top, and reload.
            </li>
            <li>
              <div class="d-block"><strong>How do I get CEUs</strong></div>
              Click 3 out of the 4 attendance checks that show randomly throughout. Once the event is over, you can add a review and get your certificate in CEU History
            </li>
            <li>
              <div class="d-block"><strong>I missed a CEU check</strong></div>
              No worries! You need 3 out of 4 checks to pass, but If you are here, you will get CEUs. You can resolve problems AFTER the event in CEU History
            </li>
            <li>
              <div class="d-block"><strong>Add BACB #</strong></div>
              If you haven't yet, you can add your BACB number in "CEU History" in "My Account".
            </li>
          </ul>
        </div>
      </div>
      <h3 class="my-auto mx-auto text-center"><button type="button" (click)="openSupportChat()" class="btn btn-sm btn-admin-primary">Chat With us</button></h3>
    </div>
  </div>
</div>
