<ng-container *ngIf="!isGrid">
  <div class="card-body pb-5">
    <!-- Details -->
    <div class="row">
      <div class="col-md-4 d-md-flex align-items-md-start flex-md-column mb-5 mb-md-0">
        <!-- Gallery -->
        <img class="img-fluid w-100" src="https://bxlimages.blob.core.windows.net/splash/{{ conference.marketingSplashPath }}" [fallback]="'https://bxlimages.blob.core.windows.net/splash/c7164287-3135-4309-b3c8-4768092714e4Coming_soon.jpg'"  />
      </div>

      <div class="col-md-8">
        <!-- Header -->
        <div class="media mb-2">
          <div class="media-body">
            <h1 class="h5 mb-1">
              <a [routerLink]="['/','conferences', conference.slug]"> {{ conference.name }}</a>
            </h1>
            <ul class="list-inline font-size-1 text-muted"></ul>
            <!-- Icon Blocks -->
            <ul class="list-inline font-size-1">
              <li *ngIf="conference.ceuCount > 0 || conference.ceuCountOverride > 0" class="list-inline-item mr-3" title="1 bedroom">
                <span class="fad fa-certificate text-muted mr-1"></span>
                <span *ngIf="!conference.ceuCountOverride"> {{ conference.ceuCount | pluralizer: 'CEU':'CEUs' }}</span>
                <span *ngIf="conference.ceuCountOverride"> {{ conference.ceuCountOverride | pluralizer: 'CEU':'CEUs' }}</span>
              </li>
            </ul>
            <!-- End Icon Blocks -->
          </div>

          <div class="d-flex ml-auto"></div>
        </div>
        <!-- End Header -->

        <div class="mb-5">
          <!-- <p>{{ conference.homePageText | stripHtml | truncate: 400 }}</p> -->
        </div>

        <div class="d-md-flex align-items-md-center">
          <!-- Location -->
          <div class="u-ver-divider u-ver-divider--none-md pr-4 mb-3 mb-md-0 mr-4">
            <h2 class="small text-secondary mb-0">{{ 'date' | translate }}</h2>
            <small class="fas fa-calendar-alt text-secondary align-middle mr-1"></small>
            <span class="align-middle">{{ conference.startDate | date }}</span>
          </div>
          <div class="u-ver-divider u-ver-divider--none-md pr-4 mb-3 mb-md-0 mr-4">
            <h2 class="small text-secondary mb-0">{{ 'time' | translate }}</h2>
            <small class="fas fa-clock text-secondary align-middle mr-1"></small>
            <span class="align-middle"
              >{{ conference.startDate | date: 'shortTime' }} <strong>{{ timezone }}</strong></span
            >
          </div>

          <a class="btn btn-sm btn-primary ml-auto" [routerLink]="['/','conferences', conference.slug]">{{ 'view-details' | translate }}</a>
        </div>
      </div>
    </div>
    <!-- End Details -->
  </div>
</ng-container>

<ng-container *ngIf="isGrid">
  <!-- Property Item -->
  <a class="card mb-5" [routerLink]="['/','conferences', conference.slug]">
    <!-- Gallery -->
    <img class="card-img-top w-100" style="border-bottom: 1px solid #e7eaf3" src="https://bxlimages.blob.core.windows.net/splash/{{ conference.marketingSplashPath }}" [fallback]="'https://bxlimages.blob.core.windows.net/splash/c7164287-3135-4309-b3c8-4768092714e4Coming_soon.jpg'"  />

    <!-- End Gallery -->

    <div class="card-body p-4">
      <div class="row">
        <div class="col-12">
          <h6 class="h6 text-dark mb-1">
            <span *ngIf="conference.shortName"
              ><strong>{{ conference.shortName }}</strong></span
            >
            <span *ngIf="!conference.shortName"
              ><strong>{{ conference.name }}</strong></span
            >
          </h6>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center font-size-1">
        <ul class="list-inline font-size-1">
          <li class="list-inline-item mr-3 text-dark" title="1 bedroom">
            <span *ngIf="!conference.ceuCountOverride"> {{ conference.ceuCount | pluralizer: 'CEU':'CEUs' }}</span>
            <span *ngIf="conference.ceuCountOverride"> {{ conference.ceuCountOverride | pluralizer: 'CEU':'CEUs' }}</span>
          </li>
        </ul>

        <ul class="list-inline font-size-1 ml-auto">
          <li class="list-inline-item text-dark text-right" title="1 bedroom">
            <strong> {{ conference.startDate | date: 'shortDate' }}</strong>
          </li>
        </ul>
      </div>
    </div>
  </a>
  <!-- End Property Item -->
</ng-container>
