import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { CourseService } from '../_services/generatedServices';
import { CourseViewModel, SegmentType } from '../_models/generatedModels';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../_services/authentication.service';
import { AnalyticsService } from '../_services/analytics.service';

@Component({
  selector: 'bxl-course-details',
  templateUrl: 'course-details.component.html',
})
export class CourseDetailsComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription;
  currentQuestion = 0;
  course: CourseViewModel;
  initialized: boolean;
  query: string;
  SegmentType = SegmentType;
  notAuthorized = false;

  constructor(private route: ActivatedRoute, private analyticsService: AnalyticsService, private router: Router, private courseData: CourseService, private modalService: NgbModal, public auth: AuthenticationService, private elRef: ElementRef) {}
  id: any;
  lessonHeight: string;
  ngOnInit(): void {
    this.routeSubscription = combineLatest(this.route.data, this.route.paramMap).subscribe(([data, params]) => {
      this.id = params.get('id');
      this.courseData.getSpecificCourse(this.id).subscribe(
        (course) => {
          this.course = course;
          this.analyticsService.setTitleAndLogPageView('Course - ' + course.title);

          this.initialized = true;
        },
        (error) => {
          this.notAuthorized = true;
          this.initialized = true;
        }
      );
    });
  }

  coursePurchased(course: CourseViewModel) {
    console.log('purchased');
    console.log(course);

    this.course = course;
  }

  search() {
    this.router.navigate(['/course-library'], { queryParams: { q: this.query } });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
