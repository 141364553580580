import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { JobSearchParameters, EmploymentType, Experience, SavedJobSearchParameters, NameValueViewModel } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { UserService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NavigationService } from "../../_services/navigation-service";
import { JobSearchService } from "../../_services/job-search.service";

@Component({
  selector: 'job-search-filter',
  templateUrl: 'job-search-filter.component.html'
})
export class JobSearchFilterComponent implements OnInit {
  //searchParameters: JobSearchParameters;
  public experienceType = Enums.ExperienceEnum;
  public employmentType = Enums.EmploymentTypeEnum;
  @Output() criteriaChanged = new EventEmitter<JobSearchParameters>();
  @Output() filterCreated = new EventEmitter<SavedJobSearchParameters>();
  @Output() filterUpdated = new EventEmitter<SavedJobSearchParameters>();
  @Output() filterDeleted = new EventEmitter<SavedJobSearchParameters>();
  public employmentTypes: NameValueViewModel[] = [];
  public experienceTypes: NameValueViewModel[] = [];
  @Input() companies: NameValueViewModel[];
  @Input() locations: NameValueViewModel[];
  @Input() showContext: boolean;

  constructor(public searchService: JobSearchService, public auth: AuthenticationService, public user: UserService, private nav: NavigationService, private cd: ChangeDetectorRef, public toastr: ToasterService) {
    this.employmentType.forEach((value, key) => {
      let model = new NameValueViewModel();
      model.value = key.toString();
      model.name = value;
      this.employmentTypes.push(model);
    });

    this.experienceType.forEach((value, key) => {
      let model = new NameValueViewModel();
      model.value = key.toString();
      model.name = value;
      this.experienceTypes.push(model);
    });
  }

  minValue: number = 50;
  maxValue: number = 200;
  // options: Options = {
  //   floor: 0,
  //   ceil: 255,
  //   step: 5,
  //   translate: (value: number): string => {
  //     if (value === 255) {
  //       return 'no limit';
  //     }
  //     return value + 'K';
  //   }
  // };

  changed(evt) {
    this.criteriaChanged.emit(this.searchService.searchParameters);
  }

  clearFilters() {
    this.searchService.clearFilters();
    this.criteriaChanged.emit(this.searchService.searchParameters);
  }

  saveFilter() {
    if (!this.auth.isLoggedIn()) {
      this.nav.showLogin();
      return;
    }

    let model = new SavedJobSearchParameters();
    model.name = this.searchService.filterName;
    model.emailNotifications = this.searchService.emailNotifications;
    if (this.searchService.locationModel) {
      model.locationText = this.searchService.locationModel.fullText;
    }

    Object.assign(model, this.searchService.searchParameters);
    this.user.createFilter(model).subscribe(filter => {
      this.filterCreated.emit(filter);
      this.searchService.selectedFilter = filter;
      this.toastr.success('Filter Added', 'Success');
      window.scrollTo(0, 0);
    });
  }

  deleteFilter() {
    this.toastr.confirmDialog('Are you sure you want to delete this filter?', 'Are you sure?').subscribe(result => {
      if (result) {
        this.user.deleteFilter(this.searchService.selectedFilter.id).subscribe(filter => {
          this.filterDeleted.emit(this.searchService.selectedFilter);
          this.searchService.loadSavedFilter(null);
          window.scrollTo(0, 0);
        });
      }
    });
  }

  updateFilter() {
    let model = new SavedJobSearchParameters();
    model.name = this.searchService.filterName;
    model.emailNotifications = this.searchService.emailNotifications;
    if (this.searchService.locationModel) {
      model.locationText = this.searchService.locationModel.fullText;
    }

    Object.assign(model, this.searchService.searchParameters);
    this.user.updateFilter(this.searchService.selectedFilter.id, model).subscribe(filter => {
      this.filterUpdated.emit(filter);
      this.searchService.selectedFilter = filter;
      this.toastr.success('Filter Updated', 'Success');
      window.scrollTo(0, 0);
    });
  }

  updateCheckbox(evt, property) {
    console.log(evt.target.value);
    console.log(evt.target.checked);
    let index = property.indexOf(evt.target.value);

    if (evt.target.checked) {
      if (index === -1) {
        property.push(evt.target.value);
      }
    } else {
      if (index > -1) {
        property.splice(index, 1);
      }
    }
  }

  updateCompanies(evt) {
    this.updateCheckbox(evt, this.searchService.searchParameters.companies);
    this.changed(evt);
  }

  updateLocation(evt) {
    this.updateCheckbox(evt, this.searchService.searchParameters.locations);
    this.changed(evt);
  }

  updateEmploymentType(evt) {
    this.updateCheckbox(evt, this.searchService.searchParameters.employmentType);
    this.changed(evt);
  }

  updateExperience(evt) {
    this.updateCheckbox(evt, this.searchService.searchParameters.experience);
    this.changed(evt);
  }

  ngOnInit(): void {}
}
