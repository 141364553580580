///<reference path="../../../../src/azuremediaplayer.d.ts"/>
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CourseViewModel, CourseReviewViewModel, CardTokenViewModel, CourseEventStatus } from 'src/app/_models/generatedModels';
import { of, Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationModalComponent } from 'src/app/events/registration/registration-modal.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CourseService } from 'src/app/_services/generatedServices';
import { Enums } from 'src/app/_models/generatedEnums';
import { Router } from '@angular/router';

@Component({
  selector: 'bxl-unpurchased-course',
  templateUrl: 'unpurchased-course.component.html',
})
export class UnpurchasedCourseComponent implements OnInit, OnDestroy {
  myPlayer: amp.Player;
  reviews: CourseReviewViewModel[];
  submitComplete: Promise<{}> | undefined;
  CourseEventStatus = CourseEventStatus;
  CourseEventStatusEnum = Enums.CourseEventStatusEnum;
  @Output() coursePurchased = new EventEmitter<CourseViewModel>();
  loginSubscription: Subscription;

  constructor(private modalService: NgbModal, private router: Router,  public auth: AuthenticationService, public courseService: CourseService) {}
  @Input() course: CourseViewModel;

  ngOnInit(): void {
    this.fetchReviews();
    this.initializePlayer();
  }

  fetchReviews() {
    this.loginSubscription = this.auth.loggedIn.subscribe((loginResult) => {
      this.courseService.getSpecificCourse(this.course.id.toString()).subscribe((event) => {
        this.course.userHasPurchased = event.userHasPurchased;
      });
    });

    this.courseService.getReviews(this.course.id).subscribe((result) => {
      this.reviews = result;
    });
  }

  initializePlayer() {
    of(true)
      .pipe(
        delay(500),
        tap(() => {
          this.setupPlayer();
        })
      )
      .subscribe();
  }

  setupPlayer() {
    if (!this.course.trailerAssetId) {
      return;
    }

    let source = {};

    // source = { src: this.course.trailerAssetId, type: 'video/mp4' };
    source = { src: this.course.trailerAssetId, type: 'video/mp4' };

    console.log(source);

    let options = {
      autoplay: false,
      controls: true,
      fluid: false,
      logo: { enabled: false },
      ampAds: {
        mainProgram: {
          source: source,
          // tracks: tracks,
        },
      },
    };

    this.myPlayer = amp('vplayer', options);
  }

  purchase() {
    const modalRef = this.modalService.open(RegistrationModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' , backdrop: 'static'});
    modalRef.componentInstance.course = this.course;

    modalRef.result.then(
      (result) => {
        this.coursePurchased.emit(result);
        this.router.navigate(['/on-demand/', this.course.slug]);
      },
      (reason) => {}
    );
    return;
  }


  ngOnDestroy(): void {
    console.log('disposing');
    if (this.myPlayer) {
      console.log('disposed media player');
      this.myPlayer.dispose();
    }
    this.loginSubscription.unsubscribe();
  }
}
