<div class="card">
  <!-- Header -->
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="h6 mb-0">{{ 'polls' | translate }}</h3>

      <button *ngIf="showInput" type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <!-- End Header -->

  <!-- Body -->
  <div style="position: relative">
    <div *ngIf="loadError || loading" style="z-index: 9999; position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-content: center; align-items: center">
      <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; opacity: 0.6; background-color: black"></div>
      <div *ngIf="!loading" style="z-index: 10000">
        <div class="text-light">Failed to load polls</div>
        <div class="text-center"><button type="button" (click)="reload()" class="btn btn-light">Try again</button></div>
      </div>
      <page-load-header *ngIf="loading"></page-load-header>
    </div>
    <div #pollContainer class="card-body" style="">
      <ng-container *ngFor="let poll of polls">
        <div *ngIf="poll.visible || role != EventRole.Viewer" class="participant-list" style="">
          <div>
            <ng-container *ngIf="!isEditMode(poll.id)">
              <h4>{{ poll.questionText }}</h4>
              <div *ngFor="let option of poll.options" class="d-flex mb-2">
                <div *ngIf="role == EventRole.Viewer" class="mr-4 p-0 d-flex align-items-center">
                  <button *ngIf="isSelected(poll, option)" type="button" class="btn btn-sm btn-success" (click)="vote(poll, option)">{{ 'vote' | translate }}</button>
                  <button *ngIf="!isSelected(poll, option)" type="button" class="btn btn-sm btn-outline-success" (click)="vote(poll, option)">{{ 'vote' | translate }}</button>
                </div>
                <div class="option-wrapper">
                  <div class="bar-contain">
                    <div *ngIf="poll.totalResponses > 0" class="bar" [ngStyle]="{ width: option.responseCount / poll.totalResponses | percent, display: option.responseCount / poll.totalResponses == 0 ? 'none' : '' }"></div>
                  </div>
                  <div class="p-1">
                    <span class="option-label">{{ option.optionText }}</span>
                    <span *ngIf="poll.totalResponses > 0" class="option-label-votes text-muted">{{ option.responseCount | pluralizer: 'vote':'votes' }} ({{ option.responseCount / poll.totalResponses | percent }})</span>
                    <span *ngIf="poll.totalResponses == 0" class="option-label-votes text-muted">{{ option.responseCount | pluralizer: 'vote':'votes' }} (0%)</span>
                  </div>
                </div>
              </div>
              <div *ngIf="role != EventRole.Viewer" class="d-flex p-0 justify-content-between">
                <div>
                  <button type="button" class="btn btn-xs btn-outline-dark" (click)="editPoll(poll)">{{ 'edit' | translate }}</button>
                  <button type="button" class="btn btn-xs btn-outline-danger ml-1" (click)="resetData(poll)">{{ 'reset-data' | translate }}</button>
                </div>
                <div *ngIf="poll.visible">
                  <button type="button" disabled class="btn btn-xs btn-warning mr-1">{{ 'visible' | translate }}</button>
                  <button type="button" (click)="setVisible(poll, false)" class="btn btn-xs btn-outline-warning">{{ 'hidden' | translate }}</button>
                </div>
                <div *ngIf="!poll.visible">
                  <button type="button" (click)="setVisible(poll, true)" class="btn btn-xs btn-outline-warning mr-1">{{ 'visible' | translate }}</button>
                  <button type="button" disabled class="btn btn-xs btn-warning">{{ 'hidden' | translate }}</button>
                </div>
              </div>
            </ng-container>
            <poll-editor *ngIf="isEditMode(poll.id)" [eventId]="event.id" [poll]="poll"></poll-editor>
          </div>
        </div>
      </ng-container>
      <div *ngIf="creating" class="participant-list" style="">
        <div>
          <poll-editor (created)="pollCreated($event)" (cancelled)="pollCreateCancelled()" [eventId]="event.id"></poll-editor>
        </div>
      </div>

      <div class="text-center mt-3">
        <button *ngIf="role != EventRole.Viewer" type="button" class="btn btn-primary" (click)="createPoll()">{{ 'create-a-poll' | translate }}</button>
      </div>
    </div>
  </div>

  <!-- Footer -->

  <!-- End Footer -->
</div>
