import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'bxl-my-account-container',
  templateUrl: 'my-account-container.component.html',
})
export class MyAccountContainerComponent implements OnInit {
  constructor(public auth: AuthenticationService, private titleService: Title) { }
   navItems = [
    { routerLink: ['profile'], text: 'My Profile' },
    { routerLink: ['library'], text: 'My Library' },
    { routerLink: ['events'], text: 'My Upcoming Events' },
    { routerLink: ['live-events'], text: 'My Presentations' },
    // { routerLink: ['subscription'], text: 'Subscription' },
    { routerLink: ['listings'], text: 'Course Listings' },
    { routerLink: ['ceus'], text: 'CEU History' },
    { routerLink: ['paper-submissions'], text: 'Papers' },
    { routerLink: ['change-password'], text: 'Change Password' },
    { routerLink: ['payments'], text: 'Banking & Travel' },
    { routerLink: ['sales-history'], text: 'Sales History' },
  ];

  ngOnInit(): void {
    this.titleService.setTitle('My Account - BehaviorLive');
  }
}
