import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'corkyy-confirm-modal',
  templateUrl: 'confirm-modal.component.html'
})
export class ConfirmModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  @Input() title: string;
  @Input() message: string;
  @Input() ok: string;
  @Input() cancel: string;
  @Input() singleButton: boolean;
  @Input() showSpinner: boolean;
  @Input() showProgress: boolean;
  @Input() percentage: number;
  @Input() error: boolean;
}
