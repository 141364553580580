import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'corkyy-not-found-layout',
  templateUrl: 'jobNotFound.component.html'
})
export class JobNotFoundComponent implements OnInit {
  constructor(private location: Location, private router: Router) {}

  ngOnInit(): void {}

  searchJobs() {
    this.router.navigate(['jobs', 'search']);
  }
}
