<div *ngIf="initialized" class="card">
  <!-- Header -->
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="h6 mb-0">{{ 'ceu-certificate' | translate }}</h3>

      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <!-- End Header -->

  <!-- Body -->
  <div class="card-body pb-1">
    <div class="participant-list">
      <div>
        <div class="text-center my-auto" *ngIf="completionStatus.metCEUCriteria">
          <h3>{{ 'event-complete' | translate }}</h3>
          <h6>You can download you certificate from <strong>CEU History</strong> in <strong>My Account</strong></h6>
          <a *ngIf="event.conferenceId" role="button" class="btn btn-sm btn-instagram" href="javascript:void(0)" [routerLink]="['/account/ceus/conference-ceus',event.conferenceId, 'event-summaries']" (click)="close()">CEU History</a>
          <a *ngIf="!event.conferenceId" role="button" class="btn btn-sm btn-instagram" href="javascript:void(0)" [routerLink]="['/account/ceus/individual-ceus', event.id]" (click)="close()">CEU History</a>
        </div>

        <ng-container *ngIf="!completionStatus.metCEUCriteria">
          <div class="alert alert-info mb-0 font-size-1">{{ 'ceu-instructions' | translate }}</div>
          <div *ngIf="ceuError" class="alert alert-danger mb-0 mt-1">{{ ceuError }}</div>

          <form [formGroup]="formGroup" (ngSubmit)="onSave()" novalidate>
            <div class="modal-body">
              <label for="messageTextArea">{{ 'leave-feedback' | translate }}</label>
              <div class="form-group">
                <star-rating formControlName="rating"></star-rating>
                <div class="invalid-message">
                  <div>{{ 'please-provide-a-star-rating' | translate }}</div>
                </div>
              </div>

              <div class="form-group">
                <textarea id="messageTextArea" class="form-control" style="transform: translateZ(0px)"  placeholder="{{ 'feedback-text' | translate }}" formControlName="text"></textarea>
                <div class="invalid-message">
                  <div>{{ 'feedback-text-is-required' | translate }}</div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col text-right pr-0">
                <button type="submit" class="btn btn-success">{{ 'complete-event' | translate }}</button>
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- End Footer -->
</div>
