<div *ngIf="initialized" class="card">
  <!-- Header -->
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="h6 mb-0">Share Videos</h3>

      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <!-- End Header -->

  <!-- Body -->

  <div #videoContainer class="card-body" style="">
    <div *ngIf="!browserSupported" class="alert alert-danger">{{browser}} does not support video sharing. Please use Chrome or Firefox if you intend on sharing videos.</div>
    <div *ngIf="browserSupported" class="participant-list" style="">
      <div>
        <h3 *ngIf="isPlaying" class="text-center"><button type="button" (click)="stopSharing()" class="btn btn-sm btn-danger transition-3d-hover" tooltip="Stop Video" placement="top" show-delay="250">Stop Sharing</button></h3>
        <ng-container *ngIf="!isPlaying">
          <ul class="mb-0">
            <ng-container *ngFor="let video of videos">
              <li class="mb-0">
                <div class="d-flex align-items-center pb-3">
                  <div class="mr-auto">
                    <span class="participant-name ml-2">{{ video.title }} </span>
                  </div>
                  <span *ngIf="video.encoded" class="participant-menu ml-2">
                    <button type="button" class="btn btn-sm btn-success transition-3d-hover" style="white-space: nowrap;" (click)="playVideo(video)" tooltip="Play Video" placement="top" show-delay="250">Share Video</button>
                  </span>
                  <span *ngIf="!video.encoded" class="participant-menu ml-2">
                    Video Processing...
                  </span>
                </div>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div *ngIf="percentComplete >= 0" class="card-footer">
    <h5>Uploading...</h5>
    <div class="d-flex align-items-center pb-3">
      <div *ngIf="percentComplete >= 0" class="progress mt-4" style="height: 20px; width: 100%">
        <div class="progress-bar bg-alternate" role="progressbar" [ngStyle]="{ width: percentComplete + '%' }" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{{ percentComplete }}%</div>
      </div>
    </div>
  </div>
  <div *ngIf="!percentComplete || percentComplete <= 0 && browserSupported && !isPlaying " class="card-footer d-flex ">
    <label *ngIf="role != EventRole.Viewer" class="btn mr-auto btn-sm btn-github transition-3d-hover file-attachment-btn mr-3" for="fileAttachmentBtn">
      Pre-Upload Video
      <input accept="video/*" [promiseBtn]="uploading" (change)="onFileChange($event)" id="fileAttachmentBtn" name="file-attachment" type="file" class="file-attachment-btn__label" />
    </label>
    <label *ngIf="role != EventRole.Viewer && !playingLocal" class="ml-auto btn btn-sm btn-github transition-3d-hover file-attachment-btn" for="fileAttachmentBtn">
      Play Local Video
      <input accept="video/*" [promiseBtn]="uploading" (change)="playLocalVideo($event)" id="fileAttachmentBtn" name="file-attachment" type="file" class="file-attachment-btn__label" />
    </label>
  </div>
</div>
