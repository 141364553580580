import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_services/generatedServices';
import { StringViewModel } from '../_models/generatedModels';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'bxl-ms-o-auth-complete',
  templateUrl: 'ms-o-auth-complete.component.html'
})
export class MsOAuthCompleteComponent implements OnInit, OnDestroy {
  initialized = false;
  oauthCode: string;
  querySubscription: Subscription;
  errorMessage: string;
  constructor(private route: ActivatedRoute, private auth: AuthenticationService, private router: Router) {}

  ngOnInit(): void {
    this.querySubscription = this.route.queryParamMap.subscribe(params => {
      this.oauthCode = params.get('code');

      this.auth.loginOffice365(this.oauthCode).subscribe(
        result => {
          this.router.navigate(['/']);
        },
        error => {
          this.errorMessage = 'There was an error logging you in. Please try again. <br> If the problem persists, please contact support.';
        }
      );
    });

    this.initialized = true;
  }

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
  }
}
