import { OnInit, Input, ElementRef, Renderer2, Directive, OnChanges, SimpleChanges } from '@angular/core';
import { CourseViewModel, CourseEventStatus } from '../_models/generatedModels';
import { AuthenticationService } from '../_services/authentication.service';

@Directive({
  selector: '[course-purchase-button]',
})
export class CoursePurchaseButtonDirective implements OnChanges {
  @Input('course-purchase-button') course: CourseViewModel;

  constructor(private element: ElementRef, private renderer: Renderer2, private auth: AuthenticationService) {}

  ngOnInit(): void {
    this.applyStyles();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.course) {
      this.applyStyles();
    }
  }

  applyStyles() {
    this.renderer.removeClass(this.element.nativeElement, 'btn-success');
    this.renderer.removeClass(this.element.nativeElement, 'btn-danger');
    this.renderer.removeClass(this.element.nativeElement, 'btn-warning');

    if (this.course.price === 0) {
      if (this.course.userHasPurchased && this.course.courseEventStatus.toString() === CourseEventStatus[CourseEventStatus.ReleaseWithConference] && !this.course.released) {
        this.element.nativeElement.innerHTML = 'Preordered!';
        this.renderer.addClass(this.element.nativeElement, 'btn-warning');
      } else if (this.course.courseEventStatus.toString() === CourseEventStatus[CourseEventStatus.ReleaseWithConference] && !this.course.released) {
        this.element.nativeElement.innerHTML = 'Preorder For Free';
        this.renderer.addClass(this.element.nativeElement, 'btn-success');
      } else {
        this.element.nativeElement.innerHTML = 'Get For Free';
        this.renderer.addClass(this.element.nativeElement, 'btn-success');
      }
    } else if (this.course.price > 0) {
      if (this.course.userHasPurchased && this.course.courseEventStatus.toString() === CourseEventStatus[CourseEventStatus.ReleaseWithConference] && !this.course.released) {
        this.element.nativeElement.innerHTML = 'Preordered!';
        this.renderer.addClass(this.element.nativeElement, 'btn-warning');
      } else if (this.course.courseEventStatus.toString() === CourseEventStatus[CourseEventStatus.ReleaseWithConference] && !this.course.released) {
        this.element.nativeElement.innerHTML = 'Preorder';
        this.renderer.addClass(this.element.nativeElement, 'btn-danger');
      } else {
        this.element.nativeElement.innerHTML = 'Purchase';
        this.renderer.addClass(this.element.nativeElement, 'btn-danger');
      }
    }
  }
}
