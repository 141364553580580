import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'public-jobs-container',
  template: `<router-outlet></router-outlet>`
})
export class PublicJobsContainerComponent implements OnInit {
  constructor() {

   }

  ngOnInit(): void { }
}
