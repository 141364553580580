<page-load-header *ngIf="!initialized"></page-load-header>
<div *ngIf="initialized" class="container space-2 ad-container">
  <div class="site-content">
    <h4 *ngIf="conferences.length > 0">All Conferences</h4>

    <ng-container *ngFor="let conference of conferences; let idx=index">
      <div class="card mb-5">
        <div class="card-body p-4">
          <div class="media d-block d-sm-flex">
            <div class="mb-3 mb-sm-0 mr-4">
              <img *ngIf="conference.marketingSplashPath" class="img-fluid" style="max-width: 250px;" src="https://bxlimages.blob.core.windows.net/splash/{{ conference.marketingSplashPath }}" alt=""/>
            </div>

            <div class="media-body">
              <div class="media mb-2">
                <div class="media-body">
                  <div class="row">
                    <div class="col-md-8">
                      <h1 class="h5 mb-1">
                        <a [routerLink]="['/','conferences', conference.slug]">{{ conference.name }}</a>
                      </h1>

                      <div class="d-md-flex align-items-md-center">
                        <div class="u-ver-divider u-ver-divider--none-md pr-4 mb-3 mb-md-0 mr-4">
                          <h2 class="small text-secondary mb-0">Date</h2>
                          <small class="fas fa-calendar-alt text-secondary align-middle mr-1"></small>
                          <span class="align-middle">{{ conference.startDate | date: 'shortDate' }} - {{ conference.endDate | date: 'shortDate' }}</span>
                        </div>
                        <div class="u-ver-divider u-ver-divider--none-md pr-4 mb-3 mb-md-0 mr-4">
                          <h2 class="small text-secondary mb-0">Total CEUs</h2>
                          <small class="fad fa-certificate text-secondary align-middle mr-1"></small>
                          <span class="align-middle">
                        <span *ngIf="!conference.ceuCountOverride"> {{ conference.ceuCount | pluralizer: 'CEU':'CEUs' }}</span>
                        <span *ngIf="conference.ceuCountOverride"> {{ conference.ceuCountOverride | pluralizer: 'CEU':'CEUs' }}</span>
                      </span>
                        </div>
                        <div *ngIf="conference.city && conference.state" class="mb-3 mb-md-0">
                          <h4 class="small text-secondary mb-0">Location</h4>
                          <span class="align-middle">{{ conference.city + ', ' + conference.state }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right">
                        <div class="position-relative d-inline-block text-left">
                          <div aria-label="Basic example" class="btn-group" role="group">
                            <a class="btn btn-sm btn-primary" [routerLink]="['/','conferences', conference.slug]">View Details</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="idx%5 == 0 && idx>0" class="mt-1">
        <ad-display [isMargin]="false"></ad-display>
      </div>
      <div class="py-3"></div>

    </ng-container>
  </div>
  <div class="ad-content">
    <ad-display [isMargin]="true"></ad-display>
  </div>
</div>
