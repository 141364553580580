import { Component, OnInit } from '@angular/core';
declare var zE: any;

@Component({
  selector: 'bxl-hof-home',
  templateUrl: 'hof-home.component.html',
})
export class HofHomeComponent implements OnInit {
  initialized = false;
  constructor() {}

  ngOnInit(): void {
    zE('webWidget', 'hide');
    zE('webWidget:on', 'close', () => {
      zE('webWidget', 'hide');
    });

    zE('webWidget:on', 'chat:unreadMessages', () => {
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    });

    setTimeout(() => {
      this.initialized = true;
    }, 2000);
  }
}
