<div #fullscreenContainer class="hof-container d-flex">
  <div [@triggerName]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
  <nav class="navbar fixed-bottom navbar-light bg-light hof-nav">
    <a class="navbar-brand" [routerLink]="['contributions']"><i class="yellow-text fas fa-star"></i> Major Contributions</a>
    <a class="navbar-brand"[routerLink]="['milestones']"><i class="yellow-text fas fa-star"></i> Career Milestones</a>
    <a class="navbar-brand"[routerLink]="['legacy']"><i class="yellow-text fas fa-star"></i> Legacy</a>
    <a class="navbar-brand"[routerLink]="['publications']"><i class="yellow-text fas fa-star"></i> Publications</a>
    <a class="navbar-brand ml-auto" [routerLink]="['../']"><i class="yellow-text fas fa-star"></i> Inductees</a>
  </nav>
</div>
