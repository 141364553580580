import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SettingsProvider } from '../../_services/settingsProvider.service';

@Component({
  selector: 'main-layout',
  templateUrl: './mainLayout.component.html'
})
export class MainLayout {
  year: number;

  constructor(public settings: SettingsProvider) {
    this.year = new Date().getFullYear();
  }

}
