import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OpentokService } from "../_services/opentok.service";
import { Caption } from "../_models/models";
import Enumerable from "linq";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import { OrganizationService, StreamBoxService } from "../_services/generatedServices";
import { ConferenceRoomViewModel } from "../_models/generatedModels";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'bxl-captions-display',
  templateUrl: 'captions-display.component.html',
})
export class CaptionsDisplayComponent implements OnInit {
  initialized = false;
  private session: OT.Session;
  captions: Caption[] = [];
  strCaptions: string [] = [''];
  strCaptionsDisplay: string[] = [];
  currentIndex = 0;
  @ViewChild('card') private card: ElementRef;
  rooms: ConferenceRoomViewModel[];
  private conferenceSlug: string;
  public selectedRoom: number;
  fontSize: number = 20;
  private subscription: OT.Subscriber;


  constructor(private route: ActivatedRoute, private streamboxService: StreamBoxService, private openTokService: OpentokService, private organizationService: OrganizationService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((parentParam) => {
      this.conferenceSlug = parentParam.get('conferenceSlug');
      this.organizationService.getRoomsForConference(this.conferenceSlug).subscribe((result) => {
        this.rooms = Enumerable.from(result).where(x => x.channel != null).toArray();
        this.initialized = true;
      });
    });
  }

  public scrollToBottom(check: boolean): void {
    if (!this.card || !this.card.nativeElement) {
      return;
    }
    of(null)
      .pipe(delay(500))
      .subscribe((result) => {
        this.card.nativeElement?.scroll({
          top: this.card.nativeElement?.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      });
  }

  public roomUpdated() {
    if (this.session) {
      this.session.off('streamCreated');
      this.session.unsubscribe(this.subscription);
      this.session.disconnect();
      this.session = null;
    }
    console.log('room updated to: ' + this.selectedRoom);

    this.streamboxService.getStreamboxForRoomId(this.selectedRoom).subscribe((result) => {
      console.log("LOading captions for channel: " + result.azureChannel);
      this.InitOpenTok(result.id);
    });
  }

  private InitOpenTok(streamboxId: number) {
    this.openTokService
      .initsessionForStreamBoxCaptions(streamboxId)
      .then((session: OT.Session) => {
        this.session = session;
        this.session.on('streamCreated', (event) => {
          console.log('here');
          console.log(event.stream.name);
          console.log(event.stream.connection.data);

          this.subscription = this.session.subscribe(event.stream, 'videoCanvas', {
            insertMode: 'append',
            subscribeToAudio: false,
            subscribeToVideo: false,
            preferredResolution: {
              width: 1280,
              height: 720
            },
            style: {nameDisplayMode: 'off'},
          });
          this.subscription.element.hidden = true;

          this.subscription.subscribeToCaptions(true);
          this.subscription.on('captionReceived', function (event) {
            console.log(`Caption received for stream ${event.streamId}`);
            console.log(`Caption text: ${event.caption}`);
            console.log(`Final text: ${event.isFinal}`);
            // var caption = new Caption();
            // caption.caption = event.caption;
            // caption.isFinal = event.isFinal;

            this.strCaptions[this.currentIndex] = event.caption;
            if (event.isFinal) {
              this.strCaptions.push('');
              this.strCaptionsDisplay = Enumerable.from(this.strCaptions).skip(this.strCaptions.length - 3).take(3).toArray();
              this.currentIndex++;
            } else {
              this.strCaptionsDisplay = Enumerable.from(this.strCaptions).skip(this.strCaptions.length - 2).take(2).toArray();
            }

            this.scrollToBottom(true);

            this.ref.detectChanges();

          }, this)
        });

      }).then(() => this.openTokService.connect())
      .catch((err) => {
        console.error(err);
        alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
      });
  }

  public increaseFont() {
    this.fontSize += 5;
  }

  public decreaseFont() {
    this.fontSize -= 5;
  }


}
