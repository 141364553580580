import { Component, Input, forwardRef, ElementRef, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NameValuePair } from '../_models/generatedModels';

@Component({
  selector: 'filter-dropdown',
  host: {},
  //encapsulation: ViewEncapsulation.None,
  template: `
    <!-- <div class="btn-group">
      <button aria-expanded="false" aria-haspopup="true" class="btn btn-xs btn-soft-primary dropdown-toggle ml-1" data-toggle="dropdown" id="dropdownMenuButton2" type="button">
        <span>Organization</span>
      </button>
      <div aria-labelledby="dropdownMenuButton2" class="dropdown-menu" id="dropdownMenuButton2" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(4px, 32px, 0px)">
        <button class="dropdown-item selected" href="#"><i class="dropdown-check fas fa-check"></i> No Preference</button>
        <div class="dropdown-divider"></div>
        <button *ngFor="let conf of options" class="dropdown-item" href="#"><i class="dropdown-check fas fa-check"></i> {{ conf.name }}</button>
      </div>
    </div> -->

    <div class="btn-group">
      <button aria-expanded="false" aria-haspopup="true" class="btn btn-xs btn-soft-primary dropdown-toggle ml-1" data-toggle="dropdown" id="dropdownMenuButton2" type="button">
        <span>{{ title }}</span>
      </button>
      <div aria-labelledby="dropdownMenuButton2" class="dropdown-menu" id="dropdownMenuButton2" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(4px, 32px, 0px)">
        <button class="dropdown-item" [ngClass]="{ selected: selectedItem == null }" href="#" (click)="selectedItem = null; onChange()"><i class="dropdown-check fas fa-check"></i> No Preference</button>
        <div class="dropdown-divider"></div>
        <button *ngFor="let conf of options" href="#" class="dropdown-item" [ngClass]="{ selected: conf.value == selectedItem }" (click)="selectedItem = conf.value; onChange()"><i class="dropdown-check fas fa-check"></i> {{ conf.name }}</button>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDropdownControl),
      multi: true,
    },
  ],
})
export class FilterDropdownControl implements ControlValueAccessor {
  @Input() title: string;
  @Input() selectedItem: number;
  @Input() options: NameValuePair[];

  cssClass: string = '';

  someAttr: string = 'anyValue';

  constructor(elementRef: ElementRef) {
    this.cssClass = elementRef.nativeElement.getAttribute('class');
    elementRef.nativeElement.setAttribute('class', '');
  }

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  writeValue(value: number): void {
    console.log(value);
    this.selectedItem = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  onChange() {
    this.propagateChange(this.selectedItem);
    this.propagateTouch();
  }

  onTouch() {
    this.propagateTouch();
  }
}
