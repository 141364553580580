<div class="hof-page-wrapper">
  <div class="hof-title mb-auto mx-auto text-white text-center">
    <div class="row mb-3" style="overflow: hidden">
      <div class="col-md-12">
        <img class="img-fluid" src="https://behaviorlive.com/assets/wiba-hof/overall-timeline.png" >
        <a [routerLink]="'../inductees/bea'" title="" style="position: absolute; left: 11.72%; top: 20.4%; width: 8.75%; height: 26.3%; z-index: 2;"></a>
        <a [routerLink]="'../inductees/evalyn'" title="" style="position: absolute; left: 40.45%; top: 20.09%; width: 10.47%; height: 31.65%; z-index: 2;"></a>
        <a [routerLink]="'../inductees/judith'" title="" style="position: absolute; left: 58.38%; top: 71.14%; width: 9.96%; height: 27.62%; z-index: 2;"></a>
        <a [routerLink]="'../inductees/martha'" title="" style="position: absolute; left: 24.38%; top: 70.83%; width: 11.41%; height: 28.55%; z-index: 2;"></a>

      </div>
    </div>
  </div>
</div>
