import { Injectable } from '@angular/core';

import * as OT from '@opentok/client';
import { EventService, LiveEventService, ExhibitService, PosterService, LobbyService, StreamBoxService } from './generatedServices';

@Injectable()
export class OpentokService {
  session: OT.Session;
  token: string;

  constructor(private eventService: LiveEventService,private streamBoxService: StreamBoxService, private lobbyService: LobbyService, private exhibitService: ExhibitService, private posterService: PosterService) {}

  getOT() {
    return OT;
  }

  initSessionForEvents(eventId: number): Promise<OT.Session> {
    let promise = new Promise<OT.Session>((resolve) => {
      this.eventService.getOpenTokToken(eventId).subscribe((result) => {
        this.session = this.getOT().initSession('46745212', result.sessionId);
        this.token = result.token;
        resolve(this.session);
      });
    });
    return promise;
  }

  initSessionForSlideControl(): Promise<OT.Session> {
    let promise = new Promise<OT.Session>((resolve) => {
      this.eventService.getOpenTokTokenForSlides().subscribe((result) => {
        this.session = this.getOT().initSession('46745212', result.sessionId);
        this.token = result.token;
        resolve(this.session);
      });
    });
    return promise;
  }

  initSessionForExhibits(exhibitId: number): Promise<OT.Session> {
    let promise = new Promise<OT.Session>((resolve) => {
      this.exhibitService.getOpenTokToken(exhibitId).subscribe((result) => {
        this.session = this.getOT().initSession('46745212', result.sessionId);
        this.token = result.token;
        resolve(this.session);
      });
    });
    return promise;
  }

  initsessionForLobby(lobbyId: number): Promise<OT.Session> {
    let promise = new Promise<OT.Session>((resolve) => {
      this.lobbyService.getOpenTokToken(lobbyId).subscribe((result) => {
        this.session = this.getOT().initSession('46745212', result.sessionId);
        this.token = result.token;
        resolve(this.session);
      });
    });
    return promise;
  }

  initsessionForStreamBox(streamBoxId: number): Promise<OT.Session> {
    let promise = new Promise<OT.Session>((resolve) => {
      this.streamBoxService.getOpenTokToken(streamBoxId).subscribe((result) => {
        this.session = this.getOT().initSession('46745212', result.sessionId);
        this.token = result.token;
        resolve(this.session);
      });
    });
    return promise;
  }
  initsessionForStreamBoxCaptions(streamBoxId: number): Promise<OT.Session> {
    let promise = new Promise<OT.Session>((resolve) => {
      this.streamBoxService.getOpenTokCaptionToken(streamBoxId).subscribe((result) => {
        this.session = this.getOT().initSession('46745212', result.sessionId);
        this.token = result.token;
        resolve(this.session);
      });
    });
    return promise;
  }

  initsessionForMultiview(): Promise<OT.Session> {
    let promise = new Promise<OT.Session>((resolve) => {
      this.streamBoxService.getOpenTokTokenForMultiView().subscribe((result) => {
        this.session = this.getOT().initSession('46745212', result.sessionId);
        this.token = result.token;
        resolve(this.session);
      });
    });
    return promise;
  }

  initSessionForPosters(posterId: number): Promise<OT.Session> {
    let promise = new Promise<OT.Session>((resolve) => {
      this.posterService.getOpenTokToken(posterId).subscribe((result) => {
        this.session = this.getOT().initSession('46745212', result.sessionId);
        this.token = result.token;
        resolve(this.session);
      });
    });
    return promise;
  }



  connect() {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(this.session);
        }
      });
    });
  }
}
