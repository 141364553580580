import { Injectable } from '@angular/core';
import { PaperSubmissionFilterViewModel, PaperSubmissionSummaryViewModel, PaperTagViewModel, PaperTypeViewModel } from '../_models/generatedModels';
import { AdminCallForPapersService, CallForPapersService, OrganizationService } from './generatedServices';

@Injectable()
export class PaperSearchService {
  currentPage = 0;
  pageSize = 20;
  resultCount = 0;
  filter: PaperSubmissionFilterViewModel;
  papers: PaperSubmissionSummaryViewModel[];
  paperTypes: PaperTypeViewModel[];
  tags: PaperTagViewModel[];
  organizationId: any;
  conferenceId: number;
  initialized: boolean;
  myPapers: boolean;
  searching = false;

  constructor(private adminCFP: AdminCallForPapersService, private cfp: CallForPapersService, private orgService: OrganizationService) {}

  initialize(orgId: number, confId: number, myPapers: boolean, resetSearch: boolean): Promise<any> {
    let promise = new Promise((resolve) => {
      if (this.initialized && orgId == this.organizationId && !resetSearch) {
        resolve(null);
        console.log('already initialized');
        return;
      }

      this.searching = true;
      this.organizationId = orgId;
      this.myPapers = myPapers;
      this.conferenceId = confId;
      this.currentPage = 0;
      this.pageSize = 20;
      this.resultCount = 0;

      this.filter = <PaperSubmissionFilterViewModel>JSON.parse(localStorage.getItem('paperSumbissionFilterData1' + this.conferenceId));

      if (!this.filter) {
        this.filter = new PaperSubmissionFilterViewModel();
        this.filter.paperApprovalStatus = [];
        this.filter.beforeDeadline = null;
        this.filter.paperSubmissionSort = null;
        this.filter.searchText = '';
        this.filter.includedTypes = [];
        this.filter.tags = [];
      }

      // //if (!this.filter.tags){
      //   this.filter.tags = [];


      this.cfp.getPaperTypesForConference(this.conferenceId.toString()).subscribe((types) => {
        this.adminCFP.getSubmissionsForConference(this.organizationId, this.conferenceId, this.myPapers, this.filter, this.currentPage, this.pageSize).subscribe((pagedResult) => {
          this.adminCFP.getTagsForConference(this.organizationId, this.conferenceId).subscribe((tags) => {
            this.currentPage = pagedResult.currentPage;
            this.pageSize = pagedResult.pageSize;
            this.resultCount = pagedResult.rowCount;
            this.papers = pagedResult.results;
            this.tags = tags;
            this.paperTypes = types;
            this.searching = false;
            this.initialized = true;
          });
        });
      });
    });
    return promise;
  }

  getNextPaperId(currentPaperId: number): number {
    if (!this.papers) {
      return -1;
    }
    var index = this.papers.findIndex((paper) => paper.id == currentPaperId) + 1;
    if (index == -1) {
      return -1;
    }

    if (!this.papers[index]) {
      return -1;
    }

    return this.papers[index].id;
  }

  getPreviousPaperId(currentPaperId: number): number {
    if (!this.papers) {
      return -1;
    }
    var index = this.papers.findIndex((paper) => paper.id == currentPaperId) - 1;
    if (index == -1) {
      return -1;
    }

    if (!this.papers[index]) {
      return -1;
    }

    return this.papers[index].id;
  }

  refetch(resetPaging: boolean) {
    this.searching = true;
    this.papers = [];
    if (resetPaging) {
      this.currentPage = 0;
    }
    localStorage.setItem('paperSumbissionFilterData1' + this.conferenceId, JSON.stringify(this.filter));
    this.adminCFP.getSubmissionsForConference(this.organizationId, this.conferenceId, this.myPapers, this.filter, this.currentPage, this.pageSize).subscribe((pagedResult) => {
      this.currentPage = pagedResult.currentPage;
      this.pageSize = pagedResult.pageSize;
      this.resultCount = pagedResult.rowCount;
      this.papers = pagedResult.results;
      this.searching = false;
    });
  }

  paginate(event) {
    this.currentPage = event.page;
    this.pageSize = event.rows;
    this.refetch(false);
    window.scroll({
      top: 0,
      left: 0,
    });
  }
}
