import { Component, Input, OnInit } from '@angular/core';
import { CEUViewModel } from '../_models/generatedModels';
import Enumerable from 'linq';

@Component({
  selector: 'bxl-ceu-display',
  templateUrl: 'ceu-display.component.html',
})
export class CeuDisplayComponent implements OnInit {
  initialized = false;
  @Input () CEUs: CEUViewModel[];
  noCeus: boolean;
  constructor() { }

  ngOnInit(): void {

    this.noCeus = Enumerable.from(this.CEUs).sum(x=>x.ceuCount) == 0
    this.initialized = true;
  }
}
