import { Component, Input, OnInit } from '@angular/core';
import { AdManagerService } from "../_services/generatedServices";
import { ServedAdViewModel } from "../_models/generatedModels";
import { guid } from "@progress/kendo-angular-common";
import { UUID } from "../_helpers/extensions.module";
import { cli } from "webpack";
import { AuthenticationService } from "../_services/authentication.service";

@Component({
  selector: 'track-job',
  templateUrl: 'job-tracker.component.html',
})
export class JobTrackerComponent implements OnInit {
  initialized = false;
  @Input() jobId:number;
  @Input() action:string;
  clientId: string;
  cacheBust = UUID.UUID();
   userId: number | undefined;

  constructor(public authService: AuthenticationService) { }

  ngOnInit(): void {
    if (localStorage.getItem('avertisementGuid') === null) {
      localStorage.setItem('avertisementGuid', UUID.UUID());
    }
    this.clientId = localStorage.getItem('avertisementGuid');

    if (this.authService.isLoggedIn() && this.authService.getUser()) {
      this.userId = this.authService.getUser()?.id;
    }
    this.initialized = true;
  }
}
