import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewChecked, OnDestroy } from '@angular/core';
import { EventViewModel, LiveEventViewModel, UserAuthenticationViewModel, PollViewModel, PollOptionViewModel } from 'src/app/_models/generatedModels';
import { ChatViewModel, EventRole } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { HubConnection } from '@microsoft/signalr';
import { of, interval, Subscription } from 'rxjs';
import { PollService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

export class SelectedOption {
  public pollId: number;
  public optionId: number;
}

@Component({
  selector: 'live-polls',
  templateUrl: 'live-polls.component.html',
})
export class LivePollsComponent implements OnInit, OnDestroy {
  initialized = false;
  creating = false;
  public user: UserAuthenticationViewModel;
  private unreadCount = 0;
  @Input() role: EventRole;
  public EventRole = EventRole;

  private selectedOptions: SelectedOption[] = [];
  private pollsInEditMode: number[] = [];

  @Input() event: LiveEventViewModel;
  @Input() connection: HubConnection;
  @Input() showInput: boolean;

  @ViewChild('pollContainer') private pollContainer: ElementRef;
  @Output()
  closed = new EventEmitter();

  @Output()
  unreadChanged = new EventEmitter<number>();

  @Output()
  countChanged = new EventEmitter<number>();
  intervalSubscription: Subscription;
  currentPollCount = 0;
  int: any;
  polls: PollViewModel[];
  loadError: boolean;
  loading: boolean;

  constructor(private auth: AuthenticationService, private pollService: PollService, private toaster: ToasterService) {}

  ngOnInit(): void {
    this.user = this.auth.getUser();

    this.connection.on('pollUpdated', (eventId: number, poll: PollViewModel) => {
      if (eventId === this.event.id) {
        let index = this.polls.findIndex((x) => x.id === poll.id);
        this.polls[index] = poll;
        this.doneEditing(poll);
      }
    });

    this.connection.on('pollCreated', (eventId: number, poll: PollViewModel) => {
      if (eventId === this.event.id) {
        this.polls.push(poll);

        if (this.pollContainer.nativeElement.offsetHeight === 0 && poll.visible) {
          this.unreadCount = 1;
          this.unreadChanged.emit(this.unreadCount);
        }
        this.checkVisible();
        this.doneEditing(poll);
      }
    });

    this.connection.on('pollDeleted', (eventId: number, pollId: number) => {
      if (eventId === this.event.id) {
        let index = this.polls.findIndex((x) => x.id === pollId);
        if (index >= 0) {
          this.polls.splice(index, 1);
        }
        let editIndex = this.pollsInEditMode.findIndex((x) => x === pollId);
        if (editIndex >= 0) {
          this.pollsInEditMode.splice(editIndex, 1);
        }

        this.checkVisible();
      }
    });

    this.connection.on('pollVisibiltyUpdated', (eventId: number, poll: PollViewModel) => {
      if (eventId === this.event.id) {
        let index = this.polls.findIndex((x) => x.id === poll.id);
        this.polls[index] = poll;
        if (this.pollContainer.nativeElement.offsetHeight === 0 && poll.visible) {
          this.unreadCount = 1;
          this.unreadChanged.emit(this.unreadCount);
        }
        this.checkVisible();
      }
    });

    this.reload();
  }

  reload() {
    this.loading = true;
    this.pollService.getPollsForEvent(this.event.id).subscribe((polls) => {
      this.polls = polls;
      this.intervalSubscription = interval(1000).subscribe((val) => this.checkUnread());
      this.checkVisible();
      this.initialized = true;
      this.loading = false;
      this.loadError = false;
    }, error=>{
      this.loadError = true;
      this.loading = false;
    });
  }

  editPoll(poll: PollViewModel) {
    let index = this.pollsInEditMode.findIndex((x) => x === poll.id);
    if (index >= 0) {
      this.pollsInEditMode.splice(index, 1);
    }
    this.pollsInEditMode.push(poll.id);
  }

  resetData(poll: PollViewModel) {
    this.toaster.confirmDialog('Are you sure you want to reset the data for this poll? This cannot be undone.', 'Are you sure?').subscribe((result) => {
      this.pollService.resetPoll(this.event.id, poll.id).subscribe((pollResult) => {
        this.toaster.success('Poll has been reset', 'Success');
      });
    });
  }

  pollCreated(poll: PollViewModel) {
    this.creating = false;
  }

  pollCreateCancelled() {
    this.creating = false;
  }

  doneEditing(poll: PollViewModel) {
    console.log('done editing');
    let pindex = this.polls.findIndex((x) => x.id === poll.id);
    this.polls[pindex] = poll;

    let index = this.pollsInEditMode.findIndex((x) => x === poll.id);
    if (index >= 0) {
      this.pollsInEditMode.splice(index, 1);
    }
  }

  vote(poll: PollViewModel, option: PollOptionViewModel) {
    this.pollService.logResponse(this.event.id, poll.id, option.id).subscribe((result) => {
      this.selectedOptions = this.selectedOptions.filter((x) => x.pollId !== poll.id);
      let selectedOption = new SelectedOption();
      selectedOption.optionId = option.id;
      selectedOption.pollId = poll.id;
      this.selectedOptions.push(selectedOption);

      let index = this.polls.findIndex((x) => x.id === poll.id);
      this.polls[index] = result;
    });
  }

  isSelected(poll: PollViewModel, option: PollOptionViewModel): boolean {
    return this.selectedOptions.findIndex((x) => x.pollId === poll.id && option.id === x.optionId) >= 0;
  }

  isEditMode(pollId: number) {
    return this.pollsInEditMode.findIndex((x) => x === pollId) >= 0;
  }

  checkUnread(): void {
    if (this.pollContainer.nativeElement.offsetHeight > 0) {
      this.unreadChanged.emit(0);
      this.unreadCount = 0;
    }
  }

  checkVisible(): void {
    let visiblePollCount = this.polls.filter((x) => x.visible).length;

    if (this.currentPollCount !== visiblePollCount) {
      this.countChanged.emit(visiblePollCount);
      this.currentPollCount = visiblePollCount;
    }
  }

  setVisible(poll: PollViewModel, val: boolean) {
    this.pollService.updatePollVisibility(this.event.id, poll.id, val).subscribe((result) => {
      let index = this.polls.findIndex((x) => x.id === poll.id);
      this.polls[index] = result;
    });
  }

  close() {
    this.closed.emit();
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  createPoll() {
    this.creating = true;
  }
}
