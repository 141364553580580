import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-mobile-test',
  templateUrl: 'mobile-test.component.html',
})
export class MobileTestComponent implements OnInit {
  initialized = false;
  data = "test";
  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
    this.data = window.localStorage.getItem("behaviorlive-currentUser");
  }

  loadData(){
    this.data = window.localStorage.getItem("behaviorlive-currentUser");
    alert('here');
  }
}
