import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class OrganizationClaimsService {
  public OrganizationId: number;

  constructor(private auth: AuthenticationService) {}

  public hasClaim(claimName: string): boolean {
    if (this.auth.isSuperUser()){
      return true;
    }

    var hasIt = this.auth.hasAdminClaim(this.OrganizationId, claimName);
    return hasIt;
  }
}
