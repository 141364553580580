import { Component, OnInit, OnDestroy } from '@angular/core';
import { CourseService, StaticDataService } from 'src/app/_services/generatedServices';
import { CourseViewModel, CourseSearchParameters, TagViewModel, SearchFilterDataModel, NameValuePair } from 'src/app/_models/generatedModels';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import { OnDemandSearchService } from 'src/app/_services/on-demand-search-service';

@Component({
  selector: 'bxl-course-list',
  templateUrl: 'course-list.component.html',
})
export class CourseListComponent implements OnInit, OnDestroy {
  filterData: SearchFilterDataModel;
  loadingMore: boolean;
  constructor(private courseData: CourseService, public searchService: OnDemandSearchService, public settings: SettingsProvider, private staticData: StaticDataService, private route: ActivatedRoute) {}
  initialized = false;

  ngOnInit(): void {
    this.searchService.initialize(null).then(() => {
      this.courseData.getFilterData().subscribe((result) => {
        this.filterData = result;
        this.initialized = true;
      });
    });
  }

  ngOnDestroy(): void {
    console.log('here');
  }

  loadMore() {
    this.loadingMore = true;
    this.searchService.loadMore().then(() => {
      this.loadingMore = false;
    });
  }
}
