import { Component, OnInit, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService, LiveEventService } from 'src/app/_services/generatedServices';
import { EventViewModel, CardTokenViewModel, StringViewModel, EventDisplayViewModel, MaterialViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationModalComponent } from '../registration/registration-modal.component';
import { Subscription, interval } from 'rxjs';
import * as moment from 'moment';
import 'moment-timezone';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import { AnalyticsService } from 'src/app/_services/analytics.service';
import { HttpClient, HttpEventType, HttpRequest } from "@angular/common/http";

@Component({
  selector: 'bxl-event-details',
  templateUrl: 'event-details.component.html',
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  eventIdOrSlug: any;
  event: EventDisplayViewModel;
  public question: string;
  initialized: boolean;
  private checker: Subscription;
  timezone: string;
  routeSubscription: Subscription;
  isConference: any;
  notAuthorized = false;
  loginSubscription: Subscription;
   materials : MaterialViewModel[];

  constructor(public settings: SettingsProvider, private http:HttpClient, private location: Location, private titleService: AnalyticsService, private route: ActivatedRoute, private eventData: EventService, private modalService: NgbModal, private liveEventService: LiveEventService, public auth: AuthenticationService, private zone: NgZone, private toastr: ToasterService) {}

  ngOnInit(): void {
    this.route.data.subscribe((routeData) => {
      this.isConference = routeData['isConference'];

      this.routeSubscription = this.route.paramMap.subscribe((params) => {
        this.eventIdOrSlug = params.get('id');

        this.loginSubscription = this.auth.loggedIn.subscribe((loginResult) => {
          if (this.event && !this.event.userHasPurchased) {
            this.eventData.getSpecificEvent(this.eventIdOrSlug).subscribe((event) => {
              this.event.userHasPurchased = event.userHasPurchased;
            });
          }
        });

        this.eventData.getSpecificEvent(this.eventIdOrSlug).subscribe(
          (event) => {
            this.event = event;
            this.eventData.getMaterials(this.event.id).subscribe((materials) => {
              this.materials = materials;
                this.checker = interval(60000).subscribe((val) => this.checkJoin());
                let timeZone = moment.tz.guess();
                let timeZoneOffset = new Date(this.event.startTime).getTimezoneOffset();
                this.timezone = moment.tz.zone(timeZone).abbr(timeZoneOffset);
                this.titleService.setTitleAndLogPageView(event.title);
                this.initialized = true;
              },
              (error) => {
                this.notAuthorized = true;
                this.initialized = true;
              }
            );
          });
      });
    });
  }

  goBack() {
    this.location.back();
  }

  checkJoin(): void {
    this.liveEventService.getAllowJoin(this.event.id).subscribe((result) => {
      this.event.allowJoin = result.value;
    });
  }

  sendQuestion() {
    let model = new StringViewModel();
    model.value = this.question;
    this.eventData.addQuestion(this.event.id, model).subscribe((result) => {
      this.question = null;
      this.toastr.success('Question Saved', 'Success');
    });
  }

  register(): void {
    const modalRef = this.modalService.open(RegistrationModalComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static'
    });
    modalRef.componentInstance.event = this.event;
    modalRef.result.then(
      (event) => {
        this.event = event;
      },

      (reason) => {}
    );
    return;
  }

  ngOnDestroy(): void {
    this.checker.unsubscribe();
    this.routeSubscription.unsubscribe();
    this.loginSubscription.unsubscribe();
  }

  downloadMaterial(material: MaterialViewModel) {
    var url = "https://bxlimages.blob.core.windows.net/materials/" + material.blobPath;
    var modalRef = this.toastr.progressDialog('Downloading file, please wait...', 'File Download').subscribe((progressSpinner) => {
      const request = new HttpRequest('GET', url, {
        reportProgress: true,
        responseType: 'blob',
      });

      this.http.request(request).subscribe((event) => {
        // progress
        if (event.type === HttpEventType.DownloadProgress) {
          const percentage = Math.floor((100 / event.total) * event.loaded);
          progressSpinner.componentInstance.percentage = percentage;
        }

        // finished
        if (event.type === HttpEventType.Response) {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(<Blob>event.body);
          a.href = objectUrl;
          a.download = material.fileName;
          a.click();
          URL.revokeObjectURL(objectUrl);
          progressSpinner.close();
        }
      });
    });
  }
}
