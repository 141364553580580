import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var zE: any;

@Component({
  selector: 'bxl-inductee-title',
  templateUrl: 'inductee-title.component.html',
})
export class InducteeTitleComponent implements OnInit {
  initialized = false;
  page: string;
  sub: number;
  person: string;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.page = this.route.snapshot.url[0].path;
    console.log(this.page);

    this.route.paramMap.subscribe((routeParams) => {
      this.route.queryParamMap.subscribe((params) => {
        this.sub = +params.get('sub');
        this.person = routeParams.get('id');
        console.log(this.person);
      });
    });

    this.initialized = true;
    zE('webWidget', 'hide');
  }
}
