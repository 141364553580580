import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewChecked, OnDestroy } from '@angular/core';
import { EventViewModel, LiveEventViewModel, UserAuthenticationViewModel, EventVideoViewModel, AzureBlobSASViewModel } from 'src/app/_models/generatedModels';
import { ChatViewModel, EventRole } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { HubConnection } from '@microsoft/signalr';
import { of, interval, Subscription, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { EventVideoService } from 'src/app/_services/generatedServices';
import { ISasToken } from 'src/app/_services/azureStorage';
import { BlobStorageService } from 'src/app/_services/blob-storage.service';
declare var UAParser: any;

interface IUploadProgress {
  filename: string;
  progress: number;
}

@Component({
  selector: 'live-videos',
  templateUrl: 'live-videos.component.html',
})
export class LiveVideosComponent implements OnInit, OnDestroy {
  initialized = false;
  currentFile: any;
  uploading = false;
  playingLocal = false;
  isPlaying = false;
  private unreadCount = 0;
  public uploadData: AzureBlobSASViewModel;
  percentComplete: number = -1;
  uploadProgress$: Observable<IUploadProgress[]>;
  uploadUrl: string;
  public EventRole = EventRole;
  public user: UserAuthenticationViewModel;
  currentVideoCount = 0;
  public browserSupported = true;

  @ViewChild('videoContainer') private videoContainer: ElementRef;

  @Input() event: LiveEventViewModel;
  @Input() connection: HubConnection;

  @Output()
  closed = new EventEmitter();

  @Output()
  unreadChanged = new EventEmitter<number>();

  @Output()
  countChanged = new EventEmitter<number>();

  @Output()
  localVideoPlayed = new EventEmitter<string>();

  @Output()
  playVideoClicked = new EventEmitter<EventVideoViewModel>();

  @Output()
  stopVideoClicked = new EventEmitter<EventVideoViewModel>();

  @Input() role: EventRole;

  intervalSubscription: Subscription;
  int: any;

  fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.mp4', 'mov', '.wmv'],
  };
  videos: EventVideoViewModel[];
  browser: any;

  constructor(private auth: AuthenticationService, private blobStorage: BlobStorageService, private videoService: EventVideoService) {}

  ngOnInit(): void {
    this.user = this.auth.getUser();
    this.browserSupported = this.checkBrowser();
    this.uploadUrl = '/api/events/' + this.event.id + '/uploadEventVideo';

    this.connection.on('videoVisibiltyUpdated', (eventId: number, video: EventVideoViewModel) => {
      if (eventId === this.event.id) {
        let index = this.videos.findIndex((x) => x.id === video.id);
        this.videos[index] = video;
        if (this.videoContainer.nativeElement.offsetHeight === 0 && video.visible) {
          this.unreadCount = 1;
          this.unreadChanged.emit(this.unreadCount);
        }
        this.checkVisible();
      }
    });

    this.videoService.getVideosForEvent(this.event.id).subscribe((result) => {
      this.videos = result;
      this.intervalSubscription = interval(5000).subscribe((val) => this.checkUnread());
      this.checkVisible();
      this.initialized = true;
    });
  }

  checkBrowser() {
    let parser = new UAParser();
    let result = parser.getResult();

    this.browser = result.browser.name;

    if (result.browser.name == 'Chrome' || result.browser.name == 'Firefox') {
      return true;
    }

    return false;
  }

  checkUnread(): void {
    let anyUnencoded = this.videos.filter((x) => !x.encoded);
    if (anyUnencoded.length == 0) {
      return;
    }

    this.videoService.getVideosForEvent(this.event.id).subscribe((result) => {
      result.forEach((r) => {
        let index = this.videos.findIndex((x) => x.id === r.id);
        if (index > -1) {
          this.videos[index].encoded = r.encoded;
          this.videos[index].assetPath = r.assetPath;
        }
      });
    });
  }

  checkVisible(): void {
    let visibleVideoCount = this.videos.filter((x) => x.visible).length;

    if (this.currentVideoCount !== visibleVideoCount) {
      this.countChanged.emit(visibleVideoCount);
      this.currentVideoCount = visibleVideoCount;
    }
  }

  fileUploadComplete(e) {
    this.videoService.getVideosForEvent(this.event.id).subscribe((result) => {
      this.videos = result;
    });
  }

  public resetPlayStatus() {
    this.playingLocal = false;
    this.isPlaying = false;
  }

  playVideo(video: EventVideoViewModel) {
    video.playing = true;
    this.isPlaying = true;
    let index = this.videos.findIndex((x) => x.id === video.id);
    this.videos[index] = video;
    this.playVideoClicked.emit(video);
    this.close();
  }

  playLocalVideo(event) {
    var file = event.target.files[0];
    var type = file.type;
    var fileURL = URL.createObjectURL(file);
    this.localVideoPlayed.emit(fileURL);
    this.playingLocal = true;
    this.isPlaying = true;
    this.close();
  }

  stopSharing() {
    this.playingLocal = false;
    this.isPlaying = false;
    this.stopVideoClicked.emit(null);
  }

  stopVideo(video: EventVideoViewModel) {
    video.playing = false;
    this.isPlaying = false;
    let index = this.videos.findIndex((x) => x.id === video.id);
    this.videos[index] = video;
    this.stopVideoClicked.emit(video);
  }

  setVisible(video: EventVideoViewModel, val: boolean) {
    this.videoService.updateVideoVisibility(this.event.id, video.id, val).subscribe((result) => {
      let index = this.videos.findIndex((x) => x.id === video.id);
      this.videos[index] = result;
    });
  }

  onFileChange(event) {
    this.currentFile = event.target.files[0];
    this.percentComplete = 1;

    this.videoService.getSASForAssetContainer(this.event.id).subscribe((result) => {
      this.uploadData = result;

      this.uploadFile(this.currentFile).subscribe(
        (progress) => {
          if (progress.progress === 0) {
            this.percentComplete = 1;
          } else {
            this.percentComplete = progress.progress;
          }
        },
        (error) => {},
        () => {
          this.successEventHandler(null);
        }
      );
    });
  }

  uploadFile(file: File): Observable<IUploadProgress> {
    const accessToken: ISasToken = {
      container: this.uploadData.containerName,
      filename: this.uploadData.fileName,
      storageAccessToken: this.uploadData.sasToken,
      storageUri: this.uploadData.storageUri,
    };

    return this.blobStorage.uploadToBlobStorage(accessToken, file).pipe(map((progress) => this.mapProgress(file, progress)));
  }

  private mapProgress(file: File, progress: number): IUploadProgress {
    return {
      filename: file.name,
      progress: progress,
    };
  }

  successEventHandler(e) {
    console.log(this.uploadData);
    this.uploadData.fileName = this.currentFile.name;
    this.videoService.uploadComplete(this.event.id, this.uploadData).subscribe((result) => {
      this.videos.push(result);
      this.percentComplete = -1;
    });
    // this.user.assetUploadComplete(this.courseId).subscribe((result) => {
    //   this.toaster.messageDialog('Your course has been submitted and will be reviewed by a BCBA for CEU eligibilty.', 'Upload Complete').subscribe((result) => {
    //     this.router.navigate(['listings'], { relativeTo: this.route.parent });
    //   });
    // });
  }

  close() {
    this.closed.emit();
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}
