import { Component, Input, forwardRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'star-rating',
  host: {
    '[class.star-rating]': 'someAttr'
  },
  template: `
    <span class="text-warning h3">
      <span *ngIf="rating > 0" class="fas fa-star cursor-pointer" (click)="rating = 1; onChange()"></span>
      <span *ngIf="rating < 1" class="far fa-star cursor-pointer" (click)="rating = 1; onChange()"></span>

      <span *ngIf="rating > 1" class="fas fa-star cursor-pointer" (click)="rating = 2; onChange()"></span>
      <span *ngIf="rating < 2" class="far fa-star cursor-pointer" (click)="rating = 2; onChange()"></span>

      <span *ngIf="rating > 2" class="fas fa-star cursor-pointer" (click)="rating = 3; onChange()"></span>
      <span *ngIf="rating < 3" class="far fa-star cursor-pointer" (click)="rating = 3; onChange()"></span>

      <span *ngIf="rating > 3" class="fas fa-star cursor-pointer" (click)="rating = 4; onChange()"></span>
      <span *ngIf="rating < 4" class="far fa-star cursor-pointer" (click)="rating = 4; onChange()"></span>

      <span *ngIf="rating > 4" class="fas fa-star cursor-pointer" (click)="rating = 5; onChange()"></span>
      <span *ngIf="rating < 5" class="far fa-star cursor-pointer" (click)="rating = 5; onChange()"></span>
    </span>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingControl),
      multi: true
    }
  ]
})
export class StarRatingControl implements ControlValueAccessor {
  @Input() rating: number = 0;

  cssClass: string = '';

  someAttr: string = 'anyValue';

  constructor(elementRef: ElementRef) {
    this.cssClass = elementRef.nativeElement.getAttribute('class');
    elementRef.nativeElement.setAttribute('class', '');
  }

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  writeValue(value: number): void {
    this.rating = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  onChange() {
    this.propagateChange(this.rating);
    this.propagateTouch();
  }

  onTouch() {
    this.propagateTouch();
  }
}
