<!--component html goes here -->

<div (keyup.arrowleft)="backward()" (keyup.arrowright)="forward()" class="container-fluid">
  <div class="row pt-1">
    <div class="col">
      <h4>
        Slide Control
         <button *ngIf="session" class="btn btn-xs btn-dark mr-1" (click)="shareScreen()" type="button">Share Screen</button>

        <button type="button" *ngIf="!session" class="btn btn-xs btn-success btn-xl" (click)="InitOpenTok()">Connect</button>
        <button  type="button" (click)="fullscreen()" class="btn btn-icon text-muted transition-3d-hover">
          <h3><span class="fas fa-compress-wide btn-icon__inner"></span></h3>
        </button>
      </h4>
    </div>
    <div class="col text-right">
      <button class="btn btn-lg btn-primary mr-2" (click)="backward()" type="submit"><i class="fas fa-arrow-left"></i> Backward</button>
      <button class="btn btn-lg btn-success" (click)="forward()" type="submit">Forward <i class="fas fa-arrow-right"></i></button>
    </div>
  </div>

  <div id="webRTCLayoutContainer" #webRTCLayoutContainer style="position: absolute; left: 0; right: 0; bottom: 0; top: 77px; background-color: darkgray;">
    &nbsp;

  </div>
</div>
