<page-load-header *ngIf="!initialized"></page-load-header>
<div *ngIf="initialized" class="container space-2 ad-container">
  <div class="site-content">
    <h4 *ngIf="items.length > 0">{{ 'upcoming-live-events' | translate }}</h4>

    <div *ngIf="items.length == 0" class="text-center">
      <h3>{{ 'there-are-no-events-currently-scheduled' | translate }}</h3>
      <h5>{{ 'check-back-soon' | translate }}</h5>
    </div>
    <ng-container *ngFor="let evt of items; let idx=index ">
      <div *ngIf="idx%5 == 0 && idx>0" class="mt-1 mb-5">
        <ad-display [isMargin]="false"></ad-display>
      </div>
      <div class="card mb-5">

        <bxl-event-item *ngIf="evt.isEvent" [event]="evt.event"></bxl-event-item>
        <bxl-conference-item *ngIf="!evt.isEvent" [conference]="evt.conference"></bxl-conference-item>
      </div>

    </ng-container>

    <div class="py-3"></div>
  </div>
  <div class="ad-content">

    <h4>&nbsp;</h4>

    <ad-display class="mb-4" [isMargin]="true"></ad-display>
    <div *ngIf="auth.isSuperUser()" style="border:1px solid #e7eaf3">
      <div style="position:relative; height: 110px; background-repeat: no-repeat; background-size: contain;  background-image: url(/fetchImage?filename=e04008d9-d503-4f98-8da0-d575db864026WIBA_Theme_and_Nav.png)">
        <span style="position:absolute; top:40px; left: calc(100% / 2 - 35px); background-color: white; padding: 10px">
          <img style="width: 50px" src="https://behaviorlive.com/fetchimage?filename=421722fd-7ac8-4db3-aaee-f4dc334735b5Untitled%20design%20(30).png&mode=Crop">
        </span>
      </div>
      <div class="text-center">
        <h5>Dunder Mifflin</h5>
        <h6>Assistant to the regional manager</h6>
        <small class="text-muted">Scanton, PA</small>
        <h6>80-90k</h6>
      </div>
      <div class="p-3">
        <h6>Benefits</h6>
        <ul class="h6">
          <li>Health Insurance</li>
          <li>401k</li>
          <li>Life Insurance</li>
          <li>PTO</li>
        </ul>

        <button class="mt-3 btn btn-block btn-sm btn-admin-primary" type="button">Learn More</button>
      </div>
    </div>

  </div>
</div>
