<div class="card">
  <header class="card-header pl-3 pr-3 pt-1 pb-0 bg-light">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="text-center">Earning CEUs?</h4>
      <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button> -->
    </div>
  </header>
  <div class="card-body text-center">
    <div class="alert alert-danger">Download the mobile app to watch live, log attendance checks, and earn CEUs</div>
    <a *ngIf="isIOS" href="{{ settings.brand.iosAppLink }}"><img style="width: 200px" class="img-fluid" src="/assets/appstore.png" /></a>
    <a *ngIf="isAndroid" href="{{ settings.brand.androidAppLink }}"><img style="width: 200px" class="img-fluid" src="/assets/appstore-google.png" /></a>

    <button type="button" class="btn btn-block btn-warning mt-2" (click)="activeModal.dismiss('Cross click')">I don't need CEUs</button>
  </div>
</div>
