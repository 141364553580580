import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StringViewModel } from '../_models/generatedModels';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/generatedServices';
import { SettingsProvider } from '../_services/settingsProvider.service';

@Component({
  selector: 'bxl-contractor-success',
  templateUrl: 'contractor-success.component.html',
})
export class ContractorSuccessComponent implements OnInit {
  initialized = false;
  oauthCode: string;
  constructor(private settings: SettingsProvider, public auth: AuthenticationService, private router: Router, private userService: UserService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      let model = new StringViewModel();
      this.oauthCode = params.get('code');
      model.value = this.oauthCode;
      this.userService.completeStripeOauthContractors(model).subscribe((user) => {
        this.initialized = true;
      });
    });
  }
}
