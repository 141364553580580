// #docregion
import { ChangeDetectorRef, Directive, Input, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { FormGroup, FormControl, FormArray, NgControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import 'moment-timezone';
import { delay, map } from 'rxjs/operators';
import invert, { RGB, RgbArray, HexColor, BlackWhite } from 'invert-color';
import { BadgeElementViewModel, CEUViewModel } from '../_models/generatedModels';
import { Observable, of, timer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ComponentCollection } from 'survey-core';
import { TimeSpan } from "../events/live/live-event-dashboard/live-event-dashboard.component";

ComponentCollection.Instance.add({
  //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
  name: 'bxlfirstname',
  //The text that shows on toolbox
  title: 'Logged In User First Name',
  icon: 'expression',
  //The actual question that will do the job
  questionJSON: {
    type: 'expression',
  },
});

ComponentCollection.Instance.add({
  //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
  name: 'bxllastname',
  //The text that shows on toolbox
  title: 'Logged In User Last Name',
  icon: 'icon-expression',
  //The actual question that will do the job
  questionJSON: {
    type: 'expression',
  },
});

ComponentCollection.Instance.add({
  //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
  name: 'bxlemail',
  //The text that shows on toolbox
  title: 'Logged In User Email',
  icon: 'icon-expression',
  //The actual question that will do the job
  questionJSON: {
    type: 'expression',
  },
});

@Pipe({ name: 'duration', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class DurationPipe implements PipeTransform {
  transform(input: string): string {
    if (!input || input.length === 0) {
      return '';
    }

    let val = input.split(':');
    let hours = parseInt(val[0]);
    let min = parseInt(val[1]);
    let totalMins = hours * 60 + min;

    if (totalMins < 60) {
      return totalMins + ' minutes';
    }

    if (totalMins === 60) {
      return '1 hour';
    }

    if (hours > 1 && min === 0) {
      return hours + ' hours';
    }

    if (hours > 1 && min > 0) {
      return hours + ' hours, ' + min + ' mins';
    }

    if (hours > 0 && min > 0) {
      return hours + ' hour, ' + min + ' mins';
    }
  }
}

@Pipe({
  name: 'phone',
})
export class PhonePipe {
  transform(tel) {
    if (!tel || tel.length === 0) {
      return '';
    }

    var value = tel
      .toString()
      .trim()
      .replace(/^\+|-|\(|\)/g, '');
    if (value.match(/[^0-9]/)) {
      return tel;
    }

    var country, city, number;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3);
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0];
        city = value.slice(1, 4);
        number = value.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        number = value.slice(5);
        break;

      default:
        return tel;
    }

    if (country == 1) {
      country = '';
    }

    number = number.slice(0, 3) + '-' + number.slice(3);

    return (country + ' (' + city + ') ' + number).trim();
  }
}

@Pipe({ name: 'bytes', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class BytePipe implements PipeTransform {
  transform(bytes: number): string {
    if (!bytes) {
      return '';
    }

    let decimals = 2;

    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
@Pipe({ name: 'invertColor', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class InvertColorPipe implements PipeTransform {
  transform(color: string): string {
    return invert(color, true);
  }
}

@Pipe({ name: 'ceuCount', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class CEUCountPipe implements PipeTransform {
  transform(model: CEUViewModel[]): number {
    var totalCEU = 0;

    model.forEach((ceu) => {
      if (ceu.certificationBody.baseTypeName != null && ceu.ceuCount > 0) {
        totalCEU = Math.max(totalCEU, ceu.ceuCount);
      } else if (ceu.certificationBody.baseTypeName == null && ceu.ceuCount > 0) {
        totalCEU = Math.max(totalCEU, ceu.ceuCount);
      }

      if (ceu.certificationBody.subType1Name != null && ceu.subType1CEUCount > 0) {
        totalCEU = Math.max(totalCEU, ceu.subType1CEUCount);
      }

      if (ceu.certificationBody.subType2Name != null && ceu.subType2CEUCount > 0) {
        totalCEU = Math.max(totalCEU, ceu.subType2CEUCount);
      }

      if (ceu.certificationBody.subType3Name != null && ceu.subType3CEUCount > 0) {
        totalCEU = Math.max(totalCEU, ceu.subType3CEUCount);
      }
    });

    return totalCEU;
  }
}

@Pipe({ name: 'ceuDisplay', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class CEUDisplayPipe implements PipeTransform {
  transform(model: CEUViewModel[], total: boolean = false): string {
    var tokens: string[] = [];
    var totalCEU = 0;

    model.forEach((ceu) => {
      if (ceu.certificationBody.baseTypeName != null && ceu.ceuCount > 0) {
        tokens.push(`${ceu.ceuCount} ${ceu.certificationBody.shortName} ${ceu.certificationBody.baseTypeAbbreviation}`);
        totalCEU = Math.max(totalCEU, ceu.ceuCount);
      } else if (ceu.certificationBody.baseTypeName == null && ceu.ceuCount > 0) {
        tokens.push(`${ceu.ceuCount} ${ceu.certificationBody.shortName}`);
        totalCEU = Math.max(totalCEU, ceu.ceuCount);
      }

      if (ceu.certificationBody.subType1Name != null && ceu.subType1CEUCount > 0) {
        tokens.push(`${ceu.subType1CEUCount} ${ceu.certificationBody.shortName} ${ceu.certificationBody.subType1Abbreviation}`);
        totalCEU = Math.max(totalCEU, ceu.subType1CEUCount);
      }

      if (ceu.certificationBody.subType2Name != null && ceu.subType2CEUCount > 0) {
        tokens.push(`${ceu.subType2CEUCount} ${ceu.certificationBody.shortName} ${ceu.certificationBody.subType2Abbreviation}`);
        totalCEU = Math.max(totalCEU, ceu.subType2CEUCount);
      }

      if (ceu.certificationBody.subType3Name != null && ceu.subType3CEUCount > 0) {
        tokens.push(`${ceu.subType3CEUCount} ${ceu.certificationBody.shortName} ${ceu.certificationBody.subType3Abbreviation}`);
        totalCEU = Math.max(totalCEU, ceu.subType3CEUCount);
      }
    });

    if (!total) {
      if (tokens.length === 0) {
        return '0';
      }
      return tokens.join(', ');
    } else {
      return totalCEU.toString();
    }
  }
}

@Pipe({ name: 'encodeUrl', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class EncodeUrlPipe implements PipeTransform {
  transform(color: string): string {
    return encodeURIComponent(color);
  }
}

@Pipe({ name: 'durationClean', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class DurationCleanPipe implements PipeTransform {
  transform(input: string): string {
    if (!input || input.length === 0) {
      return '';
    }

    let val = input.split(':');
    let hours = parseInt(val[0]);
    let min = parseInt(val[1]);
    let sec = parseInt(val[2]);

    let returnVal = '';

    if (hours === 0 && min === 0 && sec > 0) {
      return `${sec} sec`;
    }

    if (hours === 0 && min > 0) {
      return `${min}:${sec.toString().padStart(2, '0')}`;
    }

    return `${hours}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
  }
}

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (value == null) {
      return value;
    }
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    if (value.substr(0, limit).length < value.length) {
      return `${value.substr(0, limit)}${ellipsis}`;
    } else {
      return `${value.substr(0, limit)}`;
    }
  }
}

@Pipe({ name: 'websiteUrlPipe', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class WebsiteUrlPipe implements PipeTransform {
  transform(input: string, showHttp: boolean = true): string {
    if (!input) {
      return '';
    }
    let retval = '';
    if (showHttp) {
      retval = 'http://';
    }
    return retval + input.replace('http://', '').replace('https://', '');
  }
}

@Pipe({ name: 'titlecase', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class TitleCasePipe implements PipeTransform {
  transform(input: string): string {
    return !input || input.length === 0 ? '' : input.replace(/\w\S*/g, (txt) => txt[0].toUpperCase() + txt.substr(1).toLowerCase());
  }
}

@Pipe({
  name: 'secondsToTime'
})

export class SecondsToTimePipe{
  times = {
    hour: 3600,
    minute: 60,
    second: 1
  }

  transform(totalSeconds){

          //let date = new Date(this.event.streamingStartedTime);

          let pre = false;
          if (totalSeconds < 0) {
            pre = true;
          }

          totalSeconds = Math.abs(totalSeconds);

          let hours = 0;
          let minutes = 0;
          let seconds = 0;

          if (totalSeconds >= 3600) {
            hours = Math.floor(totalSeconds / 3600);
            totalSeconds -= 3600 * hours;
          }

          if (totalSeconds >= 60) {
            minutes = Math.floor(totalSeconds / 60);
            totalSeconds -= 60 * minutes;
          }

          seconds = totalSeconds;

    return new LeftPadFilter().transform(hours) + ":" + new LeftPadFilter().transform(minutes)+":"+ new LeftPadFilter().transform(seconds);

    // //console.log(hours + ':' + minutes + ':' + seconds);
    // return {
    //   hours: hours,
    //   minutes: minutes,
    //   seconds: seconds,
    //   pre: pre,
    // };

    }







  //   var timespan = new Timespan(seconds);
  //   let formattedTimeString: string = '';
  //   let letter: string = '';
  //   for(var key in this.times){
  //     if(Math.floor(seconds / this.times[key]) > 0){
  //       if(Math.floor(seconds / this.times[key]) >1 ){
  //         letter = 's';
  //       }
  //       else{
  //         letter = '';
  //       }
  //       //Appends this portion of the timeString to formattedTimeString
  //       formattedTimeString += Math.floor(seconds / this.times[key]).toString() + ':';
  //
  //       seconds = seconds - this.times[key] * Math.floor(seconds / this.times[key]);
  //
  //     }
  //   }
  //   return formattedTimeString;
  // }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html) {
    if (!html) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}

@Pipe({ name: 'safeStyle' })
export class SafeStylePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }
}

@Pipe({ name: 'LeftPadFilter', pure: false })
export class LeftPadFilter implements PipeTransform {
  transform(item: number): string {
    return (String('0').repeat(2) + item).substr(2 * -1, 2);
  }
}

@Pipe({ name: 'TranslateXPipe', pure: false })
export class TranslateXPipe implements PipeTransform {
  transform(badgeElement: BadgeElementViewModel, ref: HTMLDivElement): string {
    console.log('translatePipe');
    of(null)
      .pipe(delay(1))
      .subscribe((result) => {
        var center = ref.offsetWidth / 2;
        var left = badgeElement.x - center;
        //badgeElement.newX = left;
      });
    // console.log('translatePipe');
    // var center = ref.offsetWidth / 2;
    // var left = badgeElement.x - center;
    // badgeElement.newX = left;
    return '';
  }
}

@Pipe({ name: 'pluralizer', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class PluralizerPipe implements PipeTransform {
  transform(input: number, unpluralValue: string, pluralValue: string): string {
    return input + ' ' + (input === 1 ? unpluralValue : pluralValue);
  }
}

@Pipe({ name: 'stripHtml', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class StripHtmlPipe implements PipeTransform {
  transform(html: string): string {
    if (!html || html.length === 0) {
      return '';
    }
    var returnHtml = html
      .replace('&nbsp;', '')
      .replace('&amp;', '')
      .replace(/<[^>]*>/g, '');

    var parser = new DOMParser();
    var dom = parser.parseFromString(returnHtml, 'text/html');
    var decodedString = dom.body.textContent;

    return decodedString;
  }
}

@Pipe({ name: 'timezone', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class TimezonePipe implements PipeTransform {
  transform(datetime: any): string {
    let timeZone = moment.tz.guess();
    let timeZoneOffset = new Date(datetime).getTimezoneOffset();
    return moment.tz.zone(timeZone).abbr(timeZoneOffset);
  }
}

@Pipe({ name: 'camelCaseToSpaces', pure: false })
/** Transform camel case values into multiple words as needed, all uppercase first letters.*/
export class CamelCaseToSpacesPipe implements PipeTransform {
  transform(input: string): string {
    if (!input || input.length === 0) {
      return '';
    }
    let result = input.replace(/[A-Z]/g, (txt) => ' ' + txt);
    return result.length === 1 ? result[0].toUpperCase() : result[0].toUpperCase() + result.substr(1);
  }
}

@Pipe({ name: 'booleanToText', pure: false })
/** Transform nullable boolean value to text.*/
export class BooleanToTextPipe implements PipeTransform {
  transform(input: boolean | null, trueVal: string | null = 'True', falseVal: string | null = 'False'): string {
    if (input == null || input === undefined) {
      return '';
    }

    if (trueVal == null || trueVal == undefined) {
      trueVal = 'True';
    }

    if (falseVal == null || falseVal == undefined) {
      falseVal = 'False';
    }

    return input ? trueVal : falseVal;
  }
}

@Pipe({ name: 'enumValue', pure: false })
/** Transform camel case values into multiple words as needed, all uppercase first letters.*/
export class EnumValuePipeTransform implements PipeTransform {
  transform(enumVal: any, theEnum: any, description: any): string {
    let result = description.get(parseInt(theEnum[enumVal]));
    return result;
  }
}

@Pipe({ name: 'enumEqual', pure: false })
/** Transform camel case values into multiple words as needed, all uppercase first letters.*/
export class EnumEqualPipeTransform implements PipeTransform {
  transform(enumVal: any, theEnum: any): boolean {
   // console.log(enumVal + '=' + theEnum);
    return enumVal == theEnum;
  }
}

@Pipe({ name: 'unicode', pure: false })
/** Transform camel case values into multiple words as needed, all uppercase first letters.*/
export class UnicodePipe implements PipeTransform {
  transform(unicodeVal: string): string {
    return String.fromCharCode(parseInt(unicodeVal, 16));
  }
}

@Pipe({ name: 'noDataDisplay', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class NoDataDisplayPipe implements PipeTransform {
  transform(input: any, replacement: string): string {
    if (!input && !replacement) {
      return 'No Data';
    }

    if (!input && replacement) {
      return replacement;
    }
    return input;
  }
}

@Pipe({
  name:'timeAgo',
  pure:false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;

  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {}

  transform(value: string) {
    this.removeTimer();
    let d = new Date(value);
    let now = new Date();
    let seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    let timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    let minutes = Math.round(Math.abs(seconds / 60));
    let hours = Math.round(Math.abs(minutes / 60));
    let days = Math.round(Math.abs(hours / 24));
    let months = Math.round(Math.abs(days / 30.416));
    let years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return 'a few seconds ago';
    } else if (seconds <= 90) {
      return 'a minute ago';
    } else if (minutes <= 45) {
      return minutes + ' minutes ago';
    } else if (minutes <= 90) {
      return 'an hour ago';
    } else if (hours <= 22) {
      return hours + ' hours ago';
    } else if (hours <= 36) {
      return 'a day ago';
    } else if (days <= 25) {
      return days + ' days ago';
    } else if (days <= 45) {
      return 'a month ago';
    } else if (days <= 345) {
      return months + ' months ago';
    } else if (days <= 545) {
      return 'a year ago';
    } else { // (days > 545)
      return years + ' years ago';
    }
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number) {
    let min = 60;
    let hr = min * 60;
    let day = hr * 24;
    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }
}

declare module '@angular/forms/' {
  interface FormGroup {
    markAllControlsDirty: () => FormGroup;
  }
}

declare module '@angular/forms' {
  interface FormArray {
    markAllControlsDirty: () => FormArray;
  }
}
declare module '@angular/forms' {
  interface FormGroup {
    resetControls: () => FormGroup;
  }
}

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }

  constructor(private ngControl: NgControl) {}
}

FormArray.prototype.markAllControlsDirty = function (): FormArray {
  Object.keys(this.controls).forEach((key: string) => {
    const abstractControl = this.controls[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      abstractControl.markAllControlsDirty();
    } else {
      abstractControl.markAsDirty();
    }
  });
  return this;
};

FormGroup.prototype.markAllControlsDirty = function (): FormGroup {
  Object.keys(this.controls).forEach((key: string) => {
    const abstractControl = this.controls[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      abstractControl.markAllControlsDirty();
    } else {
      abstractControl.markAsDirty();
    }
  });
  return this;
};

FormGroup.prototype.resetControls = function (): FormGroup {
  Object.keys(this.controls).forEach((key: string) => {
    const abstractControl = this.controls[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      abstractControl.reset();
    } else {
      abstractControl.reset();
    }
  });
  return this;
};

export class UUID {

  constructor() {
      // no-op
  }

  public static UUID(): string {
      if (typeof (window) !== "undefined" && typeof (window.crypto) !== "undefined" && typeof (window.crypto.getRandomValues) !== "undefined") {
          // If we have a cryptographically secure PRNG, use that
          // http://stackoverflow.com/questions/6906916/collisions-when-generating-uuids-in-javascript
          let buf: Uint16Array = new Uint16Array(8);
          window.crypto.getRandomValues(buf);
          return (this.pad4(buf[0]) + this.pad4(buf[1]) + "-" + this.pad4(buf[2]) + "-" + this.pad4(buf[3]) + "-" + this.pad4(buf[4]) + "-" + this.pad4(buf[5]) + this.pad4(buf[6]) + this.pad4(buf[7]));
      } else {
          // Otherwise, just use Math.random
          // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
          // https://stackoverflow.com/questions/11605068/why-does-jshint-argue-against-bitwise-operators-how-should-i-express-this-code
          return this.random4() + this.random4() + "-" + this.random4() + "-" + this.random4() + "-" +
              this.random4() + "-" + this.random4() + this.random4() + this.random4();
      }
  }

  private static pad4(num: number): string {
      let ret: string = num.toString(16);
      while (ret.length < 4) {
          ret = "0" + ret;
      }
      return ret;
  }

  private static random4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
  }

}

export function parseErrorBlob(err: HttpErrorResponse): Observable<any> {
  const reader: FileReader = new FileReader();

  const obs = Observable.create((observer: any) => {
    reader.onloadend = (e) => {
      observer.error(JSON.parse(<any>reader.result));
      observer.complete();
    };
  });
  reader.readAsText(err.error);
  return obs;
}

export function dynamicSort(property: any) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  let propertyArray = property.split('.');

  return function (a: any, b: any) {
    //handles up to 3 levels of properties
    let aProperty = a[propertyArray[0]] == null || a[propertyArray[0]].length == 0 ? null : a[propertyArray[0]];
    let bProperty = b[propertyArray[0]] == null || b[propertyArray[0]].length == 0 ? null : b[propertyArray[0]];

    if (propertyArray.length > 1) {
      aProperty = aProperty == null || a[propertyArray[0]][propertyArray[1]] == null || a[propertyArray[0]][propertyArray[1]].length == 0 ? null : a[propertyArray[0]][propertyArray[1]];
      bProperty = bProperty == null || b[propertyArray[0]][propertyArray[1]] == null || b[propertyArray[0]][propertyArray[1]].length == 0 ? null : b[propertyArray[0]][propertyArray[1]];
    }
    if (propertyArray.length == 3) {
      aProperty = aProperty == null || a[propertyArray[0]][propertyArray[1]][propertyArray[2]] == null || a[propertyArray[0]][propertyArray[1]][propertyArray[2]].length == 0 ? null : a[propertyArray[0]][propertyArray[1]][propertyArray[2]];
      bProperty = bProperty == null || b[propertyArray[0]][propertyArray[1]][propertyArray[2]] == null || b[propertyArray[0]][propertyArray[1]][propertyArray[2]].length == 0 ? null : b[propertyArray[0]][propertyArray[1]][propertyArray[2]];
    }

    aProperty = typeof aProperty === 'string' ? aProperty.toLowerCase() : aProperty;
    bProperty = typeof bProperty === 'string' ? bProperty.toLowerCase() : bProperty;

    if (bProperty == null) {
      return 1 * sortOrder;
    }
    if (aProperty == null) {
      return -1 * sortOrder;
    } else if (aProperty < bProperty) {
      return -1 * sortOrder;
    } else if (aProperty > bProperty) {
      return 1 * sortOrder;
    } else {
      return 0;
    }
  };
}

export function onlyUniqueById(value: any, index: any, self: any) {
  return self.map((x: any) => x['id']).indexOf(value['id']) === index;
}

export function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}

interface Address {
  street_number?: string;
  street_name?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
}
export class GoogleAddressParser {
  private address: Address = {};

  constructor(private address_components: Array<AddressComponent>) {
    this.parseAddress();
  }

  private parseAddress() {
    if (!Array.isArray(this.address_components)) {
      return;
    }

    if (!this.address_components.length) {
      return;
    }

    for (let i = 0; i < this.address_components.length; i++) {
      const component: AddressComponent = this.address_components[i];

      if (this.isStreetNumber(component)) {
        this.address.street_number = component.long_name;
      }

      if (this.isStreetName(component)) {
        this.address.street_name = component.long_name;
      }

      if (this.isCity(component)) {
        this.address.city = component.long_name;
      }

      if (this.isCountry(component)) {
        this.address.country = component.short_name;
      }

      if (this.isState(component)) {
        this.address.state = component.short_name;
      }

      if (this.isPostalCode(component)) {
        this.address.postal_code = component.long_name;
      }
    }
  }

  private isStreetNumber(component: AddressComponent): boolean {
    return component.types.includes('street_number');
  }

  private isStreetName(component: AddressComponent): boolean {
    return component.types.includes('route');
  }

  private isCity(component): boolean {
    return component.types.includes('locality');
  }

  private isState(component): boolean {
    return component.types.includes('administrative_area_level_1');
  }

  private isCountry(component): boolean {
    return component.types.includes('country');
  }

  private isPostalCode(component): boolean {
    return component.types.includes('postal_code');
  }

  result(): Address {
    return this.address;
  }
}
