<div id="overlay" (click)="overlayClicked()" *ngIf="showingOverlay"></div>

<page-load-header *ngIf="!initialized"></page-load-header>

<div (resized)="onResized($event)" (click)="overlayClicked()" *ngIf="initialized">
  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" role="main" class="mt-2">

    <ng-container *ngIf="!auth.isLoggedIn()">
      <div style="width: 100vw; height: 100%;position: absolute; top:-8px;left:0; background-color: black; z-index: 100; opacity: .6;">
      </div>

      <div style="width: 100vw; height: 100%;position: fixed; top:95px;left:0; z-index: 101; ">
        <div class="container text-center mt-5 space-1">
          <a (click)="navigationService.showLogin()" href="javascript:void(0)">
            <!--        <h3>The BehaviorLive job board is free and exclusively for our users. </h3>-->
            <!--        <h4>Please sign up, or log in!</h4>-->
            <img style="border: 5px solid white; border-radius: 20px" class="img-fluid" src="/assets/Job-board-popup.png"/>
          </a>
          <!--        <button type="button" class="btn btn-primary btn-lg" (click)="navigationService.showLogin()">Login/Sign Up</button>-->
        </div>
      </div>
    </ng-container>

    <!-- Hero Section -->
    <div class="bg-light mt-2">
      <div class="container pt-1 pb-1">
        <div class="row">
          <div class="col-md-12">
            <h1 class="h4">ABA Job Board: Exclusive Opportunities for ABA Professionals</h1>
            <h2 class="h5">Streamline Your Applied Behavior Analysis Career Search</h2>
            <p>Welcome to the BehaviorLive Job Board, your specialized platform for Applied Behavior Analysis (ABA) career opportunities. Our job board is uniquely tailored to meet the needs of ABA professionals across all levels and specializations. By focusing exclusively on ABA positions, we eliminate the noise and frustration often associated with the traditional job search. Here, you'll find the most up-to-date listings from recognized organizations in the ABA community, ensuring that every position aligns with your qualifications and career aspirations.</p>
            <p>Whether you're an experienced professional looking for your next challenge, or a newcomer to the field aiming to advance your career, our platform provides a streamlined search experience. This allows all ABA professionals to discover and apply to relevant opportunities efficiently. With BehaviorLive Job Board, your next rewarding role in ABA is just a click away.</p>
          </div>
        </div>
        <!-- Search Jobs Form -->
        <form class="row mb-1">
          <div class="row col-12">
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-12 mb-2 pr-0 mb-lg-0">
              <!-- Input -->
              <label class="d-block">
                <span class="h4 d-block text-dark font-weight-semi-bold mb-0">what</span>
              </label>
              <div class="js-focus-state">
                <div class="input-group">
                  <input type="text" autofocus class="form-control" [ngModelOptions]="{ standalone: true }" (keyup.enter)="search()" [(ngModel)]="searchService.searchQuery" placeholder="job title, keywords, or company" aria-label="job title, keywords, or company" aria-describedby="keywordInputAddon" value=""/>
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <span class="fas fa-search" id="keywordInputAddon"></span>
                    </span>
                  </div>
                </div>
              </div>
              <!-- End Input -->
            </div>

            <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 mb-2 pr-0 mb-lg-0">
              <!-- Input -->
              <div class="row">
                <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 pr-0">
                  <label class="d-block">
                    <span class="h4 d-block text-dark font-weight-semi-bold mb-0">where</span>
                  </label>
                  <div class="js-focus-state">
                    <places-autocomplete #name="ngModel" [(ngModel)]="searchService.locationModel" [autocompleteInput]="searchService.locationModel?.fullText" placeholder="city, state, or zip code" [ngModelOptions]="{ standalone: true }" class="form-control" aria-describedby="locationInputAddon" adressType="geocode"></places-autocomplete>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 pr-2">
                  <label class="d-block">
                    <span class="h4 d-block text-dark font-weight-semi-bold mb-0">distance</span>
                  </label>
                  <select class="form-control" name="radiusInMilesMain" [(ngModel)]="searchService.searchParameters.radiusInMiles">
                    <option value="5">< 5 miles</option>
                    <option value="10">< 10 miles</option>
                    <option value="15">< 15 miles</option>
                    <option value="25" selected>< 25 miles</option>
                    <option value="50">< 50 miles</option>
                    <option value="100">< 100 miles</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- End Input -->
            <div class="col-xl-2 col-lg-2 col-md-2 d-none d-lg-block align-self-end">
              <button type="submit" [disabled]="name.invalid" (click)="search()" class="btn btn-block btn-admin-primary transition-3d-hover">Find Jobs</button>
            </div>
          </div>
          <!-- End Checkbox -->
          <!-- Additional Filter Commands -->
          <div class="col-12 d-lg-none mt-2">
            <button type="submit" [disabled]="name.invalid" (click)="search()" class="btn btn-block btn-sm btn-admin-primary transition-3d-hover">Find Job</button>
          </div>

          <div class="col-12 mt-2">
            <!-- Select -->
            <!-- <div *ngIf="searchService.recentSearches.length > 0" class="btn-group d-inline pr-1">
              <button class="btn btn-xs btn-primary dropdown-toggle" type="button" id="dropdownMenuRecent" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Recent Searches</button>
              <div class="dropdown-menu pt-0 pb-1" id="dropdownMenuRecent" aria-labelledby="dropdownMenuRecent">
                <button *ngFor="let recentSearch of searchService.recentSearches" href="#" (click)="selectRecentSearch(recentSearch)" class="dropdown-item pt-0 pb-1">
                  <div class="dropdown-divider"></div>
                  <div class="p0">
                    <strong>{{ recentSearch.query }}</strong>
                  </div>
                  <div *ngIf="recentSearch.locationText != null" class="p0">
                    <i>{{ recentSearch.locationText + ' (< ' + recentSearch.radiusInMiles + ' miles)' }}</i>
                  </div>
                </button>
                <div class="dropdown-divider"></div>
              </div>

            </div> -->

            <!-- <div class="d-inline pr-1" *ngIf="auth.isLoggedIn() && hasRecommendedSearch()">
              <button type="button" (click)="showRecommended()" class="btn btn-xs btn-outline-secondary">Recommended Jobs</button>
            </div> -->

            <div class="d-inline pr-1">
              <button type="reset" (click)="clearSearch()" class="btn btn-xs btn-outline-secondary">View All Jobs</button>
            </div>
          </div>
          <!-- End Additional Filter Commands -->
        </form>
        <!-- End Search Jobs Form -->
      </div>
    </div>


    <!-- End Hero Section -->
    <!-- Jobs Section -->
    <div class="container mt-2">
      <div class="row">

        <div class="col-lg-12">
          <!-- Filter -->
          <div class="row justify-content-between align-items-center mb-4">
            <!-- Title -->
            <div class="col-sm-3 mb-3 mb-sm-0">
              <h1 class="h5 mb-0 d-none d-lg-block">Search results ({{ searchService.jobResults.resultCount }}) {{ company != null ? " at " + company.name : "" }} </h1>
            </div>
            <!-- End Title -->

            <div class="col-sm-9 text-sm-right">
              <form class="js-validate">
                <ul class="list-inline mb-0">
                  <!-- <li class="list-inline-item">

                    <div class="btn-group">
                      <button class="btn btn-xs btn-soft-primary dropdown-toggle ml-1" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Sort by
                      </button>
                      <div class="dropdown-menu" id="dropdownMenuButton2" aria-labelledby="dropdownMenuButton2">
                        <button href="#" class="dropdown-item"><i class="dropdown-check fas fa-check"></i> Relevance</button>
                        <button href="#" class="dropdown-item"><i class="dropdown-check fas fa-check"></i> Date</button>
                      </div>
                    </div>

                  </li> -->
                  <li class="list-inline-item">
                    <div class="btn-group ml-1" role="group" aria-label="Basic example">
                      <div class="btn-group ml-1" role="group" aria-label="Basic example">
                        <button type="button" (click)="searchService.showGrid = true" class="btn btn-xs btn-soft-admin-primary" [ngClass]="{ active: searchService.showGrid }">
                          <i class="fas fa-grip-horizontal"></i>
                        </button>
                        <button type="button" (click)="searchService.showGrid = false" class="btn btn-xs btn-soft-admin-primary" [ngClass]="{ active: !searchService.showGrid }">
                          <i class="fas fa-list"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="auth.isLoggedIn()" class="list-inline-item">
                    <!-- Select -->
                    <div class="btn-group">
                      <button class="btn btn-xs btn-soft-admin-primary dropdown-toggle ml-1" type="button" id="dropdownMenuButton2s" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span *ngIf="searchService.selectedFilter == null">Saved Searches</span>
                        <span *ngIf="searchService.selectedFilter != null">{{ searchService.selectedFilter.name }}</span>
                      </button>
                      <div class="dropdown-menu" id="dropdownMenuButton2" aria-labelledby="dropdownMenuButton2">
                        <button href="#" (click)="selectFilter(null)" [ngClass]="{ selected: searchService.selectedFilter == null }" class="dropdown-item">
                          <i class="dropdown-check fas fa-check"></i> No Saved Searches
                        </button>
                        <div class="dropdown-divider"></div>
                        <button *ngFor="let filter of filters" href="#" (click)="selectFilter(filter)" [ngClass]="{ selected: isFilterSelected(filter.id) }" class="dropdown-item">
                          <i class="dropdown-check fas fa-check"></i> {{ filter.name }}
                        </button>
                      </div>
                    </div>
                    <!-- End Select -->
                  </li>
                  <li class="list-inline-item"></li>
                  <!--                  <li class="list-inline-item">-->
                  <!--                    <a class="btn btn-xs btn-admin-primary" [ngClass]="{ active: searchService.showFilter }" [routerLink]="[]" (click)="searchService.showFilter = !searchService.showFilter">-->
                  <!--                      <span class="fas fa-filter mr-2"></span>-->
                  <!--                      Filter-->
                  <!--                    </a>-->
                  <!--                  </li>-->
                </ul>
              </form>
            </div>
          </div>
          <!-- End Filter -->
        </div>
      </div>

      <div class="row">
        <!-- Jobs -->
        <div class="col order-2">
          <page-load-header *ngIf="searchService.loading"></page-load-header>
          <h3 *ngIf="!searchService.loading" class="h5 mb-2 d-block d-lg-none pl-2">Search results ({{ searchService.jobResults.resultCount }})</h3>
          <div *ngIf="!searchService.loading" class="card-deck">
            <div class="text-center mb-5 mt-5 w-100" *ngIf="!searchService.jobs.length">
              <h3 class="mb-5">No Results</h3>
            </div>

            <ng-container *ngIf="searchService.showGrid">
              <ng-container *ngFor="let job of searchService.jobs; let idx = index">
                <!-- <div class="card mb-5" [ngClass]="{ expose: searchService.selectedJob === job }" (click)="jobSelected(job, $event)"> -->
                <div id="job{{ job.id }}" class="card mb-5">
                  <div class="card-header p-0 border-bottom-0">
                    <div class="d-flex align-items-center mb-2">
                      <!-- Rating -->
                      <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-soft-dark btn-icon transition-3d-hover dropdown-toggle hide-arrow rounded-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span class="fas fa-user-cog btn-icon__inner"></span>
                        </button>

                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="#">Apply</a>
                          <!--                          <a class="dropdown-item" [recommend-button]="job" href="#">Recommend</a>-->
                        </div>
                      </div>

                      <!-- End Rating -->
                      <!-- Bookmark -->
                      <div class="ml-auto">
                        <!-- <a [routerLink]="[]" class="ml-1 mr-1 mb-auto align-top">
                          <i *ngIf="job.favorite" (click)="setFavorite(job.id, false)" class="fas fa-heart text-primary font-size-1 align-top"></i>
                          <i *ngIf="!job.favorite" (click)="setFavorite(job.id, true)" class="fal fa-heart text-primary font-size-1 align-top"></i>
                        </a> -->

                        <!-- <div class="bookmark-checkbox" data-toggle="tooltip" data-placement="top" title="Save Job">
                          <input type="checkbox" class="bookmark-checkbox-input" id="bookmark{{ job.id }}" />
                          <label class="bookmark-checkbox-label" for="bookmark{{ job.id }}"></label>
                        </div> -->
                      </div>
                      <!-- End Bookmark -->
                    </div>
                  </div>
                  <div class="card-body p-4">
                    <div class="text-center">
                      <div class="mx-auto mb-3">
                        <a [routerLink]="['.', job.id]">
                          <img class="img-fluid" src="/fetchimage?filename={{ job.thumbnailUrl }}&width=178&height=106&mode=Pad" alt="Image Description"/>
                        </a>
                      </div>

                      <!-- Title -->
                      <div class="mb-4">
                        <h3 class="h5 mb-1">
                          <a [routerLink]="['.', job.id]">{{ job.title }}</a>
                        </h3>
                        <ul class="list-inline font-size-1 text-muted">
                          <li class="list-inline-item">
                            <a class="link-muted" [routerLink]="['/companies', job.companyId]">{{ job.companyName }}</a>
                          </li>
                        </ul>
                      </div>
                      <!-- End Title -->
                    </div>
                  </div>

                  <div class="card-footer text-center py-4">
                    <!-- Location & Salary -->
                    <div class="row align-items-center">
                      <div class="col-6 u-ver-divider">
                        <h4 class="small text-secondary mb-0">Location</h4>
                        <small class="fas fa-map-marker-alt text-secondary align-middle mr-1"></small>
                        <span class="align-middle">{{ job.city + ', ' + job.state }}</span>
                      </div>
                      <div class="col-6">
                        <h4 class="small text-secondary mb-0">Type</h4>

                        <span class="align-middle">{{ job.employmentType | enumValue: EmploymentType:EmploymentTypeEnum }}</span>
                      </div>
                    </div>
                    <!-- End Location & Salary -->
                  </div>
                </div>
                <ng-container *ngIf="searchService.showFilter">
                  <div (click)="clickIntercept($event)" class="job-details w-100 d-xs-block d-md-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'xs')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 2 === 0" class="job-details w-100 d-none d-sm-block d-xl-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'sm')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 3 === 0" class="job-details w-100 d-none d-xl-block d-xxl-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'md')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 4 === 0" class="job-details w-100 d-none d-xxl-block">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'lg')"></job-details>
                  </div>
                </ng-container>
                <ng-container *ngIf="!searchService.showFilter">
                  <div (click)="clickIntercept($event)" class="job-details w-100 d-xs-block d-sm-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'xs')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 2 === 0" class="job-details w-100 d-none d-sm-block d-lg-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'sm')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 3 === 0" class="job-details w-100 d-none d-lg-block d-xl-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'lg')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 4 === 0" class="job-details w-100 d-none d-xl-block d-xxl-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'xl')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 5 === 0" class="w-100 d-none d-xxl-block">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'xxl')"></job-details>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngFor="let job of fillerCount; let idx = index">
                <div class="card mb-4 invisible d-none d-md-block">
                  <img class="card-img-top img-fluid" src="https://loremflickr.com/500/300/officespace?q=14" alt="Card image cap"/>
                  <div class="card-body">
                    <p class="emp-type">Full-Time</p>
                    <h6 class="card-title">Senior Dveloper</h6>
                    <p class="card-text">
                      <small class="text-muted">The Home Depot - Denver, CO</small>
                    </p>
                  </div>
                </div>
                <ng-container *ngIf="searchService.showFilter">
                  <div (click)="clickIntercept($event)" class="job-details w-100 d-xs-block d-md-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'xs')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 2 === 0" class="job-details w-100 d-none d-sm-block d-xl-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'sm')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 3 === 0" class="job-details w-100 d-none d-xl-block d-xxl-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'md')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 4 === 0" class="job-details w-100 d-none d-xxl-block">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'lg')"></job-details>
                  </div>
                </ng-container>
                <ng-container *ngIf="!searchService.showFilter">
                  <div (click)="clickIntercept($event)" class="job-details w-100 d-xs-block d-sm-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'xs')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 2 === 0" class="job-details w-100 d-none d-sm-block d-lg-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'sm')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 3 === 0" class="job-details w-100 d-none d-lg-block d-xl-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'md')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 4 === 0" class="job-details w-100 d-none d-xl-block d-xxl-none">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'lg')"></job-details>
                  </div>
                  <div (click)="clickIntercept($event)" *ngIf="(idx + 1) % 5 === 0" class="w-100 d-none d-xxl-block">
                    <job-details [selectedJob]="searchService.selectedJob" *ngIf="jobDetailsVisible(idx, 'xl')"></job-details>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <div class="col-md-12" *ngIf="!searchService.showGrid">
              <ng-container *ngFor="let job of searchService.jobs; let idx = index">
                <div id="job{{ job.id }}" class="card mb-5">
                  <track-job [jobId]="job.id" action="Impression"></track-job>
                  <div class="card-body p-4">
                    <!-- Details -->
                    <div class="media d-block d-sm-flex">
                      <div class=" mb-3 mb-sm-0 mr-4" style="width: 8rem;">
                        <img class="img-fluid" src="/fetchimage?filename={{ job.thumbnailUrl }}&width=500&height=500&mode=Pad" alt="Image Description"/>
                      </div>

                      <div class="media-body">
                        <!-- Header -->
                        <div class="mb-2">
                          <div class="row">
                            <div class="col-md-12">
                              <h2 class="h5 mb-1">
                                <a [routerLink]="['.', job.id]">{{ job.title }}</a>
                                <!-- <a [routerLink]="[]" class="ml-1 mb-auto align-top" href="">
                                  <i *ngIf="job.favorite" (click)="setFavorite(job.id, false)" class="fas fa-heart text-primary font-size-1 align-top"></i>
                                  <i *ngIf="!job.favorite" (click)="setFavorite(job.id, true)" class="fal fa-heart text-primary font-size-1 align-top"></i>
                                </a> -->
                              </h2>
                              <ul class="list-inline font-size-1 text-muted">
                                <li class="list-inline-item">
                                  <a class="link-muted" [routerLink]="['/companies', job.companyId]">
                                    <span class="fas fa-building mr-1"></span>
                                    {{ job.companyName }}
                                  </a>
                                </li>
                                <li class="list-inline-item text-muted">&#8226;</li>
                                <li class="list-inline-item">{{ job.employmentType | enumValue: EmploymentType:EmploymentTypeEnum }}</li>
                              </ul>
                            </div>

                          </div>
                        </div>
                        <!-- End Header -->

                        <div class="d-md-flex align-items-md-center">
                          <!-- Location -->
                          <div class="u-ver-divider u-ver-divider--none-md pr-4 mb-3 mb-md-0 mr-4">
                            <h3 class="small text-secondary mb-0">Location</h3>
                            <small class="fas fa-map-marker-alt text-secondary align-middle mr-1"></small>
                            <span class="align-middle">{{ job.city + ', ' + job.state }}</span>
                          </div>
                          <!-- End Location -->
                          <!-- Posted -->
                          <div class="u-ver-divider u-ver-divider--none-md pr-4 mb-3 mb-md-0 mr-4">
                            <h4 class="small text-secondary mb-0">Posted</h4>
                            <small class="fas fa-calendar-alt text-secondary align-middle mr-1"></small>
                            <span class="align-middle">{{ job.postDate | timeAgo }}</span>
                          </div>
                          <div class="">
                            <!--                            <a class="btn btn-sm btn-soft-primary" target="_blank" href="{{job.applyUrl}}}">Apply</a>-->
                          </div>
                          <div class="d-flex" style="justify-content: right; flex-grow: 1">
                            <div class="position-relative d-inline-block text-left">
                              <div class="btn-group" role="group" aria-label="Basic example">
                                <a class="btn btn-sm btn-soft-dark" [routerLink]="['.', job.id]">View </a>
                                <a class="btn btn-sm btn-admin-primary" target="_blank" href="track-job-apply?jobId={{job.id}}&clientIdentifier={{clientIdentifier}}&userId={{userId}}">Apply</a>
                              </div>
                            </div>
                          </div>
                          <!-- End Posted -->
                          <!-- Posted -->
                          <!-- <div class="mb-3 mb-md-0">
                            <h4 class="small text-secondary mb-0">Salary</h4>
                            <small class="fas fa-dollar-sign text-secondary align-middle mr-1"></small>
                            <span class="align-middle">35k - 45k</span>
                          </div> -->
                          <!-- End Posted -->
                        </div>
                      </div>
                    </div>
                    <!-- End Details -->
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="py-3"></div>

          <!-- Pagination -->
          <nav *ngIf="searchService.jobResults.resultCount > searchService.jobs.length" aria-label="Page navigation">
            <button type="button" (click)="loadMore()" [promiseBtn]="loadingMore" class="btn btn-primary btn-block">Load More</button>
          </nav>
          <!-- End Pagination -->
        </div>
        <!-- End Jobs -->
        <!-- Filters -->
        <div class="col-lg-3 order-lg-3 order-1 mb-3" *ngIf="searchService.showFilter">
          <job-search-filter #searchFilter (criteriaChanged)="filterChanged($event)" (filterCreated)="filterCreated($event)" (filterUpdated)="filterUpdated($event)" (filterDeleted)="filterDeleted($event)" [showContext]="auth.isLoggedIn()" [locations]="searchService.jobResults.locations" [companies]="searchService.jobResults.companies"></job-search-filter>
        </div>
        <!-- End Filters -->
      </div>
    </div>
    <!-- End Jobs Section -->
  </main>
</div>
