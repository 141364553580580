import { Component, OnInit } from '@angular/core';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
declare var UAParser: any;

@Component({
  selector: 'bxl-unsupported-browser',
  templateUrl: 'unsupported-browser.component.html',
})
export class UnsupportedBrowserComponent implements OnInit {
  initialized = false;
  name: any;
  constructor(public settings: SettingsProvider) {}

  ngOnInit(): void {
    let parser = new UAParser();
    let result = parser.getResult();
    this.name = result.browser.name;
    this.initialized = true;
  }
}
