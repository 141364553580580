import { Component, OnInit, Input, NgZone, OnDestroy } from '@angular/core';
import { CourseViewModel, SegmentProgressViewModel, SegmentType, SegmentStatus, AssessmentResponsesViewModel, CourseReviewViewModel, AssessmentAnswersViewModel } from 'src/app/_models/generatedModels';
import { CourseService, AssessmentService } from 'src/app/_services/generatedServices';
import { ResizedEvent } from 'angular-resize-event';
import { interval, Subscription, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { resolve } from 'q';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'bxl-purchased-course',
  templateUrl: 'purchased-course.component.html',
})
export class PurchasedCourseComponent implements OnInit, OnDestroy {
  SegmentType = SegmentType;
  SegmentStatus = SegmentStatus;
  segments: SegmentProgressViewModel[];
  activeSegment: SegmentProgressViewModel;
  myPlayer: amp.Player;
  lessonHeight: string;
  currentQuestion = 0;
  initialized = false;
  reviews: CourseReviewViewModel[];
  @Input() course: CourseViewModel;
  private timerSubcription: Subscription;
  assessmentForm: FormGroup;
  constructor(public auth: AuthenticationService, private modalService: NgbModal, private courseData: CourseService, private assessmentService: AssessmentService, private fb: FormBuilder, private toaster: ToasterService, private zone: NgZone) {}

  ngOnInit(): void {
    this.courseData.getSegmentStatus(this.course.id).subscribe((segments) => {
      this.segments = segments;
      this.fetchReviews();
      this.initialized = true;
    });
  }

  fetchReviews() {
    this.courseData.getReviews(this.course.id).subscribe((result) => {
      this.reviews = result;
    });
  }

  ngOnDestroy(): void {
    console.log('disposing');
    if (this.myPlayer) {
      console.log('disposed media player');
      this.myPlayer.dispose();
    }

    if (this.timerSubcription) {
      this.timerSubcription.unsubscribe();
    }
  }
}
