import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/filter';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ResizedEvent } from 'angular-resize-event';
import { AuthenticationService } from "../_services/authentication.service";
import { JobsService, OrganizationService, UserService } from "../_services/generatedServices";
import { Enums } from "../_models/generatedEnums";
import { JobSearchFilterComponent } from "./job-search-filter/job-search-filter.component";
import { JobSearchService } from "../_services/job-search.service";
import { EmploymentType, JobSearchHistoryParameters, JobSearchParameters, JobSearchResultViewModel, JobViewModel, OrganizationViewModel, SavedJobSearchParameters } from "../_models/generatedModels";
import { UUID } from "../_helpers/extensions.module";
import { NavigationService } from "../_services/navigation-service";
import { AnalyticsService } from "../_services/analytics.service";

@Component({
  selector: 'job-search',
  templateUrl: './jobSearch.component.html'
})
export class JobSearchComponent implements OnInit {
  EmploymentTypeEnum = Enums.EmploymentTypeEnum;
  EmploymentType = EmploymentType;
  public company: OrganizationViewModel;
  filters: SavedJobSearchParameters[] = [];
  initialized: boolean;
  showingOverlay: boolean;
  isMobile = false;
  clientIdentifier: string;
  userId: number | undefined;

  currentBeakpoint: string;
  loadingMore: boolean;
  @ViewChild('searchFilter', {static: false}) searchFilter: JobSearchFilterComponent;


  constructor(public auth: AuthenticationService, private analytics:AnalyticsService, public navigationService: NavigationService, private user: UserService, public jobsData: JobsService, private route: ActivatedRoute, public searchService: JobSearchService, private companyData: OrganizationService, private router: Router) { }

  ngOnInit() {
    this.analytics.setTitleAndLogPageView('ABA Jobs for RBTs and BCBAs');
    if (this.auth.isLoggedIn() && this.auth.getUser()) {
      this.userId = this.auth.getUser()?.id;
    }
    if (localStorage.getItem('avertisementGuid') === null) {
      localStorage.setItem('avertisementGuid', UUID.UUID());
    }
    this.clientIdentifier = localStorage.getItem('avertisementGuid');

    let companyId = this.route.snapshot.queryParamMap.get('companyId');
    if (companyId) {
      this.searchService.initializeParams();
      (<any>this.searchService.searchParameters.companies).push(companyId);
      this.searchService.search().then(() => {
        this.fetchFilters();
        this.loadSingleCompany();
      });
      this.searchService.showFilter = true;
      return;
    }


    console.log('init');

    if (this.searchService.jobResults && this.searchService.jobResults.jobs) {
      if (this.searchService.selectedJob) {
        this.showingOverlay = true;
      }
      this.fetchFilters();
      return;
    }

    this.searchService.search().then(x => {
      if (this.searchService.searchParameters.similiarJobID) {
        this.searchService.searchParameters.similiarJobID = null;
      }

      this.loadSingleCompany();
      this.fetchFilters();
    });
  }

  isFilterSelected(filterId: number) {
    if (!this.searchService.selectedFilter) {
      return false;
    }
    return this.searchService.selectedFilter.id === filterId;
  }

  setFavorite(jobId: number, val: boolean) {
    let index = this.searchService.jobs.findIndex(x => x.id === jobId);
    this.searchService.jobs[index].favorite = val;

    let subscription = val ? this.jobsData.setFavorite(jobId) : this.jobsData.deleteFavorite(jobId);
    subscription.subscribe(result => { });
  }

  fetchFilters() {
    if (!this.auth.isLoggedIn()) {
      this.initialized = true;
      return;
    }

    this.user.getFilters().subscribe(result => {
      this.filters = result;
      this.initialized = true;
    });
  }

  selectFilter(selectedFilter: SavedJobSearchParameters) {
    this.searchService.loadSavedFilter(selectedFilter);
  }

  selectRecentSearch(selectedSearch: JobSearchHistoryParameters) {
    this.searchService.loadRecentSearch(selectedSearch);
  }

  onResized(event: any) {
    this.isMobile = event.newRect.width < 576;

    if (event.newRect.width >= 576) {
      this.currentBeakpoint = 'sm';
    }
    if (event.newRect.width >= 768) {
      this.currentBeakpoint = 'md';
    }
    if (event.newRect.width >= 992) {
      this.currentBeakpoint = 'lg';
    }
    if (event.newRect.width >= 1200) {
      this.currentBeakpoint = 'xl';
    }
    if (event.newRect.width >= 1590) {
      this.currentBeakpoint = 'xxl';
    }
  }

  filterChanged(params: JobSearchParameters) {
    this.searchService.searchParameters = params;
    this.searchService.search().then(() => {
      this.loadSingleCompany();
    });
  }

  filterCreated(params: SavedJobSearchParameters) {
    this.filters.push(params);
  }

  filterUpdated(params: SavedJobSearchParameters) {
    let index = this.filters.findIndex(x => x.id === params.id);
    if (index >= 0) {
      this.filters[index] = params;
    }
  }

  filterDeleted(params: SavedJobSearchParameters) {
    let index = this.filters.findIndex(x => x.id === params.id);
    if (index >= 0) {
      this.filters.splice(index, 1);
    }
  }

  overlayClicked() {
    this.searchService.selectedJob = null;
    this.showingOverlay = false;
  }

  clickIntercept(event) {
    event.stopPropagation();
  }

  jobSelected(job: JobSearchResultViewModel, event) {
    event.stopPropagation();
    let target = event.currentTarget as Element;
    this.showingOverlay = true;
    this.searchService.selectedJob = <any>job;

    of(null)
      .pipe(delay(100))
      .subscribe(x => {
        if (this.isMobile) {
          target.scrollIntoView();
        }
      });
  }

  jobDetailsVisible(index: number, size: string): boolean {
    if (!this.searchService.selectedJob) {
      return false;
    }
    let columns = 0;

    switch (size) {
      case 'xl':
        columns = 6;
        break;
      case 'lg':
        columns = 4;
        break;
      case 'md':
        columns = 3;
        break;
      case 'sm':
        columns = 2;
        break;
      case 'xs':
        columns = 1;
        break;
    }

    let jobIndex = this.searchService.jobResults.jobs.indexOf(<any>this.searchService.selectedJob);
    let shift = columns - (jobIndex % columns);
    let position = jobIndex + shift - 1;

    if (position === index) {
      return true;
    } else {
      return false;
    }
  }

  search() {
    this.searchService.page = 0;
    this.searchService.search().then(() => {
      this.loadSingleCompany();
    });
  }

  hasFilter() {
    return this.searchService.hasFilter();
  }

  hasRecommendedSearch() {
    return true;
  }

  hasRecentSearch() {
    return this.searchService.hasRecentSearch();
  }

  clearSearch() {
    this.searchService.page = 0;
    this.searchService.initializeParams();
    this.searchService.search().then(() => {
      this.loadSingleCompany();
    });
  }

  loadSingleCompany() {
    // if (this.searchService.searchParameters.singleCompanyId > 0) {
    //   this.companyData.getCompanyById(this.searchService.searchParameters.singleCompanyId).subscribe(result => {
    //     this.company = result;
    //   });
    // } else {
    //   this.company = null;
    // }
  }

  showRecommended() {
    this.searchService.page = 0;
    this.searchService.initializeParams();
    this.searchService.searchParameters.recommendJobs = true;
    this.searchService.search().then(() => {
      this.loadSingleCompany();
    });
  }

  loadMore() {
    this.loadingMore = true;
    this.searchService.loadMore().then(() => {
      this.loadingMore = false;
    });
  }

  get fillerCount(): any {
    let columns = 1;

    if (this.searchService.showFilter) {
      switch (this.currentBeakpoint) {
        case 'xxl':
          columns = 4;
          break;
        case 'xl':
          columns = 3;
          break;
        case 'lg':
          columns = 2;
          break;
        case 'md':
          columns = 2;
          break;
        case 'sm':
          columns = 1;
          break;
        case 'xs':
          columns = 1;
          break;
      }
    } else {
      switch (this.currentBeakpoint) {
        case 'xxl':
          columns = 5;
          break;
        case 'xl':
          columns = 4;
          break;
        case 'lg':
          columns = 3;
          break;
        case 'md':
          columns = 2;
          break;
        case 'sm':
          columns = 2;
          break;
        case 'xs':
          columns = 1;
          break;
      }
    }

    let len = this.searchService.jobs.length;
    let filler = columns - (len % columns);
    if (columns === filler) {
      return Array(0);
    }
    return Array(filler);
  }

  removeHtml(html: string) {
    return html.replace(/<[^>]*>/g, '');
  }

  getNextSibling = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) {
        return sibling;
      }
      sibling = sibling.nextElementSibling;
    }
  };
}
