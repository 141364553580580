import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from './authentication.service';
import { RegistrationModalComponent } from '../events/registration/registration-modal.component';

@Directive()
@Injectable()
export class NavigationService {
  public sideBarVisible = false;
  public pageTitle = '';

  @Output() navigatedFromMenu = new EventEmitter<any>();

  constructor(private modalService: NgbModal, private auth: AuthenticationService) {
    let currentSessionId = this.generateSessionId();

    if (currentSessionId)
      localStorage.setItem('currentSessionId', currentSessionId);

    if (!localStorage.getItem('sessionId')) {
      let sessionId = this.generateSessionId();
      console.log('New Session ' + sessionId);
      localStorage.setItem('sessionId', sessionId);
    }
    else {
      console.log('Existing Session: ' + localStorage.getItem('sessionId'));
    }

  }

  showLogin() {
    const modalRef = this.modalService.open(RegistrationModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalRef.result.then(
      result => {
        this.fetchProfile();
      },
      reason => {}
    );
  }

  reLogin() {
    this.auth.logout();
    const modalRef = this.modalService.open(RegistrationModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalRef.result.then(
      result => {
        this.fetchProfile();
      },
      reason => {}
    );
  }

  triggerNavigationEnded() {
    this.sideBarVisible = false;
    this.navigatedFromMenu.emit();
  }

  fetchProfile() {
    if (this.auth.isLoggedIn()) {
      this.auth.fetchUserProfile().subscribe();
    }
  }

  generateSessionId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
