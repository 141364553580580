<page-load-header *ngIf="!initialized"></page-load-header>

<ng-container *ngIf="initialized">
  <div class="row mb-5">
    <div class="col-md-8 col-lg-9 mb-md-0">
      <div class="ml-lg-2">
        <div class="mb-2">
          <h2 class="font-weight-semi-bold">{{ course.title }}</h2>
          <h5>
            <a class="link-muted" [routerLink]="['/instructors', course.instructor.slug]">
              <span class="fas fa-chalkboard-teacher mr-1"></span>
              {{ course.instructor.fullNameWithCredentials }} <span *ngIf="course.instructor.pronouns"> ({{ course.instructor.pronouns }})</span>
            </a>
          </h5>
        </div>
      </div>
    </div>
    <div class="col text-right">
      <a [routerLink]="['/on-demand', course.slug]" class="btn btn-success">Watch Course</a>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 mb-7 mb-lg-0">
      <!-- Profile Card -->
      <div class="card p-1 mb-4">
        <div class="card-body text-center">
          <div class="mb-3">
            <span *ngIf="!course.instructor.avatar" class="btn btn-icon btn-soft-success rounded-circle">
              <span class="btn-icon__inner btn-lg">{{ course.instructor.firstName.substring(0, 1).toUpperCase() + course.instructor.lastName.substring(0, 1).toUpperCase() }}</span>
            </span>
            <img *ngIf="course.instructor.avatar" class="u-lg-avatar rounded-circle" src="/fetchimage?fileName={{ course.instructor.avatar }}&width=250&height=250&mode=crop"  />
          </div>

          <div class="mb-3">
            <h1 class="h6 font-weight-medium mb-0">
              {{ course.instructor.fullName }} <span *ngIf="course.instructor.pronouns"> ({{ course.instructor.pronouns }})</span>
            </h1>
            <small class="d-block text-muted">{{ course.instructor.credentials }}</small>
          </div>

          <div class="mb-2">
            <a class="btn btn-sm btn-soft-primary transition-3d-hover" [routerLink]="['/instructors', course.instructor.slug]">
              <span class="fad fa-film mr-2"></span>
              View Courses
            </a>
          </div>
        </div>
      </div>
      <!-- End Profile Card -->

      <!-- Contacts  -->
      <div class="card mb-4" *ngIf="course.coInstructors && course.coInstructors.length > 0">
        <div class="card-header pt-4 pb-3 px-0 mx-4">
          <h2 class="h6 mb-0">Additional Speakers</h2>
        </div>

        <div class="card-body pt-3 pb-4 px-4">
          <!-- User -->
          <a *ngFor="let sInstructor of course.coInstructors" class="d-flex align-items-start mb-3" [routerLink]="['/instructors', sInstructor.slug]">
            <div class="position-relative u-avatar">
              <span *ngIf="!sInstructor.avatar" class="btn btn-icon btn-soft-success rounded-circle">
                <span class="btn-icon__inner">{{ sInstructor.firstName.substring(0, 1).toUpperCase() + sInstructor.lastName.substring(0, 1).toUpperCase() }}</span>
              </span>
              <img *ngIf="sInstructor.avatar" class="img-fluid rounded-circle" src="/fetchimage?fileName={{ sInstructor.avatar }}&height=250&width=250&mode=crop" alt="Image Descriptions" />
            </div>

            <div class="ml-3">
              <span class="d-block text-dark"
                >{{ sInstructor.fullName }} <span *ngIf="sInstructor.pronouns"> ({{ sInstructor.pronouns }})</span></span
              >
              <small class="d-block text-secondary">{{ sInstructor.credentials }}</small>
            </div>
          </a>
          <!-- End User -->
        </div>
      </div>
      <!-- End Contacts  -->
    </div>

    <div class="col-lg-9">
      <ul class="nav nav-classic nav-rounded nav-justified border" id="pills-tab" role="tablist">
        <li class="nav-item">
          <a class="nav-link font-weight-medium active" id="pills-one-example1-tab" data-toggle="pill" href="#pills-one-example1" role="tab" aria-controls="pills-one-example1" aria-selected="true">
            <div class="d-md-flex justify-content-md-center align-items-md-center">
              <figure class="ie-height-40 d-none d-md-block w-100 max-width-5 mr-3" [inlineSVG]="'../../assets/svg/icons/icon-24.svg'"></figure>
              Course info
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link font-weight-medium" id="pills-two-example1-tab" data-toggle="pill" href="#pills-two-example1" role="tab" aria-controls="pills-two-example1" aria-selected="false">
            <div class="d-md-flex justify-content-md-center align-items-md-center">
              <figure class="ie-height-40 d-none d-md-block w-100 max-width-5 mr-3" [inlineSVG]="'../../assets/svg/icons/icon-7.svg'"></figure>
              Reviews
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link font-weight-medium" id="pills-three-example1-tab" data-toggle="pill" href="#pills-three-example1" role="tab" aria-controls="pills-three-example1" aria-selected="false">
            <div class="d-md-flex justify-content-md-center align-items-md-center">
              <figure class="ie-height-40 d-none d-md-block w-100 max-width-5 mr-3" [inlineSVG]="'../../assets/svg/icons/icon-22.svg'"></figure>
              Materials
            </div>
          </a>
        </li>
      </ul>

      <!-- Tab Content -->

      <!-- Tab Content -->
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade pt-6 show active" id="pills-one-example1" role="tabpanel" aria-labelledby="pills-one-example1-tab">
          <div class="mb-4">
            <h2 class="h4">Course Description</h2>
            <h4 class="h6 text-secondary mb-0">
              <small>{{ course.title }}</small>
            </h4>
          </div>
          <div class="mb-4" innerHTML="{{ course.description }}"></div>
          <ng-container>
            <hr class="my-7" />
            <!-- User Details -->
            <div class="mb-4">
              <h2 class="h4">About {{ course.instructor.fullName }}</h2>
              <h4 class="h6 text-secondary mb-0">
                <small>{{ course.instructor.credentials }}</small>
              </h4>
            </div>
            <!-- End User Details -->

            <!-- Info -->
            <div class="mb-4 ckembed" innerHTML="{{ course.instructor.biography }}"></div>
            <!-- End Info -->
          </ng-container>
          <ng-container *ngFor="let instructor of course.coInstructors">
            <hr class="my-7" />
            <!-- User Details -->
            <div class="mb-4">
              <h2 class="h4">About {{ instructor.fullName }}</h2>
              <h4 class="h6 text-secondary mb-0">
                <small>{{ instructor.credentials }}</small>
              </h4>
            </div>
            <!-- End User Details -->

            <!-- Info -->
            <div class="mb-4 ckembed" style="color: black;" innerHTML="{{ instructor.biography }}"></div>
            <!-- End Info -->
          </ng-container>
        </div>

        <div class="tab-pane fade pt-6" id="pills-two-example1" role="tabpanel" aria-labelledby="pills-two-example1-tab">
          <div *ngIf="reviews && reviews.length == 0" class="text-center">
            <div class="mb-5">
              <p class="font-size-3">No Reviews Yet</p>

            </div>
          </div>
          <ng-container *ngIf="reviews && reviews.length > 0">
            <!-- Review Header -->
            <div class="d-sm-flex justify-content-sm-between align-items-sm-center border-bottom pb-3 mb-4">
              <div class="d-flex align-items-center mb-2 mb-sm-0">
                <h4 class="h5 font-weight-semi-bold mb-0">
                  Reviews <small class="text-muted ml-1">({{ reviews.length }})</small>
                </h4>
              </div>


            </div>
            <!-- End Review Header -->

            <!-- Review -->

            <div *ngFor="let review of reviews" class="media">
              <div class="u-avatar mr-3">
                <span *ngIf="!review.authorAvatar" class="btn btn-icon btn-soft-success rounded-circle">
                  <span class="btn-icon__inner">{{ review.authorFirstName.substring(0, 1).toUpperCase() + review.authorLastName.substring(0, 1).toUpperCase() }}</span>
                  <span class="badge badge-xs badge-outline-success badge-pos badge-pos--bottom-right rounded-circle"></span>
                </span>
                <img *ngIf="review.authorAvatar" class="img-fluid rounded-circle"  src="https://bxlimages.blob.core.windows.net/avatar-cache/231-avatar.png" />
              </div>
              <div class="media-body border-bottom pb-6 mb-6">
                <span class="h6 font-weight-semi-bold mr-2">{{ review.authorFirstName.substring(0, 1).toUpperCase()  }} {{ review.authorLastName.substring(0, 1).toUpperCase()  }}</span>
                <span class="text-warning">
                  <span *ngIf="review.rating > 0" class="fas fa-star"></span>
                  <span *ngIf="review.rating > 1" class="fas fa-star"></span>
                  <span *ngIf="review.rating > 2" class="fas fa-star"></span>
                  <span *ngIf="review.rating > 3" class="fas fa-star"></span>
                  <span *ngIf="review.rating > 4" class="fas fa-star"></span>
                </span>

                <p>{{ review.text }}</p>

                <!-- Reply -->

                <!-- End Reply -->
              </div>
            </div>
          </ng-container>
          <!-- End Review -->

          <div *ngIf="false" class="text-center">
            <a href="#">Read More Reviews <span class="fas fa-angle-right ml-1"></span></a>
          </div>
        </div>

        <div class="tab-pane fade pt-6" id="pills-three-example1" role="tabpanel" aria-labelledby="pills-three-example1-tab">
          <h4 class="h5 font-weight-semi-bold mb-3">Materials</h4>

          <div *ngIf="!course.materials || course.materials.length == 0" class="text-center">
            <div class="mb-5">
              <p class="font-size-3">No Materials</p>
            </div>
          </div>
          <div>
            <table>
              <tr *ngFor="let material of course.materials">
                <td>
                  <h4>
                    <a href="/fetchMaterial?fileName={{ material.blobPath | encodeUrl }}&downloadName={{ material.description }}&access_token={{ auth.getToken() }}"><img style="width: 40px" src="../../assets/filetypes/{{ material.fileType }}.png" /> {{ material.description }}</a>
                  </h4>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!-- End Tab Content -->
    </div>
  </div>
</ng-container>
