import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { EventDashboardViewModel, EventVideoViewModel, LiveEventViewModel, PagedActiveUserViewModel, StringViewModel, UserAuthenticationViewModel } from 'src/app/_models/generatedModels';
import { EventRole, SelectedDevice, TokenModel } from 'src/app/_models/models';
import { EventService, LiveEventService } from 'src/app/_services/generatedServices';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { interval, Observable, of, Subscription, timer } from 'rxjs';
import { ToasterService } from 'src/app/_services/toaster.service';
import { OpentokService } from 'src/app/_services/opentok.service';
import { DefaultReconnectPolicy } from 'src/app/_helpers/signalR-retry-policy';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CameraTestModalComponent } from '../camera-test/camera-test-modal.component';
import { delay, map } from 'rxjs/operators';
import { CeuTokensComponent } from '../ceu-tokens/ceu-tokens.component';
import { LiveEventChatComponent } from '../chat/live-event-chat.component';
import { OTError } from '@opentok/client';
import { LiveVideosComponent } from '../videos/live-videos.component';
import { SuggestMobileAppModalCsComponent } from './suggest-mobile-app-modal-cs.component';
import { AnalyticsService } from 'src/app/_services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { Flowplayer, FPEvent } from "@flowplayer/player";
import Player = Flowplayer.Player;

const initLayoutContainer = require('opentok-layout-js');
declare var zE: any;
declare var UAParser: any;
declare var flowplayer: any;

export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
  pre: boolean;
}

@Component({
  selector: 'bxl-presenter-dashboard',
  templateUrl: 'live-event-dashboard.component.html',
  animations: [
    trigger('fadeBug', [
      state(
        'initial',
        style({
          opacity: 1,
        })
      ),
      state(
        'final',
        style({
          opacity: 0,
        })
      ),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1500ms')),
    ]),
  ],
})
export class LiveEventDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  public activeTab = 1;
  public pollCount = 0;
  public materialCount = 0;
  public videoCount = 0;
  public currentToken: TokenModel;
  public showQuestions = false;
  public showTips = false;
  public showVideos = false;
  public showSuperUser = false;
  public showMaterials = false;
  public showTechSupport = false;
  public showCEUTokens = false;
  submitTokenComplete: Promise<{}> | undefined;
  public playingVideo = false;
  public showPeople = false;
  public showPolls = false;
  public showChat = true;
  public attendeeCameraOn = false;
  public isFullScreen: boolean;
  public chatCollapsed = false;
  public eventIdOrSlug: any;
  private azureStreamChecker: Subscription;
  private adShower: Subscription;
  public initialized: boolean;
  public connection: HubConnection;
  public EventRole = EventRole;
  public unreadQuestionCount = 0;
  public unreadPollCount = 0;
  public unreadVideoCount = 0;
  public unreadMaterialCount = 0;
  public publishingAudio = true;
  public publishingVideo = true;
  public isWebRTC = false;
  public connectedToSignalR = false;
  public signalRInitialized = false;
  public hideOffAir = false;

  publisher: OT.Publisher;
  screenPublisher: OT.Publisher;
  videoPublisher: OT.Publisher;
  public publishing: boolean;
  public screenPublishing: boolean;
  public inGreenRoom: boolean;
  public selectedDevice: SelectedDevice;

  @ViewChild('webRTCLayoutContainer') webRTCLayoutContainer: ElementRef;
  @ViewChild('messageContainer') private messageContainer: ElementRef;
  @ViewChild('questionContainer') private questionContainer: ElementRef;
  @ViewChild('fullscreenContainer') fullscreenContainer: ElementRef;
  @ViewChild('ceutokens') ceutokens: CeuTokensComponent;
  @ViewChild('liveVideosComponent') liveVideosComponent: LiveVideosComponent;

  public user: UserAuthenticationViewModel;
  event: LiveEventViewModel;
  dashboard: EventDashboardViewModel;
  public layout: any;
  session: OT.Session;
  public streamCount = 0;
  role: EventRole;
  routeSubscription: Subscription;
  livePlaying = false;
  previewing = false;
  livePlayer: Player;
  videoPlayer: Player;
  @ViewChild('livePLayer') livePLayerRef: ElementRef;
  @ViewChild('wowzaPlayer') wowzaLivePLayerRef: ElementRef;
  @ViewChild('videoPlayer') videoPlayerRef: ElementRef;
  userCount: number;
  randomUserIds: number[];
  openTokInitialized: boolean;
  bugShowing = 'initial';
  compatabilityMode: boolean;
  timer$: Observable<TimeSpan>;
  cc: boolean;
  signalRFailed: boolean;
  startStreamPromise: Promise<{}> | undefined;
   submitComplete: Promise<any>;

  constructor(private translate: TranslateService, private modalService: NgbModal, private titleService: AnalyticsService, private renderer: Renderer2, private router: Router, private location: Location, public auth: AuthenticationService, private route: ActivatedRoute, private eventData: EventService, private liveEventService: LiveEventService, private toaster: ToasterService, private ref: ChangeDetectorRef, private opentokService: OpentokService) {}

  ngOnInit(): void {
    var broswer = this.checkBrowser();
    if (!broswer) {
      return;
    }

    this.user = this.auth.getUser();
    this.auth.load3rdPartyTrackingData(this.user.firstName, this.user.lastName, this.user.email, this.user.id);

    document.body.style.backgroundColor = '#333';
    let root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
    root.setAttribute('class', 'hideScrollBars');

    this.routeSubscription = this.route.paramMap.subscribe((params) => {
      this.eventIdOrSlug = params.get('id');

      this.eventData.getLiveEvent(this.eventIdOrSlug).subscribe((event) => {
        this.event = event;
        this.titleService.setTitleAndLogPageView(this.event.instructor.firstName + ' ' + this.event.instructor.lastName + ' - ' + this.event.title);
        this.isWebRTC = event.isWebRTC;

        if (this.event.streamingStarted) {
          this.timer$ = this.getElapsedTime();
        }

        console.log("USER:"+ this.user.id)
        console.log(this.event.coInstructors);
        this.route.queryParamMap.subscribe((queryParams) => {
          if (this.event.coInstructors.find((x) => x.id == this.user.id) != null || this.event.userWasInvited) {
            this.role = EventRole.Guest;
            console.log('Role is Guest');
          } else if (queryParams.get('rtmp')) {
            this.isWebRTC = false;
            this.compatabilityMode = true;
            this.role = EventRole.Viewer;
            console.log('rtmp mode');
          } else if (queryParams.get('opentok')) {
            this.isWebRTC = true;
            this.compatabilityMode = false;
            this.role = EventRole.Viewer;
            console.log('Forced RTC mode');
          } else if (queryParams.get('role')) {
            this.role = parseInt(queryParams.get('role'));
            if (this.role === EventRole.Viewer) {
              console.log('forced viewer');
              this.isWebRTC = event.isWebRTC && !event.activeUsersMaxedOut;
              this.compatabilityMode = !this.isWebRTC;
            }
          } else if (this.event.instructor.id === this.user.id) {
            this.role = EventRole.Host;
            console.log('Role is Host');
          } else {
            this.role = EventRole.Viewer;
            this.isWebRTC = event.isWebRTC && !event.activeUsersMaxedOut;
            this.compatabilityMode = !this.isWebRTC;
            console.log('Role is Viewer');
          }

          if (queryParams.has('cc')) {
            this.cc = queryParams.get('cc') ? queryParams.get('cc').toLocaleLowerCase() === 'true' : false;
          }
        });

        if (this.isMobileDevice() && this.isWebRTC && this.role == EventRole.Viewer && !this.event.failover) {
          this.compatabilityMode = true;
          this.isWebRTC = false;
        }

        if (this.event.failover && this.event.isWebRTC && !this.compatabilityMode) {
          console.log('failover active');
          this.isWebRTC = true;
          this.compatabilityMode = false;
        }

        console.log(this.role);

        this.InitSignalR();

        if (this.isWebRTC) {
          this.adShower = interval(60000).subscribe((val) => {
            this.changeBugState();
          });
          this.InitOpenTok();
        } else {
          this.initAzure();
        }

        this.loadZendesk();
      });
    });
  }

  cancelBug() {
    if (this.adShower) {
      this.bugShowing = 'final';
      this.adShower.unsubscribe();
    }
  }

  showMobileAppModal() {
    const modalRef = this.modalService.open(SuggestMobileAppModalCsComponent, {
      size: 'lg',
      backdrop: 'static',
      ariaLabelledBy: 'modal-basic-title'
    });
  }

  isMobileDevice(): boolean {
    let parser = new UAParser();
    let result = parser.getResult();

    if (result.os.name == 'Mac OS' || result.os.name == 'Windows' || result.os.name == 'Chrome OS') return false;

    console.log(result.os.name);
    //todo show android once approved
    if (result.os.name == 'iOS' || result.os.name == 'Android') {
      this.showMobileAppModal();
    }

    return true;
  }

  checkBrowser() {
    let parser = new UAParser();
    let result = parser.getResult();

    if (result.browser.name == 'Chrome' || result.browser.name == 'Edge' || result.browser.name == 'WebKit' || result.browser.name == 'Safari' || result.browser.name == 'Firefox' || result.browser.name == 'Mobile Safari') {
      return true;
    }


    this.router.navigate(['/unsupported-browser']);
    return false;
  }

  loadZendesk() {
    zE('webWidget', 'hide');
    zE('webWidget:on', 'close', () => {
      zE('webWidget', 'hide');
    });

    zE('webWidget:on', 'chat:unreadMessages', () => {
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    });
  }

  techSupportClicked() {
    this.showTechSupport = !this.showTechSupport;
    this.showQuestions = false;
    this.showPeople = false;
    this.showPolls = false;
    this.showSuperUser = false;
    this.showMaterials = false;
  }

  openSupportChat() {
    var settings = {
      webWidget: {
        helpCenter: {
          originalArticleButton: false,
          suppress: true,
        },
        chat: {
          suppress: false,
        },
        launcher: {
          label: {
            'en-US': 'Make a request',
            '*': 'Get help',
          },
        },
      },
    };
    zE('webWidget', 'updateSettings', settings);

    zE('webWidget', 'show');
    zE('webWidget', 'open');
  }

  debugRole() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('role')) {
        this.role = parseInt(params.get('role'));
      }
    });
  }

  toggleCompatabilityMode() {
    if (this.isWebRTC) {
      (<any>window).location = '/live-events/' + this.event.id + '/live?rtmp=true';
    } else {
      (<any>window).location = '/live-events/' + this.event.id + '/live?opentok=true';
    }
  }

  changeBugState() {
    this.bugShowing = this.bugShowing === 'initial' ? 'final' : 'initial';
  }

  exitPage() {
    if (this.auth.isSuperUser()) {
      this.router.navigate(['/super-user/events']);
      return;
    }

    if (this.ceutokens?.completionStatus?.metCEUCriteria) {
      if (this.role === EventRole.Viewer) {
        this.router.navigate(['/account/ceus']);
      } else {
        this.router.navigate(['/account/live-events']);
      }
    } else {
      this.toaster.confirmDialog('Are you sure you want to leave this event?', 'Exit?', 'Yes, Exit', 'Cancel').subscribe((result) => {
        if (result) {
          if (this.role === EventRole.Viewer) {
            this.router.navigate(['/events/', this.eventIdOrSlug]);
          } else {
            this.router.navigate(['/account/live-events']);
          }
        }
      });
    }
  }

  showDeviceSettings() {
    const modalRef = this.modalService.open(CameraTestModalComponent, {
      size: 'lg',
      backdrop: 'static',
      ariaLabelledBy: 'modal-basic-title'
    });
    if (this.selectedDevice) {
      console.log(this.selectedDevice);
      modalRef.componentInstance.selectedDevice = this.selectedDevice;
    }
    modalRef.result.then(
      (result) => {
        this.selectedDevice = result;

        if (this.publisher) {
          this.publisher.setAudioSource(this.selectedDevice.audioDeviceId);
          this.publisher.setVideoSource(this.selectedDevice.videoDeviceId);
        }
      },
      (reason) => {}
    );
    return;
  }

  changeLanguage(code: string) {
    localStorage.setItem('language', code);
    this.translate.use(code);
  }

  toggleVideo() {
    this.publishingVideo = !this.publishingVideo;
    this.publisher.publishVideo(this.publishingVideo);
  }

  toggleAudio() {
    this.publishingAudio = !this.publishingAudio;
    this.publisher.publishAudio(this.publishingAudio);
  }

  initAzure() {
    console.log('init azure');

    if (this.auth.isSuperUser()) {
      this.liveEventService.getDashboardIgnoreCache(this.event.id).subscribe((result) => {
        this.dashboard = result;
        this.getDashboardData();
        this.azureStreamChecker = interval(3000).subscribe((val) => this.getDashboardData());
      });
    } else {
      this.liveEventService.getDashboard(this.event.id).subscribe((result) => {
        this.dashboard = result;
        this.getDashboardData();
        this.azureStreamChecker = interval(3000).subscribe((val) => this.getDashboardData());
      });
    }
  }

  getDashboardData() {
    if (this.event.streamingFinished) {
      return;
    }

    console.log('getting dashboard data');
    if (this.dashboard.isChannelRunning && this.dashboard.isIngesting && this.dashboard.isBroadcastRunning && this.dashboard.streamingUrl && this.dashboard.streamingUrl.length > 0) {
      this.setupAzureLive();
      return;
    }

    if (this.auth.isSuperUser()) {
      this.liveEventService.getDashboardIgnoreCache(this.event.id).subscribe((dashboard) => {
        this.dashboard = dashboard;
        this.setupAzureLive();
      });
    } else {
      this.liveEventService.getDashboard(this.event.id).subscribe((dashboard) => {
        this.dashboard = dashboard;
        this.setupAzureLive();
      });
    }
  }

  setupAzureLive() {
    console.log('setup azure live');
    if (this.livePlayer && this.dashboard.isBroadcastRunning && this.dashboard.streamingUrl && (!this.livePlaying || this.livePlayer.currentTime < 2)) {
      this.livePlayer.setSrc(this.dashboard.streamingUrl);
      this.previewing = false;
      this.livePlayer.togglePlay(true);
      this.livePlaying = true;
      console.log('Player already exists. playing stream');
    }


    if (this.livePlayer) {
      console.log('Player already exists. not playing stream');
      return;
    }

    if (!this.dashboard.isChannelRunning || !this.dashboard.isIngesting) {
      return;
    }

    if (!this.auth.isSuperUser() && (!this.dashboard.isBroadcastRunning || !this.dashboard.streamingUrl || this.dashboard.streamingUrl.length === 0)) {
      return;
    }

    if (this.role != EventRole.Viewer && !this.dashboard.isBroadcastRunning && !this.dashboard.streamingUrl) {
      console.log('Preview Stream');
      this.livePlayer = flowplayer('#wowzaPlayer', {
        qsel: {
          labels: [],
        },

        src: this.dashboard.previewUrl,
        autoplay: flowplayer.autoplay.AUDIO_REQUIRED,
        ui: flowplayer.ui.NO_FULLSCREEN,
        token: "eyJraWQiOiIwWE44RnRTYkQxblYiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6MzgsXCJpZFwiOlwiMFhOOEZ0U2JEMW5WXCJ9IiwiaXNzIjoiRmxvd3BsYXllciJ9.wHlyQZ86rIHD8ldgnpiWbmFBmR4zt_3FSj78GMk7lfQ1es7K8y0MuHzbqcJfp0lm6LcUbUkQ5PsazIsAybxivg"
      });
      console.log(this.livePlayer);
      this.livePlayer.on('error', function (err) {
        console.info("error occured");
        console.info(err);
      });
      this.previewing = true;
      return;
    } else if (this.dashboard.isBroadcastRunning && this.dashboard.streamingUrl) {
      console.log('Broadcast Stream');
      this.livePlayer = flowplayer('#wowzaPlayer', {
        qsel: {
          labels: [],
        },
        dvr: true,
        live: true,
        seekable: true,
        controls: true,
        src: this.dashboard.streamingUrl,
        autoplay: flowplayer.autoplay.AUDIO_REQUIRED,
        ui: flowplayer.ui.NO_FULLSCREEN,
        token: "eyJraWQiOiIwWE44RnRTYkQxblYiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6MzgsXCJpZFwiOlwiMFhOOEZ0U2JEMW5WXCJ9IiwiaXNzIjoiRmxvd3BsYXllciJ9.wHlyQZ86rIHD8ldgnpiWbmFBmR4zt_3FSj78GMk7lfQ1es7K8y0MuHzbqcJfp0lm6LcUbUkQ5PsazIsAybxivg"
      });
      this.livePlayer.on('error', this.playbackErrorOccured.bind(this));
      this.previewing = false;
      this.livePlaying = true;
    }
  }

  playbackErrorOccured(err: FPEvent<unknown>) {
    console.info("error occured");
    console.info(err);
    this.livePlaying = false;
  }

  playVideoClicked(video: EventVideoViewModel) {
    this.playVideo(video.assetPath);
  }

  localVideoPlayed(video: string) {
    this.playVideo(video);
  }

  stopVideoClicked(video: EventVideoViewModel) {
    this.stopVideo();
  }

  reloadVideo() {
    (<any>window).location = (<any>window).location;
  }

  toggleCaptions() {
    if (this.cc) {
      (<any>window).location = '/live-events/' + this.event.id + '/live?rtmp=true';
    } else {
      (<any>window).location = '/live-events/' + this.event.id + '/live?rtmp=true&cc=true';
    }
  }

  stopVideo() {
    console.log('stopping');
    this.videoPlayer.pause();
    this.playingVideo = false;
    this.ref.detectChanges();
    this.session.unpublish(this.videoPublisher);

    this.videoPublisher.destroy();
    this.liveEventService.setStreamLayouts(this.event.id).subscribe();
    this.layout();

    //this.videoPlayer.removeEventListener('start');
    this.liveVideosComponent.resetPlayStatus();
  }

  playVideo(assetPath: string) {
    console.log('playing video ' + assetPath);

    this.playingVideo = true;
    this.ref.detectChanges();

    if (!this.videoPlayer) {
      console.log('creating player');

      this.videoPlayer = flowplayer('#videoPlayer', {
        // qsel: {
        //   labels: [],
        // },

        src: assetPath,
        autoplay: flowplayer.autoplay.AUDIO_REQUIRED,
        ui: flowplayer.ui.NO_FULLSCREEN,
        token: "eyJraWQiOiIwWE44RnRTYkQxblYiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6MzgsXCJpZFwiOlwiMFhOOEZ0U2JEMW5WXCJ9IiwiaXNzIjoiRmxvd3BsYXllciJ9.wHlyQZ86rIHD8ldgnpiWbmFBmR4zt_3FSj78GMk7lfQ1es7K8y0MuHzbqcJfp0lm6LcUbUkQ5PsazIsAybxivg"
      });

      this.videoPlayer.on('ended', (evt) => {
        this.stopVideo();
      });

      this.videoPlayer.on('loadeddata', (evt) => {
        console.log('started playing...');

        this.videoPlayer.emit(flowplayer.events.SET_QUALITY, { level: 0 })


        const canvas = <any>document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        const video = <any>document.querySelector('video.fp-engine');

        console.log(video);
        let self = this;
        let draw = function () {
          if (!self.playingVideo) {
            return;
          }

          canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
          if (document.visibilityState === 'visible') {
            window.requestAnimationFrame(draw);
          } else {
            setTimeout(draw, 33);
          }
        };

        draw();

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        let stream = video.captureStream ? video.captureStream() : video.mozCaptureStream();
        const canvasStream = canvas.captureStream(24);
        const videoTracks = canvasStream.getVideoTracks();
        const audioTracks = stream.getAudioTracks();

        const el = this.renderer.createElement('div');
        this.videoPublisher = OT.initPublisher(el, {
          name: 'video',
          //name: 'video-sm',
          resolution: '1920x1080',
          videoSource: videoTracks[0],
          audioSource: audioTracks[0],
          fitMode: 'contain',
          width: 320,
          height: 240,
          videoContentHint: 'motion',
          frameRate: 30,
          scalableVideo: true,
          scalableScreenshare: true,
        });

        let layout = this.layout;
        this.renderer.appendChild(this.webRTCLayoutContainer.nativeElement, el);
        console.log('publishing');
        this.session.publish(this.videoPublisher, (err: OTError) => {
          this.layout();
          this.liveEventService.setStreamLayouts(this.event.id).subscribe();
        });
      });
    }

      this.videoPlayer.setSrc(assetPath);
    //this.videoPlayer.src(src);

    // of(null)
    //   .pipe(delay(2000))
    //   .subscribe((result) => {
    //     this.videoPlayer.currentTime = 0;
    //     this.videoPlayer.play();
    //   });
  }

  connectToSignalR() {
    this.connection.start().then(
      () => {
        console.log('Connected to SignalR');
        this.connectedToSignalR = true;
        this.signalRInitialized = true;
        this.signalRFailed = false;

        this.connection.invoke('connectToEvent', this.event.id);
      },
      () => {
        console.log('Failed to connect to SignalR');
        this.connectedToSignalR = false;
        this.signalRInitialized = false;
        this.signalRFailed = true;
      }
    );
  }

  InitSignalR(): any {
    this.connection = new HubConnectionBuilder()
      .withUrl('/liveEventHub', {accessTokenFactory: () => this.auth.getToken()})
      .withAutomaticReconnect(new DefaultReconnectPolicy())
      .build();

    this.connectToSignalR();

    this.connection.onreconnected(() => {
      this.connection.invoke('connectToEvent', this.event.id);
      console.log('Re-Connected to SignalR');
      this.connectedToSignalR = true;
    });

    this.connection.onreconnecting(() => {
      console.log('Trying to reconnect to SignalR');
      this.connectedToSignalR = false;
    });

    this.connection.onclose(() => {
      console.log('Connection to SignalR closed');
      this.connectedToSignalR = false;
    });

    this.connection.on('streamStartedv2', (eventId: number, startTime, dashboard: EventDashboardViewModel) => {
      if (eventId === this.event.id) {
        console.log('SignalR stream started');
        console.log(dashboard);
        this.event.streamingStarted = true;
        console.log(startTime);
        this.event.streamingStartedTime = startTime;
        this.dashboard = dashboard;

        this.ref.detectChanges();
        of(null)
          .pipe(delay(500))
          .subscribe((result) => {
            console.log('resizing');
            if (this.layout) {
              this.layout();
            }
          });

        this.timer$ = this.getElapsedTime();
        if (this.role === EventRole.Viewer && this.isWebRTC) {
          this.InitOpenTok();
        } else {
          this.getDashboardData();
        }
      }
    });

    this.connection.on('streamFinished', (eventId: number) => {
      if (eventId === this.event.id) {
        console.log('stream ended');
        this.event.streamingStarted = false;
        this.event.streamingFinished = true;

        if (this.isWebRTC) {
          this.session.disconnect();
          this.screenPublishing = false;
          this.publishing = false;
          this.inGreenRoom = false;
          this.streamCount = 0;
          this.ref.detectChanges();
          of(null)
            .pipe(delay(500))
            .subscribe((result) => {
              this.layout();
            });
        }
      }
    });

    this.connection.on('inviteOnScreen', (toUser: number, eventId: number) => {
      if (eventId !== this.event.id || this.user.id !== toUser) {
        return;
      }
      this.toaster.confirmDialog('You have been invited to participate on screen. Would you like to connect? ', 'Participate on screen', 'Yes!', 'Cancel').subscribe((result) => {
        if (result) {
          this.screenInviteReceived();
        }
      });
    });

    this.connection.on('removeOnScreen', (toUser: number, eventId: number) => {
      if (eventId !== this.event.id || this.user.id !== toUser) {
        return;
      }
      this.screenUnInviteReceived();
      this.toaster.messageDialog('You have been removed from the screen', 'You have been removed').subscribe();
    });

    this.connection.on('updateAddendeeCameraAllowance', (eventId: number, allowAttendeesOnCamera: boolean, maxAttendeesOnCamera: number) => {
      if (eventId !== this.event.id) {
        return;
      }

      this.event.allowAttendeesOnCamera = allowAttendeesOnCamera;
      this.event.maxAttendeesOnCamera = maxAttendeesOnCamera;

      if (this.role == EventRole.Viewer && this.event.allowAttendeesOnCamera == false && this.attendeeCameraOn) {
        this.attendeeLeaveCamera();
      }
    });

    this.initialized = true;
  }

  usersUpdated(event: PagedActiveUserViewModel) {
    this.userCount = event.totalCount;
    this.randomUserIds = event.randomUserIds;
  }

  InitOpenTok() {
    this.ref.detectChanges();
    this.layout = initLayoutContainer(this.webRTCLayoutContainer.nativeElement).layout;
    let layout = this.layout;

    if (this.role === EventRole.Viewer && !this.event.streamingStarted) {
      return;
    }

    console.log('initialized: ' + this.openTokInitialized);
    if (this.openTokInitialized) {
      return;
    }

    console.log('initializing opentok');

    this.opentokService
      .initSessionForEvents(this.event.id)
      .then((session: OT.Session) => {
        this.session = session;

        this.session.on('streamPropertyChanged', (event) => {
          console.log('changed');
          console.log(event.stream.streamId);
        });

        this.session.on('streamCreated', (event) => {
          console.log(event.stream.name);
          console.log(event.stream.connection.data);
          this.streamCount++;

          var style: any = {nameDisplayMode: 'off'};

          if (event.stream.name.includes('ATTENDEE:')) {
            style = {nameDisplayMode: 'on'};
            event.stream.name = event.stream.name.replace('ATTENDEE:', '');
          }

          let subscription = this.session.subscribe(event.stream, 'webRTCLayoutContainer', {
            insertMode: 'append',
            style: style,
          });

          if (event.stream.name.includes('PRESENTER:')) {
            if (this.role != EventRole.Host && this.role != EventRole.Guest) {
              subscription.element.classList.add('OT_presenter');
              subscription.element.classList.add('OT_big');
            }
          }

          if (subscription.stream.videoType === 'screen') {
            subscription.element.classList.add('OT_screen');
            subscription.element.classList.add('OT_big');
          }

          if (subscription.stream.name === 'video-sm') {
            subscription.element.classList.add('OT_big');
            subscription.element.classList.add('OT_video');
          }

          if (subscription.stream.name === 'ATEM') {
            subscription.element.classList.add('OT_big');
            subscription.element.classList.add('OT_ATEM');
          }

          if (subscription.stream.name === 'video') {
            subscription.element.classList.add('OT_ignore');
          }

          subscription.element.addEventListener('dblclick', function () {
            if (subscription.element.classList.contains('OT_big')) {
              subscription.element.classList.remove('OT_big');
            } else {
              subscription.element.classList.add('OT_big');
            }
            layout();
          });
          layout();
          this.ref.detectChanges();
          this.updateLayoutClasses();
          layout();
        });

        this.session.on('streamDestroyed', (event) => {
          this.streamCount--;
          session.getSubscribersForStream(event.stream).forEach((subscriber) => {
            subscriber.element.classList.remove('ot-layout');

            setTimeout(() => {
              this.updateLayoutClasses();
              layout();
            }, 200);
          });
          layout();
          this.ref.detectChanges();

          layout();
        });
      })
      .then(() => {
        this.opentokService.connect();
        this.openTokInitialized = true;
      })
      .catch((err) => {
        console.error(err);
        alert('Unable to connect.');
      });
  }

  updateLayoutClasses() {
    //presenter should be OT_Big unless screen or video sharing.

    if (this.role == EventRole.Host || this.role == EventRole.Guest) {
      return;
    }

    try {
      var screensOrVideos = this.webRTCLayoutContainer.nativeElement.querySelectorAll('.OT_screen, .OT_video, .OT_ATEM');
      var presenters = this.webRTCLayoutContainer.nativeElement.querySelectorAll('.OT_presenter');

      if (!presenters) {
        return;
      }

      if (screensOrVideos && screensOrVideos.length > 0) {
        console.log('removing ot_big from all presenters');
        presenters.forEach((element) => {
          element.classList.remove('OT_big');
        });
      } else {
        console.log('adding ot_big to all presenters');
        presenters.forEach((element) => {
          element.classList.add('OT_big');
        });
      }

      console.log(screensOrVideos);
    } catch {}
  }

  ngAfterViewInit() {
    of(null)
      .pipe(delay(1000))
      .subscribe((result) => {
        this.chatResized(null);
      });
  }

  chatResized(event) {
    if (this.layout) {
      this.layout();
    }
  }

  collapseChat() {
    this.chatCollapsed = true;
    of(null)
      .pipe(delay(200))
      .subscribe((result) => {
        this.chatResized(null);
      });
  }

  openChat(elem: LiveEventChatComponent) {
    this.chatCollapsed = false;
    of(null)
      .pipe(delay(200))
      .subscribe((result) => {
        this.chatResized(null);
        elem.scrollToBottom(false);
      });
  }

  attendeeJoinWithCamera() {
    if (this.streamCount > this.event.maxAttendeesOnCamera) {
      this.toaster.messageDialog('The maximum number of cameras on screen has been reached. Please try again when someone drops off.', 'Maximum Videos').subscribe();
      return;
    }

    this.isWebRTC = true;
    this.compatabilityMode = false;
    this.publishingAudio = false;

    if (this.livePlayer) {
      console.log('disposed media player');
      this.livePlayer.destroy();
    }

    this.InitOpenTok();

    of(null)
      .pipe(delay(500))
      .subscribe((result) => {
        this.attendeeCameraOn = true;
        this.ref.detectChanges();

        const OT = this.opentokService.getOT();
        const el = this.renderer.createElement('div');

        if (this.selectedDevice) {
          this.publisher = OT.initPublisher(el, {
            publishAudio: this.publishingAudio,
            publishVideo: this.publishingVideo,
            resolution: '320x240',
            name: 'ATTENDEE:' + this.user.firstName + ' ' + this.user.lastName,
            width: '100%',
            insertDefaultUI: true,
            style: {
              nameDisplayMode: 'off',
              archiveStatusDisplayMode: 'off',
              buttonDisplayMode: 'off'
            },
            audioSource: this.selectedDevice.audioDeviceId,
            videoSource: this.selectedDevice.videoDeviceId
          });
        } else {
          this.publisher = OT.initPublisher(el, {
            publishAudio: this.publishingAudio,
            publishVideo: this.publishingVideo,
            resolution: '320x240',
            name: 'ATTENDEE:' + this.user.firstName + ' ' + this.user.lastName,
            width: '100%',
            insertDefaultUI: true,
            style: {
              nameDisplayMode: 'off',
              archiveStatusDisplayMode: 'off',
              buttonDisplayMode: 'off'
            }
          });
        }

        let layout = this.layout;

        el.addEventListener('dblclick', function () {
          if (el.classList.contains('OT_big')) {
            el.classList.remove('OT_big');
          } else {
            el.classList.add('OT_big');
          }
          layout();
        });

        this.renderer.appendChild(this.webRTCLayoutContainer.nativeElement, el);
        layout();

        if (this.session) {
          if (this.session['isConnected']()) {
            this.publish();
          }
          this.session.on('sessionConnected', () => this.publish());
        }
      });
  }

  attendeeLeaveCamera() {
    this.attendeeCameraOn = false;
    if (this.publisher) {
      this.session.unpublish(this.publisher);
    }
    if (this.screenPublisher) {
      this.session.unpublish(this.screenPublisher);
    }
    this.publishing = false;
    this.layout();
    this.liveEventService.setStreamLayouts(this.event.id).subscribe();
  }

  enterGreenRoom(atemInput: boolean) {
    this.inGreenRoom = true;
    this.ref.detectChanges();

    const OT = this.opentokService.getOT();
    const el = this.renderer.createElement('div');

    if (this.auth.isSuperUser() && this.event.streamingStarted) {
      this.publishingVideo = false;
    }

    var resolution: '1920x1080' | '1280x960' | '1280x720' | '640x480' | '640x360' | '320x240' | '320x180' = '1920x1080';
    var fitMode: 'cover' | 'contain' = 'cover';
    var name = 'PRESENTER: ' + this.user.firstName + ' ' + this.user.lastName;
    var mirror = true;

    if (atemInput) {
      resolution = '1920x1080';
      fitMode = 'contain';
      name = 'ATEM';
      mirror = false;
      el.classList.add('OT_big');
      el.classList.add('OT_ATEM');
    }

    if (this.selectedDevice) {
      this.publisher = OT.initPublisher(el, {
        publishAudio: this.publishingAudio,
        publishVideo: this.publishingVideo,
        mirror: mirror,
        resolution: resolution,
        name: name,
        fitMode: fitMode,
        width: '100%',
        insertDefaultUI: true,
        style: {
          nameDisplayMode: 'off',
          archiveStatusDisplayMode: 'off',
          buttonDisplayMode: 'off'
        },
        audioSource: this.selectedDevice.audioDeviceId,
        videoSource: this.selectedDevice.videoDeviceId
      });
    } else {
      this.publisher = OT.initPublisher(el, {
        publishAudio: this.publishingAudio,
        publishVideo: this.publishingVideo,
        mirror: mirror,
        resolution: resolution,
        name: name,
        fitMode: fitMode,
        width: '100%',
        insertDefaultUI: true,
        style: {
          nameDisplayMode: 'off',
          archiveStatusDisplayMode: 'off',
          buttonDisplayMode: 'off'
        }
      });
    }

    let layout = this.layout;

    el.addEventListener('dblclick', function () {
      if (el.classList.contains('OT_big')) {
        el.classList.remove('OT_big');
      } else {
        el.classList.add('OT_big');
      }
      layout();
    });

    this.renderer.appendChild(this.webRTCLayoutContainer.nativeElement, el);
    layout();

    if (this.session) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', () => this.publish());
    }
  }

  tokenReceived(token: TokenModel) {
    this.currentToken = token;

    let subscription = of(null)
      .pipe(delay(180000))
      .subscribe((result) => {
        this.currentToken = null;
        subscription.unsubscribe();
      });
  }

  submitToken(token: TokenModel) {
    this.currentToken = null;
    let model = new StringViewModel();
    model.value = token.token;
    this.eventData.submitToken(this.event.id, token.tokenNumber, model).subscribe();
    this.toaster.success('Attendance Recorded', 'Success');
  }

  publish() {
    this.session.publish(this.publisher, (err) => {
      if (err) {
        alert(err.message);
        this.layout();
      } else {
        this.publishing = true;
        this.layout();
        this.ref.detectChanges();
        this.layout();
        this.liveEventService.setStreamLayouts(this.event.id).subscribe();
      }
    });
  }

  exitGreenRoom() {
    this.inGreenRoom = false;

    if (this.publisher) {
      this.session.unpublish(this.publisher);
    }
    if (this.screenPublisher) {
      this.session.unpublish(this.screenPublisher);
    }
    this.publishing = false;
    this.liveEventService.setStreamLayouts(this.event.id).subscribe();
    of(null)
      .pipe(delay(500))
      .subscribe((result) => {
        this.layout();
      });
  }

  goLive() {
    this.toaster.confirmDialog('This will start broadcasting to everyone and the broadcast will be recorded.', 'Go live with this event?', "Yes, let's go!", 'Cancel').subscribe((result) => {
      if (result) {
        this.toaster.spinnerDialog('This can take a moment...', 'Starting Broadcast').subscribe((spinnerResult) => {
          this.liveEventService.startWebRTCStream(this.event.id).subscribe(
            (event) => {
              spinnerResult.close();
              this.event.streamingStarted = event.streamingStarted;
              this.event.streamingStartedTime = event.streamingStartedTime;
              this.toaster.success('Your stream has started!', "You're Live!");

              interval(1000).subscribe(() => {
                if (!this.ref['destroyed']) {
                  this.ref.detectChanges();
                }
              });
            },
            (error) => {
              console.log(error.error.toString());
              this.toaster.messageDialog(error.error, 'Error Starting Event', 'Ok', true).subscribe();
              spinnerResult.close();
            }
          );
        });
      }
    });
  }

  endStream() {
    this.toaster.confirmDialog('This will end the stream and stop the recording.', 'Stop the stream?', 'Yes, stop', 'Cancel').subscribe((result) => {
      if (result) {
        this.toaster.spinnerDialog('This can take a moment...', 'Stopping Broadcast').subscribe((spinnerResult) => {
          this.liveEventService.stopWebRTCStream(this.event.id).subscribe((event) => {
            spinnerResult.close();
            this.event.streamingStarted = event.streamingStarted;
            this.event.streamingStartedTime = event.streamingStartedTime;
            this.toaster.success('Your stream has ended!', 'Stream Ended');
          });
        });
      }
    });
  }

  test() {
    const video = <any>document.querySelector('#videoPlayer div div video');
    const stream = video.captureStream();
    const videoTracks = stream.getVideoTracks();
    const audioTracks = stream.getAudioTracks();

    let publisher = OT.initPublisher('publisher', {
      name: 'video',
      videoSource: videoTracks[0],
      audioSource: audioTracks[0],
      fitMode: 'contain',
      width: 320,
      height: 240,
    });

    this.session.publish(publisher);
  }

  shareScreen() {
    const OT = this.opentokService.getOT();
    const el = this.renderer.createElement('div');

    const screenOverlay = this.renderer.createElement('div');
    const messageContainer = this.renderer.createElement('div');
    const screenOverlayMessage: HTMLElement = this.renderer.createElement('p');
    this.screenPublisher = OT.initPublisher(el, {
      videoSource: 'screen',
      frameRate: 30,
      width: '100%',
      resolution: '1280x720',
      insertDefaultUI: true,
      style: {
        nameDisplayMode: 'off',
        archiveStatusDisplayMode: 'off',
        buttonDisplayMode: 'off'
      }
    });

    this.screenPublisher.on(
      'mediaStopped',
      function (event) {
        console.log('stream destroyed');
        this.stopScreenSharing();
      },
      this
    );

    let layout = this.layout;

    el.classList.add('OT_big');
    el.classList.add('OT_My_Screen');
    screenOverlay.classList.add('OT_screen_overlay');
    messageContainer.classList.add('OT_messageContainer');

    el.addEventListener('dblclick', function () {
      if (el.classList.contains('OT_big')) {
        el.classList.remove('OT_big');
      } else {
        el.classList.add('OT_big');
      }
      layout();
    });

    screenOverlayMessage.innerHTML = "<i class='fas fa-desktop fa-family'></i><br>You are sharing your screen";
    this.renderer.appendChild(messageContainer, screenOverlayMessage);
    this.renderer.appendChild(el, screenOverlay);
    this.renderer.appendChild(el, messageContainer);
    this.renderer.appendChild(this.webRTCLayoutContainer.nativeElement, el);
    layout();

    if (this.session) {
      if (this.session['isConnected']()) {
        this.publishScreen();
      }
      this.session.on('sessionConnected', () => this.publishScreen());
    }
  }

  publishScreen() {
    this.session.publish(this.screenPublisher, (err: OTError) => {
      if (err && err.name == 'OT_USER_MEDIA_ACCESS_DENIED') {
        this.layout();
      } else if (err) {
        this.toaster.messageDialog(err.name, err.message, 'Error').subscribe();
        this.layout();
      } else {
        this.screenPublishing = true;
        this.layout();
        this.ref.detectChanges();
        this.layout();
        this.liveEventService.setStreamLayouts(this.event.id).subscribe();
        this.safariScreenShareFix();
      }
    });
  }

  safariScreenShareFix() {
    let parser = new UAParser();
    let result = parser.getResult();

    if (result.browser.name != 'Safari') return;

    of(null)
      .pipe(delay(500))
      .subscribe((result) => {
        this.publisher.publishVideo(true);
      });
  }

  stopScreenSharing() {
    this.session.unpublish(this.screenPublisher);
    this.screenPublishing = false;
    this.layout();
    this.liveEventService.setStreamLayouts(this.event.id).subscribe();
  }

  ngOnDestroy(): void {
    document.body.style.backgroundColor = '#fff';
    let root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
    root.removeAttribute('class');

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.connection) {
      this.connection.stop();
    }

    if (this.publisher) {
      this.publisher.destroy();
    }

    if (this.session) {
      this.session.off('streamCreated');
      this.session.off('streamDestroyed');
      this.session.disconnect();
    }

    if (this.azureStreamChecker) {
      this.azureStreamChecker.unsubscribe();
    }

    if (this.adShower) {
      this.adShower.unsubscribe();
    }

    if (this.livePlayer) {
      console.log('disposed media player');
      this.livePlayer.destroy();
    }

    if (this.videoPlayer) {
      console.log('disposed media player');
      this.videoPlayer.destroy();
    }
  }

  screenInviteReceived() {
    this.isWebRTC = true;
    this.compatabilityMode = false;
    this.role = EventRole.Guest;

    if (this.livePlayer) {
      console.log('disposed media player');
      this.livePlayer.destroy();
    }

    this.InitOpenTok();

    of(null)
      .pipe(delay(500))
      .subscribe((result) => {
        this.enterGreenRoom(false);
      });
  }

  screenUnInviteReceived() {
    this.role = EventRole.Viewer;
    this.inGreenRoom = false;
    if (this.session && this.publisher) {
      this.session.unpublish(this.publisher);
    }
    if (this.session && this.screenPublisher) {
      this.session.unpublish(this.screenPublisher);
    }
    this.publishing = false;

    if (!this.event.streamingStarted) {
      if (this.session) {
        this.session.disconnect();
        this.session.off('streamCreated');
        this.session.off('streamDestroyed');
      }
      this.streamCount = 0;
      this.openTokInitialized = false;
    }
    this.layout();
  }

  private getElapsedTime(): Observable<TimeSpan> {
    return timer(0, 1000).pipe(
      map(() => {
        let date = new Date(this.event.streamingStartedTime);
        let totalSeconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

        let pre = false;
        if (totalSeconds < 0) {
          pre = true;
        }

        totalSeconds = Math.abs(totalSeconds);

        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        if (totalSeconds >= 3600) {
          hours = Math.floor(totalSeconds / 3600);
          totalSeconds -= 3600 * hours;
        }

        if (totalSeconds >= 60) {
          minutes = Math.floor(totalSeconds / 60);
          totalSeconds -= 60 * minutes;
        }

        seconds = totalSeconds;

        //console.log(hours + ':' + minutes + ':' + seconds);
        return {
          hours: hours,
          minutes: minutes,
          seconds: seconds,
          pre: pre,
        };
      })
    );
  }

  get offAirImage() {
    if (this.event.sponsorOffAirImage) {
      return 'https://bxlimages.blob.core.windows.net/images/' + this.event.sponsorOffAirImage;
    } else {
      return '../../assets/off-air.png';
    }
  }

  get bugImage() {
    if (this.event.sponsorBugImage) {
      return 'fetchimage?filename=' + this.event.sponsorBugImage;
    } else {
      return '../../assets/bug.gif';
    }
  }

  unreadChanged(val) {
    this.unreadQuestionCount = val;
  }

  pollUnreadChanged(val) {
    this.unreadPollCount = val;
  }

  materialUnreadChange(val) {
    this.unreadMaterialCount = val;
  }

  public requestModerator() {
    this.submitComplete = new Promise((resolve: any, reject) => {
      this.liveEventService.requestSupport(this.event.id).subscribe(() => {
        resolve();
        this.toaster.messageDialog("Your request has been sent to the moderator. A member of the BehaviorLive team will meet you in here shortly.", "Request Sent").subscribe();
      });
    });
  }

  materialCountChanged(length) {
    this.materialCount = length;
    if (length === 0 && this.role === EventRole.Viewer) {
      this.showMaterials = false;
    }
  }

  pollCountChanged(val) {
    this.pollCount = val;
    if (this.pollCount === 0 && this.role === EventRole.Viewer) {
      this.showPolls = false;
    }
  }

  videoUnreadChanged(val) {
    this.unreadVideoCount = val;
  }

  videoCountChanged(val) {
    this.videoCount = val;
    if (this.videoCount === 0 && this.role === EventRole.Viewer) {
      this.showVideos = false;
    }
  }

  fullscreen() {
    let elem = this.fullscreenContainer.nativeElement;
    let doc = <any>document;
    if (!this.isFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }

      of(null)
        .pipe(delay(500))
        .subscribe((result) => {
          this.isFullScreen = true;
          if (this.layout) {
            this.layout();
          }
        });
    } else {
      if (document.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        /* Firefox */
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        /* IE/Edge */
        doc.msExitFullscreen();
      }

      of(null)
        .pipe(delay(500))
        .subscribe((result) => {
          this.isFullScreen = false;
          if (this.layout) {
            this.layout();
          }
        });
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  exit(ev) {
    if (this.isFullScreen) {
      of(null)
        .pipe(delay(3000))
        .subscribe((result) => {
          this.isFullScreen = false;
          console.log('exiting');
          if (this.layout) {
            this.layout();
          }
        });
    }
  }

  public startRTMPStream() {
    this.startStreamPromise = new Promise((resetButton: any, reject) => {
      this.liveEventService.startStreaming(this.event.id).subscribe((result) => {
        this.toaster.success('Stream started', 'Success');
        resetButton();
      });
    });
  }
}
