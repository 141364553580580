import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service'
import { LoginViewModel } from 'src/app/_models/generatedModels';
import { Router, ActivatedRoute } from '@angular/router';



@Component({
  selector: 'login-modal-component',
  templateUrl: './loginPage.component.html'
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  errorMessage = '';
  submitComplete: Promise<{}> | undefined;
  returnUrl: string;

  constructor(private fb: FormBuilder, private authService: AuthenticationService, private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  get f() { return this.loginForm.controls; }

  onSave() {
    this.errorMessage = '';
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      var model = new LoginViewModel();
      var formData: LoginViewModel = Object.assign({}, model, this.loginForm!.value);
      console.log(formData);
      this.authService.login(formData.email, formData.password).subscribe(user => {
        if (user) {
          console.log(decodeURIComponent(this.returnUrl));
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.errorMessage = 'Invalid Username or Password!';
        }
        resetButton();

      }, error => {
        resetButton();
        this.errorMessage = 'Invalid Username or Password!';
      });
    });
  }
}
