<!--component html goes here -->
<!--component html goes here -->
<page-load-header *ngIf="!initialized"></page-load-header>
<ng-container *ngIf="initialized">
  <div #fullscreenContainer>
    <header>
      <!-- Fixed navbar -->
      <nav class="navbar nowrap navbar-expand-md navbar-light bg-white">
        <a class="navbar-brand event-title text-truncate cursor-pointer"
          >{{ event.title }} - {{ event.instructor.fullName }} <span *ngIf="event.instructor.pronouns"> ({{ event.instructor.pronouns }})</span></a
        >
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="form-inline ml-auto mt-2 mt-md-0">
            <button type="button" class="btn btn-icon" (click)="exitPage()">
              <h3 class="m-0 text-muted"><i class="fas fa-times"></i></h3>
            </button>
          </div>
        </div>
      </nav>
    </header>

    <main id="live-event" class="main-container">
      <div class="main-theater">
        <div class="row no-gutters">
          <div class="col video-container" style="padding-bottom: 60px">
            <div class="row" style="height: calc(100vh - 120px)">
              <div [ngClass]="{ 'col-md-6': windowCount > 1, 'col-md-12': windowCount == 1 }" style="overflow: scroll; height: calc(100vh - 120px)">
                <live-questions [event]="event" [showInput]="false" [role]="role" (closed)=" showMaterials = false" [connection]="connection"></live-questions>
              </div>
              <div [ngClass]="{ 'col-md-6': windowCount > 1, 'col-md-12': windowCount == 1 }" style="overflow: scroll; height: calc(100vh - 120px)">
                <live-polls [ngClass]="{ 'd-none': !showPolls }" [event]="event"  [showInput]="false" [role]="role" [connection]="connection"></live-polls>
                <bxl-event-materials [ngClass]="{ 'd-none': !showMaterials }"  [showInput]="false" [event]="event" [role]="role" [connection]="connection"></bxl-event-materials>
                <live-people [ngClass]="{ 'd-none': !showPeople }" [event]="event" [showInput]="false" [role]="role" (usersUpdated)="usersUpdated($event)" [connection]="connection"></live-people>
              </div>
            </div>
          </div>
          <footer class="live-footer">
            <div class="row no-gutters">
              <div class="col pl-2 pr-2">
                <div class="d-flex">
                  <div class="mr-auto">
                    <div class="align-middle">
                      <ul class="list-inline mb-0"></ul>
                    </div>
                  </div>

                  <div class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showMaterials }" class="cursor-pointer" (click)="showMaterials = !showMaterials; showPolls = false; showPeople = false"> Handouts</a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showChat }" class="cursor-pointer" (click)="showChat = !showChat;"> Chat</a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-3" data-toggle="tooltip" data-placement="top" title="" data-original-title="Access user polling">
                          <p class="text-primary">
                            <a [ngClass]="{ 'live-event-link-active': showPolls }" class="cursor-pointer" (click)="showPolls = !showPolls; showPeople = false; showMaterials = false">Polls </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="">
                    <div class="align-middle">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-1" data-toggle="tooltip" data-placement="top" title="">
                          <p class="text-primary"><a class="cursor-pointer" [ngClass]="{ 'live-event-link-active': showPeople }" (click)="showPeople = !showPeople; showMaterials = false; showPolls = false">People</a></p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="">
                    <div class="align-middle d-none d-md-block" style="line-height: 0; padding-top: 14px">
                      <ul class="list-inline mb-0">
                        <li *ngFor="let id of randomUserIds; let idx = index" class="list-inline-item mr-0" [ngClass]="{ 'ml-n3': idx > 0 }" data-toggle="tooltip" data-placement="top" title="" data-original-title="James Collins">
                          <div class="u-sm-avatar u-sm-avatar--bordered rounded-circle">
                            <img class="img-fluid rounded-circle"  [fallback]="'fetch-avatar?userId=' + id" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ id }}-avatar.png"  />
                          </div>
                        </li>

                        <li *ngIf="userCount > 0" class="list-inline-item ml-n3 mr-0">
                          <a class="btn btn-sm btn-icon btn-light rounded-circle" type="button" (click)="showPeople = !showPeople">
                            <span class="btn-icon__inner">{{ userCount }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div [ngClass]="{ 'd-none': !showChat }" class="col chat-sidebar pt-1"></div>
            </div>
          </footer>

          <!-- <div class="popover-window col" [ngClass]="{ 'd-none': !showMaterials }"></div> -->

          <div class="col chat-sidebar pt-1" [ngClass]="{ 'chat-collapsed': !showChat }">
            <live-event-chat #child [event]="event" (collapse)="showChat = false" [connection]="connection"></live-event-chat>
          </div>

          <!-- <div class="popover-window col" [ngClass]="{ 'd-none': !showPeople }">
            <live-people [event]="event" [role]="role" (usersUpdated)="usersUpdated($event)" [connection]="connection" (closed)="showPeople = false; showPolls = false; showSuperUser = false; showTips = false; showMaterials = false"></live-people>
          </div> -->
        </div>
      </div>
    </main>
  </div>
  <!-- <footer class="live-footer">
    <div class="container-fluid">
      <p>hello</p>
    </div>

  </footer> -->
</ng-container>
