import { Component, OnInit, AfterViewInit, Renderer2, ElementRef, ViewChild, ChangeDetectorRef, OnDestroy, Input, NgZone } from '@angular/core';
import { OpentokService } from 'src/app/_services/opentok.service';
import { interval, of, Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectedDevice } from 'src/app/_models/models';
import { Zone } from 'luxon';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'bxl-camera-test-modal',
  templateUrl: 'camera-test-modal.component.html',
})
export class CameraTestModalComponent implements AfterViewInit, OnInit, OnDestroy {
  initialized = false;
  public audioInputs: OT.Device[];
  public videoInputs: OT.Device[];
  //public currentVideoDeviceId: string;
  //public currentVideoDevice: string;
  //public currentAudioDevice: MediaStreamTrack;
  //public currentAudioDeviceId: string;

  @Input()
  public selectedDevice: SelectedDevice = new SelectedDevice(null, null);

  @Input()
  public isModal = true;

  private deviceChecker: Subscription;
  movingAvg: number = null;
  audioLevel = 0;
  fresh = false;

  publisher: OT.Publisher;
  accessAllowed: boolean = null;
  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @ViewChild('soundBar', { static: true }) soundBar: ElementRef;
  constructor(private activeModal: NgbActiveModal, private zone: NgZone, private opentokService: OpentokService, private renderer: Renderer2, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getDeviceList();
  }

  ngAfterViewInit() {
    this.initOpenTok();
  }

  initOpenTok() {
    const OT = this.opentokService.getOT();

    if (this.selectedDevice && this.selectedDevice.videoDeviceId && this.selectedDevice.audioDeviceId) {
      this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {disableAudioProcessing:true, videoSource: this.selectedDevice.videoDeviceId, audioSource: this.selectedDevice.audioDeviceId, width: 264, height: 198, insertMode: 'append', insertDefaultUI: true, style: { nameDisplayMode: 'off', buttonDisplayMode: 'off' } });
      this.fresh = false;
    } else {
      console.log('no device init');
      this.fresh = true;
      this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, { disableAudioProcessing:true, width: 264, height: 198, insertMode: 'append', insertDefaultUI: true, style: { nameDisplayMode: 'off', buttonDisplayMode: 'off' } });
    }

    this.ref.detectChanges();

    this.publisher.on(
      'accessAllowed',
      function (event) {
        console.log('access allowed');
        this.accessAllowed = true;
        console.log(this.audioInputs);
        console.log(this.videoInputs);

        if (!this.audioInputs || !this.videoInputs || this.audioInputs?.length == 0 || this.videoInputs?.length == 0 || this.videoInputs[0].deviceId == undefined || this.audioInputs[0].deviceId == undefined) {
          console.log('getting devices');
          console.log(this.audioInputs);
          console.log(this.videoInputs);
          this.getDeviceList();
        }
      },
      this
    );

    this.publisher.on(
      'videoElementCreated',
      function (event) {
        console.log('heres');
        this.initialized = true;
        this.setInitialDevices();
      },
      this
    );

    this.publisher.on(
      'accessDialogOpened',
      function (event) {
        this.initialized = true;
      },
      this
    );

    console.log('here');

    this.publisher.on(
      'audioLevelUpdated',
      function (event) {
        if (this.movingAvg == null || this.movingAvg <= event.audioLevel) {
          this.movingAvg = event.audioLevel;
        } else {
          this.movingAvg = 0.7 * this.movingAvg + 0.3 * event.audioLevel;
        }

        // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
        this.audioLevel = Math.log(this.movingAvg) / Math.LN10 / 1.5 + 1;
        this.audioLevel = Math.min(Math.max(this.audioLevel, 0), 1);
        this.soundBar.nativeElement.style.height = `${this.audioLevel * 100}%`;
      },
      this
    );

    //this.setInitialDevices();
  }

  setInitialDevices() {
    of(null)
      .pipe(delay(100))
      .subscribe((result) => {
        var videoId = this.publisher.getVideoSource();
        var audio = this.publisher.getAudioSource();
        var audioId = audio.id;
        console.log(videoId);
        console.log(audio);
        console.log(this.audioInputs);
        console.log(this.videoInputs);
        var index = this.audioInputs.findIndex((x) => x.label == audio.label);
        if (index > -1) {
          audioId = this.audioInputs[index].deviceId;
        }
        console.log(this.selectedDevice);

        this.selectedDevice.audioDeviceId = audioId;
        this.selectedDevice.videoDeviceId = videoId.deviceId;

        console.log(this.selectedDevice);
        // of(null)
        //   .pipe(delay(500))
        //   .subscribe((result) => {
        //     this.ref.detectChanges();
        //   });
      });
  }

  save() {
    this.activeModal.close(this.selectedDevice);
  }
  cancel() {
    this.activeModal.dismiss();
  }

  getDeviceList() {
    console.log('device list');
    let currentIndex = 0;
    OT.getDevices((err, devices) => {
      this.audioInputs = devices.filter((device) => device.kind === 'audioInput');
      this.videoInputs = devices.filter((device) => device.kind === 'videoInput');
    });
  }

  setAudioSource(event) {
    let deviceId = event.target.value;
    console.log(deviceId);
    this.publisher.setAudioSource(deviceId).then(() => {
      console.log(this.publisher.getAudioSource());
      this.selectedDevice.audioDeviceId = deviceId;
    });
  }

  ngOnDestroy(): void {
    this.publisher.destroy();
  }

  setVideoSource(event) {
    let deviceId = event.target.value;
    this.publisher.setVideoSource(deviceId).then(() => {
      this.selectedDevice.videoDeviceId = deviceId;
    });
  }
}
