import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BrandViewModel } from '../_models/generatedModels';

@Injectable()
export class SettingsProvider {
  private config: any;

  public loadConfig(http): Promise<any> {
    console.log('loading config');
    return this.getJSON(http)
      .pipe(
        tap((env) => {
          this.config = env;

          (<any>window).Stripe.setPublishableKey(this.stripePublishableKey);
        })
      )
      .toPromise();
  }

  public getJSON(http): Observable<any> {
    return http.get('/api/settings/client');
  }

  public get configuration(): any {
    return this.config;
  }

  public get clientIP(): string {
    return this.config.clientIP;
  }

  public get backendUrl(): string {
    return this.config.backendUrl;
  }

  public get applicationName(): string {
    return this.config.applicationName;
  }

  public get siteUrl(): string {
    return this.config.siteUrl;
  }

  public get office365ClientId(): string {
    return this.config.office365ClientId;
  }

  public get stripePublishableKey(): string {
    return this.config.stripePublishableKey;
  }

  public get stripeClientKey(): string {
    return this.config.stripeClientKey;
  }

  public get linkedInClientKey(): string {
    return this.config.linkedInClientKey;
  }

  public get brand(): BrandViewModel {
    return this.config.brand;
  }

  public get authTokenName(): string {
    return this.applicationName.toLowerCase() + '-currentUser';
  }
}
