import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { SuccessEvent } from '@progress/kendo-angular-upload';
import { interval, Subscription } from 'rxjs';
import { CourseViewModel, EventViewModel, MaterialViewModel } from 'src/app/_models/generatedModels';
import { EventRole } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { EventService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-event-materials',
  templateUrl: 'event-materials.component.html',
})
export class EventMaterialsComponent implements OnInit {
  initialized = false;
  public EventRole = EventRole;
  uploadUrl: string;
  private unreadCount = 0;
  @ViewChild('questionContainer') private questionContainer: ElementRef;

  @Input() showInput: boolean;

  @Output()
  closed = new EventEmitter();

  @Input() role: EventRole;

  @Input() connection: HubConnection;

  @Input() event: EventViewModel;

  @Output()
  unreadChanged = new EventEmitter<number>();

  @Output()
  countChanged = new EventEmitter<number>();

  intervalSubscription: Subscription;

  myFiles = [];
  materials: MaterialViewModel[];

  constructor(public auth: AuthenticationService, private eventService: EventService, private toaster: ToasterService) {}

  ngOnInit(): void {
    this.uploadUrl = '/api/uploadMaterials';
    this.intervalSubscription = interval(1000).subscribe((val) => this.checkUnread());

    console.log('materials');

    this.connection.on('materialsUpdated', (eventId: number) => {
      console.log('materials updated');
      if (eventId === this.event.id) {
        this.eventService.getMaterials(this.event.id).subscribe((materials) => {
          this.materials = materials;
          this.countChanged.emit(this.materials.length);
          if (this.questionContainer.nativeElement.offsetHeight === 0) {
            this.unreadCount++;
            this.unreadChanged.emit(this.unreadCount);
          }
        });
      }
    });

    this.loadMaterials();
    this.initialized = true;
  }

  loadMaterials(){
    this.eventService.getMaterials(this.event.id).subscribe((materials) => {
      this.materials = materials;
      this.countChanged.emit(this.materials.length);
    });
  }

  checkUnread(): void {
    if (this.questionContainer && this.questionContainer.nativeElement && this.questionContainer.nativeElement.offsetHeight > 0) {
      this.unreadChanged.emit(0);
      this.unreadCount = 0;
    }
  }

  deleteMaterial(item: MaterialViewModel) {
    this.toaster.confirmDialog('Are you sure you want to delete this handout?', 'Are you sure?').subscribe((confirm) => {
      if (confirm) {
        this.eventService.deleteMaterial(this.event.id, item.id).subscribe((result) => {});
      }
    });
  }

  successEventHandler(e: SuccessEvent) {
    var model = new MaterialViewModel();
    model.blobPath = e.response.body.name;
    model.fileName = e.files[0].name;
    model.description = e.files[0].name;

    this.eventService.createMaterial(this.event.id, model).subscribe((result) => {
      this.myFiles = [];
    });
  }

  onComplete(e) {
    // this.myFiles = [];
  }

  close() {
    console.log('close');
    this.closed.emit();
  }
}
