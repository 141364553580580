<page-load-header *ngIf="!initialized"></page-load-header>
<!-- Hero Section -->
<div id="SVGhireUsBg" class="position-relative gradient-half-warning-v4">
  <div class="container space-2">
    <div class="row justify-content-lg-between align-items-center">
      <div class="col-md-6 col-lg-5">
        <h1 class="display-4 font-size-md-down-5 text-white mb-4">
          <strong>Immersive hybrid <span class="text-primary">events</span> to delight your audience</strong>
        </h1>
        <p class="lead text-white-70">A virtual event platform like no other.</p>
      </div>
      <div class="col-md-6">
        <img class="js-svg-injector" src="../../assets/svg/flat-icons/contact-us.svg" alt="SVG Illustration" data-parent="#SVGhireUsBg" />
      </div>
    </div>
  </div>

  <!-- SVG Background -->
  <figure class="position-absolute right-0 bottom-0 left-0">
    <img class="js-svg-injector w-100" style="height: 80px" src="../../assets/svg/components/wave-1-bottom-sm.svg"  data-parent="#SVGhireUsBg" />
  </figure>
  <!-- End SVG Background Section -->
</div>
<!-- End Hero Section -->

<!-- Features Section -->
<div id="SVGmockupBg" class="container space-2">
  <!-- Nav Classic -->
  <div class="position-relative bg-white text-center z-index-2 mx-lg-auto">
    <ul class="nav nav-classic nav-rounded nav-shadow nav-justified" id="pills-tab" role="tablist">
      <li class="nav-item">
        <a class="nav-link font-weight-medium active cursor-pointer" id="pills-one-example1-tab" data-toggle="pill" href="#pills-one-example1" role="tab" aria-controls="pills-one-example1" aria-selected="true">
          <div class="d-md-flex justify-content-md-center align-items-md-center">
            <figure class="ie-height-40 d-none d-md-block w-100 max-width-6 mr-3">
              <i class="fad fa-keynote home-icon-theme-small"></i>
            </figure>
            <span class="d-none d-md-block">For&nbsp;</span>Conferences
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link font-weight-medium cursor-pointer" id="pills-two-example1-tab" data-toggle="pill" href="#pills-two-example1" role="tab" aria-controls="pills-two-example1" aria-selected="false">
          <div class="d-md-flex justify-content-md-center align-items-md-center">
            <figure class="ie-height-40 d-none d-md-block w-100 max-width-6 mr-3">
              <i class="fad fa-building home-icon-theme-small"></i>
            </figure>
            <span class="d-none d-md-block">For&nbsp;</span>Organizations
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link font-weight-medium cursor-pointer" id="pills-three-example1-tab" data-toggle="pill" href="#pills-three-example1" role="tab" aria-controls="pills-three-example1" aria-selected="false">
          <div class="d-md-flex justify-content-md-center align-items-md-center">
            <figure class="ie-height-40 d-none d-md-block w-100 max-width-6 mr-3">
              <i class="fad fa-user-chart home-icon-theme-small"></i>
            </figure>
            <span class="d-none d-md-block">For&nbsp;</span>Speakers
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link font-weight-medium cursor-pointer" id="pills-three-example1-tab" data-toggle="pill" href="#pills-three-example1" role="tab" aria-controls="pills-three-example1" aria-selected="false">
          <div class="d-md-flex justify-content-md-center align-items-md-center">
            <figure class="ie-height-40 d-none d-md-block w-100 max-width-6 mr-3">
              <i class="fad fa-bullseye-pointer home-icon-theme-small"></i>
            </figure>
            <span class="d-none d-md-block">For&nbsp;</span>Advertisers
          </div>
        </a>
      </li>
    </ul>
  </div>
  <!-- End Nav Classic -->

  <!-- Tab Content -->
  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade pt-9 show active" id="pills-one-example1" role="tabpanel" aria-labelledby="pills-one-example1-tab">
      <!-- Mockup Block -->

      <div class="row justify-content-lg-between">
        <div class="col-lg-4 mb-7 mb-lg-0">
          <!-- Title -->
          <small class="text-secondary text-uppercase font-weight-medium mb-2">The Hybrid Ecosystem</small>
          <h3 class="font-weight-medium">In the room, or online, it doesn't matter!</h3>
          <p>A cohesive environment for any hybrid event. In-the-room attendees can acess the same tools as the online attendees. Chat, polls, questions, handouts and more!</p>
          <p>With our intuitive <strong>mobile app</strong>, on-site users can scan into the event, and then automaically join the online discussion with their peers.</p>
          <!-- End Title -->
        </div>

        <div class="col-lg-7">
          <div class="row">
            <div class="col-sm-6 mb-3">
              <!-- Icon Blocks -->
              <figure id="icon26" class="ie-height-56 w-100 max-width-8 mb-3">
                <i class="fad fa-qrcode home-icon-theme"></i>
              </figure>
              <h4 class="h5">Automatic CEUs</h4>
              <p class="font-size-1">Physical attendeees can scan a QR code in the room. Online attendees click "I'm here" and all their CEUs are combined in a single place.</p>
              <!-- End Icon Blocks -->
            </div>

            <div class="col-sm-6 mb-3">
              <!-- Icon Blocks -->
              <figure id="icon27" class="ie-height-56 w-100 max-width-8 mb-3">
                <i class="fad fa-tools home-icon-theme"></i>
              </figure>
              <h4 class="h5">Presenter Tools</h4>
              <p class="font-size-1">Live & virtual speakers can access ALL of the online tools. Chat, polls, handouts, questions...even standing at the podium!</p>
              <!-- End Icon Blocks -->
            </div>

            <div class="w-100"></div>

            <div class="col-sm-6 mb-3 mb-sm-0">
              <!-- Icon Blocks -->
              <figure id="icon25" class="ie-height-56 w-100 max-width-8 mb-3">
                <i class="fad fa-closed-captioning home-icon-theme"></i>
              </figure>
              <h4 class="h5">Live Captioning</h4>
              <p class="font-size-1">Reduce your interpeter costs by embedding, in real-time, live captions into the stream.</p>
              <!-- End Icon Blocks -->
            </div>

            <div class="col-sm-6">
              <!-- Icon Blocks -->
              <figure id="icon28" class="ie-height-56 w-100 max-width-8 mb-3">
                <i class="fad fa-tv-alt home-icon-theme"></i>
              </figure>
              <h4 class="h5">Instant On-Demand Access</h4>
              <p class="font-size-1">Immediately following a live event, the talk will be on-demand for anyone who missed it or wants to watch again.</p>
              <!-- End Icon Blocks -->
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-lg-between align-items-lg-center mt-5" style="background-color: ghostwhite">
        <div class="col-lg-7 mb-9 mb-lg-0">
          <!-- Info -->
          <div class="mb-5">
            <h3 class="h4 font-weight-semi-bold">BehaviorLive StreamBox</h3>
            <p>A proprietary, remote controlled, streaming system shipped directly to the venue and operated by our Staff</p>
          </div>

          <ul class="list-unstyled text-secondary font-size-1">
            <li class="media my-3">
              <span class="fas fa-check text-primary mt-1 mr-3"></span>
              <span class="media-body"> Keeps travel costs down </span>
            </li>
            <li class="media my-3">
              <span class="fas fa-check text-primary mt-1 mr-3"></span>
              <span class="media-body"> Robotic Pan, Tilt, Zoom camera, remote controllable </span>
            </li>
            <li class="media my-3">
              <span class="fas fa-check text-primary mt-1 mr-3"></span>
              <span class="media-body"> Speaker & Slide Layout side-by-side </span>
            </li>
          </ul>
          <!-- End Info -->
        </div>

        <div class="col-lg-4 position-relative">
          <div class="pl-md-9">
            <img class="img-fluid rounded" style="max-width: 200px" src="../../assets/StreamBox.png"  />
          </div>

          <!-- End SVG Component -->
        </div>
      </div>

      <div class="row mt-9 mb-9">
        <div class="col-sm-6 col-md-4 mb-7">
          <!-- Icon Block -->
          <div class="media align-items-center mb-2">
            <figure id="iconExample15-29" class="ie-height-40 w-100 max-width-6 mr-3">
              <img class="js-svg-injector" src="../../assets/svg/icons/icon-29.svg" alt="SVG" data-parent="#iconExample15-29" />
            </figure>
            <div class="media-body">
              <h4 class="h6 mb-0">Flexible planning</h4>
            </div>
          </div>
          <p>Front Software's rich planning features enable your team to flexibly plan.</p>
          <!-- End Icon Block -->
        </div>

        <div class="col-sm-6 col-md-4 mb-7">
          <!-- Icon Block -->
          <div class="media align-items-center mb-2">
            <figure id="iconExample15-26" class="ie-height-40 w-100 max-width-6 mr-3">
              <img class="js-svg-injector" src="../../assets/svg/icons/icon-26.svg" alt="SVG" data-parent="#iconExample15-26" />
            </figure>
            <div class="media-body">
              <h4 class="h6 mb-0">Accurate estimations</h4>
            </div>
          </div>
          <p>Use story points, hours, t-shirt sizes, or your own estimation technique.</p>
          <!-- End Icon Block -->
        </div>

        <div class="col-sm-6 col-md-4 mb-7 mb-md-0">
          <!-- Icon Block -->
          <div class="media align-items-center mb-2">
            <figure id="iconExample15-31" class="ie-height-40 w-100 max-width-6 mr-3">
              <img class="js-svg-injector" src="../../assets/svg/icons/icon-31.svg" alt="SVG" data-parent="#iconExample15-31" />
            </figure>
            <div class="media-body">
              <h4 class="h6 mb-0">Value-driven prioritization</h4>
            </div>
          </div>
          <p>Order user stories, issues, and bugs in your product backlog with simple dragging.</p>
          <!-- End Icon Block -->
        </div>

        <div class="col-sm-6 col-md-4 mb-7 mb-md-0">
          <!-- Icon Block -->
          <div class="media align-items-center mb-2">
            <figure id="iconExample15-37" class="ie-height-40 w-100 max-width-6 mr-3">
              <img class="js-svg-injector" src="../../assets/svg/icons/icon-37.svg" alt="SVG" data-parent="#iconExample15-37" />
            </figure>
            <div class="media-body">
              <h4 class="h6 mb-0">Transparent execution</h4>
            </div>
          </div>
          <p>Front Software brings a new level of transparency to your team's work.</p>
          <!-- End Icon Block -->
        </div>

        <div class="col-sm-6 col-md-4 mb-7 mb-md-0">
          <!-- Icon Block -->
          <div class="media align-items-center mb-2">
            <figure id="iconExample15-5" class="ie-height-40 w-100 max-width-6 mr-3">
              <img class="js-svg-injector" src="../../assets/svg/icons/icon-5.svg" alt="SVG" data-parent="#iconExample15-5" />
            </figure>
            <div class="media-body">
              <h4 class="h6 mb-0">Actionable results</h4>
            </div>
          </div>
          <p>Extensive reporting functionality gives your team critical insight into their agile process.</p>
          <!-- End Icon Block -->
        </div>

        <div class="col-sm-6 col-md-4">
          <!-- Icon Block -->
          <div class="media align-items-center mb-2">
            <figure id="iconExample15-28" class="ie-height-40 w-100 max-width-6 mr-3">
              <img class="js-svg-injector" src="../../assets/svg/icons/icon-28.svg" alt="SVG" data-parent="#iconExample15-28" />
            </figure>
            <div class="media-body">
              <h4 class="h6 mb-0">Scalable evolution</h4>
            </div>
          </div>
          <p>Front Software is agile project management designed for any teams.</p>
          <!-- End Icon Block -->
        </div>
      </div>
      <!-- End Icon Blocks -->

      <div class="text-center">
        <!-- <div class="mb-3">
          <a class="btn btn-primary btn-wide transition-3d-hover mb-2 mb-sm-0 mx-1" href="#">Sign up and Start Building</a>
          <a class="btn btn-outline-primary btn-wide transition-3d-hover mb-2 mb-sm-0 mx-1" href="#">Let's Talk</a>
        </div> -->

        <!-- <p class="small">Start free trial. * No credit card required.</p> -->
      </div>

      <div class="d-none d-md-flex align-items-end iphone">
        <!-- SVG Phone Mockup -->
        <div class="u-devices-v1__phone">
          <div class="u-devices-v1__phone-svg">
            <figure class="ie-devices-v1-phone" [inlineSVG]="'../../assets/svg/components/iphone.svg'">
              <!-- <img class="js-svg-injector" src="../../assets/svg/components/iphone.svg"
                   data-img-paths='[
                     {"targetId": "#SVGiphoneImg1", "newPath": "../../assets/img/282x500/img6.jpg"}
                   ]'
                   data-parent="#SVGheroBGShapes"> -->
            </figure>
          </div>
        </div>
        </div>
      <!-- Pricing Section -->
      <div >
        <div >
          <h3>Pricing</h3>
        </div>

        <!-- Table -->
        <div class="table-responsive-lg">
          <table class="table table-striped table-borderless">
            <thead class="text-center">
              <tr>
                <th scope="col" class="w-40"></th>
                <th scope="col" class="w-20">
                  Virtual
                  <small class="d-block text-secondary">$15/registrant</small>
                </th>
                <th scope="col" class="w-20 border-left">
                  Hybrid

                  <small class="d-block text-secondary">$20/registrant</small>
                </th>
                <!-- <th scope="col" class="w-20">
                  Hybrid <span class="badge badge-warning font-weight-medium badge-pill ml-1">Pro</span>
                  <small class="d-block text-secondary">$25/registrant</small>
                </th> -->
              </tr>
            </thead>
            <tbody>
              <tr class="border-top border-bottom">
                <th scope="row" class="h6 bg-white pt-5 pb-3 px-4 mb-0">General</th>
                <td class="bg-white"></td>
                <td class="bg-white border-left"></td>
                <!-- <td class="bg-white"></td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Included Days</th>
                <td class="text-muted text-center p-3"><span class="text-success">2</span><br /></td>
                <td class="text-center text-muted border-left p-3"><span class="text-success">2</span><br /></td>
                <!-- <td class="text-muted text-center p-3"><span class="text-success">3</span><br /></td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Concurrent Events</th>
                <td class="text-muted text-center p-3"><span class="text-success">2</span><br /></td>
                <td class="text-center text-success border-left p-3">2</td>
                <!-- <td class="text-muted text-center p-3"><span class="text-success">3</span><br /></td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">
                  Additional Concurrent Events & Days
                  <br />
                  <span class="text-muted small">Priced by concurrent slots per day</span>
                </th>
                <td class="text-muted text-center p-3"><span class="text-success">$250</span></td>
                <td class="text-center text-success border-left p-3">$500</td>
                <!-- <td class="text-muted text-center p-3"><span class="text-success">3</span><br /></td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Instructor Support</th>
                <td class="text-center text-success p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Attendee Support</th>
                <td class="text-center text-success p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Website with registration page</th>
                <td class="text-center text-success p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
              </tr>
              <tr class="border-top border-bottom">
                <th scope="row" class="h6 bg-white pt-5 pb-3 px-4 mb-0">CEU Issuance</th>
                <td class="bg-white"></td>
                <td class="bg-white border-left"></td>
                <!-- <td class="bg-white"></td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Online Attendance Checks</th>
                <td class="text-center text-success p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">QR Code Scan In/Out</th>
                <td class="text-center text-secondary p-3">N/A</td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Combined CEU Certificate</th>
                <td class="text-center text-success p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <tr>
                <th scope="row" class="h6 bg-white pt-5 pb-3 px-4 mb-0">Streaming</th>
                <td class="bg-white"></td>
                <td class="bg-white border-left"></td>
                <!-- <td class="bg-white"></td> -->
              </tr>

              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">StreamBox</th>
                <td class="text-center text-secondary py-3 px-4">N/A</td>
                <td class="text-center text-success border-left py-3 px-4">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">On-Demand Access</th>
                <td class="text-success text-center text-secondary p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <tr>
                <th scope="row" class="h6 bg-white pt-5 pb-3 px-4 mb-0">Presenter tools</th>
                <td class="bg-white"></td>
                <td class="bg-white border-left"></td>
                <!-- <td class="bg-white"></td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Questions</th>
                <td class="text-success text-center text-secondary p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Polls</th>
                <td class="text-center text-success p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Chat</th>
                <td class="text-center text-success p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Materials & Handouts</th>
                <td class="text-center text-success p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <tr>
                <th scope="row" class="font-size-1 font-weight-normal py-3 px-4">Embedded Videos</th>
                <td class="text-center text-success p-3">
                  <small class="fas fa-check"></small>
                </td>
                <td class="text-center text-success border-left p-3">
                  <small class="fas fa-check"></small>
                </td>
                <!-- <td class="text-success text-center p-3">
                  <small class="fas fa-check"></small>
                </td> -->
              </tr>
              <!-- <tr>
                <th scope="row" class="bg-white p-3"></th>
                <td class="bg-white text-center p-3">
                  <button type="button" class="btn btn-xs btn-soft-indigo transition-3d-hover">Choose Plan</button>
                </td>
                <td class="bg-white text-center border-left p-3">
                  <button type="button" class="btn btn-xs btn-soft-indigo transition-3d-hover">Choose Plan</button>
                </td>
                <td class="bg-white text-center p-3">
                  <button type="button" class="btn btn-xs btn-primary transition-3d-hover">Contact Us</button>
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <!-- End Table -->
      </div>

      <!-- End Mockup Block -->
    </div>
    <div class="tab-pane fade pt-9" id="pills-two-example1" role="tabpanel" aria-labelledby="pills-two-example1-tab">
      <!-- Mockup Block -->
      <div class="row justify-content-lg-between align-items-lg-center">
        <div class="col-lg-4 mb-9 mb-lg-0">
          <!-- Info -->
          <div class="mb-5">
            <h3 class="h4 font-weight-semi-bold">Focus on achieving measurable outcomes</h3>
            <p>Now that we've aligned the details, it's time to get things mapped out and organized.</p>
          </div>

          <ul class="list-unstyled text-secondary font-size-1">
            <li class="media my-3">
              <span class="fas fa-check text-primary mt-1 mr-3"></span>
              <span class="media-body"> We aim high at being focused on building relationships </span>
            </li>
            <li class="media my-3">
              <span class="fas fa-check text-primary mt-1 mr-3"></span>
              <span class="media-body"> Working together on the daily requires each individual </span>
            </li>
            <li class="media my-3">
              <span class="fas fa-check text-primary mt-1 mr-3"></span>
              <span class="media-body"> It's important to stay detail oriented with every project we tackle </span>
            </li>
          </ul>
          <!-- End Info -->
        </div>

        <div class="col-lg-7 position-relative">
          <div class="pl-md-9">
            <img class="img-fluid shadow-sm rounded" src="../../assets/img/700x525/img2.jpg"  />
          </div>

          <!-- SVG Component -->

          <!-- End SVG Component -->
        </div>
      </div>
      <!-- End Mockup Block -->
    </div>
    <div class="tab-pane fade pt-9" id="pills-three-example1" role="tabpanel" aria-labelledby="pills-three-example1-tab">
      <!-- Mockup Block -->
      <div class="row justify-content-lg-between align-items-lg-center">
        <div class="col-lg-4 mb-9 mb-lg-0">
          <!-- Info -->
          <div class="mb-5">
            <h3 class="h4 font-weight-semi-bold">Review performance and improve strategy</h3>
            <p>The time has come to bring those ideas and plans to life.</p>
          </div>

          <ul class="list-unstyled text-secondary font-size-1">
            <li class="media my-3">
              <span class="fas fa-check text-primary mt-1 mr-3"></span>
              <span class="media-body"> Whether through commerce or just an experience </span>
            </li>
            <li class="media my-3">
              <span class="fas fa-check text-primary mt-1 mr-3"></span>
              <span class="media-body"> Now that your brand is all dressed up </span>
            </li>
            <li class="media my-3">
              <span class="fas fa-check text-primary mt-1 mr-3"></span>
              <span class="media-body"> This part is really crucial in keeping the project </span>
            </li>
          </ul>
          <!-- End Info -->
        </div>

        <div class="col-lg-7 position-relative">
          <div class="pl-md-9">
            <img class="img-fluid shadow-sm rounded" src="../../assets/img/700x525/img3.jpg"  />
          </div>

          <!-- SVG Component -->

          <!-- End SVG Component -->
        </div>
      </div>
      <!-- End Mockup Block -->
    </div>
  </div>
  <!-- End Tab Content -->
</div>
<!-- End Features Section -->

<!-- End Pricing Section -->
