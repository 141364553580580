<div *ngIf="initialized" class="space-1 container-fluid height-100vh">
  <div class="row no-gutters no-candidates" style="height: 300px; border: 0">
    <div class="col-sm-12 my-auto">
      <h1><i class="fas fa-empty-set"></i></h1>
      <h4>Page Not Found</h4>
      <p>The page you are trying to access doesn't exist. Please try again.</p>
      <a [routerLink]="['../']" class="btn btn-instagram">Home</a>
    </div>
  </div>
</div>
