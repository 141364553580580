import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'bxl-hof-main-wrapper',
  templateUrl: 'hof-main-wrapper.component.html',
})
export class HofMainWrapperComponent implements OnInit {
  initialized = false;
  @ViewChild('fullscreenContainer') fullscreenContainer: ElementRef;
  isFullScreen: any;
  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
  }

  fullscreen() {
    let elem = this.fullscreenContainer.nativeElement;
    let doc = <any>document;
    if (!this.isFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }

      this.isFullScreen = true;
    } else {
      if (document.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        /* Firefox */
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        /* IE/Edge */
        doc.msExitFullscreen();
      }


          this.isFullScreen = false;

    }
  }
}
