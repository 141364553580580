import { Component, OnInit, Input } from '@angular/core';
import { CourseViewModel, CourseEventStatus, CourseViewModelSimple } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Enums } from 'src/app/_models/generatedEnums';

@Component({
  selector: 'bxl-course-item',
  styles: [':host { display: contents; }'],
  templateUrl: 'course-item.component.html'
})
export class CourseItemComponent implements OnInit {
  initialized = false;
  @Input() course: CourseViewModelSimple;
  @Input() displayPrice = true;
  @Input() isGrid = false;
  @Input() rootRoute: string = "/";

  CourseEventStatus = CourseEventStatus;
  CourseEventStatusEnum = Enums.CourseEventStatusEnum;
  Math = Math;
  constructor(public auth: AuthenticationService) {}

  ngOnInit(): void {
    this.initialized = true;
  }
}
