import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LiveEventViewModel, ActiveUserViewModel, UserAuthenticationViewModel, PagedActiveUserViewModel } from 'src/app/_models/generatedModels';
import { HubConnection } from '@microsoft/signalr';
import { EventService, LiveEventService } from 'src/app/_services/generatedServices';
import { EventRole } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { debounce, debounceTime, delay, distinctUntilChanged, map, throttleTime } from 'rxjs/internal/operators';
import { asyncScheduler, Observable, Subject } from 'rxjs';

@Component({
  selector: 'live-people',
  templateUrl: 'live-people.component.html',
})
export class LivePeopleComponent implements OnInit {
  initialized = false;
  searching = false;
  @Input() event: LiveEventViewModel;
  @Input() role: EventRole;
  @Input() connection: HubConnection;
  @Input() showInput: boolean;

  public EventRole = EventRole;
  @Output()
  closed = new EventEmitter();

  @Output()
  usersUpdated = new EventEmitter<PagedActiveUserViewModel>();

  currentPage = 0;
  activeUsers: PagedActiveUserViewModel;
  user: UserAuthenticationViewModel;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  throttleSubject = new Subject();
  loadError: boolean;

  constructor(private eventService: LiveEventService, public auth: AuthenticationService, private toaster: ToasterService) {
    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;


      this.getActiveUsers();
    });
  }

  ngOnInit(): void {

    this.user = this.auth.getUser();
    this.activeUsers = new PagedActiveUserViewModel();
    this.activeUsers.users = [];
    this.activeUsers.totalCount;
    this.activeUsers.count;
    this.activeUsers.randomUserIds = [];
    this.initSignalR();
    this.getActiveUsers();
  }

  get pageCount() {
    return new Array(this.activeUsers.totalPages);
  }

  goToPrevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.getActiveUsers();
    }
  }

  goToNextPage() {
    if (this.currentPage < this.activeUsers.totalPages) {
      this.currentPage++;
      this.getActiveUsers();
    }
  }

  getActiveUsers() {
    this.searching = true;
    this.loadError = false;
    this.initialized = false;



    this.eventService.getActiveUsers(this.event.id, this.currentPage, this.filter).subscribe(
      (activeUsers) => {
        this.activeUsers = activeUsers;
        if (!this.filter || this.filter.length === 0) {
          this.usersUpdated.emit(activeUsers);
        }
        this.currentPage = activeUsers.currentPage;
        this.searching = false;
        this.initialized = true;
        this.loadError = false;
      },
      (error) => {
        this.loadError = true;
        this.searching = false;
        this.initialized = true;

      }
    );
  }

  initSignalR() {
    this.throttleSubject
      .pipe(
        throttleTime(5000, asyncScheduler, { leading: false, trailing: true }),
        map((searchText) => {
          this.getActiveUsers();
        })
      )
      .subscribe();

    this.connection.on('userDisconnected', (eventId: number, user: ActiveUserViewModel) => {
      if (eventId !== this.event.id) {
        return;
      }
      this.throttleSubject.next('');
    });

    this.connection.on('userConnected', (eventId: number, user: ActiveUserViewModel) => {
      if (eventId !== this.event.id) {
        return;
      }
      this.throttleSubject.next('');
    });
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  inviteUserOnScreen(user: ActiveUserViewModel) {
    this.toaster.confirmDialog('Are you sure you want to invite ' + user.firstName + ' on screen?', 'Invite ' + user.firstName, 'Yes!', 'Cancel').subscribe((result) => {
      if (result) {
        this.connection.invoke('inviteOnScreen', this.user.id, user.id, this.event.id).then(() => {
          this.getActiveUsers();
        });
      }
    });
  }

  removeUser(user: ActiveUserViewModel) {
    this.toaster.confirmDialog('Are you sure you want to remove ' + user.firstName + ' from the screen?', 'Remove ' + user.firstName, 'Remove Them!', 'Cancel').subscribe((result) => {
      if (result) {
        this.connection.invoke('removeOnScreen', this.user.id, user.id, this.event.id).then(() => {
          this.getActiveUsers();
        });
      }
    });
  }

  mergeUser(user: ActiveUserViewModel) {
    let index = this.activeUsers.users.findIndex((x) => x.id === user.id);
    if (index === -1) {
      this.activeUsers.users.push(user);
    } else {
      this.activeUsers[index] = user;
    }
  }

  close() {
    this.closed.emit();
  }
}
