import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-hof-timeline',
  templateUrl: 'hof-timeline.component.html',
})
export class HofTimelineComponent implements OnInit {
  initialized = false;
  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
  }
}
