import { Component, OnInit } from '@angular/core';
declare var zE: any;

@Component({
  selector: 'bxl-hof-about',
  templateUrl: 'hof-about.component.html',
})
export class HofAboutComponent implements OnInit {
  initialized = false;
  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
    zE('webWidget', 'hide');
    zE('webWidget:on', 'close', () => {
      zE('webWidget', 'hide');
    });

    zE('webWidget:on', 'chat:unreadMessages', () => {
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    });
  }
}
