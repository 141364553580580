
import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/_services/generatedServices';
import { EventViewModel, EventSearchParameters, ConferenceViewModel, ConferenceViewModelSimple } from 'src/app/_models/generatedModels';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';

@Component({
  selector: 'bxl-conference-listing',
  templateUrl: 'conference-listing.component.html',
})
export class ConferenceListingComponent implements OnInit {
  events: EventViewModel[];
  initialized: boolean;
  conferences: ConferenceViewModelSimple[];

  constructor(private eventService: EventService) {}

  ngOnInit(): void {
    this.eventService.getAllConferences().subscribe((conf) => {
      this.conferences = conf;
      this.initialized = true;
    });
  }
}
