import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsProvider } from '../_services/settingsProvider.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private settings: SettingsProvider) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('channel.media.azure.net') >= 0) {
      return next.handle(request);
    }

    // if (request.url.indexOf('htmltopdf') >= 0) {
    //   return next.handle(request);
    // }

    if (request.url.startsWith('https://behaviorliveassets.blob.core.windows.net')) {
      return next.handle(request);
    }
    if (request.url.startsWith('https://api.fontawesome.com')) {
      return next.handle(request);
    }

    if (request.url.startsWith('https://translation.googleapis.com')) {
      return next.handle(request);
    }

    if (request.url.startsWith('https://bxlimages.blob.core.windows.net')) {
      return next.handle(request);
    }

    if (request.url.indexOf('/api/settings/client') > -1) {
      return next.handle(request);
    }

    // add authorization header with jwt token if available
    let currentUser = JSON.parse(localStorage.getItem(this.settings.authTokenName));
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
    }

    let referralToken = localStorage.getItem('referralToken');
    if (referralToken) {
      request = request.clone({
        setHeaders: {
          Referral_Token: `${referralToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
