import { Component, OnInit, HostListener, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { EventService } from '../_services/generatedServices';
import { KEY_CODE } from '../events/live/presenter-view/presenter-view.component';
import { OpentokService } from '../_services/opentok.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
const initLayoutContainer = require('opentok-layout-js');
declare var zE: any;

@Component({
  selector: 'bxl-slide-control',
  templateUrl: 'slide-control.component.html',
})
export class SlideControlComponent implements OnInit {
  initialized = false;
  publisher: OT.Publisher;
  screenPublisher: OT.Publisher;
  public publishing: boolean;
  public screenPublishing: boolean;
  public isFullScreen: boolean;
  public layout: any;
  session: OT.Session;

  @ViewChild('webRTCLayoutContainer') webRTCLayoutContainer: ElementRef;

  constructor(private eventData: EventService, private ref: ChangeDetectorRef, private opentokService: OpentokService, private renderer: Renderer2) {}

  ngOnInit(): void {
    zE('webWidget', 'hide');

    this.initialized = true;
  }

  forward() {
    console.log('here');
    this.eventData.slideControl('Right').subscribe();
  }

  backward() {
    this.eventData.slideControl('Left').subscribe();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);

    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.forward();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.backward();
    }
  }

  InitOpenTok() {
    this.ref.detectChanges();
    this.layout = initLayoutContainer(this.webRTCLayoutContainer.nativeElement).layout;
    let layout = this.layout;

    console.log('initializing opentok');

    this.opentokService
      .initSessionForSlideControl()
      .then((session: OT.Session) => {
        this.session = session;
        this.session.on('streamCreated', (event) => {
          console.log(event.stream.name);
          console.log(event.stream.connection.data);

          let subscription = this.session.subscribe(event.stream, 'webRTCLayoutContainer', {
            insertMode: 'append',
            style: { nameDisplayMode: 'off' },
          });

          layout();
          this.ref.detectChanges();
          layout();
        }, this);
        this.session.on('streamDestroyed', (event) => {
          session.getSubscribersForStream(event.stream).forEach((subscriber) => {
            subscriber.element.classList.remove('ot-layout');

            setTimeout(() => {
              layout();
            }, 200);
          });
          layout();
          this.ref.detectChanges();
          layout();
        });
      })
      .then(() => this.opentokService.connect())
      .catch((err) => {
        console.error(err);
        alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
      });
  }

  shareScreen() {
    const OT = this.opentokService.getOT();
    const el = this.renderer.createElement('div');
    this.screenPublisher = OT.initPublisher(el, { videoSource: 'screen', publishAudio: false, width: '100%', insertDefaultUI: true });

    let layout = this.layout;

    el.classList.add('OT_big');

    el.addEventListener('dblclick', function () {
      if (el.classList.contains('OT_big')) {
        el.classList.remove('OT_big');
      } else {
        el.classList.add('OT_big');
      }
      layout();
    });

    this.renderer.appendChild(this.webRTCLayoutContainer.nativeElement, el);
    layout();

    if (this.session) {
      if (this.session['isConnected']()) {
        this.publishScreen();
      }
      this.session.on('sessionConnected', () => this.publishScreen());
    }
  }

  publishScreen() {
    this.session.publish(this.screenPublisher, (err) => {
      if (err) {
        alert(err.message);
        this.layout();
      } else {
        this.screenPublishing = true;
        this.layout();
        this.ref.detectChanges();
        this.layout();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layout();
    this.ref.detectChanges();
    this.layout();
  }

  fullscreen() {
    let elem = this.webRTCLayoutContainer.nativeElement;
    let doc = <any>document;
    if (!this.isFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      setTimeout(() => {
        this.layout();
        this.isFullScreen = true;
      }, 500);
    } else {
      if (document.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        /* Firefox */
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        /* IE/Edge */
        doc.msExitFullscreen();
      }

      of(null)
        .pipe(delay(500))
        .subscribe((result) => {
          this.isFullScreen = false;
          this.layout();
        });
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  exit(ev) {
    if (this.isFullScreen) {
      of(null)
        .pipe(delay(3000))
        .subscribe((result) => {
          this.isFullScreen = false;
          console.log('exiting');
          this.layout();
        });
    }
  }
}
