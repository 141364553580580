<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container text-center space-2">
  <div class="row">
    <div class="col-2"></div>
    <div class="col">
      <h1>Browser Error</h1>
      <h3>{{name}}</h3>
      <h3>Upgrade browser for full {{ settings.brand.brandName }} experience</h3>
      <p>It looks like you may be using a web browser version that we don't support. Make sure you're using the most recent version of your browser, or try using one of these supported browsers to get the full {{ settings.brand.brandName }} experience.</p>
      <img style="width: 266px" src="../../../../assets/browsers.png" />
    </div>
    <div class="col-2"></div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <a [routerLink]="['/']" class="btn btn-primary">Go Home</a>
    </div>
  </div>
</div>
