import { Component } from "@angular/core";

@Component({
    selector: "page-load-header",
    template: `
    <div class="col-md-12 text-center">
        <span class="k-icon k-i-loading text-primary" style="font-size:60px;"></span>
    </div>
`
})

export class PageLoadHeaderComponent {
}
