import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PollViewModel, CreatePollViewModel, UpdatePollViewModel, PollOptionViewModel } from 'src/app/_models/generatedModels';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { PollService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'poll-editor',
  templateUrl: 'edit-poll.component.html',
})
export class EditPollComponent implements OnInit {
  initialized = false;
  submitComplete: Promise<{}> | undefined;
  public formGroup: FormGroup;
  public visible: boolean;

  @Output() created = new EventEmitter<PollViewModel>();
  @Output() cancelled = new EventEmitter<PollViewModel>();

  @Input() poll: PollViewModel;
  @Input() eventId: number;

  constructor(private toaster: ToasterService, private pollService: PollService, private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.poll == null) {
      this.poll = new PollViewModel();
      this.poll.options = [];
      this.poll.options[0] = new PollOptionViewModel();
      this.poll.options[1] = new PollOptionViewModel();
    }

    this.setupForm();
  }

  setupForm(): any {
    this.formGroup = this.fb.group({
      questionText: [this.poll.questionText, [Validators.required]],
      options: this.fb.array([]),
    });

    this.loadOptions();
  }

  loadOptions() {
    this.poll.options.forEach((option) => {
      let options = this.formGroup.get('options') as FormArray;
      options.push(
        this.fb.group({
          id: [option.id],
          optionText: [option.optionText, Validators.required],
        })
      );
    });

    this.initialized = true;
  }

  removeOption(index: number): void {
    let options = this.formGroup.get('options') as FormArray;
    options.removeAt(index);
  }

  addOption(): void {
    let options = this.formGroup.get('options') as FormArray;
    options.push(this.createOption());
  }

  createOption(): FormGroup {
    return this.fb.group({
      optionText: ['', Validators.required],
    });
  }

  doneClicked(): void {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toaster.error('Please fill out all required fields', 'Error');
      console.log(this.formGroup);
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      if (this.poll.id == null) {
        this.save(resetButton);
      } else {
        this.update(resetButton);
      }
    });
  }

  cancel() {
    this.cancelled.emit();
  }

  save(resetButton: () => any) {
    const formData: CreatePollViewModel = Object.assign({}, this.poll, this.formGroup.value);
    formData.visible = this.visible;
    this.pollService.createPoll(this.eventId, formData).subscribe(
      (poll) => {
        this.created.emit(poll);
        this.toaster.success('Poll Created', 'Success');
        resetButton();
      },
      (error) => resetButton()
    );
  }

  update(resetButton: () => any) {
    const formData: UpdatePollViewModel = Object.assign({}, this.poll, this.formGroup.value);
    this.pollService.updatePoll(this.eventId, this.poll.id, formData).subscribe(
      (result) => {
        this.toaster.success('Poll Updated', 'Success');
        resetButton();
      },
      (error) => resetButton()
    );
  }

  delete() {
    this.toaster.confirmDialog('Are you sure you want to delete this question?', 'Delete Question').subscribe((result) => {
      if (result) {
        this.pollService.deletePoll(this.eventId, this.poll.id).subscribe((poll) => {});
      }
    });
  }
}
