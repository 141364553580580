<div class="card-body pb-5">
  <!-- Details -->
  <div class="row">
    <div class="col-md-4 d-md-flex align-items-md-start flex-md-column mb-5 mb-md-0">
      <!-- Gallery -->
      <img class="img-fluid w-100" src="https://bxlimages.blob.core.windows.net/splash/{{ event.marketingSplashPath }}" [fallback]="'/fetch-cache-image?filename=' + event.marketingSplashPath" />
    </div>

    <div class="col-md-8">
      <!-- Header -->
      <div class="media mb-2">
        <div class="media-body">
          <div *ngIf="event.userHasPurchased && event.conferenceId" class="float-right">
            <i [ngClass]="{'far': !isFavorited, 'fas': isFavorited }" class="fa-heart mr-1 mt-1" style="font-size: 20px; cursor: pointer;" (click)="onFavoriteChange()"></i>
          </div>
          <h1 class="h5 mb-1">
            <a [routerLink]="[rootRoute, 'events', event.slug]">{{ event.title }}</a>
          </h1>
          <ul class="list-inline font-size-1 text-muted">
            <li *ngIf="event.room && event.room.roomName" class="mb-1">
              <strong>{{ event.room.roomName }} </strong>
            </li>
            <li *ngIf="event.organizationName && (!event.conferenceName || event.conferenceBlowOutEvents)" class="mb-1">
              <strong>{{ 'presented-by' | translate }}: </strong> <a [routerLink]="['/', 'organizations', event.organizationSlug]">{{ event.organizationName }}</a>
            </li>
            <li class="list-inline-item">
              <a class="font-size-1" [routerLink]="[rootRoute, 'instructors', event.instructor.slug]">
                <img  class="img-fluid rounded-circle" style="max-width: 30px" [fallback]="'fetch-avatar?userId=' + event.instructor.id" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ event.instructor.id }}-avatar.png" />
                {{ event.instructor.firstName + ' ' + event.instructor.lastName }} <span *ngIf="event.instructor.pronouns"> ({{ event.instructor.pronouns }})</span>
              </a>
              <ng-container *ngFor="let instructor of event.coInstructors">
                <a *ngIf="!instructor.hidden" class="font-size-1 ml-2" [routerLink]="[rootRoute, 'instructors', instructor.slug]">
                  <img  class="img-fluid rounded-circle" style="max-width: 30px" [fallback]="'fetch-avatar?userId=' + instructor.id" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ instructor.id }}-avatar.png" />
                  {{ instructor.firstName + ' ' + instructor.lastName }} <span *ngIf="instructor.pronouns"> ({{ instructor.pronouns }})</span>
                </a>
              </ng-container>
            </li>
          </ul>
          <!-- Icon Blocks -->
          <bxl-ceu-display [CEUs]="event.ceus"></bxl-ceu-display>
          <span *ngIf="event.tags && event.tags.length > 0" class="d-block small mt-0 mb-3"
          ><span class="paper-tag" *ngFor="let tag of event.tags">{{ tag }}</span></span
          >
          <!-- End Icon Blocks -->
        </div>

        <div class="d-flex ml-auto"></div>
      </div>
      <!-- End Header -->

      <div class="mb-5">
        <p>{{ event.description | stripHtml | truncate: 600 }}</p>
      </div>

      <div class="d-md-flex align-items-md-center">
        <!-- Location -->
        <div class="u-ver-divider u-ver-divider--none-md pr-4 mb-3 mb-md-0 mr-4">
          <h2 class="small text-secondary mb-0">{{ 'date' | translate }}</h2>
          <small class="fas fa-calendar-alt text-secondary align-middle mr-1"></small>
          <span class="align-middle">{{ event.startTime | date }}</span>
        </div>
        <div class="u-ver-divider u-ver-divider--none-md pr-4 mb-3 mb-md-0 mr-4">
          <h2 class="small text-secondary mb-0">{{ 'time' | translate }}</h2>
          <small class="fas fa-clock text-secondary align-middle mr-1"></small>
          <span class="align-middle"
            >{{ event.startTime | date: 'shortTime' }} - {{ event.endTime | date: 'shortTime' }} <strong>{{ timezone }}</strong></span
          >
        </div>
        <!-- End Location -->

        <!-- Posted -->
        <div *ngIf="!event.userHasPurchased && !event.packageOnly" class="mb-3 mb-md-0">
          <h4 class="small text-secondary mb-0">{{ 'price' | translate }}</h4>
          <span class="align-middle">{{ event.price | currency: event.currencyCode:'symbol' }}</span>
        </div>

        <ng-container *ngIf="!event.userHasPurchased && !event.packageOnly">
          <a class="btn btn-sm btn-soft-alternate transition-3d-hover ml-auto" [routerLink]="[rootRoute, 'events', event.slug]">
            {{ event.price | currency: event.currencyCode:'symbol' }}
          </a>
        </ng-container>

        <ng-container *ngIf="event.userHasPurchased">
          <button type="button" class="btn btn-sm btn-soft-success transition-3d-hover ml-auto" [routerLink]="[rootRoute, 'events', event.slug]">
            <i class="fas fa-check"></i>
            {{ 'registered' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- End Details -->
</div>
