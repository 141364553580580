<page-load-header *ngIf="!initialized"></page-load-header>
<main *ngIf="initialized" id="content" role="main">
  <!-- Hero Section -->
  <div class="d-lg-flex">
    <div class="container d-lg-flex align-items-lg-center space-top-0 space-bottom-2 space-lg-0">
      <div class="w-100 mx-auto space-5">
        <div class="w-md-80 w-lg-50 text-center mx-md-auto">
          <!-- Title -->
          <div class="mb-5">
            <h1 class="h2 font-weight-normal">Your email has been Verified.</h1>
            <p>Thank you!</p>
          </div>
          <!-- End Title -->

          <a class="btn btn-primary transition-3d-hover" [routerLink]="['/']">Home</a>
        </div>
      </div>
    </div>
  </div>
  <!-- End Hero Section -->
</main>
