import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewChecked, OnDestroy } from '@angular/core';
import { EventViewModel, LiveEventViewModel, UserAuthenticationViewModel, QuestionHistoryViewModel, ActiveUserViewModel, UserViewModel } from 'src/app/_models/generatedModels';
import { ChatViewModel, EventRole } from 'src/app/_models/models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { HubConnection } from '@microsoft/signalr';
import { of, interval, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { EventService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { LiveEventDashboardComponent } from '../live-event-dashboard/live-event-dashboard.component';

@Component({
  selector: 'live-questions',
  templateUrl: 'live-questions.component.html',
})
export class LiveQuestionsComponent implements OnInit, OnDestroy {
  initialized = false;
  public question: string;
  //public questions: ChatViewModel[] = new Array();
  public questions: QuestionHistoryViewModel[] = new Array();
  public user: UserAuthenticationViewModel;
  private unreadCount = 0;
  public answered = false;
  public preTest = false;
  public techSupport = false;

  @Input() parent: LiveEventDashboardComponent;

  @Input() event: LiveEventViewModel;
  @Input() showInput: boolean;
  @Input() connection: HubConnection;
  @Input() role: EventRole;
  public EventRole = EventRole;

  @ViewChild('questionContainer') private questionContainer: ElementRef;
  @Output()
  closed = new EventEmitter();

  @Output()
  unreadChanged = new EventEmitter<number>();
  intervalSubscription: Subscription;
  int: any;
  loadError: boolean;
  loading: boolean;

  constructor(private auth: AuthenticationService, private eventService: EventService, private toaster: ToasterService) {}

  ngOnInit(): void {
    this.user = this.auth.getUser();

    this.intervalSubscription = interval(1000).subscribe((val) => this.checkUnread());

    this.reload();

    this.connection.on('postQuestion', (model: QuestionHistoryViewModel, eventId: number) => {
      if (eventId === this.event.id) {
        this.questions.push(model);

        this.sortQuestions();

        if (this.questionContainer.nativeElement.offsetHeight === 0) {
          this.unreadCount++;
          this.unreadChanged.emit(this.unreadCount);
        }
      }
    });

    this.connection.on('voteReceived', (model: QuestionHistoryViewModel, eventId: number) => {
      if (eventId === this.event.id) {
        let index = this.questions.findIndex((x) => x.id === model.id);
        this.questions[index] = model;
        this.questions[index].currentUserDidVote = this.questions[index].questionVotes.findIndex((x) => x.userId === this.user.id) > -1;
        this.sortQuestions();
      }
    });

    this.connection.on('questionAnswered', (model: QuestionHistoryViewModel, eventId: number) => {
      if (eventId === this.event.id) {
        let index = this.questions.findIndex((x) => x.id === model.id);
        this.questions[index] = model;
        this.questions[index].currentUserDidVote = this.questions[index].questionVotes.findIndex((x) => x.userId === this.user.id) > -1;
        this.sortQuestions();
      }
    });

    this.connection.on('questionReopened', (model: QuestionHistoryViewModel, eventId: number) => {
      if (eventId === this.event.id) {
        let index = this.questions.findIndex((x) => x.id === model.id);
        this.questions[index] = model;
        this.questions[index].currentUserDidVote = this.questions[index].questionVotes.findIndex((x) => x.userId === this.user.id) > -1;
        this.sortQuestions();
      }
    });
  }

  toggleCompatibility() {
    this.parent.toggleCompatabilityMode();
  }

  refresh() {
    this.parent.reloadVideo();
  }

  sortQuestions() {
    this.questions = this.questions.sort((n2, n1) => {
      if (n1.questionVotes.length > n2.questionVotes.length) {
        return 1;
      }

      if (n1.questionVotes.length < n2.questionVotes.length) {
        return -1;
      }

      return 0;
    });
  }

  reload() {
    this.loading = true;
    this.eventService.getQuestionHistory(this.event.id).subscribe(
      (result) => {
        this.questions = result;

        this.questions.forEach((q) => {
          q.currentUserDidVote = q.questionVotes.findIndex((x) => x.userId === this.user.id) > -1;
        });

        this.initialized = true;
        this.loadError = false;
        this.loading = false;
      },
      (error) => {
        this.loadError = true;
        this.loading = false;
      }
    );
    this.preTest = false;
    this.techSupport = false;
  }

  vote(questionId: number) {
    this.connection.send('voteOnQuestion', questionId, this.user.id, this.event.id);
  }

  showTechSupport() {
    this.closed.emit();
    this.parent.showTechSupport = true;
  }

  markAnswered(questionId: number) {
    this.connection.send('markQuestionAnswered', questionId, this.event.id);
  }

  public select(message: QuestionHistoryViewModel) {
   // this.eventService.
    this.questions.forEach((m) => (m.selected = false));
    message.selected = true;
    this.connection.send('MarkQuestionSelected', message.id, this.event.id);

  }

  reopenQuestion(questionId: number) {
    this.connection.send('reopenQuestion', questionId, this.event.id);
  }

  checkUnread(): void {
    if (this.questionContainer && this.questionContainer.nativeElement && this.questionContainer.nativeElement.offsetHeight > 0) {
      this.unreadChanged.emit(0);
      this.unreadCount = 0;
    }
  }

  close() {
    this.preTest = false;
    this.techSupport = false;
    this.closed.emit();
  }

  sendQuestion() {
    this.toaster.confirmDialog("This tool is for <strong>CONTENT</strong> questions only. If you have a technical issue including CEU issues, please use the 'Help' tool.<br><strong>Is this a content related question?</strong>", 'Content Questions Only', 'Yes, this is for the speaker', 'No, its something else').subscribe((result) => {
      if (result) {
        this.preTest = false;
        this.connection.invoke('postQuestion', this.user.firstName + ' ' + this.user.lastName, this.question, this.user.id, this.event.id);
        this.question = null;
      }
    });
  }

  scrollToBottom(): void {
    of(null)
      .pipe(delay(100))
      .subscribe((result) => {
        try {
          this.questionContainer.nativeElement.scrollTop = this.questionContainer.nativeElement.scrollHeight;
        } catch (err) {}
      });
  }

  inviteUserOnScreen(user: UserViewModel) {
    this.toaster.confirmDialog('Are you sure you want to invite ' + user.firstName + ' on screen?', 'Invite ' + user.firstName, 'Yes!', 'Cancel').subscribe((result) => {
      if (result) {
        this.connection.invoke('inviteOnScreen', this.user.id, user.id, this.event.id).then(() => {});
      }
    });
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }



}
