import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../_services/generatedServices';
import { ResetPasswordViewModel } from '../_models/generatedModels';
import { ToasterService } from '../_services/toaster.service';
import { SettingsProvider } from '../_services/settingsProvider.service';

@Component({
  selector: 'complete-instructor-invite',
  templateUrl: 'complete-instructor-invite.component.html',
})
export class CompleteInstructorInviteComponent implements OnInit {
  errorMessage: string;
  submitComplete: Promise<{}> | undefined;
  constructor(private router: Router, public settings: SettingsProvider, public fb: FormBuilder, private user: UserService, private route: ActivatedRoute, public auth: AuthenticationService, private toaster: ToasterService) {}
  passwordForm: any;
  ngOnInit(): void {
    this.passwordForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: [''],
      },
      { validator: this.checkPasswords }
    );
  }

  save(): void {
    if (!this.passwordForm.valid) {
      this.passwordForm.markAllControlsDirty();
      return;
    }

    let model = new ResetPasswordViewModel();
    model.newPassword = this.passwordForm.controls.password.value;
    model.resetToken = this.route.snapshot.queryParamMap.get('token');
    model.userId = parseInt(this.route.snapshot.queryParamMap.get('id'));

    this.submitComplete = new Promise((resetButton: any, reject) => {
      this.user.resetPassword(model).subscribe(
        (result) => {
          this.toaster.success('Successfully Created Account', 'Success');
          this.auth.setToken(result);
          resetButton();
          this.router.navigate(['/account/profile']);
        },
        (error) => {
          resetButton();
          this.errorMessage = error.error[0];
        }
      );
    });
  }

  cancel() {
    this.router.navigate(['/']);
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
  get f() {
    return this.passwordForm;
  }
}
