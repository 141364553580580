import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bxl-products-services',
  templateUrl: 'products-services.component.html',
})
export class ProductsServicesComponent implements OnInit {
  initialized = false;
  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
  }
}
