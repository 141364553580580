import { Component, OnInit } from '@angular/core';
declare var zE: any;


@Component({
  selector: 'bxl-zendesk-mobile',
  templateUrl: 'zendesk-mobile.component.html',
})
export class ZendeskMobileComponent implements OnInit {
  initialized = false;
  constructor() { }

  ngOnInit(): void {
    this.loadZendesk();
    this.initialized = true;
  }

  openSupportChat() {

    var settings = {
       webWidget: {
         helpCenter: {
           originalArticleButton: false,
           suppress:true,
         },
         chat: {
           suppress: false
         },
         launcher: {
           label: {
             'en-US': 'Make a request',
             '*': 'Get help'
           }
         }
       }
     };
     zE('webWidget', 'updateSettings',settings);



     zE('webWidget', 'show');
     zE('webWidget', 'open');


   }

  loadZendesk() {
    zE('webWidget', 'hide');
    zE('webWidget:on', 'close', () => {
      zE('webWidget', 'hide');
    });

    zE('webWidget:on', 'chat:unreadMessages', () => {
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    });
  }
}
