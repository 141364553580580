import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { OrganizationService } from '../_services/generatedServices';

@Injectable()
export class CompanyMemberGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthenticationService, private org: OrganizationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let orgId = route.paramMap.get('organizationId');

    return new Promise<boolean>(resolve => {
      this.org.getOrganization(orgId).subscribe(result => {
        if (result.public) {
          resolve(true);
          return;
        }

        if (this.auth.user) {
          if (this.auth.userBelongsToOrganization(orgId, result.public)) {
            resolve(true);
          }  else {
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
            resolve(false);
          }
        } else {
          this.auth.fetchUserProfile().subscribe(
            user => {
              if (this.auth.userBelongsToOrganization(orgId, result.public)) {
                resolve(true);
              } else {
                this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
                resolve(false);
              }
            },
            err => {
              this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
              resolve(false);
            }
          );
        }
      });
    });
  }
}
