import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { of } from 'rxjs';
import { routeTransitionAnimations } from '../route-transition-animations-ts.component';
import { HoFTimeoutService } from '../_services/hof-timeout.service';
declare var zE: any;

@Component({
  selector: 'bxl-wiba-hof',
  templateUrl: 'wiba-hof.component.html',
  animations: [routeTransitionAnimations]
})
export class WibaHofComponent implements OnInit {
  initialized = false;
  isFullScreen: boolean;
  @ViewChild('fullscreenContainer') fullscreenContainer: ElementRef;

  constructor(private timeout: HoFTimeoutService, private router: Router) {}

  ngOnInit(): void {
    this.initialized = true;
    this.timeout.resetTimer();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      this.timeout.resetTimer();
    });

    this.loadZendesk();
  }

  prepareRoute(outlet: RouterOutlet) {

    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animationState'];
   }

  fullscreen() {
    let elem = this.fullscreenContainer.nativeElement;
    let doc = <any>document;
    if (!this.isFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }

      this.isFullScreen = true;
    } else {
      if (document.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        /* Firefox */
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        /* IE/Edge */
        doc.msExitFullscreen();
      }


          this.isFullScreen = false;

    }
  }

  loadZendesk() {
    zE('webWidget', 'hide');
    zE('webWidget:on', 'close', () => {
      zE('webWidget', 'hide');
    });

    zE('webWidget:on', 'chat:unreadMessages', () => {
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    });
  }
}
