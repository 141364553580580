<div class="hof-page-wrapper hof-stars2">
  <div class="hof-title my-auto mx-auto text-white text-center">
    <div class="row mb-3">
      <div class="col-md-12 text-center">
        <h3>Mission Statement</h3>
        <h2 class="yellow-text">
          To honor the accomplishments of women pioneers in the field of behavior analysis and <br />
          create a historic record to educate behavior analysts on their contributions.
        </h2>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-6">
        <h4 class="section-header">Criteria:</h4>
        <ul class="text-left yellow-text fa-ul">
           <li><span class="fa-li"><i class="fas fa-star"></i></span>Have 40+ years of experience in the field, potentially retired or semi-retired</li>
           <li><span class="fa-li"><i class="fas fa-star"></i></span>Awards and accolades, scholarly contributions, teaching, mentoring and leadership positions all taken into consideration</li>
           <li><span class="fa-li"><i class="fas fa-star"></i></span>Inductees will be selected upon their ability to serve as a role model for the next generation of behavior analysts</li>
        </ul>
      </div>
      <div class="col-md-6">
        <h4 class="section-header">Selection Committee:</h4>
        <ul class="text-left yellow-text fa-ul">
           <li><span class="fa-li"><i class="fas fa-star"></i></span>Have 40+ years of experience in the field, potentially retired or semi-retired</li>
           <li><span class="fa-li"><i class="fas fa-star"></i></span>Awards and accolades, scholary contributions, teaching, mentoring and leadership positions all taken into consideration</li>
           <li><span class="fa-li"><i class="fas fa-star"></i></span>Inductees will be selected upon their ability to serve as a role model for the next generation of behavior analysts</li>
        </ul>
      </div>
    </div>
  </div>
</div>
