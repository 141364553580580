<div class="card">
  <!-- Header -->
  <header class="card-header bg-light py-3 px-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 *ngIf="event.showQuestions" class="h6 mb-0">{{ 'questions-for-instructor' | translate }}</h3>
      <h3 *ngIf="event.showRequests" class="h6 mb-0">Song Requests</h3>

      <button *ngIf="showInput" type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </header>
  <!-- End Header -->

  <!-- Body -->
  <div style="position: relative">
    <div *ngIf="loadError || loading" style="z-index: 9999; position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-content: center; align-items: center">
      <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; opacity: 0.6; background-color: black"></div>
      <div *ngIf="!loading" style="z-index: 10000">
        <div class="text-light">Failed to load questions</div>
        <div class="text-center"><button type="button" (click)="reload()" class="btn btn-light">Try again</button></div>
      </div>
      <page-load-header *ngIf="loading"></page-load-header>
    </div>
    <div class="card-body" *ngIf="techSupport">
      <div class="participant-list">
        <div>
          <h5 class="text-center">Technical Support</h5>
          <div class="alert alert-warning">Some events can be very large. Please look over these common solutions before you chat our team.</div>

          <ul class="text-prechat">
            <li>
              <div class="d-block">Stream is not playing</div>
              Most of the time a simple <a href="javascript:void(0)" (click)="refresh()">refresh</a> of your browser will fix this. Sometimes you need to click the play icon on the video player. You can also try <a href="javascript:void(0)" (click)="toggleCompatibility()">compatibily mode</a> if that doesn't work!
            </li>
            <li>
              <div class="d-block">How do I get CEUs</div>
              Click 3 out of the 4 attendance checks that show randomly. Once the event is over, you can add a review and get your certificate in
              <a *ngIf="event.conferenceId" target="_blank" [routerLink]="['/account/ceus/conference-ceus', event.conferenceId, 'event-summaries']">CEU History</a>
              <a *ngIf="!event.conferenceId" target="_blank" [routerLink]="['/account/ceus/individual-ceus', event.id]">CEU History</a>
            </li>
            <li>
              <div class="d-block">I missed a CEU check</div>
              No worries! If you are here, you will get CEUs. You can resolve problems AFTER the event in
              <a *ngIf="event.conferenceId" target="_blank" [routerLink]="['/account/ceus/conference-ceus', event.conferenceId, 'event-summaries']">CEU History</a>
              <a *ngIf="!event.conferenceId" target="_blank" [routerLink]="['/account/ceus/individual-ceus', event.id]">CEU History</a>
            </li>
            <li>
              <div class="d-block">Add BACB #</div>
              If you haven't yet, you can add your BACB number in
              <a target="_blank" [routerLink]="['/account/ceus/certifications']"> My Account</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div #questionContainer class="card-body" style="" *ngIf="!techSupport">
      <span class="ml-3 mr-2 mb-2"
        ><a role="button" class="text-primary cursor-pointer" [ngClass]="{ selected: !answered }" (click)="answered = false"
          ><span *ngIf="event.showQuestions">{{ 'un-answered' | translate }}</span
          ><span *ngIf="event.showRequests">Un-Played</span></a
        ></span
      >
      <span class="mr-2 mb-2"
        ><a role="button" class="text-primary cursor-pointer" [ngClass]="{ selected: answered }" (click)="answered = true"
          ><span *ngIf="event.showQuestions">{{ 'answered' | translate }}</span
          ><span *ngIf="event.showRequests">Played</span></a
        ></span
      >

      <ng-container *ngFor="let message of questions">
        <div *ngIf="message && message?.answered == answered" class="participant-list" [ngClass]="{ 'selected': message.selected && role != EventRole.Viewer }"  >
          <div>
            <div class="row">
              <div class="col-md-2 text-center">
                <button *ngIf="message.user.id != user.id && !message.answered" type="button" (click)="vote(message.id)" class="btn btn-icon transition-3d-hover" style="font-size: 3rem; height: 2.5rem; margin-top: -20px">
                  <span [ngClass]="{ 'text-primary': message.currentUserDidVote }" class="fas fa-caret-up"></span>
                </button>
                <span class="d-block h5 mb-0">{{ message.questionVotes.length }}</span>
                <span class="text-muted"> {{ 'votes' | translate }}</span>
              </div>
              <div class="col-md-10">
                <span class="d-block">{{ message.question }}</span>
                <span class="d-block">
                  <div class="d-flex align-items-center pb-1">
                    <div class="mr-auto">
                      <img  class="avatar u-sm-avatar rounded-circle" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ message.user.id }}-avatar.png" />
                      <span class="participant-name ml-2 text-muted">{{ message.user.firstName + ' ' + message.user.lastName }} </span>
                    </div>
                    <span *ngIf="role == EventRole.Host && showInput" class="participant-menu ng-star-inserted">
                      <button class="btn btn-icon text-muted transition-3d-hover" (click)="inviteUserOnScreen(message.user)" placement="top" show-delay="250" tooltip="Invite user on screen" type="button">
                        <span class="fas fa-user-plus btn-icon__inner"></span>
                      </button>
                    </span>
                  </div>
                </span>
                <div *ngIf="role != EventRole.Viewer" class="d-flex p-0 justify-content-between ng-star-inserted">
                  <div>
                    <a *ngIf="!message.answered" role="button" class="text-primary cursor-pointer" (click)="markAnswered(message.id)">{{ 'mark-answered' | translate }}</a>
                    <a *ngIf="message.answered" role="button" class="text-primary cursor-pointer" (click)="reopenQuestion(message.id)">{{ 'reopen-question' | translate }}</a>

                  </div>
                  <a  role="button" class="text-primary cursor-pointer" (click)="select(message)">Select</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Footer -->
    <div class="card-footer d-flex justify-content-end" *ngIf="showInput">
      <div class="input-group d-flex justify-content-around" *ngIf="!preTest">
        <button type="button" class="btn btn-admin-primary btn-sm" (click)="preTest = true">Content Question</button>
        <button type="button" class="btn btn-admin-primary btn-sm" (click)="showTechSupport()">Technical/CEU/Help</button>
      </div>
      <div class="input-group" *ngIf="preTest">
        <input *ngIf="event.showQuestions" type="text" class="form-control" [(ngModel)]="question" (keyup.enter)="sendQuestion()" placeholder="{{ 'ask-instructor-a-question' | translate }}" aria-label="" aria-describedby="button-addon2" />
        <input *ngIf="event.showRequests" type="text" class="form-control" [(ngModel)]="question" (keyup.enter)="sendQuestion()" placeholder="Request a song..." aria-label="" aria-describedby="button-addon2" />
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" (click)="sendQuestion()" id="button-addon2">
            <span *ngIf="event.showQuestions">{{ 'ask-instructor' | translate }}</span
            ><span *ngIf="event.showRequests">Request Song</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Footer -->
</div>
