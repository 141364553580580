import { Directive, Input, HostBinding } from '@angular/core';
@Directive({
  selector: 'img[fallback]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src',
  },
})
export class ImageFallbackDirective {
  @Input() src: string;
  @Input() fallback: string;
  @HostBinding('class') @Input('class') classList: string = '';

  updateUrl() {
    this.src = this.fallback;
  }
  load() {
    this.classList = this.classList + ' '+ 'image-loaded';
  }
}
