<ng-container *ngIf="person == 'bea'">
  <ng-container *ngIf="page == 'home'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3">
          <div class="col-md-7 d-flex align-items-center">
            <div>
              <h3 style="transform: rotate(-20deg)" class="mb-6"><i>Honoring...</i></h3>
              <h1 class="yellow-text">Bea Barrett</h1>
              <h3 class=""><span class="d-block">2021</span> <i>Women in Behavior Analysis Hall of Fame Inductee</i></h3>
            </div>
          </div>
          <div class="col-md-5 container-height" style="background-image: url('../assets/wiba-hof/bea-large.jpg'); background-repeat: no-repeat; background-size: cover"></div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'contributions'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3">
          <div class="col-md-8 container-height">
            <h1 class="yellow-text">Beatrice H. Barrett</h1>
            <h3 class="ml-auto text-right">A True Pioneer</h3>
            <ul class="text-left fa-ul">
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Laboratory study of free-operant human behavior
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Integration of basic laboratory analysis and applied behavioral science
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Seminal laboratory work on processes of discrimination and differentiation in individuals with developmental delays
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Unwavering proponent of response rate measures for clinical and experimental work
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Focus on identifying individual differences and using data for diagnosis and prescription in clinical assessment and evaluation
              </li>
            </ul>
          </div>

          <div class="col-md-4">
            <h3 class="yellow-text">(1929 - 2003)</h3>
            <img src="../assets/wiba-hof/bea-alt.jpg" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'milestones'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/bea-timeline.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'legacy' && !sub">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/bea-legacy.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 1 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && sub == 1">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/bea-legacy2.png" class="img-fluid" alt="" />
          </div>

          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 2 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
          <div [routerLink]="['.']" [queryParams]="{}" class="yellow-text" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && sub == 2">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/bea-legacy3.png" class="img-fluid" alt="" />
          </div>

          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 3 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
          <div [routerLink]="['.']" [queryParams]="{ sub: 1 }" class="yellow-text" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && sub == 3">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/bea-legacy4.png" class="img-fluid" alt="" />
          </div>

          <div [routerLink]="['.']" [queryParams]="{ sub: 2 }" class="yellow-text" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'publications' && !sub">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/publications.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 1 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'publications' && sub == 1">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/publications2.png" class="img-fluid" alt="" />
          </div>
          <div [routerLink]="['.']" [queryParams]="{}" class="yellow-text" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- MARTHA -->
<!-- MARTHA -->
<!-- MARTHA -->
<!-- MARTHA -->
<!-- MARTHA -->
<!-- MARTHA -->
<!-- MARTHA -->

<ng-container *ngIf="person == 'martha'">
  <ng-container *ngIf="page == 'home'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3">
          <div class="col-md-7 d-flex align-items-center">
            <div>
              <h3 style="transform: rotate(-20deg)" class="mb-6"><i>Honoring...</i></h3>
              <h1 class="yellow-text">Martha Bernal</h1>
              <h3 class=""><span class="d-block">2021</span> <i>Women in Behavior Analysis Hall of Fame Inductee</i></h3>
            </div>
          </div>
          <div class="col-md-5 container-height" style="background-image: url('../assets/wiba-hof/martha-alt.png'); background-repeat: no-repeat; background-size: cover"></div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'contributions' && !sub">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3">
          <div class="col-md-8 container-height">
            <h1 class="yellow-text">Martha Bernal</h1>
            <ul class="text-left fa-ul">
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>First Latina to receive a Ph.D. in psychology in the United States and first woman of Mexican descent to receive a doctorate in clinical psychology
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Contributed significantly to the advancement of multicultural psychology through research, service, mentoring, and advocacy
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Promoted the development of a multicultural psychology that valued diversity in education and research.
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Developed empirically validated assessments and interventions that improved the evaluation and treatment of children with behavior problems
              </li>
            </ul>
          </div>

          <div class="col-md-4">
            <h3 class="yellow-text">(1931-2001)</h3>
            <img src="../assets/wiba-hof/martha-alt2.jpg" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 1 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'contributions' && sub == 1">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3">
          <div class="col-md-8 container-height">
            <h1 class="yellow-text">Martha Bernal</h1>
            <ul class="text-left fa-ul">
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Highlighted the lack of psychologists and graduate students of color, and made recommendations to address the problem.
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Received NIMH award to study the training of clinical psychologists for work with diverse groups
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Provided significant leadership to organizations that supported multicultural psychology including the Board of Ethnic Minority Affairs (BEMA). the National Latino/a Psychological Association, and APA's Commission on Ethnic, Minority Recruitment, Retention, and Training (CEMRRAT)
              </li>
              <li class="mt-5">
                <span class="fa-li"><i class="yellow-text fas fa-star"></i></span>Recipient of multiple lifetime achievement awards for her contributions to multicultural psychology
              </li>
            </ul>
          </div>

          <div class="col-md-4">
            <h3 class="yellow-text">(1931-2001)</h3>
            <img src="../assets/wiba-hof/martha-book.jpg" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{}" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'milestones'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/martha-timeline.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'legacy' && !sub">
    <div class="hof-page-wrapper">
      <div class="hof-title mb-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/martha-legacy.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 1 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && sub == 1">
    <div class="hof-page-wrapper">
      <div class="hof-title mb-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/martha-legacy2.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{}" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'publications'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/martha-publications.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<!-- EVALYN -->
<!-- EVALYN -->
<!-- EVALYN -->
<!-- EVALYN -->
<!-- EVALYN -->
<!-- EVALYN -->
<!-- EVALYN -->
<!-- EVALYN -->
<!-- EVALYN -->
<!-- EVALYN -->

<ng-container *ngIf="person == 'evalyn'">
  <ng-container *ngIf="page == 'home'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3">
          <div class="col-md-7 d-flex align-items-center">
            <div>
              <h3 style="transform: rotate(-20deg)" class="mb-6"><i>Honoring...</i></h3>
              <h1 class="yellow-text">Evalyn F. Segal</h1>
              <h3 class=""><span class="d-block">2021</span> <i>Women in Behavior Analysis Hall of Fame Inductee</i></h3>
            </div>
          </div>
          <div class="col-md-5 container-height" style="background-image: url('../assets/wiba-hof/evalyn.jpg'); background-repeat: no-repeat; background-size: cover"></div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'contributions' && !sub">
    <div class="hof-page-wrapper">
      <div class="hof-title mb-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/eve-contributions.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 1 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'contributions' && sub == 1">
    <div class="hof-page-wrapper">
      <div class="hof-title mb-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/eve-contributions2.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{}" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'milestones'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/eve-timeline.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'legacy' && !sub">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/eve-legacy.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 1 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && sub == 1">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/eve-legacy2.png" class="img-fluid" alt="" />
          </div>

          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 2 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
          <div [routerLink]="['.']" [queryParams]="{}" class="yellow-text" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && sub == 2">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/eve-legacy3.png" class="img-fluid" alt="" />
          </div>

          <div [routerLink]="['.']" [queryParams]="{ sub: 1 }" class="yellow-text" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'publications'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/eve-publications.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<!-- JUDITH -->
<!-- JUDITH -->
<!-- JUDITH -->
<!-- JUDITH -->
<!-- JUDITH -->
<!-- JUDITH -->
<!-- JUDITH -->
<ng-container *ngIf="person == 'judith'">
  <ng-container *ngIf="page == 'home'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3">
          <div class="col-md-7 d-flex align-items-center">
            <div>
              <h3 style="transform: rotate(-20deg)" class="mb-6"><i>Honoring...</i></h3>
              <h1 class="yellow-text">Judith E. Favell</h1>
              <h3 class=""><span class="d-block">2021</span> <i>Women in Behavior Analysis Hall of Fame Inductee</i></h3>
            </div>
          </div>
          <div class="col-md-5 container-height" style="background-image: url('../assets/wiba-hof/judy.jpg'); background-repeat: no-repeat; background-size: cover"></div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="page == 'contributions' && !sub">
    <div class="hof-page-wrapper">
      <div class="hof-title mb-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/judith-contributions.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 1 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'contributions' && sub == 1">
    <div class="hof-page-wrapper">
      <div class="hof-title mb-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/judith-contributions2.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{}" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'milestones'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/judith-timeline.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'publications'">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/judith-publications.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && !sub">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/judith-legacy.png" class="img-fluid" alt="" />
          </div>
          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 1 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && sub == 1">
    <div class="hof-page-wrapper">
      <div class="hof-title my-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/judith-legacy2.png" class="img-fluid" alt="" />
          </div>

          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 2 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
          <div [routerLink]="['.']" [queryParams]="{}" class="yellow-text" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && sub == 2">
    <div class="hof-page-wrapper">
      <div class="hof-title mb-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/judith-legacy3.png" class="img-fluid" alt="" />
          </div>

          <div class="yellow-text" [routerLink]="['.']" [queryParams]="{ sub: 3 }" style="position: absolute; right: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-right"></i>
          </div>
          <div [routerLink]="['.']" [queryParams]="{ sub: 1 }" class="yellow-text" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page == 'legacy' && sub == 3">
    <div class="hof-page-wrapper">
      <div class="hof-title mb-auto mx-auto text-white text-center">
        <div class="row mb-3" style="overflow: hidden">
          <div class="col-md-12">
            <img src="../assets/wiba-hof/judith-legacy4.png" class="img-fluid" alt="" />
          </div>

          <div [routerLink]="['.']" [queryParams]="{ sub: 2 }" class="yellow-text" style="position: absolute; left: 20px; bottom: 70px; font-size: 3rem">
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
